import React from 'react';
import PropTypes from 'prop-types';
import ScoringModalData from './ScoringModalData';
import StatemachineModalData from './StatemachineModalData';
import TracingModalData from './TracingModalData';
import ManageBookmarksData from './ManageBookmarksData';
import ModalDialog from './ModalDialog';

export { ModalDialog };
export const SCORING = 'Scoring';
export const TRACING = 'Tracing';
export const STATE_MACHINE = 'State Machine';
export const BOOKMARKS = 'ManageBookmarks';

const ModalManager = (props) => {
  const { currentModalContent: content } = props;
  switch (props.currentModal) {
    case SCORING:
      return <ScoringModalData {...props} />;
    case TRACING:
      return <TracingModalData {...props} />;
    case STATE_MACHINE:
      return <StatemachineModalData {...props} />;
    case BOOKMARKS:
      return <ManageBookmarksData content={content} />;
    default:
      return null;
  }
};

export default ModalManager;

ModalManager.propTypes = {
  currentModalContent: PropTypes.object.isRequired,
  currentModal: PropTypes.oneOf([SCORING, TRACING, STATE_MACHINE, BOOKMARKS]).isRequired
}
