import React from 'react';
import PropTypes from 'prop-types';


const StatemachineModalData = (props) => {

  const { currentModalContent: statemachineDetails } = props;
  return (
    <div className="cba-modal-content">
      <div className="detailsWrapper">
        <table className="details">
          <thead>
            <tr>
              <th>Variable Name</th>
              <th>Current Value</th>
            </tr>
          </thead>
          <tbody>
            {statemachineDetails.variables.map(row => (
              <tr key={row.name}>
                <td>{row.name}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p />
        <table className="details">
          <thead>
            <tr>
              <th>Current State Name</th>
            </tr>
          </thead>
          <tbody>
            {statemachineDetails.states.map(row => (
              <tr key={row}>
                <td>{row}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p />
        <table className="details">
          <thead>
            <tr>
              <th>Visited State Name</th>
            </tr>
          </thead>
          <tbody>
            {statemachineDetails.visitedStates.map(row => (
              <tr key={row}>
                <td>{row}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p />
        <table className="details">
          <thead>
            <tr>
              <th>Raised Event Name</th>
            </tr>
          </thead>
          <tbody>
            {statemachineDetails.raisedEvents.map(row => (
              <tr key={row}>
                <td>{row}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

StatemachineModalData.propTypes = {
  currentModalContent: PropTypes.shape(
    {
      variables: PropTypes.array.isRequired,
      states: PropTypes.array.isRequired,
      visitedStates: PropTypes.array.isRequired,
      raisedEvents: PropTypes.array.isRequired,
    }
  ).isRequired
}

export default StatemachineModalData;
