import React from 'react';
import PropTypes from 'prop-types';
import MathJaxTranspiler from '../MathJax/MathJaxTranspiler'
/**
 * A react component used by the CbaRichTextField to display Mathematical expressions via MathJax.
 */
class MathJaxEntity extends React.Component {

  getTex = () => {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData().tex;
  }

  render() {
    return (
      <span suppressContentEditableWarning="true" contentEditable="false">
        <MathJaxTranspiler tex={this.getTex()} />
      </span>
    );
  }

}

const findMathJaxEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'TEX'
      );
    },
    callback
  );
}

MathJaxEntity.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
}

export { findMathJaxEntities, MathJaxEntity };
