/**
 * A catalog of components. 
 * 
 * Components are identified by their index path. 
 * 
 * While the different instances of this catalog could be used 
 * we currently have a single instance of it only that 
 * keeps a catalog of all currently rendering components.
 */
export default class ComponentDirectory {

  constructor() {
    this.componentList = {};

    this.clear = this.clear.bind(this);
    this.registerComponent = this.registerComponent.bind(this);
    this.deregisterComponent = this.deregisterComponent.bind(this);
    this.findComponent = this.findComponent.bind(this);
  }

  clear() {
    this.componentList = {};
  }

  registerComponent(path, component) {
    this.componentList[path] = component;
  }

  deregisterComponent(path) {
    this.componentList[path] = undefined;
  }

  findComponent(path) {
    return this.componentList[path];
  }

  findByComponentType(componentType) {
    return Object.values(this.componentList).filter(c => c instanceof componentType);
  }

  /**
   * don't use this method in constructor, because page isn't loaded yet, hence an empty array will be return
   * @param {*} pageName 
   */
  findComponentsByPageName(pageName) {
    return Object.keys(this.componentList).map((path) => {
      if (path.indexOf(`page=${pageName}`) !== -1) {
        return this.componentList[path];
      }
      return undefined;
    }).filter(x => !!x);
  }

  findSubTreeComponentsWithinAPage(pageName, treeInstnancesUserDefinedIds) {
    const pageComponents = this.findComponentsByPageName(pageName);
    const treeComnOnPage = [];
    for (let i = 0, len = treeInstnancesUserDefinedIds.length; i < len; i += 1) {
      for (let j = 0, lenComp = pageComponents.length; j < lenComp; j += 1) {
        if (pageComponents[j].props.config.userDefinedId === treeInstnancesUserDefinedIds[i]) {
          treeComnOnPage.push(pageComponents[j]);
        }
      }
    }
    return treeComnOnPage;
  }


  findTreeViewComponentsWithinAPage(pageName, treeInstnancesUserDefinedIds, treeChildAreaIdToIgnore) {
    const cbaTrees = this.findSubTreeComponentsWithinAPage(pageName, treeInstnancesUserDefinedIds);
    const subTreeIds = cbaTrees[0].props.config.treeInstnancesUserDefinedIds;
    const subTreeViewIds = subTreeIds.filter(comp => comp !== treeChildAreaIdToIgnore);
    const pageComponents = this.findComponentsByPageName(pageName);

    const treeViewComnOnPage = [];
    for (let i = 0, len = subTreeViewIds.length; i < len; i += 1) {
      for (let j = 0, lenComp = pageComponents.length; j < lenComp; j += 1) {
        if (pageComponents[j].props.config.userDefinedId === subTreeViewIds[i]) {
          treeViewComnOnPage.push(pageComponents[j]);
        }
      }
    }

    return treeViewComnOnPage;
  }


}
