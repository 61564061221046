import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import { EventEmitter } from 'fbemitter';
import moment from 'moment';
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import App from './App';
import ComponentStateManager from '../state/ComponentStateManager';
import ComponentDirectory from '../state/ComponentDirectory';
import IncidentsAccumulator from '../state/IncidentsAccumulator';
import TaskManager from '../state/TaskManager';
import TaskResultsManager from '../state/TaskResultsManager';
import StatemachinesManager from '../state/StatemachinesManager';
import PresenterStateManager from '../state/PresenterStateManager';
import TaskNavigatorStateManager from '../state/TaskNavigatorStateManager';
import TraceLogBuffer from '../state/TraceLogBuffer';
import TraceLogHelper from '../state/TraceLogHelper';
import ClipboardManager from '../state/ClipboardManager';
import SearchManager from '../state/SearchManager';
import TraceLogUploader from '../transmit/TraceLogUploader';
import PostMessageReceiver from '../transmit/PostMessageReceiver';
import RecommendationsManager from '../state/RecommendationsManager';
import CalculatorsManager from '../state/CalculatorsManager';
import Utils from '../utils/Utils';
import '../utils/polyfills';
import ActionRegister from '../state/ActionRegister';
import FocusRegister from '../state/FocusRegister';
import CustomDragLayer from '../config/CustomDragLayer';
import RecordingBuffer from '../state/RecordingBuffer';
import RecordingUploader from '../transmit/RecordingUploader';
import ServerCalls from '../controller/ServerCalls';
import LibraryManager from '../state/LibraryManager';

/**
 * Entry point of task player layer.
 */
export default class TaskPlayer {

  // ---------- public API ---------------------------------------------------------------------

  /**
   * Build a task player. 
   * 
   * The new task player will start to listen for postMessage events. 
   * You may configure and start the player via postMessage events or 
   * via the public API instance methods.
   */
  constructor(eventTargetWindow, eventDomainUri) {
    this.runtime = TaskPlayer.buildRuntime();
    this.runtime.postMessageReceiver.startReceiving(this.runtime, this);
    this.eventTargetWindow = eventTargetWindow === undefined ? 'self' : eventTargetWindow;
    this.eventDomainUri = eventDomainUri === undefined ? Utils.getCallingUrlWithoutPath() : eventDomainUri;

    this.apiState = 'appNotRunning';

    this.settings = {
      debugScoreHotKey: undefined,
      debugTraceHotKey: undefined,
      debugStatemachineHotKey: undefined,
      ShowTaskNavigationBars: false
    };

    this.headerButtons = [];
    this.courseForNavigator = [];
    this.testsForNavigator = [];
  }

  /**
   * Run the task player, i.e. make it display the App component.
   */
  runPlayer = () => {
    Utils.printCbaVersion();
    this.apiState = 'notLoggedIn';
    const runtimer = this.runtime;

    // Use drag&drop technique adapted to screen type of the target device:
    // - use specific touch backend for DnD library to capture touch events
    // - use custom made drag layer for touch backend (html5 backend uses drag layer provided by browser)
    const isTouchDevice = Utils.isTouchDevice()
    ReactDOM.render(
      <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
        <div>
          <App
            runtime={runtimer}
          />
          {isTouchDevice && <CustomDragLayer />}
        </div>
      </DndProvider>,
      document.getElementById('root')
    );
  }

  // ------- Initialization ------------------------------------------------------------------------
  sendPlayerReady = () => {
    if (this.checkNotRunning) {
      this.runtime.postMessageReceiver.sendTaskPlayerReadyEvent(this.eventDomainUri, this.eventTargetWindow);
    }
  }


  // ------- Configuration Control -----------------------------------------------------------------------
  addItem = (itemConfig, resourcePath, externalResourcePath, libraryPathsMap) => {
    if (this.checkNotLoggedInOrNoTaskRunning('addItem')) {
      const itemConfigVersion = itemConfig.runtimeCompatibilityVersion;
      if (TaskPlayer.isCompatibleVersion(itemConfigVersion)) {
        this.runtime.taskManager.addItem(itemConfig, resourcePath, externalResourcePath);
        this.runtime.libraryManager.addLibrariesForItem(itemConfig.dependencies, libraryPathsMap);
      } else {
        console.info(`Ignored item ${itemConfig.name} with incompatible version: ${itemConfigVersion}`);
      }
    }
  }

  clearItems = () => {
    if (this.checkNotLoggedInOrNoTaskRunning('clearItems')) {
      this.runtime.taskManager.clearItems();
    }
  }

  /**
   * We return a Promise that can execute the preload and wait for the results.
   * 
   * The caller must trigger the Promise and provide the success/failure handlers.
   * 
   * @param {String} itemName 
   */
  setPreload = itemName => (
    this.checkNotLoggedInOrNoTaskRunning('setPreload')
      ? this.preloadItemResources(itemName)
      : undefined
  );

  // ------- Trace Control -----------------------------------------------------------------------

  insertMessageInTrace = (message) => {
    if (this.checkNoTaskRunningOrTaskRunning('insertMessageInTrace')) {
      TaskPlayer.dumpMessageToTraceLog(message, this.runtime.traceLogBuffer);
    }
  }

  logStateToTrace = () => {
    if (this.checkNoTaskRunningOrTaskRunning('logStateToTrace')) {
      TraceLogHelper.dumpSnapshotToTrace(this.runtime);
    }
  }

  flushTrace = () => {
    if (this.checkNoTaskRunningOrTaskRunning('flushTrace')) {
      this.runtime.traceLogUploader.collectEntriesAndTriggerTransmission();
    }
  }

  setCallbackTraceTransmission = (callback, interval) => {
    if (this.checkNotLoggedIn('setCallbackTraceTransmission')) {
      this.runtime.traceLogUploader.setCallbackTransmissionChannel(callback, interval)
    }
  }

  setHttpTraceTransmission = (transmitUrl, interval, httpTimeout) => {
    if (this.checkNotLoggedIn('setHttpTraceTransmission')) {
      this.runtime.traceLogUploader.setHttpTransmissionChannel(transmitUrl, interval, httpTimeout);
    }
  }

  setConsoleTraceTransmission = (interval) => {
    if (this.checkNotLoggedIn('setConsoleTraceTransmission')) {
      this.runtime.traceLogUploader.setConsoleTransmissionChannel(interval);
    }
  }

  setTraceContextId = (contextId) => {
    if (this.checkNotLoggedInOrNoTaskRunning('setTraceContextId')) {
      const { traceLogUploader } = this.runtime;
      const { username, loginTimestamp } = traceLogUploader.getSessionContext();
      traceLogUploader.setSessionContext(contextId, username, loginTimestamp);
    }
  }

  // ------- Recordings Control -----------------------------------------------------------------------

  setCallbackRecordingTransmission = (callback) => {
    if (this.checkNotLoggedIn('setCallbackRecordingTransmission')) {
      this.runtime.recordingUploader.setCallbackTransmissionChannel(callback, undefined);
    }
  }

  setHttpRecordingTransmission = (transmitUrl, httpTimeout) => {
    if (this.checkNotLoggedIn('setHttpRecordingTransmission')) {
      this.runtime.recordingUploader.setHttpTransmissionChannel(transmitUrl, undefined, httpTimeout);
    }
  }

  setConsoleRecordingTransmission = () => {
    if (this.checkNotLoggedIn('setConsoleRecordingTransmission')) {
      this.runtime.recordingUploader.setConsoleTransmissionChannel(undefined);
    }
  }

  setRecordingContextId = (contextId) => {
    if (this.checkNotLoggedInOrNoTaskRunning('setRecordingContextId')) {
      const { recordingUploader } = this.runtime;
      const { username, loginTimestamp } = recordingUploader.getSessionContext();
      recordingUploader.setSessionContext(contextId, username, loginTimestamp);
    }
  }

  // ------- User Control -----------------------------------------------------------------------
  setUserId = (id) => {
    if (this.checkNotLoggedIn('setUserId')) {
      const timestamp = moment().format();
      const { traceLogUploader, recordingUploader, traceLogBuffer } = this.runtime;
      const { sessionId: traceSessionId } = traceLogUploader.getSessionContext();
      traceLogUploader.setSessionContext(traceSessionId, id, timestamp);
      const { sessionId: recordingSessionId } = recordingUploader.getSessionContext();
      recordingUploader.setSessionContext(recordingSessionId, id, timestamp);
      TaskPlayer.dumpLoginToTraceLog(id, timestamp, traceLogBuffer);
      this.apiState = 'noTaskRunning';
    }
  }

  logout = () => {
    if (this.checkNoTaskRunning('logout')) {
      const { traceLogUploader, componentStateManager } = this.runtime;

      // Flush all pending trace messages before we drop the user id:
      traceLogUploader.collectEntriesAndTriggerTransmission();

      const { sessionId } = traceLogUploader.getSessionContext();
      traceLogUploader.setSessionContext(sessionId, undefined, undefined);
      componentStateManager.clear();
      this.apiState = 'notLoggedIn';
    }
  }

  getUserId = () => (this.apiState === 'notLoggedIn'
    ? undefined
    : this.runtime.traceLogUploader.getSessionContext().username
  );

  showLogin = (titleLabel, fieldLabel, buttonLabel, fieldValueCallback) => {
    if (this.checkNotLoggedInOrNoTaskRunning('showLogin')) {
      const { app } = this.runtime;
      app.showLogin(titleLabel, fieldLabel, buttonLabel, fieldValueCallback);
    }
  }

  // ------- Task Control -----------------------------------------------------------------------
  startTask = (scope, item, task) => {
    if (this.checkNoTaskRunning('startTask')) {
      const { app } = this.runtime;
      app.showTask(scope, item, task, this.settings, this.headerButtons, this.courseForNavigator, this.testsForNavigator);
      this.apiState = 'taskRunning';
    }
  }

  stopTask = () => {
    if (this.checkTaskRunning('stopTask')) {
      const { app } = this.runtime;
      app.showWaiting();
      this.apiState = 'noTaskRunning';
    }
  }

  pauseTask = () => {
    if (this.checkTaskRunning('pauseTask')) {
      TaskPlayer.pauseOrResume(true, this.runtime);
    }
  }

  resumeTask = () => {
    if (this.checkTaskRunning('resumeTask')) {
      TaskPlayer.pauseOrResume(false, this.runtime);
    }
  }

  getTask = () => {
    if (this.apiState === 'taskRunning') {
      const { taskManager } = this.runtime;
      const { test, item, task } = taskManager.getCurrentTestTaskItemNames();
      return {
        scope: test,
        item,
        task
      }
    } else {
      return undefined;
    }
  }

  setTaskSequencer = (switchCallback, availabilityCallback) => {
    if (this.checkNoTaskRunning('setTaskSequencer')) {
      const { taskManager } = this.runtime;
      taskManager.setSwitchCallback(switchCallback);
      taskManager.setAvailabilityCallback(availabilityCallback);
    }
  }

  // ------- Task State Control ----------------------------------------------------------------

  getTasksState = () => {
    if (this.checkNoTaskRunningOrTaskRunning('getTasksState')) {
      return this.runtime.taskManager.getAllTasksState();
    } else {
      return undefined;
    }
  }

  clearTasksState = () => {
    if (this.checkNoTaskRunning('clearTasksState')) {
      this.runtime.taskManager.clearTasksState();
    }
  }

  preloadTasksState = (state) => {
    if (this.checkNoTaskRunning('preloadTasksState')) {
      this.runtime.taskManager.preloadTasksState(state);
      TraceLogHelper.dumpSnapshotToTrace(this.runtime);
    }
  }

  // ------- Scoring Control ----------------------------------------------------------------

  getScoringResult = () => {
    if (this.checkTaskRunning('getScoringResult')) {
      const { taskManager } = this.runtime;
      return taskManager.getCurrentTaskResults();
    } else {
      return undefined;
    }
  }

  // ------- Statemachine Control --------------------------------------------------------------
  sendStatemachineEvent = (event) => {
    if (this.checkTaskRunning('sendStatemachineEvent')) {
      const { statemachinesManager, traceLogBuffer } = this.runtime;
      TaskPlayer.dumpStatemachineEventToTraceLog(event, traceLogBuffer);
      statemachinesManager.triggerEvent(event);
    }
  }


  // ------- Header Control --------------------------------------------------------------------
  setHeaderButtons = (headerButtons) => {
    if (this.checkNotLoggedInOrNoTaskRunning('setHeaderButtons')) {
      this.headerButtons = headerButtons;
    }
  }

  setMenuCarousels = (course, scopes) => {
    if (this.checkNotLoggedInOrNoTaskRunning('setMenuCarousels')) {
      this.settings.ShowTaskNavigationBars = course.length > 0;
      this.courseForNavigator = course;
      this.testsForNavigator = scopes.map(scope => ({
        name: scope.name,
        taskCourse: scope.tasks
      }));
    }
  }

  // ------- Developer Mode Control ------------------------------------------------------------
  activateDebuggingWindows = (score, trace, statemachine) => {
    if (this.checkNotLoggedInOrNoTaskRunning('activateDebuggingWindows')) {
      this.settings.debugScoreHotKey = TaskPlayer.normalizeHotKeySpecification(score, 'scoring');
      this.settings.debugTraceHotKey = TaskPlayer.normalizeHotKeySpecification(trace, 'trace');
      this.settings.debugStatemachineHotKey = TaskPlayer.normalizeHotKeySpecification(statemachine, 'state machine');
    }
  }

  // ---------- private stuff ------------------------------------------------------------------


  static buildRuntime() {
    const traceLogBuffer = new TraceLogBuffer();
    const recordingBuffer = new RecordingBuffer();
    const componentDirectory = new ComponentDirectory();
    const statemachinesManager = new StatemachinesManager();
    const result = {
      componentStateManager: new ComponentStateManager(),
      componentDirectory,
      incidentsAccumulator: new IncidentsAccumulator(),
      taskResultsManager: new TaskResultsManager(),
      statemachinesManager,
      presenterStateManager: new PresenterStateManager(),
      taskNavigatorStateManager: new TaskNavigatorStateManager(),
      traceLogBuffer,
      traceLogUploader: new TraceLogUploader(traceLogBuffer),
      recordingBuffer,
      recordingUploader: new RecordingUploader(recordingBuffer),
      eventEmitter: new EventEmitter(),
      clipboardManager: new ClipboardManager(traceLogBuffer),
      searchManager: new SearchManager(componentDirectory, statemachinesManager, traceLogBuffer),
      postMessageReceiver: new PostMessageReceiver(),
      calculatorsManager: new CalculatorsManager(),
      actionRegister: new ActionRegister(),
      focusRegister: new FocusRegister(),
      libraryManager: new LibraryManager()
    }
    result.recommendationsManager = new RecommendationsManager(result);
    result.taskManager = new TaskManager(result);

    return result;
  }

  checkNotRunning = action => this.checkApiState(['appNotRunning'], action);

  checkNotLoggedIn = action => this.checkApiState(['notLoggedIn'], action);

  checkNoTaskRunning = action => this.checkApiState(['noTaskRunning'], action);

  checkTaskRunning = action => this.checkApiState(['taskRunning'], action);

  checkNotLoggedInOrNoTaskRunning = action => this.checkApiState(['notLoggedIn', 'noTaskRunning'], action);

  checkNoTaskRunningOrTaskRunning = action => this.checkApiState(['noTaskRunning', 'taskRunning'], action);

  checkApiState = (acceptedList, action) => {
    const result = acceptedList.includes(this.apiState);
    if (!result) {
      console.info(`TaskPlayer API call ${action} denied in state ${this.apiState}`);
    }
    return result
  }

  static normalizeHotKeySpecification(hotKeySpecification, windowNameForErrorMessage) {
    if (hotKeySpecification === undefined || hotKeySpecification === "") {
      return undefined;
    }
    const withoutCtrl = hotKeySpecification.startsWith('ctrl+') ? hotKeySpecification.substring(5) : hotKeySpecification;
    const withoutShift = withoutCtrl.startsWith('shift+') ? withoutCtrl.substring(6) : withoutCtrl;
    if (withoutShift.length !== 1) {
      console.error(`Invalid hot key for ${windowNameForErrorMessage} debugging window ignored: ${hotKeySpecification}`);
      return undefined;
    }
    return hotKeySpecification;
  }


  /**
   * Dump the login configuration to the trace log.
   * 
   * @param {*} login The data obtained in login phase.
   * @param {*} traceLogBuffer The trace log buffer to dump to.
   */
  static dumpLoginToTraceLog(username, timestamp, traceLogBuffer) {
    traceLogBuffer.reportEvent('UserLogin', new Date(), {
      user: username,
      loginTimestamp: timestamp,
      runtimeVersion: Utils.getCbaVersion(),
      webClientUserAgent: window.navigator.userAgent
    })
  }

  /**
   * Write a message from the runtime controller to the trace log buffer.
   * 
   * @param {*} message The message to be written to the trace log
   * @param {*} traceLogBuffer The trace log buffer to write to.
   */
  static dumpMessageToTraceLog(message, traceLogBuffer) {
    traceLogBuffer.reportEvent('RuntimeController', new Date(), {
      actionType: 'insertMessageInTrace',
      details: message
    })
  }

  /**
   * Write a statemachine event to the trace log buffer.
   * 
   * @param {*} event The statemachine event to write to the trace log.
   * @param {*} traceLogBuffer The trace log buffer to write to
   */
  static dumpStatemachineEventToTraceLog(event, traceLogBuffer) {
    traceLogBuffer.reportEvent('RuntimeController', new Date(), {
      actionType: 'sendStatemachineEvent',
      details: event
    })
  }

  /**
   * Write a pause/resume event to the trace log buffer.
   * 
   * @param {boolean} enter Do we enter or leave the paused state?
   * @param {*} traceLogBuffer The trace log buffer to write to.
   */
  static dumpPauseResumeToTraceLog(enter, traceLogBuffer) {
    traceLogBuffer.reportEvent('PauseResume', new Date(), {
      type: enter === true ? 'pause' : 'resume',
    })
  }


  /**
   * Check that the given version number is compatible with our internal version.
   */
  static isCompatibleVersion(versionNumber) {
    return versionNumber === Utils.getCbaVersionNumber();
  }

  /**
   * Pause or resume the currently running task.
   * 
   * @param {boolean} enterPause Should we pause (or resume)?
   * @param {*} runtime The common runtime context structure. 
   */
  static pauseOrResume(enterPause, runtime) {
    const { testPresenter, traceLogBuffer, incidentsAccumulator, statemachinesManager } = runtime;
    TaskPlayer.dumpPauseResumeToTraceLog(enterPause, traceLogBuffer);
    if (testPresenter !== null) {
      if (enterPause) {
        testPresenter.pause();
      } else {
        testPresenter.resume();
      }
    }
    const atTime = new Date().getTime();
    if (enterPause) {
      incidentsAccumulator.pauseTask(atTime);
      statemachinesManager.pauseCurrentStatemachine();
    } else {
      incidentsAccumulator.resumeTask(atTime);
      statemachinesManager.resumeCurrentStatemachine();
    }

    // TODO: CKI add more stuff to be done for a proper pause: media players
  }

  /**
   * 
   * @param {String} itemName Name of the item 
   * @param {Object} config Configuration object
   * @param {Boolean} config.image 
   * @param {Boolean} config.video 
   * @param {Boolean} config.audio
   *  
   * @returns {Promise} Promise object that completes when all resources are preloaded. Of the form [ [images], [videos], [audios] ]
   */
  preloadItemResources = (itemName, config = {}) => new Promise((resolve, reject) => {
    console.log("Starting preload for item", itemName);

    const defaultConfig = {
      image: true,
      video: true,
      audio: true
    }
    const invalidConfigError = "Wrong config? If no preload is required, a call to this function is not required.";
    const invalidItemError = "No resources to preload";

    config = Object.assign(defaultConfig, config);

    if (!config.image && !config.video && !config.audio) {
      return reject(invalidConfigError);
    }

    const resources = this.runtime.taskManager.getItemResources(itemName);

    if (!resources || resources.length === 0) {
      return reject(invalidItemError);
    }
    const imageType = "image";
    const videoType = "video";
    const audioType = "audio";

    let imagesPromise;
    let videoPromise;
    let audioPromise;

    if (config.image) {
      const assets = resources.filter(res => res.type === imageType);
      imagesPromise = ServerCalls.preloadResources(assets, imageType);
    }

    if (config.video) {
      const assets = resources.filter(res => res.type === videoType);
      videoPromise = ServerCalls.preloadResources(assets, videoType);
    }

    if (config.audio) {
      const assets = resources.filter(res => res.type === audioType);
      audioPromise = ServerCalls.preloadResources(assets, audioType);
    }

    return resolve(Promise.all([imagesPromise, videoPromise, audioPromise]))
  });


}
