import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import SelectGroupHelper from '../components/SelectGroupHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import PathTranslationHelper from '../state/PathTranslationHelper';
import UserDefPathHelper from '../state/UserDefPathHelper';
import TextBlockSelectHelper from '../components/CbaRichTextField/TextBlockSelectHelper';
import TraceLogHelper from '../state/TraceLogHelper';
import BookmarkHelper from '../components/BookmarkHelper';
import CbaPageArea from '../components/CbaPageArea';
import CbaRichTextField from '../components/CbaRichTextField/CbaRichTextField';
import CbaTableCell from '../components/table/CbaTableCell';
import TreeUtils from '../components/CbaTree/TreeUtils';


export default class TermEvaluator {

  // all operators we know: -----------------------------------------------------------------
  static operators = {
    // context value access
    contextValue: TermEvaluator.contextValue,

    // basic boolean
    and: TermEvaluator.and,
    or: TermEvaluator.or,
    not: TermEvaluator.not,
    ifThenElse: TermEvaluator.ifThenElse,

    // compare
    equal: TermEvaluator.equal,
    notEqual: TermEvaluator.notEqual,
    greater: TermEvaluator.greater,
    greaterEqual: TermEvaluator.greaterEqual,
    less: TermEvaluator.less,
    lessEqual: TermEvaluator.lessEqual,
    max: TermEvaluator.max,
    maxNamed: TermEvaluator.maxNamed,
    matches: TermEvaluator.matches,

    // arrays 
    arrayLength: TermEvaluator.arrayLength,
    union: TermEvaluator.union,
    intersection: TermEvaluator.intersection,

    // numbers
    add: TermEvaluator.add,
    subtract: TermEvaluator.subtract,
    multiply: TermEvaluator.multiply,
    divide: TermEvaluator.divide,
    modulo: TermEvaluator.modulo,
    floor: TermEvaluator.floor,
    ceil: TermEvaluator.ceil,
    trunc: TermEvaluator.trunc,
    round: TermEvaluator.round,

    // strings
    stringFormat: TermEvaluator.stringFormat,

    // currently running
    getCurrentTest: TermEvaluator.getCurrentTest,
    getCurrentTask: TermEvaluator.getCurrentTask,
    getCurrentItem: TermEvaluator.getCurrentItem,
    getCurrentPage: TermEvaluator.getCurrentPage,
    getEmbeddedPage: TermEvaluator.getEmbeddedPage,

    switchPage: TermEvaluator.switchPage,

    saveTaskResults: TermEvaluator.saveTaskResults,
    previousTask: TermEvaluator.previousTask,
    nextTask: TermEvaluator.nextTask,
    cancelTask: TermEvaluator.cancelTask,
    switchTaskInTest: TermEvaluator.switchTaskInTest,
    switchTaskAndTest: TermEvaluator.switchTaskAndTest,

    recommend: TermEvaluator.recommend,

    // interaction events
    nbUserInteractions: TermEvaluator.nbUserInteractions,
    nbUserInteractionsTotal: TermEvaluator.nbUserInteractionsTotal,
    firstReactionTime: TermEvaluator.firstReactionTime,
    firstReactionTimeTotal: TermEvaluator.firstReactionTimeTotal,
    taskExecutionTime: TermEvaluator.taskExecutionTime,
    taskExecutionTimeTotal: TermEvaluator.taskExecutionTimeTotal,

    // get/set on widgets
    focus: TermEvaluator.focus,

    getDisabled: TermEvaluator.getDisabled,
    setDisabled: TermEvaluator.setDisabled,

    getSelected: TermEvaluator.getSelected,
    setSelected: TermEvaluator.setSelected,

    getHidden: TermEvaluator.getHidden,
    setHidden: TermEvaluator.setHidden,

    getVisited: TermEvaluator.getVisited,
    setVisited: TermEvaluator.setVisited,

    getTextValue: TermEvaluator.getTextValue,
    setTextValue: TermEvaluator.setTextValue,

    setHighlightable: TermEvaluator.setHighlightable,
    isSelectedComponentOrTextBlock: TermEvaluator.isSelectedComponentOrTextBlock,
    isHighlighted: TermEvaluator.isHighlighted,
    getIntegerValue: TermEvaluator.getIntegerValue,

    setMediaPlayerVolume: TermEvaluator.setMediaPlayerVolume,
    setMediaPlayer: TermEvaluator.setMediaPlayer,
    initMediaPlayer: TermEvaluator.initMediaPlayer,

    setDragAndDropMode: TermEvaluator.setDragAndDropMode,

    setSingleSelectMode: TermEvaluator.setSingleSelectMode,
    setSelectChangeBlockMode: TermEvaluator.setSelectChangeBlockMode,

    // named calculations
    evaluateNamedCalculation: TermEvaluator.evaluateNamedCalculation,
    getCalculationResult: TermEvaluator.getCalculationResult,

    // state machine
    raiseEvent: TermEvaluator.raiseEvent,
    postponeTaskSwitch: TermEvaluator.postponeTaskSwitch,
    getStatemachineVariable: TermEvaluator.getStatemachineVariable,
    setStatemachineVariable: TermEvaluator.setStatemachineVariable,
    switchStatemachineVariables: TermEvaluator.switchStatemachineVariables,
    setTimedEventInterval: TermEvaluator.setTimedEventInterval,
    setStatemachineAssignedPage: TermEvaluator.setStatemachineAssignedPage,
    getNbStatemachineEventsRaised: TermEvaluator.getNbStatemachineEventsRaised,
    getCurrentLeafStates: TermEvaluator.getCurrentLeafStates,
    getRaisedStatemachineEvents: TermEvaluator.getRaisedStatemachineEvents,
    getVisitedStates: TermEvaluator.getVisitedStates,
    getRaisedEventsInState: TermEvaluator.getRaisedEventsInState,
    getStatemachineVariableValues: TermEvaluator.getStatemachineVariableValues,

    // trace
    traceText: TermEvaluator.traceText,
    traceSnapshot: TermEvaluator.traceSnapshot,

    // calculator
    calcGetMem: TermEvaluator.calcGetMem,
    calcOp: TermEvaluator.calcOp,
    calcOpnd: TermEvaluator.calcOpnd,
    calcSettings: TermEvaluator.calcSettings,

    // tree
    currentNode: TermEvaluator.currentNode,
    getVisitedNodes: TermEvaluator.getVisitedNodes,
    matchNodes: TermEvaluator.matchNodes,
    matchNodesWithColumns: TermEvaluator.matchNodesWithColumns,
    treeMove: TermEvaluator.treeMove,
    treeCopy: TermEvaluator.treeCopy,

    // system environment
    currentTimestamp: TermEvaluator.currentTimestamp,
    consoleLog: TermEvaluator.consoleLog,

    setGlobalPropertyHighlightColor: TermEvaluator.setGlobalPropertyHighlightColor,

    // others
    containerMembersFormLocalGroup: TermEvaluator.containerMembersFormLocalGroup,
    containerRangeContainsMembers: TermEvaluator.containerRangeContainsMembers,
    isInBookmarksList: TermEvaluator.isInBookmarksList,

  }

  static contextValue(params, runtime, contextSlots) {
    const mainEntry = contextSlots[params.valueIndex];
    if (params.attributes === undefined) {
      return mainEntry;
    }
    let result = mainEntry;
    params.attributes.forEach((attribute) => { result = result === undefined ? undefined : result[attribute]; })
    return result;
  }

  // basic boolean ------------------------------------
  static and(params, runtime) {
    if (params.paramsArray !== undefined) {
      return params.paramsArray.reduce((previous, current, index, all) => previous && current, true);
    }
    return params.left && params.right;
  }

  static or(params, runtime) {
    if (params.paramsArray !== undefined) {
      return params.paramsArray.reduce((previous, current, index, all) => previous || current, false);
    }
    return params.left || params.right;
  }

  static not(params, runtime) {
    return !params.value;
  }

  static ifThenElse(params, runtime) {
    return params.if ? params.then : params.else;
  }

  // compare ------------------------------------
  static equal(params, runtime) {
    return params.left === params.right;
  }

  static notEqual(params, runtime) {
    return params.left !== params.right;
  }

  static greater(params, runtime) {
    return params.left > params.right;
  }

  static greaterEqual(params, runtime) {
    return params.left >= params.right;
  }

  static less(params, runtime) {
    return params.left < params.right;
  }

  static lessEqual(params, runtime) {
    return params.left <= params.right;
  }

  static max(params, runtime) {
    if (params.paramsArray !== undefined) {
      return params.paramsArray.sort((a, b) => b - a)[0];
    }
    return params.left > params.right ? params.left : params.right;
  }

  static maxNamed(params, runtime) {
    // use the params names as return
    // compare params values and return params name of max value
    let currentMaximumValue;
    let currentMaximumName;
    Object.keys(params).forEach((candidateName) => {
      const candidateValue = params[candidateName];
      if (currentMaximumValue === undefined || currentMaximumValue < candidateValue) {
        currentMaximumValue = candidateValue;
        currentMaximumName = candidateName;
      }
    });
    return currentMaximumName;
  }

  static matches(params) {
    try {
      const matcher = RegExp(params.pattern, 'm');
      return matcher.test(params.candidate);
    } catch (exception) {
      console.log(`Invalid regular expression in matches operator: ${params.pattern} -> evaluate matches call to 'false'`);
      return false;
    }
  }

  // arrays -----------------------------------
  static arrayLength(params, runtime) {
    return params.value.length;
  }

  static union(params, runtime) {
    const { left, right } = params;

    const result = [];
    left.forEach((candidate) => {
      if (!result.includes(candidate)) {
        result.push(candidate);
      }
    })
    right.forEach((candidate) => {
      if (!result.includes(candidate)) {
        result.push(candidate);
      }
    })
    return result;
  }


  static intersection(params, runtime) {
    const { left, right } = params;

    const result = [];
    left.forEach((candidate) => {
      if (right.includes(candidate) && !result.includes(candidate)) {
        result.push(candidate);
      }
    })
    return result;
  }

  // numbers ------------------------------------
  static add(params, runtime) {
    if (params.paramsArray !== undefined) {
      return params.paramsArray.reduce((previous, current, index, all) => previous + current, 0);
    }
    return params.left + params.right;
  }

  static subtract(params, runtime) {
    return params.left - params.right;
  }

  static multiply(params, runtime) {
    if (params.paramsArray !== undefined) {
      return params.paramsArray.reduce((previous, current, index, all) => previous * current, 1);
    }
    return params.left * params.right;
  }

  static divide(params, runtime) {
    return params.left / params.right;
  }

  static modulo(params, runtime) {
    return params.left % params.right;
  }

  static floor(params, runtime) {
    return Math.floor(params.value);
  }

  static ceil(params, runtime) {
    return Math.ceil(params.value);
  }

  static trunc(params, runtime) {
    return Math.trunc(params.value);
  }

  static round(params, runtime) {
    return Math.round(params.value);
  }

  // strings --------------------------------------------------------------
  static stringFormat(params, runtime) {
    let result = params.expression;
    params.valuesArray.forEach((value, index) => {
      // build regex based on the value index
      const exp = `%${index + 1}[$]s`;
      const regexp = new RegExp(exp, "g");
      result = result.replace(regexp, value);
    });
    return result;
  }

  // currently running -----------------------------------------------------
  static getCurrentTest(params, runtime) {
    return runtime.taskManager.getCurrentTestTaskItemNames().test;
  }

  static getCurrentTask(params, runtime) {
    return runtime.taskManager.getCurrentTestTaskItemNames().task;
  }

  static getCurrentItem(params, runtime) {
    return runtime.taskManager.getCurrentTestTaskItemNames().item;
  }

  static getCurrentPage(params, runtime) {
    switch (params.pageType) {
      case "standard":
        return runtime.taskManager.getCurrentPageNames().standardPage;
      case "xPage":
        return runtime.taskManager.getCurrentPageNames().xPage;
      default:
        console.error(`Illegal page type in getCurrentPage call: ${params.pageType}`);
        return undefined;
    }
  }

  static getEmbeddedPage(params, runtime) {
    const path = PathTranslationHelper.getIndexPathForUserDefPath(params.absoluteUserDefId, runtime);
    const pageSegment = runtime.pageConfigurationsManager.findPageSegmentForUserDefId(UserDefPathHelper.getLastUserDefIdFromPath(params.absoluteUserDefId));
    const pageAreaConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    if (pageAreaConfig === undefined) {
      console.error(`Illegal page area reference in getEmbeddedPage call: ${params.absoluteUserDefId}`);
      return undefined;
    }
    return CbaPageArea.getEmbeddedPageName(path, pageAreaConfig.config, runtime);
  }

  static switchPage(params, runtime) {
    // translate the user defined id path to the target CBAPageArea to a proper state path 
    const receiverPath = params.receiver === undefined ? undefined
      : IndexPathHelper.trimRootAndPageAreaFromPath(PathTranslationHelper.getIndexPathForUserDefPath(params.receiver, runtime));
    const receiverTabInfo = params.receiverTabName === undefined ? undefined : {
      name: params.receiverTabName,
      image: params.receiverTabImage
    }
    const position = (params.x === undefined || params.y === undefined) ? undefined : {
      x: params.x, y: params.y
    }

    runtime.taskManager.switchPage(
      params.pageName, undefined, params.pageUrl,
      params.pageAreaType, params.pageAreaName, receiverPath,
      receiverTabInfo, undefined, position
    );
    return undefined;
  }

  static saveTaskResults(params, runtime) {
    runtime.taskManager.saveCurrentTaskResults();
    return undefined;
  }

  static previousTask(params, runtime) {
    runtime.taskManager.switchTaskPrevious();
    return undefined;
  }

  static nextTask(params, runtime) {
    runtime.taskManager.switchTaskNext();
    return undefined;
  }

  static cancelTask(params, runtime) {
    runtime.taskManager.cancelTask();
    return undefined;
  }

  static switchTaskInTest(params, runtime) {
    runtime.taskManager.switchFirstMatchingTaskIntraTest(params.taskName);
    return undefined;
  }

  static switchTaskAndTest(params, runtime) {
    runtime.taskManager.switchFirstMatchingTaskInterTest(params.testName, params.taskName);
    return undefined;
  }

  /**
   * The parameter recommendations must be an array of recommendation objects.
   * Each recommendation object 
   * - must have a testName attribute and 
   * - may have a taskName atrribute and 
   * - may have an absoluteUserDef attribute if a taskName attribute is given.
   */
  static recommend(params, runtime) {
    runtime.recommendationsManager.setRecommendations(params.recommendations);
    return undefined;
  }

  // interaction events ---------------------------------------------------
  static nbUserInteractions(params, runtime) {
    return runtime.incidentsAccumulator.nbUserInteractions(runtime.taskManager.getCurrentStatePathRoot());
  }

  static nbUserInteractionsTotal(params, runtime) {
    return runtime.incidentsAccumulator.nbUserInteractionsTotal(runtime.taskManager.getCurrentStatePathRoot());
  }

  static firstReactionTime(params, runtime) {
    return runtime.incidentsAccumulator.firstReactionTime(runtime.taskManager.getCurrentStatePathRoot());
  }

  static firstReactionTimeTotal(params, runtime) {
    return runtime.incidentsAccumulator.firstReactionTimeTotal(runtime.taskManager.getCurrentStatePathRoot());
  }

  static taskExecutionTime(params, runtime) {
    return runtime.incidentsAccumulator.taskExecutionTime(runtime.taskManager.getCurrentStatePathRoot(), new Date().getTime());
  }

  static taskExecutionTimeTotal(params, runtime) {
    return runtime.incidentsAccumulator.taskExecutionTimeTotal(runtime.taskManager.getCurrentStatePathRoot());
  }


  // get/set on widgets ------------------------------------------------------------------
  static focus(params, runtime) {
    const component = TermEvaluator.findComponentByAbsoluteUserDefId(params.absoluteUserDefId, runtime);
    const pathId = PathTranslationHelper.getIndexPathForUserDefPath(params.absoluteUserDefId, runtime);
    const pagePath = IndexPathHelper.getPagePath(pathId);
    if (component !== undefined) {
      component.focus();
    } else {
      runtime.actionRegister.registerAction(pathId, "focus");
    }
    runtime.focusRegister.registerFocus(pagePath);
    return undefined;
  }

  static getDisabled(params, runtime) {
    return ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractDisabled, params.absoluteUserDefId, runtime);
  }

  static setDisabled(params, runtime) {
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractDisabled, StateAttributeAccess.setDisabled, params.value, params.absoluteUserDefId, runtime, true);
    return undefined;
  }

  static getSelected(params, runtime) {
    const pathState = runtime.componentStateManager.findOrBuildStateByUserDefPath(params.absoluteUserDefId, runtime);
    return SelectGroupHelper.extractSelectedState(pathState, runtime);
  }

  static setSelected(params, runtime) {
    const pathId = PathTranslationHelper.getIndexPathForUserDefPath(params.absoluteUserDefId, runtime);
    const pathState = runtime.componentStateManager.findOrBuildStateForPathId(pathId, runtime);
    SelectGroupHelper.setSelectedForPossiblyControlledComponent(params.value, pathId, pathState, false, runtime);
    return undefined;
  }

  static getHidden(params, runtime) {
    return ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractHidden, params.absoluteUserDefId, runtime);
  }

  static setHidden(params, runtime) {
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractHidden, StateAttributeAccess.setHidden, params.value, params.absoluteUserDefId, runtime, true);
    return undefined;
  }

  static getVisited(params, runtime) {
    return ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractVisited, params.absoluteUserDefId, runtime);
  }

  static setVisited(params, runtime) {
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractVisited, StateAttributeAccess.setVisited, params.value, params.absoluteUserDefId, runtime, true);
    return undefined;
  }


  static getTextValue(params, runtime) {
    const { selector } = params;
    if (selector) {
      return CbaTableCell.getFormulaOrValue(selector, params.absoluteUserDefId, runtime);
    }
    return ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractTextValue, params.absoluteUserDefId, runtime);
  }

  static setTextValue(params, runtime) {

    // create trace log entry
    const oldTextValue = ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractTextValue, params.absoluteUserDefId, runtime);
    const indexPath = PathTranslationHelper.getIndexPathForUserDefPath(params.absoluteUserDefId, runtime);
    const traceDetails = {
      indexPath,
      userDefIdPath: params.absoluteUserDefId,
      userDefId: UserDefPathHelper.getLastUserDefIdFromPath(params.absoluteUserDefId),
      oldTextValue,
      newTextValue: params.value
    };
    runtime.traceLogBuffer.reportEvent('OperatorSetTextValue', new Date(), traceDetails);

    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractTextValue, StateAttributeAccess.setTextValue, params.value, params.absoluteUserDefId, runtime, true);
    return undefined;
  }

  static setHighlightable(params, runtime) {
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractHighlightable, StateAttributeAccess.setHighlightable, params.value, params.absoluteUserDefId, runtime, true);
    return undefined;
  }

  static isSelectedComponentOrTextBlock(params, runtime) {
    const pathParameter = params.absolutePath;
    const blockOwnerPathId = PathTranslationHelper.getIndexPathForTextBlockPath(pathParameter, runtime);
    if (blockOwnerPathId === undefined) {
      const pathState = runtime.componentStateManager.findOrBuildStateByUserDefPath(pathParameter, runtime);
      return SelectGroupHelper.extractSelectedState(pathState, runtime);
    } else {
      const { partial } = params;
      const richTextPathState = runtime.componentStateManager.findOrBuildStateForPathId(blockOwnerPathId, runtime);
      const blockName = UserDefPathHelper.getLastUserDefIdFromPath(pathParameter);
      const richTextPageSegment = runtime.pageConfigurationsManager.findPageSegmentForTextBlockOwner(blockName);
      const richTextConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(richTextPageSegment);
      return TextBlockSelectHelper.isTextBlockSelected(blockName, richTextPathState, richTextConfig.config, partial);
    }
  }

  static isHighlighted(params, runtime) {
    const richTextPathState = runtime.componentStateManager.findOrBuildStateByUserDefPath(params.absoluteUserDefId, runtime);
    const richTextPageSegment = runtime.pageConfigurationsManager.findPageSegmentForUserDefId(UserDefPathHelper.getLastUserDefIdFromPath(params.absoluteUserDefId));
    const richTextConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(richTextPageSegment);
    return TextBlockSelectHelper.isSelectionContainsNonBlank(richTextPathState, richTextConfig.config);
  }

  static getIntegerValue(params, runtime) {
    const { absoluteUserDefId, roundingMode, defaultValue } = params;
    // TODO: CKI or BHO: unfortunately the TableCell component puts a Number into the textValue field -> cast that to String:
    const valueAsText = String(ComponentStateHelper.getStateAttributeByUserDefPath(StateAttributeAccess.extractTextValue, absoluteUserDefId, runtime));

    if (valueAsText === undefined || valueAsText.length === 0) {
      return defaultValue;
    }

    const parsedValue = TermEvaluator.integerValueFromString(valueAsText, roundingMode);
    return parsedValue === undefined ? defaultValue : parsedValue;
  }

  /**
   * Internal helper: Parse the given String as integer with rounding applied.
   * 
   * The method returns undefined if the string does not represent a decimal number. 
   * 
   * @param {String} valueAsString The value given as string
   * @param {String} roundingMode The rounding mode, one of 'up', 'down', 'half_up', 'half_down'
   */
  static integerValueFromString(valueAsString, roundingMode) {
    const parseResult = TermEvaluator.parseDecimalString(valueAsString);
    if (parseResult === undefined) {
      return undefined;
    }

    const { wholeValue: whole, fractionClass } = parseResult;
    const wholeUp = whole >= 0 ? (whole + 1) : (whole - 1);
    switch (roundingMode) {
      case 'up':
        return fractionClass === 'zero' ? whole : wholeUp;
      case 'down':
        return whole;
      case 'half_up':
        return fractionClass === 'five' || fractionClass === 'greaterThanFive' ? wholeUp : whole;
      case 'half_down':
        return fractionClass === 'greaterThanFive' ? wholeUp : whole;
      default:
        console.warn(`Invalid rounding mode: ${roundingMode}`);
        return undefined;
    }

  }

  /**
   * Internal helper that parses a decimal string into the whole part
   * and a classification of the fractional part: 'zero', 'lessThanFive', 'five', greaterThanFive'.
   * 
   * The method returns undefined if the given String is not a proper decimal number.
   * 
   * @param {String} valueAsString 
   */
  static parseDecimalString(valueAsString) {
    const dotIndex = valueAsString.indexOf('.');
    const wholeString = dotIndex === -1 ? valueAsString : valueAsString.substr(0, dotIndex);
    const fractionString = dotIndex === -1 ? '0' : valueAsString.substr(dotIndex + 1);
    const wholeValue = parseInt(wholeString, 10);
    const fractionValue = parseInt(fractionString, 10);
    if (String(wholeValue) !== wholeString) {
      return undefined;
    }
    let fractionCompareString = fractionString;
    while (fractionCompareString.startsWith('0')) {
      fractionCompareString = fractionCompareString.substr(1);
    }
    if (fractionCompareString.length === 0) {
      fractionCompareString = '0';
    }
    if (String(fractionValue) !== fractionCompareString) {
      return undefined;
    }
    if (fractionValue < 0) {
      return undefined;
    }
    let fractionClass;
    if (fractionValue === 0) {
      fractionClass = 'zero'
    } else if (fractionString[0] === '0' || fractionValue < 5) {
      fractionClass = 'lessThanFive'
    } else if (fractionValue > 5) {
      fractionClass = 'greaterThanFive'
    } else {
      fractionClass = 'five'
    }
    return {
      wholeValue, fractionClass
    };
  }

  static setMediaPlayerVolume(params, runtime) {
    if (params.value >= 0 && params.value <= 10) {
      ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractMediaVolume, StateAttributeAccess.setMediaVolume, params.value, params.absoluteUserDefId, runtime, true);
    }
    return undefined;
  }

  static setMediaPlayer(params, runtime) {
    const component = TermEvaluator.findComponentByAbsoluteUserDefId(params.absoluteUserDefId, runtime);
    if (component !== undefined) {
      const operation = params.value.toLowerCase();
      switch (operation) {
        case 'start':
          component.play();
          break;
        case 'stop':
          component.stop();
          break;
        case 'pause':
          component.pause();
          break;
        default:
          TermEvaluator.logMessage(`invalid operation ${operation}`);
          break;
      }
    }
    return undefined;
  }

  static initMediaPlayer(params, runtime) {
    const { absoluteUserDefId, automaticStart, hideControls, maxPlay } = params;
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractAutomaticStart, StateAttributeAccess.setAutomaticStart, automaticStart, absoluteUserDefId, runtime, true);
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractHideControls, StateAttributeAccess.setHideControls, hideControls, absoluteUserDefId, runtime, true);
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractMaxPlay, StateAttributeAccess.setMaxPlay, maxPlay, absoluteUserDefId, runtime, true);
  }

  static setDragAndDropMode(params, runtime) {
    const { isSender, isReceiver, absoluteUserDefId } = params;
    const modeToSet = {
      isSender,
      isReceiver
    }
    ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractDragAndDrop, StateAttributeAccess.setDragAndDrop, modeToSet, absoluteUserDefId, runtime, true);
    return undefined;
  }

  static setSingleSelectMode(params, runtime) {
    const { value, absoluteUserDefId } = params;
    SelectGroupHelper.setSingleSelectActiveForController(absoluteUserDefId, value, runtime);
    return undefined;
  }

  static setSelectChangeBlockMode(params, runtime) {
    const { value, absoluteUserDefId } = params;
    const pathState = runtime.componentStateManager.findOrBuildStateByUserDefPath(params.absoluteUserDefId, runtime);
    const selectableFlagInPathState = StateAttributeAccess.extractSelectable(pathState);
    if (selectableFlagInPathState === undefined) {
      // selectability is controlled by select group:
      SelectGroupHelper.setSelectionChangesBlockedForController(absoluteUserDefId, value, runtime);
    } else {
      // selectability is explicitly controlled by component itself:
      ComponentStateHelper.updateStateAttributeByUserDefPath(StateAttributeAccess.extractSelectable, StateAttributeAccess.setSelectable, !value, absoluteUserDefId, runtime, false);
    }
    return undefined;
  }


  // named calculations ----------------------------------------------------------------------

  static evaluateNamedCalculation(params, runtime) {
    const currentTaskName = runtime.taskManager.getCurrentTestTaskItemNames().task;
    const termIdentifiedByName = runtime.calculationsConfigurationManager.findCalculationByTaskName(currentTaskName, params.calculationName);
    return TermEvaluator.evaluateTerm(termIdentifiedByName, runtime, [], params.calculationName);

  }

  static getCalculationResult(params, runtime) {

    const currentTaskName = runtime.taskManager.getCurrentTestTaskItemNames().task;
    if (currentTaskName === params.taskName) {
      // evaluate ad hoc in current task
      return TermEvaluator.evaluateNamedCalculation({
        calculationName: params.calculationName
      }, runtime);
    } else {
      // look up calculation result in stored result of inactive task
      const taskPath = runtime.taskManager.getStatePathRootForTask(params.taskName);
      return runtime.taskResultsManager.getResult(taskPath, params.calculationName);
    }
  }


  // state machine  --------------------------------------------------------------------------

  static raiseEvent(params, runtime) {
    runtime.statemachinesManager.triggerEvent(params.event);
    return undefined;
  }

  static postponeTaskSwitch(params, runtime) {
    runtime.statemachinesManager.setPostponedTaskSwitch(params.switchCall.deferred);
    return undefined;
  }

  static getStatemachineVariable(params, runtime) {
    return runtime.statemachinesManager.getVariable(params.name);
  }

  static setStatemachineVariable(params, runtime) {
    runtime.statemachinesManager.setVariable(params.name, params.value, runtime);
    return undefined;
  }

  static switchStatemachineVariables(params, runtime) {
    const { left, right } = params;
    const { statemachinesManager } = runtime;
    const oldValueLeft = statemachinesManager.getVariable(left);
    const oldValueRight = statemachinesManager.getVariable(right);
    statemachinesManager.setVariable(left, oldValueRight, runtime);
    statemachinesManager.setVariable(right, oldValueLeft, runtime);
  }

  static setTimedEventInterval(params, runtime) {
    const { eventName, interval } = params;
    const { statemachinesManager } = runtime;
    statemachinesManager.setTimedEventInterval(eventName, interval, runtime);
  }

  static setStatemachineAssignedPage(params, runtime) {
    const { state, pageName, pageAreaType, pageAreaName } = params;
    const { statemachinesManager } = runtime;
    statemachinesManager.setStatePageAssignment(state, pageName, pageAreaType, pageAreaName);
  }

  static getNbStatemachineEventsRaised(params, runtime) {
    return runtime.statemachinesManager.getTotalNbOfRaisedEvents();
  }

  static getCurrentLeafStates(params, runtime) {
    return runtime.statemachinesManager.getCurrentStatemachineData().states;
  }

  static getRaisedStatemachineEvents(params, runtime) {
    return runtime.statemachinesManager.getRaisedEvents();
  }

  static getVisitedStates(params, runtime) {
    return runtime.statemachinesManager.getVisitedStates();
  }

  static getRaisedEventsInState(params, runtime) {
    return runtime.statemachinesManager.getRaisedEventsInState(params.state);
  }

  static getStatemachineVariableValues(params, runtime) {
    return runtime.statemachinesManager.getValuesOfVariable(params.variable);
  }

  // trace ----------------------------------------------------------------------------------

  static traceText(params, runtime) {
    TermEvaluator.traceTextInternal('OperatorTraceText', params, runtime);
    return true;
  }

  static traceSnapshot(params, runtime) {
    TermEvaluator.traceTextInternal('OperatorTraceSnapshot', params, runtime);
    TraceLogHelper.dumpSnapshotToTrace(runtime);
    return true;
  }

  static traceTextInternal(operator, params, runtime) {
    const text = TermEvaluator.evaluateTerm(params.expression, runtime, [], operator);
    const traceDetails = {
      text
    };
    runtime.traceLogBuffer.reportEvent(operator, new Date(), traceDetails);
  }

  // calculator ----------------------------------------------------------------------------------

  static calcGetMem(params, runtime) {
    return runtime.calculatorsManager.calcGetMem(TermEvaluator.evaluateTerm(params.expression, runtime, [], 'calcGetMem'));
  }

  static calcOp(params, runtime) {
    const value = (params.expression !== undefined) ? TermEvaluator.evaluateTerm(params.expression, runtime, [], `calcOp${params.operation}`) : undefined;
    runtime.calculatorsManager.calcOp(params.operation, value);
    return undefined;
  }

  static calcOpnd(params, runtime) {
    const value = (params.expression !== undefined) ? TermEvaluator.evaluateTerm(params.expression, runtime, [], `calcOpnd${params.operation}`) : undefined;
    runtime.calculatorsManager.calcOpnd(params.operation, value);
    return undefined;
  }

  static calcSettings(params, runtime) {
    const settings = {};
    Object.keys(params).forEach((key) => {
      settings[key] = TermEvaluator.evaluateTerm(params[key], runtime, [], `calcSettings parameter:${key}`)
    });
    runtime.calculatorsManager.calcSettings(settings);
  }


  // system environment -----------------------------------------------------------------------

  static currentTimestamp(params, runtime) {
    return new Date().getTime();
  }

  static consoleLog(params, runtime) {
    console.log(params.message);
    return undefined;
  }

  static setGlobalPropertyHighlightColor(params, runtime) {
    const topLevelConfiguration = runtime.presenterStateManager.getTaskState(runtime.taskManager.getCurrentStatePathRoot());
    topLevelConfiguration.itemHighlightColor = params.value;
    runtime.presenterStateManager.saveTaskState(runtime.taskManager.getCurrentStatePathRoot(), topLevelConfiguration);

    runtime.componentDirectory.findByComponentType(CbaRichTextField).forEach((richTextComponent) => {
      richTextComponent.highlightColorChanged();
    });
    return undefined;
  }

  // others -----------------------------------------------------------------------
  static containerMembersFormLocalGroup(params, runtime) {
    const { container, minDistance, maxDistance, anchorPointType, groupMembers, checkNonMembers } = params;
    const containerIndexPath = PathTranslationHelper.getIndexPathForUserDefPath(container, runtime);
    const memberIndexPaths = TermEvaluator.getIndexPathsForUserDefIds(groupMembers, runtime);
    if (!TermEvaluator.checkChildhood(containerIndexPath, memberIndexPaths)) {
      console.warn('Some group members in containerMembersFormLocalGroup call are not children of the given container -> returning false.')
      return false;
    }
    const { memberComparePoints, nonMemberComparePoints } = TermEvaluator.getComparePointSets(containerIndexPath, 'comparesPosition', anchorPointType, memberIndexPaths, runtime);

    return TermEvaluator.checkLocalGroup(minDistance, maxDistance, memberComparePoints, checkNonMembers === false ? [] : nonMemberComparePoints);
  }

  static containerRangeContainsMembers(params, runtime) {
    const { container, minX, maxX, minY, maxY, anchorPointType, rangeMembers, checkNonMembers } = params;
    const containerIndexPath = PathTranslationHelper.getIndexPathForUserDefPath(container, runtime);
    const memberIndexPaths = TermEvaluator.getIndexPathsForUserDefIds(rangeMembers, runtime);
    if (!TermEvaluator.checkChildhood(containerIndexPath, memberIndexPaths)) {
      console.warn('Some group members in containerRangeContainsMembers call are not children of the given container -> returning false.')
      return false;
    }
    const { memberComparePoints, nonMemberComparePoints } = TermEvaluator.getComparePointSets(containerIndexPath, 'comparesPosition', anchorPointType, memberIndexPaths, runtime);

    return TermEvaluator.checkInRange(minX, maxX, minY, maxY, memberComparePoints, checkNonMembers === false ? [] : nonMemberComparePoints);
  }

  static isInBookmarksList(params, runtime) {
    const { pageName: pageToLookFor } = params;
    const pageAreaPathList = ComponentStateHelper.findIndexPathsInCurrentTaskOfComponentWithType('CbaPageArea', runtime);

    const pageAreaWithMatchingBookmark = pageAreaPathList.find(indexPath => BookmarkHelper.getBookmarks(indexPath, runtime).find(bookmark => bookmark.pageName === pageToLookFor) !== undefined);
    return pageAreaWithMatchingBookmark !== undefined;
  }

  // client interface ------------------------------------------------------------------------
  static evaluateTerm(term, runtime, contextSlots, topLogName) {
    return TermEvaluator.evaluateTermInternal(term, runtime, contextSlots, `<${topLogName === undefined ? '' : topLogName}>`);
  }


  // internal methods of evaluation loop ------------------------------------------------------------------------
  static evaluateTermInternal(term, runtime, contextSlots, logContext) {
    if (runtime === undefined) TermEvaluator.logMessage(`Runtime undefined for ${logContext}`);

    // detect atomic terms and return their values immediately:
    const typeOfTerm = typeof term
    if (typeOfTerm === 'string' || typeOfTerm === 'boolean' || typeOfTerm === 'number') {
      TermEvaluator.logCalculation(`${logContext}=>${term}`);
      return term;
    }

    if (!TermEvaluator.isTermComplete(term, logContext)) {
      TermEvaluator.logCalculation(`${logContext}=> undefined`);
      return undefined;
    }

    // get operator
    const operatorName = term.operator;

    // detect non recursive structures (i.e. parameter objects that are not operator calls)
    // and return them immediately
    if (operatorName === undefined) {
      TermEvaluator.logCalculation(`${logContext}=>${JSON.stringify(term)}`);
      return term;
    }

    const operatorFunction = TermEvaluator.operators[operatorName];
    if (operatorFunction === undefined) {
      TermEvaluator.logMessage(`Unknown operator ${operatorName} in term in ${logContext}`);
      TermEvaluator.logCalculation(`${logContext}=> undefined`);
      return undefined;
    }

    // evaluate parameters
    const evaluatedParams = {};

    if (operatorFunction === TermEvaluator.ifThenElse) {
      // special case for if-then-else: evaluate one branch only:

      const ifParamIndex = term.params.findIndex(param => param.name === 'if');
      if (ifParamIndex === -1) {
        TermEvaluator.logMessage(`Missing if parameter in if-then-else in ${logContext}`);
        evaluatedParams.if = false;
      } else {
        const evaluationResult = TermEvaluator.evaluateParam(term.params[ifParamIndex], ifParamIndex, runtime, contextSlots, operatorName, logContext);
        evaluatedParams[evaluationResult.name] = evaluationResult.value;
      }

      const chosenBranch = evaluatedParams.if === true ? 'then' : 'else';
      const chosenBranchIndex = term.params.findIndex(param => param.name === chosenBranch);
      if (chosenBranchIndex !== -1) {
        const evaluationResult = TermEvaluator.evaluateParam(term.params[chosenBranchIndex], chosenBranchIndex, runtime, contextSlots, operatorName, logContext)
        evaluatedParams[evaluationResult.name] = evaluationResult.value;
      }
    } else {
      // standard case: evaluate all parameters
      term.params.forEach((param, index) => {
        const evaluationResult = TermEvaluator.evaluateParam(param, index, runtime, contextSlots, operatorName, logContext);
        evaluatedParams[evaluationResult.name] = evaluationResult.value;
      });
    }

    // call operator with evaluated parameters
    const result = operatorFunction(evaluatedParams, runtime, contextSlots);
    TermEvaluator.logCalculation(`${logContext}=>${result}`);
    return result;

  }

  static isTermComplete(term, logContext) {
    if (term === undefined) {
      TermEvaluator.logMessage(`Undefined term in ${logContext}`);
      return false;
    }
    if (term.operator === undefined) {
      // terms without operator are object value parameters that should not be evaluated
      return true;
    }
    if (term.params === undefined) {
      TermEvaluator.logMessage(`Undefined parameters for operator ${term.operator} in term in ${logContext}`);
      return false;
    }
    return true;
  }

  static evaluateParam(param, index, runtime, contextSlots, operatorName, logContext) {
    if (param === undefined) {
      TermEvaluator.logMessage(`Undefined parameter at index ${index} in term in ${logContext}`);
      TermEvaluator.logCalculation(`${logContext}=> undefined`);
      return undefined;
    }
    if (param.name === undefined) {
      TermEvaluator.logMessage(`Parameter without name at index ${index} in term in ${logContext}`);
      TermEvaluator.logCalculation(`${logContext}=> undefined`);
      return undefined;
    }

    const resultValue = (Array.isArray(param.value))
      // manage arrays as param.value content: call evaluateTermInternal for each array element 
      // and create an array of these results in evaluatedParams[param.name]
      ? param.value.map((value, index2, all) => TermEvaluator.evaluateTermInternal(value, runtime, contextSlots, `${logContext}-> parameter ${param.name}[${index2}] for operator ${operatorName}`))
      : TermEvaluator.evaluateTermInternal(param.value, runtime, contextSlots, `${logContext}-> parameter ${param.name} for operator ${operatorName}`);

    return {
      name: param.name,
      value: resultValue
    }

  }

  static logMessage(message) {
    console.warn(message);
  }

  static logCalculation(message) {
    // console.log(message);
  }


  // ---- internal helper methods ------------------------------------------------------------------------------------------------------------
  static checkLocalGroup(minDistance, maxDistance, memberComparePoints, nonMemberComparePoints) {
    const membersNotKeepingDistance = memberComparePoints.filter((candidate, index) => !TermEvaluator.keepsDistanceToGroup(candidate, memberComparePoints, minDistance, maxDistance, index));
    const nonMembersKeepingDistance = nonMemberComparePoints.filter(candidate => TermEvaluator.keepsDistanceToGroup(candidate, memberComparePoints, minDistance, maxDistance, undefined));

    return membersNotKeepingDistance.length === 0 && nonMembersKeepingDistance.length === 0;

  }

  static keepsDistanceToGroup(candidate, group, minDistance, maxDistance, excludeIndex) {
    return group.filter((groupMember, index) => excludeIndex !== index && !TermEvaluator.keepDistance(candidate, groupMember, minDistance, maxDistance)).length === 0;
  }

  static keepDistance(pointA, pointB, minDistance, maxDistance) {
    const xDelta = pointA.x - pointB.x;
    const yDelta = pointA.y - pointB.y;
    const distance = Math.sqrt((xDelta * xDelta) + (yDelta * yDelta));
    return minDistance < distance && distance < maxDistance;
  }


  static checkInRange(minX, maxX, minY, maxY, memberComparePoints, nonMemberComparePoints) {
    const membersNotInRange = TermEvaluator.filterInRange(minX, maxX, minY, maxY, memberComparePoints);
    const nonMembersInRange = TermEvaluator.filterOutOfRange(minX, maxX, minY, maxY, nonMemberComparePoints);

    return membersNotInRange.length === 0 && nonMembersInRange.length === 0;

  }

  static filterInRange(minX, maxX, minY, maxY, points) {
    return points.filter(point => minX > point.x || point.x > maxX || minY > point.y || point.y > maxY);
  }

  static filterOutOfRange(minX, maxX, minY, maxY, points) {
    return points.filter(point => minX <= point.x && point.x <= maxX && minY <= point.y && point.y <= maxY);
  }

  static checkChildhood(container, children) {
    const nonChildren = children.filter(candidatePath => !candidatePath.startsWith(container));
    return nonChildren.length === 0;
  }

  /**
   * Get the positions of the children of the given container according to the given anchor point type: topLeft/center/bottomLeft/...
   * The children are divided in two groups: 
   *  - the 'members' (i.e. those in the given memberIndexPaths set) and
   *  - the 'non-members' (i.e. those not in the given memberIndexPaths set) 
   * 
   * @param {*} containerIndexPath 
   * @param {*} classifier 
   * @param {*} anchorPointType 
   * @param {*} memberIndexPaths 
   * @param {*} runtime 
   */
  static getComparePointSets(containerIndexPath, classifier, anchorPointType, memberIndexPaths, runtime) {
    const nonMemberIndexPaths = TermEvaluator.getIndexPathsForChildrenWithClassifier(containerIndexPath, classifier, runtime).filter(childPath => !memberIndexPaths.includes(childPath));
    return {
      memberComparePoints: TermEvaluator.getComparePointsForIndexPaths(memberIndexPaths, anchorPointType, runtime),
      nonMemberComparePoints: TermEvaluator.getComparePointsForIndexPaths(nonMemberIndexPaths, anchorPointType, runtime)
    }
  }

  static getIndexPathsForChildrenWithClassifier(containerIndexPath, classifier, runtime) {
    const { pageConfigurationsManager } = runtime;
    const matchingChildrenPaths = [];
    const containerChildrenArray = pageConfigurationsManager.findConfigurationForPageSegment(IndexPathHelper.getLastPageSegmentFromPath(containerIndexPath)).config.cbaChildren;
    if (containerChildrenArray !== undefined) {
      containerChildrenArray.forEach((childConfig, index) => {
        if (childConfig.config.classifiers !== undefined && childConfig.config.classifiers.includes(classifier)) {
          matchingChildrenPaths.push(IndexPathHelper.appendIndexToPageSegment(containerIndexPath, index));
        }
      });
    }
    return matchingChildrenPaths;
  }

  static getIndexPathsForUserDefIds(userDefIdPaths, runtime) {
    return userDefIdPaths.map(userDefIdPath => PathTranslationHelper.getIndexPathForUserDefPath(userDefIdPath, runtime));
  }

  /**
   * For each given display component instance get the point that we should use for position compoarisons
   * according to the given anchor point type: topLeft/center/bottomLeft/...
   * 
   * @param {} indexPaths The index paths of the display component instances.
   * @param {*} anchorPointType The anchor point type.
   * @param {*} runtime The common runtime context structure.
   */
  static getComparePointsForIndexPaths(indexPaths, anchorPointType, runtime) {
    return indexPaths.map(indexPath => TermEvaluator.getComparePointForIndexPath(indexPath, anchorPointType, runtime));
  }

  /**
   * Get the point of the given display component instance that we should use for position compoarisons
   * according to the given anchor point type: topLeft/center/bottomLeft/...
   * 
   * @param {} indexPath The index path of the display component instance.
   * @param {*} anchorPointType The anchor point type.
   * @param {*} runtime The common runtime context structure.
   */
  static getComparePointForIndexPath(indexPath, anchorPointType, runtime) {
    const { componentStateManager, pageConfigurationsManager } = runtime;
    return TermEvaluator.getComparePoint(
      StateAttributeAccess.extractPosition(componentStateManager.findOrBuildStateForPathId(indexPath, runtime)),
      pageConfigurationsManager.findConfigurationForPageSegment(IndexPathHelper.getLastPageSegmentFromPath(indexPath)).config.position,
      anchorPointType
    );
  }

  static getComparePoint(point, positionFromConfig, anchorPointType) {
    switch (anchorPointType) {
      case 'topLeft':
        return {
          x: point.x,
          y: point.y
        }
      case 'bottomLeft':
        return {
          x: point.x,
          y: point.y + positionFromConfig.height
        }
      case 'topRight':
        return {
          x: point.x + positionFromConfig.width,
          y: point.y
        }
      case 'bottomRight':
        return {
          x: point.x + positionFromConfig.width,
          y: point.y + positionFromConfig.height
        }
      case 'center':
        return {
          x: point.x + (positionFromConfig.width / 2),
          y: point.y + (positionFromConfig.height / 2)
        }
      default:
        console.error(`Unknown anchor point type ${anchorPointType} -> use upper left corner instead.`);
        return {
          x: point.x,
          y: point.y
        }
    }
  }


  static findComponentByAbsoluteUserDefId(absoluteUserDefId, runtime) {
    return runtime.componentDirectory.findComponent(PathTranslationHelper.getIndexPathForUserDefPath(absoluteUserDefId, runtime));
  }

  static currentNode(params, runtime) {
    return TreeUtils.getCurrentNodePathId(params.absoluteUserDefId, runtime);
  }

  static getVisitedNodes(params, runtime) {
    return TreeUtils.getVisitedPathIds(params.absoluteUserDefId, runtime);
  }

  static matchNodes(params, runtime) {
    const { absoluteUserDefId, regularExpressions } = params;
    const matchedNodes = [];
    const nodePathIds = TreeUtils.getNodePathIds(absoluteUserDefId, runtime);
    regularExpressions.forEach((pattern) => {
      nodePathIds.forEach((nodePathId) => {
        if (TermEvaluator.matches({
          pattern,
          candidate: nodePathId
        }) && !matchedNodes.includes(nodePathId)) {
          matchedNodes.push(nodePathId);
        }
      })
    })

    return matchedNodes;
  }

  static matchNodesWithColumns(params, runtime) {
    const { absoluteUserDefId, regularExpressions } = params;
    const matchedNodes = [];
    if (regularExpressions.length > 0) {
      const matchedNodesByPatternId = TermEvaluator.matchNodes({
        absoluteUserDefId, regularExpressions: [regularExpressions[0]]
      }, runtime);
      const nodeToColumnValuesMap = TreeUtils.getColumnValuesMap(absoluteUserDefId, matchedNodesByPatternId, runtime);
      regularExpressions.shift();
      nodeToColumnValuesMap.forEach((values, key) => {
        if (TermEvaluator.columnsMatch(regularExpressions, values)) {
          matchedNodes.push(key);
        }
      });
    } else {
      console.log("Empty list of regular expressions!");
    }

    return matchedNodes;
  }

  static columnsMatch = (patterns, candidates) => {
    let hasMatch = true;
    if (patterns === undefined || patterns === null
      || patterns.length === 0) {
      return hasMatch;
    }

    for (let i = 0; i < patterns.length; i += 1) {
      if (!TermEvaluator.matches({
        pattern: patterns[i],
        candidate: candidates[i]
      })) {
        hasMatch = false;
        break;
      }
    }

    return hasMatch;
  }

  static treeAction(params, runtime, callback) {
    const { absoluteUserDefId, targetNode } = params;
    const nodePathIds = TermEvaluator.matchNodes(
      {
        absoluteUserDefId,
        regularExpressions: [targetNode]
      }, runtime
    );
    if (nodePathIds.length === 1) {
      callback(absoluteUserDefId, nodePathIds[0], runtime);
    }
  }

  static treeCopy(params, runtime) {
    TermEvaluator.treeAction(params, runtime, TreeUtils.treeCopyCurrentNode);
  }

  static treeMove(params, runtime) {
    TermEvaluator.treeAction(params, runtime, TreeUtils.treeMoveCurrentNode);
  }

}
