import PropTypes from 'prop-types';

class PropertyMissingException {

  constructor(message) {
    this.name = 'PropertyMissingException';
    this.message = message;
  }

  toString = () => `${this.name}: ${this.message}`;

}

export default class PropTypesHelper {

  static asOptional(propTypesRequirement) {
    return (props, propName, componentName) => {
      if (props[propName] === undefined) return undefined;
      return PropTypes.checkPropTypes(propTypesRequirement, props[propName], propName, componentName);
    }
  }


  static raiseError(message) {
    const exception = new PropertyMissingException(message);
    console.error(exception.toString());
  }

  static addPropTypes(propTypes, toAdd) {
    Object.keys(toAdd).forEach((key) => {
      propTypes[key] = toAdd[key]
    });
    return propTypes;
  }

  // common config property checking

  static getStandardRuntimePropTypes() {
    const result = {
      componentStateManager: PropTypes.object.isRequired,
      componentDirectory: PropTypes.object.isRequired,
      incidentsAccumulator: PropTypes.object.isRequired,
      taskResultsManager: PropTypes.object.isRequired,
      statemachinesManager: PropTypes.object.isRequired,
      presenterStateManager: PropTypes.object.isRequired,
      taskNavigatorStateManager: PropTypes.object.isRequired,
      traceLogBuffer: PropTypes.object.isRequired,
      traceLogUploader: PropTypes.object.isRequired,
      recordingBuffer: PropTypes.object.isRequired,
      recordingUploader: PropTypes.object.isRequired,
      clipboardManager: PropTypes.object.isRequired,
      pageConfigurationsManager: PropTypes.object.isRequired,
      taskManager: PropTypes.object.isRequired,
    };

    return result;
  }

  static positionPropsCheck(props, propName, componentName) {
    const propTypes = {
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static fontPropsCheck(props, propName, componentName) {
    const propTypes = {
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      alignmentHorizontal: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
      bold: PropTypes.bool.isRequired,
      italic: PropTypes.bool.isRequired,
      underlined: PropTypes.bool.isRequired,
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static getColorVariantsPropTypes() {
    return {
      // Note: manage transparency with rgba(..., ..., ..., 0) for the background color
      default: PropTypes.string.isRequired,
      // An empty object as value will not fall back to default color but display no color at all:
      disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      visited: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };
  }

  static colorPropsCheck(props, propName, componentName) {
    const propTypes = {
      text: PropTypesHelper.asOptional(PropTypesHelper.getColorVariantsPropTypes()),
      background: PropTypesHelper.asOptional(PropTypesHelper.getColorVariantsPropTypes())
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static borderPropsCheck(props, propName, componentName) {
    const propTypes = {
      style: PropTypes.oneOf(['none', 'solid', 'outset', 'inset']).isRequired,
      width: PropTypes.number,
      radius: PropTypes.number,
      color: PropTypesHelper.asOptional(PropTypesHelper.getColorVariantsPropTypes())
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static getTransformOriginPropTypes() {
    return {
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    };
  }

  static transformPropsCheck(props, propName, componentName) {
    const propTypes = {
      rotate: PropTypes.number,
      transformOrigin: PropTypesHelper.asOptional(PropTypesHelper.getTransformOriginPropTypes())
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static statePropsCheck(props, propName, componentName) {
    const propTypes = {
      disabled: PropTypes.bool.isRequired,
      selected: PropTypes.bool.isRequired,
      hidden: PropTypes.bool.isRequired,
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static textPropsCheck(props, propName, componentName) {
    const propTypes = {
      // TODO: maybe we should drop the number type here as it may lead to unexpected situation 
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mouseOver: PropTypes.string,
      dynamic: PropTypes.shape({
        variable: PropTypes.string.isRequired,
        valueMap: PropTypes.string
      })
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static imagePropsCheck(props, propName, componentName) {
    const propTypes = {
      displayMode: PropTypes.oneOf(['none', 'left', 'right', 'initial', 'overlay']).isRequired,
      overlayType: PropTypes.oneOf(['stretch', 'center', 'default', 'left']),
      default: PropTypes.string,
      // An empty object as value will not fall back to default image but display no image at all:
      disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      visited: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      dynamic: PropTypes.shape({
        variable: PropTypes.string.isRequired,
        valueMap: PropTypes.string
      })
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static eventPropsCheck(props, propName, componentName) {
    const propTypes = {
      standard: PropTypes.string,
      alternate: PropTypes.string,
      onFocusIn: PropTypes.string,
      onFocusOut: PropTypes.string
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static linkPropsCheck(props, propName, componentName) {
    const propTypes = {
      page: PropTypes.string,
      pageUrl: PropTypes.string,
      conditional: PropTypes.object,
      pageAreaType: PropTypes.oneOf(['main', 'dialog', 'modal']),
      pageAreaName: PropTypes.string,
      receiver: PropTypes.string,
      receiverTab: PropTypes.object,
      historyMove: PropTypes.oneOf(['home', 'forward', 'back']),
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static tracePropsCheck(props, propName, componentName) {
    const propTypes = {
      type: PropTypes.string.isRequired,
      addOn: PropTypes.object,
      skipTrace: PropTypes.bool.isRequired
    }
    return PropTypes.checkPropTypes(propTypes, props[propName], propName, componentName);
  }

  static getStandardConfigPropTypes(withChildren) {
    const result = {
      position: PropTypesHelper.positionPropsCheck,
      font: PropTypesHelper.fontPropsCheck,
      color: PropTypesHelper.colorPropsCheck,
      border: PropTypesHelper.borderPropsCheck,
      transform: PropTypesHelper.transformPropsCheck,
      state: PropTypesHelper.statePropsCheck,
      text: PropTypesHelper.textPropsCheck,
      image: PropTypesHelper.imagePropsCheck,
      event: PropTypesHelper.eventPropsCheck,
      classifiers: PropTypes.array.isRequired,
      link: PropTypesHelper.linkPropsCheck,
      userDefinedId: PropTypes.string,
      trace: PropTypesHelper.tracePropsCheck,
    };
    if (withChildren) {
      result.cbaChildren = PropTypes.array.isRequired;
    }
    return result;
  }

  /**
   * Add prop checking for controller configuration.
   */
  static addSelectGroupControllerConfigPropTypes(propTypes) {
    propTypes.selectGroupMode = PropTypesHelper.asOptional({
      blockSelectionChanges: PropTypes.bool.isRequired,
      enforceSingleSelect: PropTypes.bool.isRequired,
      allowDeselect: PropTypes.bool.isRequired,
      initiallySelected: PropTypes.number,
      deselectTarget: PropTypes.number,
      propagateChanges: PropTypes.bool.isRequired
    });
    return propTypes;
  }

  /**
   * Add prop checking for possibly controlled component configuration.
   */
  static addSelectGroupMemberConfigPropTypes(propTypes) {
    propTypes.selectGroupMember = PropTypes.bool.isRequired;
    return propTypes;
  }

  static getCbaTreeColumnConfig() {
    return {
      mouseOver: PropTypes.string,
      label: PropTypes.string,
      moveable: PropTypes.bool,
      resizable: PropTypes.bool,
      width: PropTypes.number
    }
  }

  static getCbaTreeRowConfig() {
    return {
      cells: PropTypes.array.isRequired,
      expanded: PropTypes.bool.isRequired,
      nodes: PropTypes.array.isRequired,
      imagePath: PropTypes.string,
      depth: PropTypes.number.isRequired,
      onRowExpandClick: PropTypes.func,
      onRowSelect: PropTypes.func.isRequired,
      path: PropTypes.string.isRequired
    }


  }


}
