import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaRadioButton extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    // Flip the selected state: 'yes' <-> 'no'
    this.updateStateAndTriggerRendering(pathState);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState) {
    const { runtime, path } = this.props;

    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, runtime);
    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);
  }

  render() {
    const { runtime, path, config, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);

    const text = RenderingHelper.extractMultilineText(CommonConfigHelper.buildDisplayText(config, pathState, runtime));
    const textWrap = config.textWrap === undefined ? false : config.textWrap;

    const wrapperStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, selectedState, orientation, runtime);
    CommonConfigHelper.setStyleAttribute(wrapperStyle, "backgroundImage", "none");


    const textWrapStyle = {
      whiteSpace: textWrap ? "initial" : "noWrap"
    }

    const forKey = shortid.generate();

    return (
      <div
        style={wrapperStyle}
        title={CommonConfigHelper.buildTitle(config)}
        className="inputWrapper overflow-hidden"
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
      >
        <div className="radioWrap">
          <label
            htmlFor={forKey}
            className="label"
            onClick={(event) => { event.stopPropagation(); }}
          >
            <span style={textWrapStyle}>
              {CommonConfigHelper.getImageTag(pathState, config, selectedState, runtime)}
              <span className="inputTextWrap">{text}</span>
            </span>
          </label>
          <input
            id={forKey}
            type="radio"
            checked={selectedState}
            disabled={StateAttributeAccess.extractDisabled(pathState)}
            // using a blank function to remove the warning src: https://stackoverflow.com/questions/36715901/reactjs-error-warning
            onChange={() => { }}
          />
        </div>
      </div>
    );
  }

}


CbaRadioButton.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        textWrap: PropTypes.bool.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
