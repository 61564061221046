import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateManagerHelper from '../../state/StateManagerHelper';
import RenderingHelper from '../RenderingHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess'
import CommonActionsHelper from '../CommonActionsHelper';
import './CbaList.css'
import ListItem from './ListItem';

/**
 * A display component that displays a list.
 * 
 * This component manages the items in the list as children of its own 
 * in the display component instances tree.
 */
export default class CbaList extends Component {

  constructor(props) {
    super(props);
    this.comboRef = React.createRef();
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  // basic state management --------------------------------------------------------------

  static getItemType() {
    return 'ListItem';
  }

  /**
   * Build an array of 'standard' configuration structures for the items in the items list.
   * The page configurations manager expects a structure with the attributes 'type' and 'config'. 
   * When accessing this structure the state manager expects a config.state object as a minimum. 
   */
  static buildListItemsArray(itemsInConfig) {
    return itemsInConfig.map((itemInConfig, index, all) => {
      const configOnPathSegment = StateManagerHelper.deepCopy(itemInConfig);
      const additionalState = {}
      StateAttributeAccess.setSelected(additionalState, false);
      StateAttributeAccess.setDisabled(additionalState, false);
      StateAttributeAccess.setHidden(additionalState, false);
      configOnPathSegment.state = additionalState;
      return {
        type: CbaList.getItemType(),
        config: configOnPathSegment
      }
    })
  }

  /**
   * Returns the array of selected ids stored in state
   */
  getSelectedIdsFromState = () => {
    const pathState = ComponentStateHelper.getState(this);
    return StateAttributeAccess.extractSelectedIds(pathState) || [];
  }


  /**
   * Change the selected settings for all list items to reflect
   * the currently selected items. 
   */
  setSelectedIdInState = (newSelectedId, isCtrlKeyEvent) => {
    const pathState = ComponentStateHelper.getState(this);
    let selectedIds = StateAttributeAccess.extractSelectedIds(pathState) || [];
    const selectGroupControllerState = StateAttributeAccess.extractSelectGroupControllerState(pathState);
    if (!isCtrlKeyEvent) {
      // clear the previous selections
      selectedIds = [];
    }

    if (selectGroupControllerState !== undefined
      && (selectGroupControllerState.singleSelectActive
      || !isCtrlKeyEvent)) {
      selectedIds[0] = newSelectedId;
    } else if (selectedIds.includes(newSelectedId)) {
      selectedIds = selectedIds.filter(value => value !== newSelectedId)
    } else {
      selectedIds.push(newSelectedId);
    }
    StateAttributeAccess.setSelectedIds(pathState, selectedIds);
    ComponentStateHelper.registerState(this, pathState);
  }

  // handle user triggered events ----------------------------------------------------------------

  onClickHandler = (event, index) => {
    const { runtime, config, path } = this.props;
    const selectedIds = this.getSelectedIdsFromState();
    const oldSelectedId = selectedIds[selectedIds.length - 1];
    const newSelectedId = parseInt(index, 10);
    const oldSelectedItem = config.items[oldSelectedId];
    const newSelectedItem = config.items[newSelectedId];

    this.updateStateAndTriggerRendering(newSelectedId, event);

    // CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path, CbaList.buildTraceDetailsAddOn(oldSelectedItem, oldSelectedId, newSelectedItem, newSelectedId), event, runtime);
    CommonActionsHelper.sendStandardEvent(newSelectedItem, runtime);
    const defaultLinkReceiver = CommonActionsHelper.getDefaultLinkReceiver(this);
    CommonActionsHelper.doPageSwitch(newSelectedItem.link, runtime, defaultLinkReceiver, path);
    event.stopPropagation();
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  static buildTraceDetailsAddOn(oldSelectedItem, oldSelectedId, newSelectedItem, newSelectedId) {
    // the first selection will not have an old selected id
    return {
      oldSelected: oldSelectedId,
      oldSelectedUserDefId: oldSelectedItem !== undefined ? oldSelectedItem.userDefinedId : undefined,
      newSelected: newSelectedId,
      newSelectedUserDefId: newSelectedItem.userDefinedId
    };
  }

  updateStateAndTriggerRendering = (newSelectedId, event) => {
    const { runtime, path } = this.props;
    this.setSelectedIdInState(newSelectedId, event.ctrlKey);
    RenderingHelper.triggerRenderingViaPath(path, runtime);
  }


  // rendering ---------------------------------------------------------------------------------------

  checkForInvalidItems = () => {
    const { config } = this.props;
    const { items } = config;
    return !Array.isArray(items) || (Array.isArray(items) && items.length === 0);
  }

  generateOptions = (itemsConfig, selectedIds, itemHeight) => itemsConfig.map((itemConfig, i) => (
    <ListItem
      config={itemConfig}
      selectedIds={selectedIds}
      itemHeight={itemHeight}
      onClickHandler={this.onClickHandler}
      index={i}
    />
  ))

  render() {
    if (this.checkForInvalidItems()) {
      return React.createElement(
        'div',
        null,
        `Invalid item configuration for list.`
      );
    }


    const { config, path, runtime, orientation } = this.props;
    const wrapperStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    const selectedIds = this.getSelectedIdsFromState() || [];
    const options = this.generateOptions(config.items, selectedIds, config.font.size * 1.9);

    return (
      <div
        className="list-container"
        style={wrapperStyle}
        title={CommonConfigHelper.buildTitle(config)}
      >
        <div className="list">
          {options}
        </div>
      </div>
    );
  }

}


CbaList.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupControllerConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        items: PropTypes.array.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}
