export default class CalculationsConfigurationManager {

  constructor(item) {
    this.tasksList = item.tasks;

  }

  /**
   * Find the calculation with the given name configured for the given task.
   * 
   * The method returns 'undefined' if no matching calculation exists.
   * 
   */
  findCalculationByTaskName = (taskName, calculationName) => {
    const calculationEntry = this.findAllCalculationsByTaskName(taskName).find((value, index, all) => value.name === calculationName);
    return calculationEntry === undefined ? undefined : calculationEntry.calculation;
  }

  /**
   * Find all named calculations configured for the given task.
   * 
   * The method returns a list of calculation configurations.
   * 
   */
  findAllCalculationsByTaskName = (taskName) => {
    const task = this.tasksList.find((value, index, theArray) => value.name === taskName);
    if (task === undefined) {
      console.error(`Cannot find task configuration for task name ${taskName}`);
      return [];
    }
    return task.namedCalculations;
  }

  /**
   * Find the score result list with the given name for the given task.
   */
  findScoreResultListByTaskName = (taskName, listName) => {
    const allResultLists = this.findAllScoreResultListsByTaskName(taskName);
    const list = allResultLists.find((value, index, theArray) => value.name === listName);
    if (list === undefined) {
      console.log(`Cannot find score result list configuration for task name ${taskName} and list name ${listName}`);
      return [];
    }
    return list.elements;
  }


  /**
   * Find all result lists that are part of the score result for a given task.
   * 
   */
  findAllScoreResultListsByTaskName = (taskName) => {
    const task = this.tasksList.find((value, index, theArray) => value.name === taskName);
    if (task === undefined) {
      console.error(`Cannot find task configuration for task name ${taskName}`);
      return [];
    }
    return task.scoreResult.lists;
  }

  /**
   * Find the attributes structure of the score result for a given task.
   */
  findScoreResultAttributesByTaskName = (taskName) => {
    const task = this.tasksList.find((value, index, theArray) => value.name === taskName);
    if (task === undefined) {
      console.error(`Cannot find task configuration for task name ${taskName}`);
      return [];
    }
    return task.scoreResult.attributes;
  }


}
