import IndexPathHelper from "./IndexPathHelper";
import StateAttributeAccess from "./StateAttributeAccess";
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateManagerHelper from "./StateManagerHelper";
import PathTranslationHelper from "./PathTranslationHelper";
import SelectGroupHelper from '../components/SelectGroupHelper';
import CbaPageArea from '../components/CbaPageArea';
import CbaRichTextField from '../components/CbaRichTextField/CbaRichTextField';
import CbaMedia from '../components/media/CbaMedia';
import CbaTable from '../components/table/CbaTable';
import InputComponent from "../components/InputComponent";
import CbaTree from "../components/CbaTree/CbaTree";
import CbaExternalPageFrame from "../components/CbaExternalPageFrame";
import CbaSimpleTextField from "../components/CbaSimpleTextField";

export default class ComponentStateManager {

  constructor() {
    this.stateMap = {};
  }

  /**
   * Clear all content in the state manager.
   */
  clear = () => {
    this.stateMap = {};
  }

  /**
   * Get a snapshot representation of the full state. 
   * 
   * The result differs from our internal state representation: 
   * For display components that deposit state that cannot be cloned we use a clonable representation.
   * To obtain the cloneable representation we ask the display component in charge.
   * 
   * @param {ComponentDirectory} componentDirectory The directory of all currently visible component instances.
   */
  getStateSnapshot = componentDirectory => this.getSnapshot(entry => true, componentDirectory);


  /**
   * Get a snapshot representation of all state belonging to the specified task. 
   * 
   * The result differs from our internal state representation: 
   * For display components that deposit state that cannot be cloned we use a clonable representation.
   * To obtain the cloneable representation we ask the display component in charge.
   * 
   * @param {ComponentDirectory} componentDirectory The directory of all currently visible component instances.
   */
   getTaskSnapshot = (test, item, task, componentDirectory) => this.getSnapshot(entry => (IndexPathHelper.getRootFromPath(entry[0]) === IndexPathHelper.buildPathRoot(test, item, task)), componentDirectory);


  /**
   * Preload the given snapshot as our state memory. 
   * 
   * The expected snapshot structure differs from our internal state representation: 
   * For display components that deposit state that cannot be cloned we expect a clonable representation.
   * To obtain the internal representation from the cloneable representation we ask the display component in charge.
   */
  preloadWithStateSnapshot = (snapshot) => {
    this.stateMap = ComponentStateManager.fromSnapshotRepresentation(Object.entries(snapshot));
  }

  /**
   * Register the given state for the given pathId.
   * 
   * The method stores a 'deep copy' of the state depending on the deepCopy flag in the state. 
   */
  registerStateByPathId = (pathId, state) => {
    this.stateMap[pathId] = ComponentStateManager.doDeepCopyIfRequired(state);
  }

  /**
   * Find the state for the given path id. 
   * The method implicitly tries to create an initial state if there is no
   * state registered yet.
   * This will fail if the task loaded in the task/page manager in the runtime 
   * does not match the task of the given path id. 
   */
  findOrBuildStateForPathId = (pathId, runtime) => this.findOrBuildStateForPathIdInternal(pathId, runtime);


  /**
   * Register the given state with a pathId corresponding to the given userDefPath. 
   * The method will build the pathId using the path root currently loaded in the given runtime.
   */
  registerStateByUserDefPath = (userDefPath, state, runtime) => {
    this.registerStateByPathId(PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime), state);
  }


  /**
   * Find the state for the given userDefPath. 
   * The method implicitly tries to create an initial state if there is no
   * state registered yet. The method will implicitly register the created 
   * initial state with a pathId using the path root currently loaded
   * in the given runtime.
   */
  findOrBuildStateByUserDefPath = (userDefPath, runtime) => this.findOrBuildStateForPathId(PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime), runtime);


  /**
   * Return all already existing index paths that pass the given filter.
   */
  filterExistingPathIds = filterMethod => Object.keys(this.stateMap).filter(key => filterMethod(key));


  // private stuff ------------------------------------------------------------------------------------


  findOrBuildStateForPathIdInternal = (pathId, runtime) => {
    const registered = ComponentStateManager.doDeepCopyIfRequired(this.stateMap[pathId]);
    if (registered !== undefined) return registered;

    const rootFromPath = IndexPathHelper.getRootFromPath(pathId);
    const rootInRuntime = runtime.taskManager.getCurrentStatePathRoot();
    if (!rootFromPath === rootInRuntime) {
      console.warn(`Cannot create state for ${pathId} since runtime is loaded for ${rootInRuntime}`);
      return undefined;
    }

    const initialState = ComponentStateManager.buildStateFromConfig(pathId, runtime);
    this.registerStateByPathId(pathId, initialState);

    return initialState;
  }

  static buildStateFromConfig(pathId, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(pathId);
    if (pageSegment === undefined) {
      console.warn(`Empty page segment chopped off from path id ${pathId}`);
    }
    const { pageConfigurationsManager } = runtime;
    const componentConfiguration = pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    if (componentConfiguration === undefined) {
      console.error(`Cannot find configuration for path ${pageSegment}`);
      return undefined;
    }
    const { config, type } = componentConfiguration;
    const result = {};

    // store and return deep copies of state per default (components may change this in their addAttributesToInitialState methods):
    StateAttributeAccess.setDeepCopy(result, true);
    // mark state as 'not volatile' per default (components may change this in their addAttributesToInitialState methods):
    StateAttributeAccess.setVolatile(result, false);

    StateAttributeAccess.setDisabled(result, CommonConfigHelper.getDisabled(config));
    StateAttributeAccess.setHidden(result, CommonConfigHelper.getHidden(config));
    StateAttributeAccess.setVisited(result, false);
    StateAttributeAccess.setSelected(result, CommonConfigHelper.getSelected(config));
    const positionInConfig = CommonConfigHelper.getPosition(config);
    if (positionInConfig !== undefined) {
      StateAttributeAccess.setPosition(result, {
        x: positionInConfig.x,
        y: positionInConfig.y
      });
    }
    StateAttributeAccess.setDefaultLinkReceiver(result, ComponentStateManager.calculateDefaultLinkReceiver(pathId, pageConfigurationsManager));
    if (config.text !== undefined && config.text.label !== undefined) {
      StateAttributeAccess.setTextValue(result, config.text.label)
    }
    const dragAndDropInConfig = CommonConfigHelper.getDragAndDrop(config);
    StateAttributeAccess.setDragAndDrop(result, {
      isSender: (dragAndDropInConfig !== undefined && dragAndDropInConfig.sender !== undefined),
      isReceiver: (dragAndDropInConfig !== undefined && dragAndDropInConfig.receiver !== undefined)
    });
    SelectGroupHelper.addSelectGroupControllerState(result, config);
    SelectGroupHelper.addSelectGroupMemberInfo(result, type, pathId, runtime);
    switch (type) {
      case "CbaSingleLineInputField":
        InputComponent.addAttributesToInitialState(result, config);
        break;
      case "CbaSimpleTextField":
        CbaSimpleTextField.addAttributesToInitialState(result, config, runtime);
        break;
      case "CbaPageArea":
        CbaPageArea.addAttributesToInitialState(result, config);
        break;
      case "CbaRichTextField":
        CbaRichTextField.addAttributesToInitialState(result, config);
        break;
      case "CbaMedia":
        CbaMedia.addAttributesToInitialState(result, config);
        break;
      case "CbaTable":
        CbaTable.addAttributesToInitialState(result, config);
        break;
      case "CbaInputField":
        InputComponent.addAttributesToInitialState(result, config);
        break;
      case "CbaTreeChildArea":
        CbaPageArea.addAttributesToInitialState(result, config);
        break;
      case "CbaTree":
        CbaTree.addAttributesToInitialState(result, config, pathId, runtime);
        break;
      case "CbaExternalPageFrame":
        CbaExternalPageFrame.addAttributesToInitialState(result, config);
        break;
      default:
      // do nothing here
    }
    return result;
  }

  /**
   * Calculate the default link receiver for page links that don't explicitly specify 
   * a receiver.
   * 
   * The method calculates the default receiver as follows:
   * - Starting from the given display component instance we climb up the
   *   tree of embedding CbaPageAreas (i.e. the page segments in the index path).
   * - The first CbaPageArea with its 'catchLinks' configuration option set to true 
   *   becomes the default receiver. 
   * - If there is not such CbaPageArea, the default receiver is 'undefined'. 
   * 
   * The method returns 'undefined' if an error occurs. 
   * 
   * @param {*} path The index path of the display component instance.
   * @param {*} pageConfigurationManager The page configuration manager providing static display component configurations.
   */
  static calculateDefaultLinkReceiver(path, pageConfigurationsManager) {
    // We ignore the last display component instance which can be any type of component, 
    // i.e. it might not even have a catch links configuration setting.
    let remainingPath = IndexPathHelper.dropPageSegmentFromPath(path);

    if (remainingPath !== undefined) {
      let pageSegment = IndexPathHelper.getLastPageSegmentFromPath(remainingPath);
      while (pageSegment !== undefined) {
        const isLinkCatcher = ComponentStateManager.isLinkCatcher(pageSegment, pageConfigurationsManager, path);
        if (isLinkCatcher === undefined) {
          return undefined;
        }
        if (isLinkCatcher) {
          return IndexPathHelper.trimRootAndPageAreaFromPath(remainingPath);
        }
        remainingPath = IndexPathHelper.dropPageSegmentFromPath(remainingPath);
        pageSegment = IndexPathHelper.getLastPageSegmentFromPath(remainingPath);
      }
    }
    return undefined;
  }

  /**
   * Does the display component specified by the given index pageSegment catch links? 
   * 
   * The method returns undefined if the specified component does not have a catch link setting in its static configuration. 
   * 
   * @param {*} pageSegment The page segment specifying the display component.
   * @param {*} pageConfigurationManager The page configuration manager providing static display component configurations.
   * @param {*} path The full path (used for log messages only).
   */
  static isLinkCatcher(pageSegment, pageConfigurationsManager, path) {
    // The last display component of a page segment that is followed by another page segment
    // has to be a CbaPageArea component (or more specifically: it has to have a 'catchLink' property at least).
    const linkCatcherConfiguration = pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    if (linkCatcherConfiguration === undefined) {
      console.error(`Missing page embedding component at end of inner page segment: ${pageSegment} in path ${path}`);
      return undefined;
    }
    const catchLinkSetting = linkCatcherConfiguration.config.catchLinks;
    if (catchLinkSetting === undefined) {
      console.error(`Missing catch link configuration for page embedding component at end of inner page segment: ${pageSegment} in path ${path}`);
      return undefined;
    }
    return catchLinkSetting === true;
  }

  static doDeepCopyIfRequired(state) {
    if (state === undefined) return undefined;

    // set deepCopy per default:
    if (StateAttributeAccess.extractDeepCopy(state) === undefined) {
      StateAttributeAccess.setDeepCopy(state, true);
    }

    return StateAttributeAccess.extractDeepCopy(state) === false ? state : StateManagerHelper.deepCopy(state);
  }

  /**
   * Apply updateVolatiles and toSnapshotRepresentation on those entries in our state map that match the given filter.
   */
  getSnapshot(entryFilter, componentDirectory) {
    ComponentStateManager.updateVolatiles(Object.entries(this.stateMap).filter(entryFilter), componentDirectory);
    return ComponentStateManager.toSnapshotRepresentation(Object.entries(this.stateMap).filter(entryFilter));
  }

  /**
   * Update all state entries marked as 'volatile' by calling the update method on their component instances.
   * 
   * The method skips component instances that are not visible currently. 
   * 
   * @param {*} entries The entries to be updated.
   * @param {ComponentDirectory} componentDirectory The directory of all currently visible component instances.
   */
  static updateVolatiles(entries, componentDirectory) {
    entries.forEach((entry) => {
      const [path, state] = entry;
      if (StateAttributeAccess.extractVolatile(state)) {
        const componentInstance = componentDirectory.findComponent(path);
        if (componentInstance !== undefined) {
          // Components that set the 'volatile' flag to true must implement a 'updateStateInComponentStateManager method:
          componentInstance.updateStateInComponentStateManager();
        }
      }
    });
  }

  /**
   * Create a snapshot representation for the given list of state entries.
   * 
   * The snapshot representation is cloneable.
   * 
   * 
   * @param {*} entries The entries to be transformed
   */
  static toSnapshotRepresentation(entries) {
    const result = {};
    entries.forEach((entry) => {
      const [path, state] = entry;
      if (StateAttributeAccess.extractDeepCopy(state)) {
        result[path] = StateManagerHelper.deepCopy(state);
      } else {
        result[path] = ComponentStateManager.toSnapshotRepresentationByComponent(path, state);
      }
    });
    return result;
  }

  /**
   * Create a proper component state representation for the given list of snapshot entries.
   * 
   * @param {*} entries The snapshot entries to be transformed.
   */
  static fromSnapshotRepresentation(entries) {
    const result = {};
    entries.forEach((entry) => {
      const [path, state] = entry;
      if (StateAttributeAccess.extractDeepCopy(state)) {
        result[path] = StateManagerHelper.deepCopy(state);
      } else {
        result[path] = ComponentStateManager.fromSnapshotRepresentationByComponent(path, state);
      }
    });
    return result;
  }

  static toSnapshotRepresentationByComponent(path, state) {
    const componentClassName = StateAttributeAccess.extractComponentClassName(state);
    if (componentClassName === undefined) {
      console.error(`Cannot create snapshot for component state in path ${path}`, state);
      return undefined;
    } else {
      switch (componentClassName) {
        case "CbaRichTextField":
          return CbaRichTextField.toSnapshot(path, state);
        case "CbaTable":
          return CbaTable.toSnapshot(path, state);
        default:
          console.error(`Unexpected component class ${componentClassName} for snapshot in path ${path}`, state);
          return undefined;
      }
    }
  }

  static fromSnapshotRepresentationByComponent(path, state) {
    const componentClassName = StateAttributeAccess.extractComponentClassName(state);
    if (componentClassName === undefined) {
      console.error(`Cannot extract component state from snapshot for path ${path}`, state);
      return undefined;
    } else {
      switch (componentClassName) {
        case "CbaRichTextField":
          return CbaRichTextField.fromSnapshot(path, state);
        case "CbaTable":
          return CbaTable.fromSnapshot(path, state);
        default:
          console.error(`Unexpected component class ${componentClassName} in snapshot in path ${path}`, state);
          return undefined;
      }
    }
  }

}
