import TermEvaluator from './TermEvaluator';

export default class ConditionalLinkHelper {

  static evaluateConditionalLink(conditionalLink, runtime) {
    if (conditionalLink === undefined) {
      return undefined;
    }

    // find the first branch that fulfills its condition:
    const { branches } = conditionalLink;
    if (branches === undefined) {
      ConditionalLinkHelper.logUndefinedMessage('branches array');
      ConditionalLinkHelper.logCalculation('undefined (branches undefined)');
      return undefined;
    }

    const chosenBranch = branches.find((branch, index) => {
      if (branch.condition === undefined) {
        ConditionalLinkHelper.logUndefinedMessage('condition in branch');
        return false;
      }
      return TermEvaluator.evaluateTerm(branch.condition, runtime, [], `guard[${index}]`) === true
    });
    if (chosenBranch === undefined) {
      ConditionalLinkHelper.logCalculation('undefined (no branch chosen)');
      return undefined;
    }

    // execute the actions of the fulfilling branch:
    if (chosenBranch.actions === undefined) {
      ConditionalLinkHelper.logUndefinedMessage('actions in chosen branch');
    } else {
      chosenBranch.actions.forEach((action, index) => {
        if (action === undefined) {
          ConditionalLinkHelper.logUndefinedMessage('action in action array of chosen branch');
        } else {
          TermEvaluator.evaluateTerm(action, runtime, [], `action[${index}]`);
        }
      });
    }

    if (chosenBranch.page === undefined) {
      ConditionalLinkHelper.logUndefinedMessage('page in selected branch');
      ConditionalLinkHelper.logCalculation('undefined (no page in chosen branch)');
      return undefined;
    }

    const result = chosenBranch.page;
    ConditionalLinkHelper.logCalculation(result);
    return result;
  }


  static logUndefinedMessage(propertyName) {
    console.log(`Conditional link definition contains undefined ${propertyName}`);
  }

  static logCalculation(result) {
    console.log(`Conditional link evaluates to ${result}`);
  }

}
