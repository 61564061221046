import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from "react-data-grid";
import PropTypesHelper from '../PropTypesHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import RenderingHelper from '../RenderingHelper';
import TreeUtils from './TreeUtils';
import { CbaTreeRow } from './DataGridRenderers';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import TreeTraceHelper from './TreeTraceHelper';
import CommonActionsHelper from '../CommonActionsHelper';


export default class CbaTreeView extends Component {

  constructor(props) {
    super(props);

    const { runtime } = this.props;

    this.eventEmitter = runtime.eventEmitter;
  }

  componentDidMount() {
    const { path, config } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);

    this.subscription = this.eventEmitter.addListener(TreeUtils.getTreeEventChangeName(treePath), this.handleTreeChange);

    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
    this.subscription.remove();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  handleTreeChange = () => {
    RenderingHelper.triggerRendering(this);
  }

  onRowSelect = (event, rowData) => {
    event.preventDefault();
    event.stopPropagation();

    const { path, config } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);

    TreeUtils.selectNodeAction(treePath, rowData);

    // reset sort and triggers rendering;
    // cannot visually reset sort due to library only allowing setting of current column and direction only on first init.
    // this.handleGridSort(null, "NONE");
  }

  handleGridSort = (columnId, sortDirection) => {
    const { path, runtime } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setTreeViewSort(pathState, {
      type: sortDirection,
      column: columnId
    });

    TreeTraceHelper.traceSort(path, columnId, sortDirection, runtime);

    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  onClickHandler = (event) => {
    const { path, runtime } = this.props;
    TreeTraceHelper.traceNodeAction("emptySelection", undefined, {
      isTreeView: true,
      treeViewPath: path,
      cells: []
    }, runtime);
    CommonActionsHelper.stopEventPropagation(event);
  }

  onContextMenuHandler = (event) => {
    const { path, runtime } = this.props;
    TreeTraceHelper.traceNodeAction("emptySelection", undefined, {
      isTreeView: true,
      treeViewPath: path,
      cells: []
    }, runtime);
  }

  addRowReferences = (row = {}) => {
    const { runtime, path, config } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);
    const { nodeTypes, treeReadOnly } = TreeUtils.getTreeDataByPath(treePath, runtime)

    row.onRowSelect = this.onRowSelect;
    row.updateOnSingleClick = false;
    row.isTreeView = true;

    row.onRowExpandClick = () => { };

    row.runtime = runtime;
    row.nodeTypes = nodeTypes;
    row.treePath = treePath;
    row.treeViewPath = path;

    row.isReadOnly = treeReadOnly;
    return row;
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const { filterLeaves, sortable, treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);
    const pathState = ComponentStateHelper.getState(this);
    const sort = StateAttributeAccess.extractTreeViewSort(pathState) || {
      type: "NONE",
      column: null
    };

    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    const { treeNodes, treeColumns, treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const { currentNode } = treeState;

    let treeViewState = StateAttributeAccess.extractTreeState(pathState);

    // init tree view state if not initialised 
    if (!treeViewState) {
      treeViewState = TreeUtils.initTreeState(treeColumns);

      StateAttributeAccess.setTreeState(pathState, treeViewState);
      ComponentStateHelper.registerState(this, pathState);
    }

    const displayNodes = TreeUtils.getTreeViewDisplayNodes(treeNodes, filterLeaves, currentNode, sort) || [];
    const displayColumns = TreeUtils.getDisplayColumns(treeColumns, sortable, treeViewState.modifiedColumns, path, runtime) || [];
    const hideColumnsHeader = TreeUtils.isDefaultColumn(treeColumns);

    return (
      <div
        style={style}
        className={hideColumnsHeader ? "hideColumns" : ""}
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        onScroll={event => event.stopPropagation()} // prevent scroll propagation (stops tracing)
        title={CommonConfigHelper.buildTitle(config)}
      >
        <ReactDataGrid
          columns={displayColumns}
          headerRowHeight={TreeUtils.const.COLUMN_HEIGHT}
          rowHeight={TreeUtils.const.ROW_HEIGHT} // this needs to be provided by generator by computing font in when no columns available
          rowGetter={index => this.addRowReferences(displayNodes[index])}
          rowsCount={displayNodes.length}
          rowRenderer={CbaTreeRow}
          onGridSort={this.handleGridSort}
          sortColumn={sort.column}
          sortDirection={sort.type}
          onColumnResize={(columnIndex, newWidth) => TreeUtils.handleColumnResize(displayColumns[columnIndex].key, newWidth, this)}
        />
      </div>
    )
  }

}

CbaTreeView.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        filterLeaves: PropTypes.bool.isRequired,
        sortable: PropTypes.bool.isRequired,
        treePathId: PropTypes.string.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
