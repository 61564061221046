import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import RenderingHelper from './RenderingHelper';
import TableHelper from './table/TableHelper';
import InputComponent from './InputComponent';
import IndexPathHelper from '../state/IndexPathHelper';

export default class CbaSingleLineInputField extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.blockedDynamicUpdateText = undefined;
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { runtime, config, path, isInEditMode } = this.props;
    const { isSpreadsheet } = config;
    if (isSpreadsheet) {
      runtime.eventEmitter.addListener(`${TableHelper.buildTablePath(path)}-inputContentUpdate`, this.onContentUpdate.bind(this));

      if (isInEditMode) {
        this.focus();
      }
    }
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  focus = () => {
    this.inputRef.current.focus();
  }

  // ------------- Table specific handlers ------------------- //

  onContentUpdate(value, receivedRow, receivedColumn) {
    const { config } = this.props;
    const { row, column } = config;
    if (row === receivedRow && column === receivedColumn) {
      const pathState = ComponentStateHelper.getState(this);

      // update our full state in state manager:
      StateAttributeAccess.setTextValue(pathState, value);
      StateAttributeAccess.setVisited(pathState, true);
      ComponentStateHelper.registerState(this, pathState);
    }
  }

  onKeyDownHandler = (e) => {
    const { path, runtime, config, isInEditMode } = this.props;
    TableHelper.handleKeyDown(e, runtime, config, TableHelper.buildTablePath(path), isInEditMode);
  }

  onFocusHandler = () => {
    const { path, runtime, isInEditMode } = this.props;
    InputComponent.onFocusHandler(this);
    TableHelper.handleCellFocus(runtime, TableHelper.buildTablePath(path), isInEditMode);
  }

  onKeyPressOnInput = (e) => {
    const { runtime, config, path, isInEditMode, onBlur } = this.props;
    // handle 'Enter' key pressed only we are inside of a table cell 
    if (isInEditMode) {
      const { row, column } = config;
      if (e.key === 'Enter') {
        runtime.eventEmitter.emit(`${TableHelper.buildTablePath(path)}-contentUpdate`, e.target.value, row, column, false);
        onBlur(e);
        e.preventDefault();
      }
    }
  }


  render() {
    const { config, path, runtime, orientation, isInEditMode, onBlur, title } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selected = StateAttributeAccess.extractSelected(pathState);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selected, orientation, runtime);

    if (isInEditMode) {
      // use the evaluated value as initial text if the config text is a formula
      const textValue = StateAttributeAccess.extractTextValue(pathState);
      if (textValue !== undefined && textValue === config.text.label
        && TableHelper.isFormula(textValue)) {
        const cellPath = IndexPathHelper.dropIndexFromPageSegment(path);
        const cellPathState = runtime.componentStateManager.findOrBuildStateForPathId(cellPath, runtime);
        const cellValue = StateAttributeAccess.extractTextValue(cellPathState);
        StateAttributeAccess.setTextValue(pathState, cellValue);
      }

      // force border style when in table cell
      CommonConfigHelper.setStyleAttribute(style, "zIndex", 1);
      CommonConfigHelper.setStyleAttribute(style, "boxShadow", "rgb(79, 124, 177) 0px 0px 5px");
    }

    const value = this.blockedDynamicUpdateText === undefined ? CommonConfigHelper.buildDisplayText(config, pathState, runtime) : this.blockedDynamicUpdateText;
    this.blockedDynamicUpdateText = undefined;
    const userInteraction = config.isVariableValueInput ? 'ValueInputModified' : 'SingleLineInputFieldModified';
    const traceType = config.isVariableValueInput ? 'ValueInputField_onChange' : 'CbaSingleLineInputField_onChange';

    return (
      <input
        ref={this.inputRef}
        type="text"
        title={isInEditMode ? title : CommonConfigHelper.buildTitle(config)}
        style={style}
        readOnly={config.readOnly}
        disabled={StateAttributeAccess.extractDisabled(pathState)}
        value={value}
        onClick={event => InputComponent.onClickHandler(this, event)}
        onContextMenu={event => InputComponent.onContextMenuHandler(this, event)}
        onChange={event => InputComponent.onChangeHandler(this, event, userInteraction, traceType)}
        onSelect={event => InputComponent.onSelectionHandler(this, event)}
        onBlur={e => (isInEditMode ? onBlur(e, StateAttributeAccess.extractTextValue(pathState), false) : InputComponent.onBlurHandler(this))}
        onFocus={this.onFocusHandler}
        onKeyPress={this.onKeyPressOnInput}
        onKeyDown={this.onKeyDownHandler}
        onCut={event => InputComponent.onCutCopyPaste(this, event)}
        onCopy={event => InputComponent.onCutCopyPaste(this, event)}
        onPaste={event => InputComponent.onCutCopyPaste(this, event)}
      />
    );
  }

}


CbaSingleLineInputField.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        validationPattern: PropTypes.string,
        sourceInputPath: PropTypes.string
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired,
  isInEditMode: PropTypes.bool,
  onBlur: PropTypes.func,
  title: PropTypes.string
}

CbaSingleLineInputField.defaultProps = {
  isInEditMode: false,
  onBlur: () => {},
  title: ""
}
