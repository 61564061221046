import TermEvaluator from '../eval/TermEvaluator';
import StateManagerHelper from './StateManagerHelper';

export default class TaskResultsManager {

  constructor() {
    this.taskEntriesList = {};
  }

  // set values ------------------------------------------------------------------

  /**
   * Calculate the results of all given named calculations for the given task and
   * store them in the task results catalog.
   */
  saveTaskResults = (taskPath, calculations, runtime) => {
    this.taskEntriesList[taskPath] = {};
    const resultsForTask = this.taskEntriesList[taskPath];


    calculations.forEach((value, index, all) => {
      resultsForTask[value.name] = TermEvaluator.evaluateTerm(value.calculation, runtime, [], value.name);
    });

  }


  // get values --------------------------------------------------------------------

  /**
   * Get a stored result for a given named calculation in the given task.
   */
  getResult = (taskPath, calculationName) => this.getResultsListForTask(taskPath)[calculationName];


  /**
   * Get all stored calculation results for the given task.
   */
  getResultsListForTask = (taskPath) => {
    if (this.taskEntriesList[taskPath] === undefined) {
      return {};
    }
    return this.taskEntriesList[taskPath];
  }

  /**
   * Get the stored calculation results for all existing tasks.
   * 
   * Use the result of this method as parameter to preloadTasksState to preload another instance to our current state. 
   */
  getAllTasksState = () => StateManagerHelper.deepCopy(this.taskEntriesList);

  /**
   * Drop stored calculation results for all tasks.
   */
  clearTasksState = () => { this.taskEntriesList = {}; };

  /**
   * Preload the stored calculation results for all tasks returned by a call to getAllTasksState.
   */
  preloadTasksState = (allTasksState) => {
    this.taskEntriesList = StateManagerHelper.deepCopy(allTasksState);
  }

}
