import TermEvaluator from "./TermEvaluator";

/**
 * Service to map values to strings using value maps configured in the item configuration.
 */
export default class ValueMapper {

  // ---------- public API ---------------------------------------------------------------------

  /**
   * Build a value mapper service. 
   */
  constructor(item, runtime) {
    this.valueMaps = item.valueMaps;
    this.runtime = runtime;
  }

  /**
   * Map a value using the specified map.
   * 
   * The method returns undefined if the specified value map does not exist 
   * or the given value cannot not find an accepting guard.
   */
  getMappedValue = (valueMapName, valueToMap) => {
    const valueMapToUse = this.valueMaps.find(valueMap => valueMap.name === valueMapName);
    if (valueMapToUse === undefined) {
      return undefined;
    }

    const acceptingGuard = valueMapToUse.guards.find(
      (guard, index) => TermEvaluator.evaluateTerm(guard.guardTerm, this.runtime, [valueToMap], `guard[${index}]_${valueMapName}`) === true
    );
    if (acceptingGuard === undefined) {
      return undefined;
    }

    return acceptingGuard.value;
  }

}
