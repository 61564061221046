import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import CommonActionsHelper from "./CommonActionsHelper";
import PathTranslationHelper from '../state/PathTranslationHelper';
import RenderingHelper from './RenderingHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';

export default class CbaExternalPageFrame extends Component {

  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();

  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    this.restoreIframeState();
  }

  componentWillUnmount() {
    this.saveIframeState();
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  // ----------- snapshot related ------------------------------------------------------------------------
  /**
   * Build the initial state in the component state manager. 
   * 
   * The component state manager will call this method to initialize state for new display component instances.
   */
  static addAttributesToInitialState(initialState, configProps) {
    // activate volatile flag in state manager since state of external java code is not fully synchronized. 
    StateAttributeAccess.setVolatile(initialState, true);
    StateAttributeAccess.setComponentClassName(initialState, "CbaExternalPageFrame");
  }

  updateStateInComponentStateManager() {
    this.saveIframeState();
  }

  saveIframeState = () => {
    const iframeWindow = this.iframeRef.current.contentWindow;
    if (iframeWindow === undefined || iframeWindow === null) {
      const { path } = this.props;
      console.error(`No window in IFrame! -> We don't save state of code running in IFrame at path ${path}.`);
      return;
    }
    try {
      if (iframeWindow.getState !== undefined) {
        const stateToSave = iframeWindow.getState();
        const pathState = ComponentStateHelper.getState(this);
        StateAttributeAccess.setExternalContentState(pathState, stateToSave);
        ComponentStateHelper.registerState(this, pathState);
      } else {
        console.log(`No getState method found. ${this.reactionMessage(true)}`);
      }
    } catch (exception) {
      if (CbaExternalPageFrame.isPermissionDeniedException(exception)) {
        console.log(`We don't save state of code running in IFrame from another orgin. URL in IFrame is ${this.iframeRef.current.src}`);
      } else {
        console.error(`Exception while trying to save state. ${this.reactionMessage(true)}`, exception);
      }
    }

  }

  restoreIframeState = () => {
    const pathState = ComponentStateHelper.getState(this);
    const savedState = StateAttributeAccess.extractExternalContentState(pathState);
    if (savedState !== undefined) {
      setTimeout(() => CbaExternalPageFrame.processSaveTimeout(this, savedState, 100), 20)
    }
  }

  static processSaveTimeout(component, stateToRestore, callCount) {
    const iframeWindow = component.iframeRef.current.contentWindow;
    if (iframeWindow === undefined || iframeWindow === null) {
      console.error(`No window in IFrame! ${component.reactionMessage(false)}`);
      return;
    }
    try {
      if (iframeWindow.setState !== undefined) {
        iframeWindow.setState(stateToRestore);
      } else if (callCount > 0) {
        setTimeout(() => CbaExternalPageFrame.processSaveTimeout(component, stateToRestore, callCount - 1), 20);
      } else {
        console.log(`No setState method found. ${component.reactionMessage(false)}`);
      }
    } catch (exception) {
      if (CbaExternalPageFrame.isPermissionDeniedException(exception)) {
        console.log(`We don't restore state of code running in IFrame from another orgin. URL in IFrame is ${component.iframeRef.current.src}`);
      } else {
        console.error(`Exception while trying to restore state ${component.reactionMessage(false)}`, exception);
      }
    }
  }

  static isPermissionDeniedException(exception) {
    return exception.message.startsWith('Permission denied to access property')
  }

  reactionMessage = (isSaving) => {
    const { path, runtime } = this.props;
    const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(path, runtime);
    const userDefIdPathMessage = userDefIdPath === undefined ? "" : ` (user defined ID path: ${userDefIdPath})`;
    return ` -> We don't ${isSaving ? "save" : "restore"} state of code running in IFrame at index path ${path}${userDefIdPathMessage}.`
  }

  onClick = (event) => {
    const { config, path, runtime } = this.props;
    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path, undefined, event, runtime);
  }

  static buildIframeUrl(configPageAddress, indexPath, runtime) {
    const url = CommonConfigHelper.getProperResourcePathExternalResources(configPageAddress, runtime);

    if (configPageAddress.startsWith('http')) {
      // register foreign http server as source for post message events:
      runtime.postMessageReceiver.registerAcceptableUrlForExternalPageFrameEvent(url);
      return url;
    } else {
      const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(indexPath, runtime);

      // add additional URL parameters for index path and user defined Id path
      const urlContainsParametersAlready = url.indexOf('?') !== -1;
      return `${url + (urlContainsParametersAlready ? '&' : '?')}indexPath=${indexPath}&userDefIdPath=${userDefIdPath}`;
    }

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  render() {
    const { path, config, runtime, orientation } = this.props;

    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    CommonConfigHelper.setStyleAttribute(style, "resize", "none");

    // <iframe> elements must have a unique title property
    const uniqueTitle = `${config.pageAddress}-${(new Date()).getTime()}`;

    const iframeUrl = CbaExternalPageFrame.buildIframeUrl(config.pageAddress, path, runtime);


    return (
      <iframe
        ref={this.iframeRef}
        title={uniqueTitle}
        src={iframeUrl}
        style={style}
        onClick={this.onClick}
        onContextMenu={this.onContextMenuHandler}
      />
    );
  }

}

CbaExternalPageFrame.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.getStandardConfigPropTypes(false)
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
