import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaLink extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    // Update the 'selected' state: 
    this.updateStateAndTriggerRendering(pathState);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on old selected state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState) {
    const { runtime, path } = this.props;
    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, runtime);

    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);

  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);

    const selected = SelectGroupHelper.extractSelectedState(pathState, runtime);

    const text = RenderingHelper.extractMultilineText(CommonConfigHelper.buildDisplayText(config, pathState, runtime));
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selected, orientation, runtime);
    const linkStyle = {};

    CommonConfigHelper.setStyleAttribute(linkStyle, "cursor", "pointer");

    return (
      <div
        style={style}
        title={CommonConfigHelper.buildTitle(config)}
      >
        <span
          style={linkStyle}
          onClick={this.onClickHandler}
          onContextMenu={this.onContextMenuHandler}
        >
          {CommonConfigHelper.getImageTag(pathState, config, false, runtime)}
          {text}
        </span>
      </div>
    );
  }

}


CbaLink.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
