import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TracingModalData extends Component {

  constructor(props) {
    super(props);

    const { currentModalContent: traceLogBuffer } = this.props;
    this.state = {
      tracingDetails: traceLogBuffer.peekEntries()
    };
  }

  static dumpAsStringAndTruncate(object) {
    const maxLen = 1000;
    const fullString = JSON.stringify(object);
    return fullString.length > maxLen ? `${fullString.substring(0, maxLen)}...` : fullString;
  }

  /**
   * Clean logs from traceLogBuffer and updates the tracing details accordingly
   */
  truncateTraceLog = () => {
    const { currentModalContent: traceLogBuffer } = this.props;
    traceLogBuffer.popEntries();
    this.updateTraceLogState();
  }

  /**
   * Updates the tracing details with data from the source of truth
   */
  updateTraceLogState = () => {
    const { currentModalContent: traceLogBuffer } = this.props;
    this.setState({
      tracingDetails: traceLogBuffer.peekEntries()
    });
  }


  render() {
    const { tracingDetails } = this.state;
    return (
      <div className="cba-modal-content">
        Tracing data not transferred to server yet:
        <div className="detailsWrapper">
          <table className="details">
            <thead />
            <tbody>
              {[...tracingDetails].map(row => (
                <tr key={`${row.entryId} - ${row.timestamp}`}>
                  <td>{row.entryId}</td>
                  <td>{row.timestamp}</td>
                  <td>{row.type}</td>
                  {Object.keys(row.details).map((key, rowIndex) => (
                    <td key={key}>
                      {`${key}:${TracingModalData.dumpAsStringAndTruncate(row.details[key])}`}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="cba-trace-modal-buttons">
          <button type="button" onClick={this.truncateTraceLog}> Truncate Log </button>
          <button type="button" onClick={this.updateTraceLogState}> Refresh </button>
        </div>
      </div>
    );
  }

}

TracingModalData.propTypes = {
  currentModalContent: PropTypes.object.isRequired
}
