import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import CbaSimpleTextField from "../CbaSimpleTextField";
import RenderingHelper from '../RenderingHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import IndexPathHelper from '../../state/IndexPathHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateManagerHelper from '../../state/StateManagerHelper';

/**
 * 
 */
export default class CbaCalculatorHistory extends Component {

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }


  static getSimpleTextFieldPath(path) {
    return IndexPathHelper.appendIndexToPageSegment(path, 0);
  }

  render() {
    const { config, runtime, path, orientation } = this.props;

    const newValue = runtime.calculatorsManager.getHistoryDisplayingValue();

    // Make the new value available for TermEvaluator operators using user defined IDs: 
    const myState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setTextValue(myState, newValue);
    ComponentStateHelper.registerState(this, myState);

    // Set the new value for the CbaSimpleTextField that we use for display:
    const simpleTextFieldPath = CbaCalculatorHistory.getSimpleTextFieldPath(path);
    const simpleTextFieldState = runtime.componentStateManager.findOrBuildStateForPathId(simpleTextFieldPath, runtime);
    StateAttributeAccess.setTextValue(simpleTextFieldState, newValue);
    runtime.componentStateManager.registerStateByPathId(simpleTextFieldPath, simpleTextFieldState);

    const simpleTextFieldConfig = StateManagerHelper.deepCopy(config);
    simpleTextFieldConfig.userDefinedId = undefined;

    return (
      <CbaSimpleTextField
        runtime={runtime}
        path={simpleTextFieldPath}
        config={simpleTextFieldConfig}
        orientation={orientation}
      />
    );
  }

}

CbaCalculatorHistory.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.getStandardConfigPropTypes(false)
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
