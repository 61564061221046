import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notify-toast';
import CbaTestPresenter from './CbaTestPresenter';
import HeaderButtons from './HeaderButtons';
import TaskNavigator from './TaskNavigator';
import RenderingHelper from "./RenderingHelper";
import MenuItemTree from './MenuItemTree';

/**
 * Display a running task. 
 */
export default class LoggedInView extends Component {

  constructor(props) {
    super(props);
    this.testPresenter = React.createRef();
    this.contextMenu = React.createRef();

    const { runtime } = this.props;
    runtime.taskManager.setTopLevelPageChangeHandler(this.handleTopLevelPageChange);
  }

  componentDidMount() {
    // activate the automtaic send trace log mechanism while mounted
    const { runtime } = this.props;
    runtime.traceLogUploader.startAutomaticTransmissions();
    runtime.contextMenu = this.contextMenu.current;
  }

  componentWillUnmount() {
    // stop automatic trace log transmissions (and run a final transmission immediately)
    const { runtime } = this.props;
    runtime.traceLogUploader.stopAutomaticTransmissions();
  }


  handleTopLevelPageChange = () => {
    if (this.testPresenter.current !== undefined) {
      RenderingHelper.triggerRendering(this.testPresenter.current);
    }
  }

  setTaskNavigator = (navigator) => {
    const { runtime } = this.props;
    runtime.recommendationsManager.setTaskNavigator(navigator);
  }

  buildTaskNavigator = (testButtonHeight, taskButtonHeight) => {
    if (!this.shouldBuildNavigator()) {
      return undefined;
    }

    const { courses, tests, runtime } = this.props;
    const taskInfo = runtime.taskManager.getCurrentTestTaskItemNames();
    const { test, item, task } = taskInfo;
    return (
      <TaskNavigator
        courses={courses}
        tests={tests}
        currentTest={test}
        currentItem={item}
        currentTask={task}
        testButtonHeight={testButtonHeight}
        taskButtonHeight={taskButtonHeight}
        ref={this.setTaskNavigator}
        runtime={runtime}
      />
    );
  }

  shouldBuildNavigator = () => {
    const { settings } = this.props;
    return !!settings && settings.ShowTaskNavigationBars;
  }


  render() {
    // TODO: if buttons are not given by settings and are static for test and task. I would remove them from the App level.
    const testButtonHeight = 70;
    const taskButtonHeight = 50;

    const { runtime, settings, headerButtons } = this.props;
    const taskId = runtime.taskManager.getCurrentStatePathRoot();

    return (
      <div className="appDiv">
        <div className="topToolBarContainer">
          <HeaderButtons runtime={runtime} config={headerButtons} />
          {this.buildTaskNavigator(testButtonHeight, taskButtonHeight)}
        </div>
        <Notifications />
        <CbaTestPresenter taskId={taskId} ref={this.testPresenter} runtime={runtime} settings={settings} />
        <MenuItemTree ref={this.contextMenu} runtime={runtime} />
      </div>
    );
  }

}


LoggedInView.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.string).isRequired,
  tests: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    const testPropTypes = {
      name: PropTypes.string.isRequired,
      taskCourse: PropTypes.arrayOf((propValue2, key2, componentName2, location2, propFullName2) => {
        const taskEntryPropTypes = {
          item: PropTypes.string.isRequired,
          task: PropTypes.string.isRequired,
        };
        return PropTypes.checkPropTypes(taskEntryPropTypes, propValue2[key2], propFullName2, componentName2)
      }).isRequired
    };
    return PropTypes.checkPropTypes(testPropTypes, propValue[key], propFullName, componentName)
  }).isRequired,
  settings: PropTypes.object.isRequired,
  headerButtons: PropTypes.array.isRequired,
  runtime: PropTypes.object.isRequired
}
