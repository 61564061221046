import throttle from 'lodash.throttle';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import IndexPathHelper from '../state/IndexPathHelper';
import PathTranslationHelper from '../state/PathTranslationHelper';
import MenuBuildHelper from "./MenuBuildHelper";
import DialogPresenter from '../dialog/DialogPresenter';
import UserDefPathHelper from '../state/UserDefPathHelper';
import TraceLogHelper from '../state/TraceLogHelper';

/**
 * Helper methods that factor out code patterns commonly used by many display components.
 */
export default class CommonActionsHelper {

  /**
   * Do the usual processing of an onClick event on a display component:
   *  - Trace a user interaction.
   *  - Do a page switch according to the link specification.
   *  - Send a standard state machine event.
   * w
   * @param {*} event The onClick event triggering the processing.
   * @param {*} traceValues Values to add to the on click trace log (may be undefined if there is nothing to add).
   * @param {*} component The diplay component instance.
   */
  static doStandardOnClick(event, traceValues, component) {
    const { config, path, runtime } = component.props;
    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path, traceValues, event, runtime);
    CommonActionsHelper.doPageSwitchForComponent(component);
    CommonActionsHelper.sendStandardEvent(config, runtime);
  }

  /**
   * Do the basic processing of an onClick event on a display component:
   *  - Stop propagation of the event to parent components.
   *  - Deregister other current selection provider and insert position at the clipboard manager.
   * 
   * @param {*} event The onClick event triggering the processing.
   * @param {String} path The Index Path of the display component instance.
   * @param {*} runtime The common runtime object.
   */
  static doBasicOnClick(event, path, runtime) {
    CommonActionsHelper.stopEventPropagation(event);
    CommonActionsHelper.signalFocusChangeToClipboardManager(path, runtime);
  }

  /**
   * Stop the propagation of the given event.
   * 
   * @param {*} event 
   */
  static stopEventPropagation(event) {
    if (event !== undefined) {
      event.stopPropagation();
    }
  }

  /**
   * Deregister other components as selection provider and insert position in the clipboard manager.
   * 
   * @param {String} path The Index Path of the component. 
   * @param {*} runtime The common runtime object.
   */
  static signalFocusChangeToClipboardManager(path, runtime) {
    runtime.clipboardManager.registerFocus(path);
  }

  /**
   * Signal a user interaction to the user interaction counter and the trace log using the trace log config structure.
   * 
   * @param {*} config The component's configuration object containing the trace config structure.
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} values Additional attribute values to put into the trace log entry. 
   * @param {*} browserEvent The browser side event (like onClick or onChange) that triggered the log (optional).
   * @param {*} runtime The common runtime object.
   */
  static traceUserInteractionPerConfig(config, path, values, browserEvent, runtime) {
    CommonActionsHelper.traceUserInteractionPerTraceConfig(config.trace, path, values, browserEvent, runtime);
  }

  /**
   * Signal a user interaction to the user interaction counter and the trace log using the trace log config structure.
   * 
   * @param {*} traceConfig The trace configuration structure from the component's configuration object.
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} values Additional attribute values to put into the trace log entry. 
   * @param {*} browserEvent The browser side event (like onClick or onChange) that triggered the log (optional).
   * @param {*} runtime The common runtime object.
   */
  static traceUserInteractionPerTraceConfig(traceConfig, path, values, browserEvent, runtime) {
    if (traceConfig.skipTrace === undefined || traceConfig.skipTrace !== true) {
      const extendedValues = {};
      CommonActionsHelper.extendTraceDetailsObject(extendedValues, traceConfig.addOn);
      CommonActionsHelper.extendTraceDetailsObject(extendedValues, values);

      CommonActionsHelper.traceUserInteraction(traceConfig.type, path, extendedValues, browserEvent, undefined, runtime, traceConfig.isDelegate);
    }
  }


  /**
   * Signal a user interaction to the user interaction counter and the trace log.
   * 
   * @param {string} eventType The type of event to log in the trace log.
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} values Additional attribute values to put into the trace log entry. 
   * @param {*} browserEvent The browser side event (like onClick or onChange) that triggered the log (optional).
   * @param {{type: String, value: String}} continuingInteractionKey The key used to identify a user interaction that might trigger 
   *  several consecutive calls but should be counted as a single interaction only. 
   * @param {*} runtime The common runtime object.
   */
  static traceUserInteraction(eventType, path, values, browserEvent, continuingInteractionKey, runtime, isDelegate) {
    const details = CommonActionsHelper.buildTraceLogDetails(path, values, browserEvent, runtime, isDelegate);
    const timestamp = new Date();
    runtime.incidentsAccumulator.userInteraction(timestamp.getTime(), continuingInteractionKey);
    runtime.traceLogBuffer.reportEvent(eventType, timestamp, details);
  }

  /**
   * Signal a user scroll interaction to the user interaction counter and the trace log.
   * Function auto throttles itself to 2 calls per second
   * 
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} runtime The common runtime object.
   * 
   * @returns {Function(event)} Function must be added to the onScroll event of a Component
   */
  static traceUserScroll(path, runtime, xPath, isXPageFirst) {
    let lastScrollValue;
    let currentScrollValue
    let lastOrientationValue;
    let currentOrientationValue;
    const throttleOptions = {
      leading: false
    }

    const throttleTraceScrollFunction = throttle(TraceLogHelper.traceScrollWrap(), 500, throttleOptions);

    return (event) => {
      event.stopPropagation();
      lastScrollValue = currentScrollValue;
      currentScrollValue = TraceLogHelper.getScrollDataFromEvent(event);

      let tracePath;

      // xpage case (cannot assign a onScroll function. on scroll is captured at a higher level and computes path based on which comes first.)
      if (xPath) {
        const isScrollOnPane1 = event.target.className.includes("Pane1");
        if (isXPageFirst) {
          tracePath = isScrollOnPane1 ? xPath : path;
        } else {
          tracePath = isScrollOnPane1 ? path : xPath;
        }
      } else {
        tracePath = path;
      }

      lastOrientationValue = currentOrientationValue;
      currentOrientationValue = TraceLogHelper.computeScrollOrientation(currentScrollValue, lastScrollValue);

      if (lastOrientationValue && (lastOrientationValue.direction !== currentOrientationValue.direction)) {
        TraceLogHelper.traceScroll(tracePath, runtime, currentScrollValue, currentOrientationValue);
      }

      throttleTraceScrollFunction(currentScrollValue, currentOrientationValue, tracePath, runtime);
    };

  }

  /**
   * Build the details structure for trace log entries triggered by display components.
   * 
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} values Additional attribute values to put into the trace log entry. 
   * @param {*} browserEvent The browser side event (like onClick or onChange) that triggered the log (optional).
   * @param {*} runtime The common runtime object.
   */
  static buildTraceLogDetails(path, values, browserEvent, runtime, isDelegate) {
    if (isDelegate) {
      // for delegate components we need to trace its parent path and userDefId
      path = IndexPathHelper.dropIndexFromPageSegment(path);
    }
    const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(path, runtime);
    const details = {
      indexPath: path,
      userDefIdPath,
      userDefId: UserDefPathHelper.getLastUserDefIdFromPath(userDefIdPath),
    };
    CommonActionsHelper.addMouseEventDetails(browserEvent, details);
    CommonActionsHelper.extendTraceDetailsObject(details, values);
    return details;
  }

  /**
   * Extend the given oldValues trace details object with the values given. 
   * 
   * @param {*} detailsObject The trace details object to be extended.
   * @param {*} valuesToAdd The attributes to add to the trace details object.
   */
  static extendTraceDetailsObject(detailsObject, valuesToAdd) {
    if (valuesToAdd !== undefined) {
      Object.keys(valuesToAdd).forEach((attribute) => {
        detailsObject[attribute] = valuesToAdd[attribute];
      })
    }
  }

  /**
   * Add properties specific to MouseEvents to the given trace log details object.
   * 
   * @param {*} browserEvent The browser side event (like onClick or onChange) that might be a MouseEvent.
   * @param {*} traceDetails The trace log details where we should add the MouseEvent attribute values to.
   */
  static addMouseEventDetails(browserSideEvent, traceDetails) {
    if (browserSideEvent !== undefined) {
      traceDetails.clientX = browserSideEvent.clientX;
      traceDetails.clientY = browserSideEvent.clientY;
      traceDetails.pageX = browserSideEvent.pageX;
      traceDetails.pageY = browserSideEvent.pageY;
      traceDetails.screenX = browserSideEvent.screenX;
      traceDetails.screenY = browserSideEvent.screenY;
    }
  }


  /**
   * Build a trace log additional value object for the 'old selected' status 
   * to use as values parameter in the traceUserInteraction method.
   * 
   * @param {*} pathState The component's state to extract the selected value from.
   * @param {*} runtime The common runtime context structure.
   */
  static buildOldSelectedTraceLogValueObject(selectedState) {
    return {
      oldSelected: selectedState
    }
  }

  /**
   * Send the standard or alternate event according to the 'selected' state of the component instance. 
   * 
   * @param {*} selectedState The 'selected' state of the component instance.
   * @param {*} props The component's configuration object.
   * @param {*} runtime The common runtime object.
   */
  static sendStandardOrAlternateEvent(selectedState, props, runtime) {
    if (selectedState) {
      CommonActionsHelper.sendAlternateEvent(props, runtime);
    } else {
      CommonActionsHelper.sendStandardEvent(props, runtime);
    }
  }

  /**
   * Send the standard state machine event according to the display component's configuration.
   * 
   * @param {*} props The component's configuration object.
   * @param {*} runtime The common runtime object.
   */
  static sendStandardEvent(props, runtime) {
    const event = props.event.standard;
    if (event !== undefined) {
      runtime.statemachinesManager.triggerEvent(event);
    }
  }

  /**
   * Send a standard event using only the name of the event
   * 
   * @param {"String"} name the name of the event
   * @param {*} runtime the common runtime object
   */
  static sendEvent(name, runtime) {
    if (name !== undefined) {
      runtime.statemachinesManager.triggerEvent(name);
    }
  }

  /**
   * Send the alternate state machine event according to the display component's configuration.
   * 
   * @param {*} props The component's configuration object.
   * @param {*} runtime The common runtime object.
   */
  static sendAlternateEvent(props, runtime) {
    const event = props.event.alternate === undefined ? props.event.standard : props.event.alternate;
    if (event !== undefined) {
      runtime.statemachinesManager.triggerEvent(event);
    }
  }

  /**
   * Send the state machine event according to the display component's configured onFocusIn event.
   * 
   * @param {*} component The display component instance.
   */
  static doStandardOnFocus(component) {
    const { props } = component;
    const { config, runtime } = props;

    const event = config.event.onFocusIn;
    if (event !== undefined) {
      runtime.statemachinesManager.triggerEvent(event);
    }
  }

  /**
   * Send the state machine event according to the display component's configured onFocusOut event.
   * 
   * @param {*} component The display component instance.
   */
  static doStandardOnBlur(component) {
    const { props } = component;
    const { config, runtime } = props;

    const event = config.event.onFocusOut;
    if (event !== undefined) {
      runtime.statemachinesManager.triggerEvent(event);
    }
  }

  /**
   * Perform the page switch for the specified display component instance.
   * @param {*} component 
   */
  static doPageSwitchForComponent(component) {
    const defaultLinkReceiver = CommonActionsHelper.getDefaultLinkReceiver(component);
    CommonActionsHelper.doPageSwitch(component.props.config.link, component.props.runtime, defaultLinkReceiver, component.props.path);
  }


  /**
   * Perform the page switch specified in the given link specification.
   * 
   * @param {*} link The link specification object from the display component's configuration.
   * @param {*} runtime The common runtime object.
   * @param {*} defaultReceiver An optional receiving page area to be specified if the display component sits in a page that is embedded in a page area that catches page switches.
   * @param {*} path Path of the component requesting the page switch
   */
  static doPageSwitch(link, runtime, defaultReceiver, path) {
    const targetReceiver = CommonActionsHelper.buildTargetReceiver(link, defaultReceiver, path);
    const targetPage = link.page;
    const conditionalLink = link.conditional;
    const { pageUrl, historyMove } = link;
    if (targetPage !== undefined || conditionalLink !== undefined || (targetReceiver !== undefined && historyMove !== undefined)) {
      if (path !== undefined && DialogPresenter.isDialogParentInPath(path)) {
        DialogPresenter.closeParentDialogFromPath(runtime, path);
      }

      const position = CommonActionsHelper.getTopComponentPosition(targetPage, runtime.pageConfigurationsManager);
      runtime.taskManager.switchPage(
        targetPage,
        conditionalLink,
        pageUrl,
        link.pageAreaType == null ? IndexPathHelper.getPageAreaTypeFromPath(path) : link.pageAreaType,
        link.pageAreaName == null ? IndexPathHelper.getPageAreaNameFromPath(path) : link.pageAreaName,
        targetReceiver,
        link.receiverTab,
        link.historyMove,
        position
      );
    }
  }

  /**
   * Internal helper method: Get position of the top level component in the page given by the page's name.
   */
  static getTopComponentPosition(pageName, pageConfigurationsManager) {
    const targetPageConfig = pageConfigurationsManager.findPage(pageName);
    if (targetPageConfig === undefined) {
      console.error(`Could not find configuration for page: ${pageName}`);
      return undefined;
    }
    const { content } = targetPageConfig;
    if (content === undefined) {
      console.error(`Could not find content in configuration of page ${pageName}: ${targetPageConfig}`);
      return undefined;
    }
    const { config } = content;
    if (config === undefined) {
      console.error(`Could not find config for content in page ${pageName}: ${content}`);
      return undefined;
    }
    const { position } = targetPageConfig.content.config;
    return {
      x: position.x,
      y: position.y
    }
  }

  /**
   * Get the default link receiver from the state of the display component instance.
   * 
   * @param {*} component The display component instance.
   */
  static getDefaultLinkReceiver(component) {
    if (component.props === undefined) {
      console.error(`Component without props detected: ${component}`);
      return undefined;
    }
    const pathState = ComponentStateHelper.getState(component);
    return StateAttributeAccess.extractDefaultLinkReceiver(pathState);
  }


  /**
   * Open the context menu for the calling display component instance. 
   * 
   * @param {*} component The calling display component instance.
   * @param {MouseEvent} event The mouse event opening the context menu.
   */
  static doContextMenuOpen(component, event) {
    const { props } = component;

    if (props === undefined || props.runtime === undefined
      || props.config === undefined) {
      console.error("Cannot open context menu for component.", props);
      return;
    }

    const { runtime, config, path: indexPath } = props;
    const { contextMenu } = config;

    if (contextMenu !== undefined) {
      event.stopPropagation();
      runtime.contextMenu.openMenuItemTreeWithDynamicConfig(
        MenuBuildHelper.buildMenuTreeItemConfiguration(
          contextMenu,
          event,
          indexPath,
          CommonActionsHelper.getDefaultLinkReceiver(component),
          runtime
        )
      );
    }

  }


  /**
   * Register or deregister the current selection of a textarea or input tag for cut&paste due to a click or select event.
   * 
   * Firefox and Chrome differ a bit with selection data in the events and events sequence when dropping a selection. 
   * Fortunately both send the proper selection data with the last event triggered by each selecting or deselecting action.
   * Therefore we have to process the selected text in onClick and onSelection. 
   * 
   * @param {*} path The index path of the display component instance.
   * @param {*} event The event that triggers the selection processing.
   * @param {*} readOnly Is the component read only, i.e. it cannot do a 'cut' operation.
   * @param {*} cutCallback The method to call at an actual cut operation to replace the selected text.
   * @param {*} cutCallbackObj The component instance on which will be applied the 'cut' operation.
   * @param {*} runtime The common runtime context structure.
   */
  static processSelectedTextForCutAndPaste(path, event, readOnly, cutCallback, cutCallbackObj, runtime) {
    const { selectionStart, selectionEnd, value } = event.target;
    const selectedText = `${value.substring(selectionStart, selectionEnd)}`;
    if (selectedText === undefined || selectedText.length === 0) {
      runtime.clipboardManager.deregisterSelection(path);
    } else {
      runtime.clipboardManager.registerSelection(
        path,
        (drop) => {
          if (drop && !readOnly && cutCallback !== undefined) {
            cutCallback(cutCallbackObj, selectionStart, selectionEnd, '');
          }
          return selectedText;
        },
        () => readOnly
      );
    }

  }


  // ---------------- private stuff -------------------------------------------------------------------

  /**
   * Get the target receiver from link and defaultReceiver:
   * 
   * - If no default receiver is given (i.e. no intercepting embedding page area) just use the receiver given in the link.
   * - If a default receiver is given and the link specifies a different top level page area than the default receiver then 
   *   return the receiver of the link: The embedding page area does not intercept a page setting in a foreign top level page area.
   * - If a default receiver is given and the top level page area specified by the link is the same as the top level page area
   *   of the defaultReceiver (i.e. the intercepting embedding page area) concatenate receiver of intercepting page area and link:  
   *   The receiver in the link becomes relative to the intercepting embedding page. (If the link specifies no specific receiver
   *   just use the default receiver instead of concatenating both: The link sets the page embedded in the embedding page area itself.)
   */
  static buildTargetReceiver(link, defaultReceiver, pathOfLinkRequestor) {
    let targetReceiver;
    if (defaultReceiver === undefined) {
      targetReceiver = link.receiver;
    } else if (CommonActionsHelper.linkTargetsOtherPageArea(link, pathOfLinkRequestor)) {
      targetReceiver = link.receiver;
    } else if (link.receiver === undefined) {
      targetReceiver = defaultReceiver;
    } else {
      targetReceiver = IndexPathHelper.appendPageSegmentsToPath(defaultReceiver, link.receiver);
    }
    return targetReceiver;
  }

  /**
   * Internal helper: Does the given link target the page area of the link requesting component?
   */
  static linkTargetsOtherPageArea(link, pathOfLinkRequestor) {
    if (link.pageAreaName === undefined || link.pageAreaType === undefined) {
      // link does not specifiy a proper page area of its own -> assume page area of calling component is to be used
      return false;
    }
    return link.pageAreaType !== IndexPathHelper.getPageAreaTypeFromPath(pathOfLinkRequestor) || link.pageAreaName !== IndexPathHelper.getPageAreaNameFromPath(pathOfLinkRequestor);
  }

}
