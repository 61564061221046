import Utils from '../utils/Utils';
import UserDefPathHelper from '../state/UserDefPathHelper';

/**
 * Receive Windows.postMessage() events.
 */
export default class PostMessageReceiver {

  constructor() {
    this.acceptedExternalPageFrameUrlsList = [];
    this.defaultAcceptedUrl = undefined;

    this.availableTaskSwitches = [];

    // Access point for test code to intercept the response event sending:
    this.responder = (eventToSend, triggeringEventOrigin, triggeringEventSourceWindow) => {
      try {
        const messageString = JSON.stringify(eventToSend);
        const eventOrigin = (triggeringEventOrigin === undefined || triggeringEventOrigin == null || triggeringEventOrigin.length === 0 || triggeringEventOrigin === 'null') ? '*' : triggeringEventOrigin;
        triggeringEventSourceWindow.postMessage(messageString, eventOrigin);
      } catch (error) {
        console.error('Sending a response message failed.', error);
      }
    }
  }

  // ---------- public API ---------------------------------------


  /**
   * Register a URL as source of events from external JavaScript code involved via an external page frame component.
   * 
   * We don't accept runtime control events (i.e. events that are part of the task player API) from these registered URLs.
   */
  registerAcceptableUrlForExternalPageFrameEvent = (url) => {

    // trim to the http(s)://<host>:<port> part:
    const parsedUrl = PostMessageReceiver.tryToParseURL(url);
    if (parsedUrl === undefined) {
      console.log(`We don't accept invalid URLs as source URLs for post message events.  Ignored URL is: ${url}`);
      return;
    }
    const { origin } = parsedUrl;

    if (origin === undefined || origin.length < 1) {
      console.log(`We only accept http(s) protocols as source URLs for post message events.  Ignored URL is: ${url}`);
      return;
    }

    if (!this.acceptedExternalPageFrameUrlsList.includes(origin)) {
      this.acceptedExternalPageFrameUrlsList.push(origin);
    }
  }


  /**
   * Start to receive events.
   * 
   * @param runtime The global runtime context structure.
   */
  startReceiving = (runtime, taskPlayer) => {
    if (runtime === undefined) {
      console.error('Cannot start receiving events without a runtime context.')
      return;
    }
    if (taskPlayer === undefined) {
      console.error('Cannot start receiving events without a task player reference.')
      return;
    }
    this.runtime = runtime;
    this.taskPlayer = taskPlayer;
    const href = Utils.getCallingUrlWithoutPath();
    this.defaultAcceptedUrl = href === 'file:' ? 'null' : href;
    this.acceptedExternalPageFrameUrlsList.push(this.defaultAcceptedUrl);
    window.addEventListener('message', this.receiveEvent, false);
  }

  /**
   * Send the 'task player is ready' message to the given window using the given target origin URI.
   */
  sendTaskPlayerReadyEvent = (domainUri, windowType) => {
    PostMessageReceiver.sendResponseEvent(
      {
        eventType: 'taskPlayerReady'
      },
      domainUri,
      this.getTargetWindow(windowType),
      this.responder
    );
  }

  // ---------- private stuff ---------------------------------------

  static tryToParseURL(url) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl;
    } catch (ex) {
      return undefined;
    }
  }


  /**
   * Process an icoming event.
   */
  receiveEvent = (event) => {
    const { origin, source, data: dataInEvent } = event;

    // check event origin
    if (this.defaultAcceptedUrl !== origin && !this.acceptedExternalPageFrameUrlsList.includes(origin)) {
      console.log(`PostMessageReceiver ignored event from origin ${origin}, our default accepted url is ${this.defaultAcceptedUrl}, additional accepted URLs:`, this.acceptedExternalPageFrameUrlsList);
      return;
    }

    const data = PostMessageReceiver.tryJsonParse(dataInEvent);
    if (data === undefined) {
      console.log(`PostMessageReceiver ignored event with non-JSON data: ${dataInEvent}`);
      return;
    }

    const eventTime = new Date();

    // chain of event processors starts here: 
    let eventProcessed = false;
    if (!eventProcessed) {
      eventProcessed = this.processRuntimeControlEvent(origin, source, data);
    }
    if (!eventProcessed) {
      eventProcessed = this.processIfExternalPageFrameEvent(eventTime, origin, data);
    }
    if (!eventProcessed) {
      console.log(`PostMessageReceiver ignored unknown event with data: ${dataInEvent}`);
    }

  }

  /**
   * Try to parse the given string as JSON object. 
   * 
   * The method silently returns undefined if parsing fails.
   * @param {String} data 
   */
  static tryJsonParse(data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  }


  /**
   * Try to process the event as event coming from code in an external page frame. 
   * 
   * The method silently ignores the event if it does 
   * neither contain trace log data nor state machine event data.
   * 
   * @param {Date} eventTime The time the event is processed.
   * @param {String} origin The event origin.
   * @param {*} data The data contained in the event.
   * @return True if the event was processed, false otherwise.
   */
  processIfExternalPageFrameEvent = (eventTime, origin, data) => {

    // check event origin
    if (!this.acceptedExternalPageFrameUrlsList.includes(origin)) {
      return false;
    }

    const { traceMessage, microfinEvent, microfinVariable, indexPath, userDefIdPath } = data;
    if (PostMessageReceiver.isEmptyOrNoValue(traceMessage)
      && PostMessageReceiver.isEmptyOrNoValue(microfinEvent)
      && PostMessageReceiver.isEmptyOrNoValue(microfinVariable)) {
      return false;
    }

    if (!PostMessageReceiver.isEmptyOrNoValue(traceMessage)) {
      const safeIndexPath = PostMessageReceiver.isEmptyOrNoValue(indexPath) ? undefined : indexPath;
      const safeUserDefIdPath = PostMessageReceiver.isEmptyOrNoValue(userDefIdPath) ? undefined : userDefIdPath;
      // create an entry in the trace log
      this.runtime.traceLogBuffer.reportEvent('JavaScriptInjected', eventTime, {
        indexPath: safeIndexPath,
        userDefIdPath: safeUserDefIdPath,
        userDefId: safeUserDefIdPath === undefined ? undefined : UserDefPathHelper.getLastUserDefIdFromPath(safeUserDefIdPath),
        origin,
        message: traceMessage
      })
    }

    if (!PostMessageReceiver.isEmptyOrNoValue(microfinEvent)) {
      this.runtime.statemachinesManager.triggerEvent(microfinEvent);
    }

    if (!PostMessageReceiver.isEmptyOrNoValue(microfinVariable)) {
      const { variableName, newValue } = microfinVariable;
      if (!PostMessageReceiver.isEmptyOrNoValue(variableName) && !PostMessageReceiver.isEmptyOrNoValue(newValue)) {
        this.runtime.statemachinesManager.setVariable(variableName, newValue, this.runtime);
      }
    }

    // signal user interactions
    for (let index = 0; index < PostMessageReceiver.getUserInteractionCount(data); index += 1) {
      this.runtime.incidentsAccumulator.userInteraction(eventTime, undefined);
    }

    return true;
  }

  /**
   * Check whether a parameter coming in from external JavaScript code
   * does not contain a 'substantial' value, i.e. 
   *  - it is undefined or
   *  - it is null or
   *  - it is empty
   * @param {*} value 
   */
  static isEmptyOrNoValue(value) {
    return value === undefined || value === null || value === '';
  }

  /**
   * Process an arriving message setting the 
   * availability of a task switch. 
   * 
   * @param {*} data The data contained in the availability setting event.
   */
  processAvailabilityMessage = (data) => {
    const oldEntry = this.availableTaskSwitches.find(entry => (
      entry.request === data.request
      && entry.scope === data.scope
      && entry.item === data.item
      && entry.task === data.task));
    if (oldEntry === undefined) {
      this.availableTaskSwitches.push({
        request: data.request,
        scope: data.scope,
        item: data.item,
        task: data.task,
        value: data.value
      })
    } else {
      oldEntry.value = data.value;
    }
  }

  /**
   * Is the requested task switch available currently?
   * 
   * We do a lookup in our availability map. If there is 
   * not entry for the given request we return false as default.
   */
  isTaskSwitchAvailable = (request, scope, item, task) => {
    const existingEntry = this.availableTaskSwitches.find(entry => (
      entry.request === request
      && entry.scope === scope
      && entry.item === item
      && entry.task === task));
    return existingEntry === undefined ? false : existingEntry.value;
  }

  /**
   * Try to process the event as event coming from some runtime controller. 
   * 
   * The method silently ignores the event if 
   *  - the data does not contain the eventType field.
   *  - the event origin is not our defaultAcceptedUrl.
   * 
   * @param {String} origin The event origin.
   * @param {*} sourceWindow The source window where the event came from.
   * @param {*} data The data contained in the event.
   * @return True if the event was processed, false otherwise.
   */
  processRuntimeControlEvent = (origin, sourceWindow, data) => {
    const { runtime } = this;

    // check event origin
    if (this.defaultAcceptedUrl !== origin) {
      return false;
    }

    if (data.eventType === undefined) {
      return false;
    }

    switch (data.eventType) {
      // ---- Configuration Control ---------------------------------------------
      case 'addItem':
        this.taskPlayer.addItem(data.itemConfig, data.resourcePath, data.externalResourcePath, data.libraryPathsMap);
        break;
      case 'clearItems':
        this.taskPlayer.clearItems();
        break;
      case 'setPreload':
        {
          const preloadPromise = this.taskPlayer.setPreload(data.itemName);
          if (preloadPromise !== undefined) {
            preloadPromise.then((resources) => {
              PostMessageReceiver.sendResponseEvent(
                {
                  eventType: 'setPreloadReturn',
                  isSuccess: true,
                  message: {
                    images: resources[0],
                    videos: resources[1],
                    audios: resources[2]
                  }
                },
                origin,
                sourceWindow,
                this.responder
              );
            }, (error) => {
              PostMessageReceiver.sendResponseEvent(
                {
                  eventType: 'setPreloadReturn',
                  isSuccess: false,
                  message: error
                },
                origin,
                sourceWindow,
                this.responder
              );
            });
          }
        }
        break;
      // ---- Trace Control ---------------------------------------------
      case 'insertMessageInTrace':
        this.taskPlayer.insertMessageInTrace(data.message);
        break;
      case 'logStateToTrace':
        this.taskPlayer.logStateToTrace();
        break;
      case 'flushTrace':
        this.taskPlayer.flushTrace();
        break;
      case 'setTraceLogTransmissionChannel':
        if (data.channel === 'http') {
          runtime.traceLogUploader.setHttpTransmissionChannel(data.transmitUrl, data.interval, data.httpTimeout);
        } else if (data.channel === 'postMessage') {
          runtime.traceLogUploader.setPostMessageTransmissionChannel(data.targetWindowType, data.targetOrigin, data.interval);
        } else if (data.channel === 'console') {
          runtime.traceLogUploader.setConsoleTransmissionChannel(data.interval);
        } else {
          console.error(`Invalid trace log channel setting ignored: ${data.channel}`);
        }
        break;
      case 'traceLogTransmission':
        // this is a trace log event not meant for us -> ignore.
        console.info('Trace log event echo ignored.');
        break;
      case 'setTraceContextId':
        this.taskPlayer.setTraceContextId(data.contextId);
        break;
      // ---- Recordings Control -------------------------------------
      case 'setRecordingTransmissionChannel':
        if (data.channel === 'http') {
          runtime.recordingUploader.setHttpTransmissionChannel(data.transmitUrl, undefined, data.httpTimeout);
        } else if (data.channel === 'postMessage') {
          runtime.recordingUploader.setPostMessageTransmissionChannel(data.targetWindowType, data.targetOrigin, undefined);
        } else if (data.channel === 'console') {
          runtime.recordingUploader.setConsoleTransmissionChannel(undefined);
        } else {
          console.error(`Invalid trace log channel setting ignored: ${data.channel}`);
        }
        break;
      case 'recordingTransmission':
        // this is a recording transmission event not meant for us -> ignore.
        console.info('Recording transmission event echo ignored.');
        break;
      case 'setRecordingContextId':
        this.taskPlayer.setRecordingContextId(data.contextId);
        break;
      // ---- User Control ---------------------------------------------
      case 'setUserId':
        this.taskPlayer.setUserId(data.id);
        break;
      case 'logout':
        this.taskPlayer.logout();
        break;
      case 'getUserId':
        PostMessageReceiver.sendResponseEvent(
          {
            eventType: 'getUserIdReturn',
            id: this.taskPlayer.getUserId()
          },
          origin,
          sourceWindow,
          this.responder
        );
        break;
      case 'showLogin':
        this.taskPlayer.showLogin(
          data.titleLabel,
          data.fieldLabel,
          data.buttonLabel,
          (fieldValue) => {
            PostMessageReceiver.sendResponseEvent(
              {
                eventType: 'loginDialogClosed',
                fieldValue
              },
              origin,
              sourceWindow,
              this.responder
            )
          },
          data.dialogConfig
        );
        break;
      // ---- Task Control ---------------------------------------------
      case 'startTask':
        this.taskPlayer.startTask(data.scope, data.item, data.task);
        break;
      case 'stopTask':
        this.taskPlayer.stopTask();
        break;
      case 'pauseTask':
        this.taskPlayer.pauseTask();
        break;
      case 'resumeTask':
        this.taskPlayer.resumeTask();
        break;
      case 'getTask': {
        const taskInfo = this.taskPlayer.getTask();
        const { scope, item, task } = (taskInfo === undefined ? {} : taskInfo);
        PostMessageReceiver.sendResponseEvent(
          {
            eventType: 'getTaskReturn',
            scope,
            item,
            task
          },
          origin,
          sourceWindow,
          this.responder
        );
        break;
      }
      case 'setTaskSequencer': {
        const responderFunction = this.responder;
        const targetWindow = this.getTargetWindow(data.targetWindowType);
        this.taskPlayer.setTaskSequencer(
          (request, scope, item, task) => {
            PostMessageReceiver.sendResponseEvent(
              {
                eventType: 'taskSwitchRequest',
                request,
                scope,
                item,
                task
              },
              data.targetOrigin,
              targetWindow,
              responderFunction
            );
          },
          (request, scope, item, task) => this.isTaskSwitchAvailable(request, scope, item, task)
        );
        break;
      }
      case 'setSwitchAvailability':
        this.processAvailabilityMessage(data);
        break;
      // ---- Task State Control ---------------------------------------------
      case 'getTasksState':
        PostMessageReceiver.sendResponseEvent(
          {
            eventType: 'getTasksStateReturn',
            userId: this.taskPlayer.getUserId(),
            state: this.taskPlayer.getTasksState()
          },
          origin,
          sourceWindow,
          this.responder
        );
        break;
      case 'clearTasksState':
        this.taskPlayer.clearTasksState();
        break;
      case 'preloadTasksState':
        this.taskPlayer.preloadTasksState(data.state);
        break;
      // ---- Scoring Control -------------------------------------------------
      case 'getScoringResult': {
        PostMessageReceiver.sendResponseEvent(
          {
            eventType: 'getScoringResultReturn',
            result: this.taskPlayer.getScoringResult(),
          },
          origin,
          sourceWindow,
          this.responder
        );
        break;
      }
      // ---- Statemachine Control ---------------------------------------------
      case 'sendStatemachineEvent':
        this.taskPlayer.sendStatemachineEvent(data.event);
        break;
      // ---- Header Control ---------------------------------------------
      case 'setHeaderButtons':
        this.taskPlayer.setHeaderButtons(data.headerButtons);
        break;
      case 'setMenuCarousels':
        this.taskPlayer.setMenuCarousels(data.course, data.scopes);
        break;
      // ---- Developer Mode Control ---------------------------------------------
      case 'activateDebuggingWindows':
        this.taskPlayer.activateDebuggingWindows(data.scoreHotKey, data.traceHotKey, data.statemachineHotKey);
        break;
      default:
        return false;
    }

    return true;
  }


  static sendResponseEvent(eventToSend, triggeringEventOrigin, triggeringEventSourceWindow, responder) {
    responder(eventToSend, triggeringEventOrigin, triggeringEventSourceWindow);
  }


  /**
   * Get the posting window specified by the given target window type.
   * 
   * @param {String} targetWindowType The type of reference to the window to post messages to: 'parent' (for IFRAME parent), 'opener' (for the window that spawned our window), 'self' (our own window, useful for testing only)
   */
  getTargetWindow = (targetWindowType) => {
    switch (targetWindowType) {
      case 'parent':
        return window.parent;
      case 'opener':
        return window.opener;
      case 'self':
        return window;
      default:
        console.error(`Unknown target window type ${targetWindowType}`)
        return undefined;
    }
  }

  /**
   * Get the number of user interactions to signal for this event. 
   * 
   * The method return 0 if it cannot detect a valid trace count in the event data.
   * 
   * @param {*} data The data contained in the event.
   */
  static getUserInteractionCount(data) {
    if (!PostMessageReceiver.isEmptyOrNoValue(data.traceCount) && data.traceCount >= 0) {
      return data.traceCount;
    }
    console.warn(`Invalid traceCount in external page frame event ignored: ${data.traceCount}`);
    return 0;
  }

}
