import IndexPathHelper from "./IndexPathHelper";
import UserDefPathHelper from "./UserDefPathHelper";
import CommonConfigHelper from '../config/CommonConfigHelper';

/**
 * Helper methods to work translate user defined ID paths to index paths and vice versa.
 */
export default class PathTranslationHelper {

  // ----- public API --------------------------------------------------------------------------------

  /**
   * Get the userDefPath for an index path. 
   * 
   * The method obtains the necessary userDefPathID from the currently loaded item configuration.
   * If there are CbaPageAreas in the index path without a user defined ID the method returns undefined.
   */
  static getUserDefPathForIndexPath(indexPath, runtime) {
    if (indexPath === undefined) {
      return undefined;
    }
    const pageSegments = IndexPathHelper.getPageSegmentArray(indexPath);
    if (pageSegments.length === 0) {
      return undefined;
    }
    const firstUserDefId = PathTranslationHelper.getUserDefIdForPageSegment(pageSegments[0], runtime);

    let userDefIdPath = UserDefPathHelper.buildUserDefIdPath(
      IndexPathHelper.getPageAreaTypeFromPath(indexPath),
      IndexPathHelper.getPageAreaNameFromPath(indexPath),
      firstUserDefId
    );

    for (let index = 1; index < pageSegments.length; index += 1) {
      const userDefinedId = PathTranslationHelper.getUserDefIdForPageSegment(pageSegments[index], runtime);
      if (userDefinedId === undefined) {
        return undefined;
      }
      userDefIdPath = UserDefPathHelper.appendUserDefIdToPath(userDefIdPath, userDefinedId);
    }

    return userDefIdPath;
  }


  /**
   * Get the index path for a userDefPath.
   * A userDefPath consists of the UserDefIds of the page areas leading to a page instance and the 
   * userDefId of a component that is part of the page. 
   * The method will use the path root currently loaded in the given runtime.
   */
  static getIndexPathForUserDefPath(userDefPath, runtime) {
    let pathId = IndexPathHelper.appendPageSegmentToPathRoot(
      runtime.taskManager.getCurrentStatePathRoot(),
      UserDefPathHelper.getPageAreaTypeFromPath(userDefPath), UserDefPathHelper.getPageAreaNameFromPath(userDefPath),
      ''
    );
    UserDefPathHelper.forEachUserDefIdInPath(userDefPath, (userDefId) => {
      const pageSegment = runtime.pageConfigurationsManager.findPageSegmentForUserDefId(userDefId);
      pathId = IndexPathHelper.appendPageSegmentsToPath(pathId, pageSegment);
    });
    return pathId;
  }

  /**
   * Get the index path for textBlockNamePath (i.e. the index path of the component owning the text block).
   * A textBlockNamePath consists of the UserDefIds of the page areas leading to a page instance and the 
   * name of a text block in a rich text field that is part of the page. 
   * The method will use the path root currently loaded in the given runtime.
   */
  static getIndexPathForTextBlockPath(textBlockNamePath, runtime) {
    let pathId = IndexPathHelper.appendPageSegmentToPathRoot(
      runtime.taskManager.getCurrentStatePathRoot(),
      UserDefPathHelper.getPageAreaTypeFromPath(textBlockNamePath), UserDefPathHelper.getPageAreaNameFromPath(textBlockNamePath),
      ''
    );


    // drop last id from path and process this after the loop
    const pageAreasPath = UserDefPathHelper.dropUserDefIdFromPath(textBlockNamePath);
    const textBlockName = UserDefPathHelper.getLastUserDefIdFromPath(textBlockNamePath);

    const textBlockOwnerPageSegment = runtime.pageConfigurationsManager.findPageSegmentForTextBlockOwner(textBlockName);
    if (textBlockOwnerPageSegment === undefined) {
      // the block name path does not match any block name 
      return undefined;
    }

    if (pageAreasPath !== undefined) {
      UserDefPathHelper.forEachUserDefIdInPath(pageAreasPath, (userDefId) => {
        const pageSegment = runtime.pageConfigurationsManager.findPageSegmentForUserDefId(userDefId);
        pathId = IndexPathHelper.appendPageSegmentsToPath(pathId, pageSegment);
      });
    }
    pathId = IndexPathHelper.appendPageSegmentsToPath(pathId, textBlockOwnerPageSegment);
    return pathId;
  }


  // ----- private stuff --------------------------------------------------------------------------------

  /**
   * Get the user defined ID from the component specified by the given page segment.
   * 
   * The method returns undefined if it cannot find a matching component or the matching component
   * does not have a user defined ID.
   * 
   * @param {*} pageSegment A page segments from an index path specifying the display component.
   * @param {*} runtime  The common runtime
   */
  static getUserDefIdForPageSegment(pageSegment, runtime) {
    const componentConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    if (componentConfig === undefined) {
      return undefined;
    }
    return CommonConfigHelper.getUserDefinedId(componentConfig.config);
  }

}
