import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaInnerHtml extends Component {

  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  static sanitizeHtml(original) {
    // TODO: Should we scan the generated HTML code?
    return {
      __html: original
    };
  }

  render() {
    const { runtime, config, path, orientation } = this.props;

    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    return (
      <div
        // We will check the embedded code in our sanitize method -> disable warning here:
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={CbaInnerHtml.sanitizeHtml(config.innerHtml)}
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={style}
      />
    );
  }

}

CbaInnerHtml.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        innerHtml: PropTypes.string.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
