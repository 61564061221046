import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import RenderingHelper from '../RenderingHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import Utils from '../../utils/Utils';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import IndexPathHelper from '../../state/IndexPathHelper';
import CommonActionsHelper from '../CommonActionsHelper';

export default class CbaTableCellEditor extends Component {

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { runtime } = this.props;
    runtime.eventEmitter.addListener(`${this.buildAttachedTableIndexPath()}-showAddress`, this.onAddressUpdate);
    runtime.eventEmitter.addListener(`${this.buildAttachedTableIndexPath()}-showContent`, this.onContentUpdate);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onChangeHandler = (event) => {
    this.updateContent(event.target.value);
  }

  onClickHandler = (event) => {
    // avoid container tracing 
    CommonActionsHelper.stopEventPropagation(event);
  }

  onAddressUpdate = (value) => {
    const pathState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setCellEditorAddress(pathState, value);
    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  onContentUpdate = (value, isNotValid, isReadOnly, errorMessage) => {
    this.updateContent(value, isNotValid, isReadOnly, errorMessage);
  }

  updateContent(value, isNotValidFormula, isReadOnly, errorMessage) {
    const pathState = ComponentStateHelper.getState(this);

    if (isNotValidFormula !== undefined) {
      StateAttributeAccess.setCellEditorIsNotValid(pathState, isNotValidFormula);
    }
    if (errorMessage !== undefined) {
      StateAttributeAccess.setCellEditorErrorMessage(pathState, errorMessage);
    }
    if (isReadOnly !== undefined) {
      StateAttributeAccess.setCellEditorContentIsReadOnly(pathState, isReadOnly);
    }

    StateAttributeAccess.setCellEditorContent(pathState, value);
    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRendering(this);
  }

  onKeyPressOnInput = (e) => {
    const { runtime } = this.props;
    if (e.key === 'Enter') {
      runtime.eventEmitter.emit(`${this.buildAttachedTableIndexPath()}-contentUpdate`, e.target.value);
      e.preventDefault();
    }
  }

  /**
   * This method will compose the index path for the attached table, based on the current path.
   */
  buildAttachedTableIndexPath() {
    const { config, path } = this.props;
    const cellEditorPathWithoutLastPageSegment = IndexPathHelper.dropPageSegmentFromPath(path);
    return IndexPathHelper.appendPageSegmentsToPath(cellEditorPathWithoutLastPageSegment, config.attachedTable);
  }

  render() {
    const { config, path, runtime, orientation } = this.props;
    const { adressTitle, contentTitle } = config;
    const pathState = ComponentStateHelper.getState(this);
    const address = StateAttributeAccess.extractCellEditorAddress(pathState);
    const content = StateAttributeAccess.extractCellEditorContent(pathState);

    const readOnly = StateAttributeAccess.extractCellEditorContentIsReadOnly(pathState);
    const containerStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    const addressWidth = config.cellAddressRelWidth * 100;
    const contentWidth = 100 - addressWidth;
    const addressrStyle = Utils.deepCopy(containerStyle);
    addressrStyle.left = 0;
    addressrStyle.top = 0;
    addressrStyle.width = `${addressWidth}%`;
    addressrStyle.height = '100%';
    addressrStyle.boxSizing = "border-box";
    addressrStyle.lineHeight = containerStyle.height;
    addressrStyle.textAlign = "center";


    const contentStyle = {
      position: 'absolute',
      left: `${addressWidth}%`,
      width: `${contentWidth}%`,
      height: '100%',
      boxSizing: 'border-box'
    }

    const isError = StateAttributeAccess.extractCellEditorIsNotValid(pathState);
    const errorMessage = StateAttributeAccess.extractCellEditorErrorMessage(pathState);
    const errorStyle = {
      position: "absolute",
      top: "-2px",
      right: "0",
      width: "7px",
      height: "8px",
      fontSize: "8px",
      lineHeight: "8px",
      backgroundColor: "#d8424f",
      color: "#fff",
      display: isError ? "block" : "none"
    }

    return (
      <div style={containerStyle}>
        <div style={addressrStyle} title={adressTitle}>
          {address !== undefined ? address : ''}
          <span style={errorStyle} title={errorMessage}>&#215;</span>
        </div>
        <input
          style={contentStyle}
          title={contentTitle}
          onChange={this.onChangeHandler}
          onKeyPress={this.onKeyPressOnInput}
          onClick={this.onClickHandler}
          type="text"
          value={content !== undefined ? content : ''}
          readOnly={readOnly}
        />
      </div>
    );
  }

}

CbaTableCellEditor.propTypes = {
  runtime: PropTypes.object.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.getStandardConfigPropTypes(false)
  ).isRequired,
  path: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired
}
