import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import CbaInterpreter from './CbaInterpreter';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import PageHistoryHelper from './PageHistoryHelper';
import BookmarkHelper from './BookmarkHelper';
import RenderingHelper from './RenderingHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import PathTranslationHelper from '../state/PathTranslationHelper';
import WebToolbar from './WebToolbar/WebToolbar';
import Utils from '../utils/Utils';
import SelectGroupHelper from './SelectGroupHelper';
import UserDefPathHelper from '../state/UserDefPathHelper';

export default class CbaPageArea extends Component {

  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }


  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  static addAttributesToInitialState(initialState, configProps) {
    const { historyMode, page } = configProps;
    initialState.pageName = page;
    PageHistoryHelper.initializeHistoryState(historyMode !== "singlePage", historyMode === "withTabs", initialState);
  }

  static setPageName(path, receiverTab, pageName, pageUrl, runtime) {
    if (PageHistoryHelper.hasStateWithoutPageHistory(path, runtime)) {
      // The component for the path seems not to be a CbaPageArea....
      console.warn(`Set page name call for a component that is not a CbaPageArea: ${path}`);
      return;
    }

    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);

    const currentEmbeddedPage = PageHistoryHelper.getPage(path, runtime);
    if (currentEmbeddedPage === undefined || currentEmbeddedPage.name !== pageName) {

      // update the component's state in state manager:
      const { name: receiverTabName, image: receiverTabImage } = CbaPageArea.getSafeReceiverTabDetails(receiverTab);
      PageHistoryHelper.addPage(pageName, pageUrl, receiverTabImage, receiverTabName, path, runtime);

      // update the button (i.e. tab or taskbar button) that is linked to the new page:
      CbaPageArea.updatePageIndicators(path, config, pageName, runtime);

      // update the browser toolbar components that depend on the page history (i.e. forward/back buttons and URL display):
      CbaPageArea.updatePageHistoryDependents(path, config, runtime);
    }

  }

  static buildIndicatorIndexPath(embeddingIndexPath, indicator) {
    return IndexPathHelper.appendPageSegmentsToPath(embeddingIndexPath, indicator.indicatorPath);
  }

  static getSafeReceiverTabDetails(receiverTab) {
    if (receiverTab === undefined) {
      return {
        name: '',
        image: undefined
      }
    }
    return {
      name: receiverTab.name === undefined ? '' : receiverTab.name,
      image: receiverTab.image
    }
  }


  /**
   * Get the 'hidden' state of the component specified by the given indicator info. 
   * 
   * @param {{ pageName: String, indicatorPath: String}} indicator The indicator info entry from the config structure. The path is a single plain page segment without path root.
   * @param {*} embeddingIndexPath The index path where we append the indicatorPath to obatin the full component index path.
   * @param {*} runtime The common runtime structure. 
   */
  static isIndicatorNotHidden(indicator, embeddingIndexPath, runtime) {
    const indicatorIndexPath = CbaPageArea.buildIndicatorIndexPath(embeddingIndexPath, indicator);
    const indicatorState = runtime.componentStateManager.findOrBuildStateForPathId(indicatorIndexPath, runtime);
    return StateAttributeAccess.extractHidden(indicatorState) === false;
  }

  /**
   * Select a 'best matching' page indicator from the given candidates list. 
   * 
   * The method selects the first page indicator that is linked to the given page
   * and is currently not hidden. If there is no such indicator it choses the first 
   * indicator linked to the given page. If there is no indicator linked to 
   * the given page it silently returns undefined.
   * 
   * @param {*} pageName The page name that the page indicator must be linked to.
   * @param {[*]} pageIndicators The list of page indicator candidates.
   * @param {String} embeddingIndexPath The path that we will prepend to the path given by the candidate entry to obtain the indicator's full index path.
   * @param {*} runtime The common runtime structure.
   */
  static findPreferredIndicator(pageName, pageIndicators, embeddingIndexPath, runtime) {
    if (pageIndicators === undefined) {
      return undefined;
    }
    const pageMatchingIndicators = pageIndicators.filter(indicator => indicator.pageName === pageName);
    if (pageMatchingIndicators.length === 0) {
      return undefined;
    }
    if (pageMatchingIndicators.length === 1) {
      return pageMatchingIndicators[0];
    }
    const firstVisibleCandidate = pageMatchingIndicators.find(indicator => CbaPageArea.isIndicatorNotHidden(indicator, embeddingIndexPath, runtime));
    return firstVisibleCandidate === undefined ? pageMatchingIndicators[0] : firstVisibleCandidate;
  }

  /**
   * Switch the 'selected' status to true for the 'page indicating' button component that links to the given page.
   * 
   * We have to do this explicitly since the page switch might be triggered by a state machine operator or a button 
   * that is not member of our 'page indicating' buttons set. 
   * 
   * Examples for 'page indicating' buttons are the 'tabs' in a tabfolder page or the taskbar buttons in a taskbar page. 
   * 
   * 
   * @param {*} path The full index path of the CbaPageArea component that switches the embedded page.
   * @param {*} config The configuration structure for the CbaPageArea that contains the list of info pairs for the 'page indicating' components. 
   * @param {*} pageName The name of the page we switch to. 
   * @param {*} runtime The common runtime structure.
   */
  static updatePageIndicators(path, config, pageName, runtime) {
    const { currentPageIndicators } = config;
    const indicatorPathRoot = IndexPathHelper.dropPageSegmentFromPath(path);
    const chosenIndicator = CbaPageArea.findPreferredIndicator(pageName, currentPageIndicators, indicatorPathRoot, runtime);
    if (chosenIndicator !== undefined) {
      const indicatorIndexPath = CbaPageArea.buildIndicatorIndexPath(indicatorPathRoot, chosenIndicator);
      const indicatorState = runtime.componentStateManager.findOrBuildStateForPathId(indicatorIndexPath, runtime);
      SelectGroupHelper.setSelectedForPossiblyControlledComponent(true, indicatorIndexPath, indicatorState, false, runtime);
    }
  }


  static doHistoryMove(path, move, runtime) {
    if (PageHistoryHelper.hasStateWithoutPageHistory(path, runtime)) {
      // The component for the path seems not to be a CbaPageArea....
      console.warn(`Do history move call for a component that is not a CbaPageArea: ${path}`);
      return;
    }

    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(path, runtime)

    // update the component's state in state manager:
    switch (move) {
      case 'home':
        PageHistoryHelper.goHome(path, runtime);
        CbaPageArea.updatePageHistoryDependents(path, config, runtime);
        break;
      case 'forward':
        PageHistoryHelper.goForward(path, runtime);
        CbaPageArea.updatePageHistoryDependents(path, config, runtime);
        break;
      case 'back':
        PageHistoryHelper.goBack(path, runtime);
        CbaPageArea.updatePageHistoryDependents(path, config, runtime);
        break;
      default:
        console.warn(`Unknown type of history move : ${move} for component path ${path}`);
        break;
    }

    runtime.traceLogBuffer.reportEvent('PageSwitchEmbedded', new Date(),
      {
        indexPath: path,
        userDefIdPath,
        userDefId: UserDefPathHelper.getLastUserDefIdFromPath(userDefIdPath),
        newPageName: PageHistoryHelper.getPage(path, runtime).name,
        tab: PageHistoryHelper.getTab(path, runtime),
        historyMove: move
      });

  }

  static historyMoveEnabled(path, move, runtime) {
    if (PageHistoryHelper.hasStateWithoutPageHistory(path, runtime)) {
      // The component for the path seems not to be a CbaPageArea....
      console.warn(`Enabled history move inquiry for a component that is not a CbaPageArea: ${path}`);
      return false;
    }

    switch (move) {
      case 'home':
        return true;
      case 'forward':
        return PageHistoryHelper.canGoForward(path, runtime);
      case 'back':
        return PageHistoryHelper.canGoBack(path, runtime);
      default:
        console.warn(`Unknown type of history move : ${move} for component path ${path}`);
        return false;
    }

  }

  static switchTab(path, newTab, event, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    event.stopPropagation();
    PageHistoryHelper.switchTab(newTab, path, runtime);
    CbaPageArea.updatePageHistoryDependents(path, config, runtime);

    CommonActionsHelper.traceUserInteraction(
      'BrowserTab',
      path,
      {
        page: PageHistoryHelper.getPage(path, runtime).name,
        tab: PageHistoryHelper.getTab(path, runtime)
      },
      event,
      null,
      runtime
    );

  }

  static updatePageHistoryDependents(path, config, runtime) {
    const { forwardSwitchers, backwardSwitchers, locationDisplays } = config;
    const myPathWithoutMyPageSegment = IndexPathHelper.dropPageSegmentFromPath(path);

    if (forwardSwitchers !== undefined) {
      const canGoForward = PageHistoryHelper.canGoForward(path, runtime);
      forwardSwitchers.forEach((switcher) => {
        const pathId = IndexPathHelper.appendPageSegmentsToPath(myPathWithoutMyPageSegment, switcher);
        ComponentStateHelper.updateStateAttribute(StateAttributeAccess.extractDisabled, StateAttributeAccess.setDisabled, !canGoForward, pathId, runtime, true);
      });
    }
    if (backwardSwitchers !== undefined) {
      const canGoBack = PageHistoryHelper.canGoBack(path, runtime);
      backwardSwitchers.forEach((switcher) => {
        const pathId = IndexPathHelper.appendPageSegmentsToPath(myPathWithoutMyPageSegment, switcher);
        ComponentStateHelper.updateStateAttribute(StateAttributeAccess.extractDisabled, StateAttributeAccess.setDisabled, !canGoBack, pathId, runtime, true);
      });
    }
    if (locationDisplays !== undefined) {
      const pageEntry = PageHistoryHelper.getPage(path, runtime);
      const newValue = Utils.getPropUndefinedSafe(pageEntry, 'url', '');
      locationDisplays.forEach((locationDisplay) => {
        const pathId = IndexPathHelper.appendPageSegmentsToPath(myPathWithoutMyPageSegment, locationDisplay);
        ComponentStateHelper.updateStateAttribute(StateAttributeAccess.extractTextValue, StateAttributeAccess.setTextValue, newValue, pathId, runtime, true);
      });
    }
  }


  /**
   * Add the page currently embedded as bookmark to our bookmarks list.
   * 
   * @param {String} path The index path of the CbaPageArea to operate on.
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the component requesting to add the bookmark (for tracing purposes).
   * @param {*} runtime The common runtime context structure.
   */
  static addBookmark(path, triggeringType, requestingComponentPath, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(path);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    const currentPageEntry = CbaPageArea.getEmbeddedPageEntry(path, config, runtime);
    if (currentPageEntry !== undefined) {
      const currentTab = PageHistoryHelper.getTab(path, runtime);
      BookmarkHelper.addBookmark(path, currentPageEntry.name, currentPageEntry.url, currentTab, currentPageEntry.image, triggeringType, requestingComponentPath, runtime);
    }
  }

  /**
   * Drop the specified page from our bookmarks list.
   * 
   * @param {String} path The index path of the CbaPageArea to operate on.
   * @param {String} pageName The name of the page to drop from the bookmarks list.
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the component requesting to drop the bookmark (for tracing purposes).
   * @param {*} runtime The common runtime context structure.
   */
  static dropBookmark(path, pageName, triggeringType, requestingComponentPath, runtime) {
    BookmarkHelper.dropBookmark(path, pageName, triggeringType, requestingComponentPath, runtime);
  }

  /**
   * Get the list of page names in our bookmarks list.
   */
  static getBookmarks(path, runtime) {
    return BookmarkHelper.getBookmarks(path, runtime);
  }

  static getEmbeddedPageName(path, config, runtime) {
    const pageEntry = CbaPageArea.getEmbeddedPageEntry(path, config, runtime);
    return pageEntry === undefined ? undefined : pageEntry.name;
  }

  static getEmbeddedPageEntry(path, config, runtime) {
    const { historyMode } = config;
    // fail fast if config param is invalid (don't mess up initialization of history structure!)
    if (historyMode === undefined) {
      console.error(`Invalid config structure for CbaPageArea: ${path}`, config);
      return undefined;
    }

    let pageEntry = PageHistoryHelper.getPage(path, runtime);

    // Use initial page from my config if nobody did set another page yet.
    // Don't forget to set it in the page history helper. Otherwise goHome will not work properly!
    if (pageEntry === undefined) {
      const initialPageName = config.page;
      const initialPageUrl = config.pageUrl;
      const { name: receiverTabName, image: receiverTabImage } = CbaPageArea.getSafeReceiverTabDetails(config.initialTab);
      PageHistoryHelper.addPage(initialPageName, initialPageUrl, receiverTabImage, receiverTabName, path, runtime);
      pageEntry = PageHistoryHelper.getPage(path, runtime);
    }
    return pageEntry;
  }

  static isExcessivePageNesting(pagePath) {
    return IndexPathHelper.getPageSegmentArray(pagePath).length >= 15;
  }

  render() {
    const { config, runtime, path: myPath, orientation } = this.props;

    // get the page configuration:
    const pageName = CbaPageArea.getEmbeddedPageName(myPath, config, runtime);
    const page = runtime.pageConfigurationsManager.findPage(pageName);

    const pathState = ComponentStateHelper.getState(this);
    const positionOnlyState = {};
    StateAttributeAccess.setPosition(positionOnlyState, StateAttributeAccess.extractPosition(pathState));

    if (page === undefined) {
      return (
        <div
          style={CommonConfigHelper.buildStyleByState(positionOnlyState, config, false, orientation, false, runtime)}
        >
          {`Cannot find page with name ${pageName}`}
        </div>
      );
    } else if (CbaPageArea.isExcessivePageNesting(myPath)) {
      return (
        <div
          style={CommonConfigHelper.buildStyleByState(positionOnlyState, config, false, orientation, false, runtime)}
        >
          Page nesting exceeds maximum nesting depth.
        </div>
      );
    }

    const { historyMode } = config;
    const hasTabs = historyMode === "withTabs";

    const childAreaStyle = {
      position: "absolute",
      [orientation]: 0,
      top: (hasTabs ? 30 : 0),
      width: config.position.width,
      height: config.position.height,
      overflow: "auto"
    }

    const pagePath = IndexPathHelper.appendPageSegmentsToPath(myPath, IndexPathHelper.buildPageSegment(pageName));

    return (
      <div
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={CommonConfigHelper.buildStyleByState(positionOnlyState, config, false, orientation, false, runtime)}
        role="presentation"
      >
        {hasTabs && <WebToolbar parentPath={myPath} runtime={runtime} />}
        <div
          style={childAreaStyle}
          onScroll={CommonActionsHelper.traceUserScroll(pagePath, runtime)}
        >
          <CbaInterpreter config={page.content} path={pagePath} runtime={runtime} orientation={orientation} />
        </div>
      </div>
    );
  }

}


CbaPageArea.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        page: PropTypes.string.isRequired,
        pageUrl: PropTypes.string.isRequired,
        catchLinks: PropTypes.bool.isRequired,
        historyMode: PropTypes.oneOf(["singlePage", "noTabs", "withTabs"]),
        initialTab: PropTypes.object,
        currentPageIndicators: PropTypes.arrayOf(PropTypes.shape({
          pageName: PropTypes.string.isRequired,
          indicatorPath: PropTypes.string.isRequired,
        })),
        forwardSwitchers: PropTypes.arrayOf(PropTypes.string),
        backwardSwitchers: PropTypes.arrayOf(PropTypes.string),
        locationDisplays: PropTypes.arrayOf(PropTypes.string),
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
