import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import CbaPageArea from '../CbaPageArea';
import TreeUtils from './TreeUtils';
import RenderingHelper from '../RenderingHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import IndexPathHelper from '../../state/IndexPathHelper';
import StateManagerHelper from '../../state/StateManagerHelper';
import CommonActionsHelper from '../CommonActionsHelper';


export default class CbaTreeChildArea extends PureComponent {

  constructor(props) {
    super(props);
    const { runtime } = this.props;

    this.eventEmitter = runtime.eventEmitter;
  }

  componentDidMount() {
    RenderingHelper.onMount(this);

    const { path, config } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);

    this.subscription = this.eventEmitter.addListener(TreeUtils.getTreeEventChangeName(treePath), this.handleTreeChange);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
    this.subscription.remove();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  handleTreeChange = () => {
    const { path, runtime, config } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);

    const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const page = treeState.currentPage;

    if (page) {
      CbaPageArea.setPageName(path, undefined, page, "", runtime);
    }

    RenderingHelper.triggerRendering(this);
  }

  static getPageAreaPath(path) {
    return IndexPathHelper.appendIndexToPageSegment(path, 0);
  }

  static getPageAreaConfig(treeChildAreaConfig) {
    const pageAreaConfig = StateManagerHelper.deepCopy(treeChildAreaConfig)
    // Don't bequeath user defined ID to our delegate:
    pageAreaConfig.userDefinedId = undefined;
    // mark the component as delegate, 
    // this will make it trace the proper indexPath and userDefId
    pageAreaConfig.trace.isDelegate = true;
    Object.assign(pageAreaConfig, {
      historyMode: "singlePage",
      catchLinks: false,
      pageUrl: "",
      page: undefined
    });
    return pageAreaConfig;
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);
    const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const currentPage = treeState.currentPage || null;
    const emptyStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    if (currentPage) {
      const pageAreaConfig = CbaTreeChildArea.getPageAreaConfig(config);

      // CbaPageArea.setPageName will hide the currentPage in the config but we need it to satisfy the propType checker of CbaPageArea:
      pageAreaConfig.page = currentPage;
      const pageAreaPath = CbaTreeChildArea.getPageAreaPath(path);
      CbaPageArea.setPageName(pageAreaPath, '', currentPage, '', runtime);

      return (
        <CbaPageArea
          config={pageAreaConfig}
          runtime={runtime}
          path={pageAreaPath}
          orientation={orientation}
        />
      )
    } else {
      return (
        <div
          style={emptyStyle}
          onClick={event => CommonActionsHelper.doStandardOnClick(event, undefined, this)}
          title={CommonConfigHelper.buildTitle(config)}
        />
      )
    }
  }

}


CbaTreeChildArea.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        treePathId: PropTypes.string.isRequired,
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
