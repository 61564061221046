import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Dialog from 'react-dialog';
import CbaInterpreter from '../components/CbaInterpreter';
import RenderingHelper from '../components/RenderingHelper';
import IndexPathHelper from '../state/IndexPathHelper';

export default class DialogPresenter extends Component {

  // NOTE: The DialogPresenter has no index path. Therefore it cannot register at the RenderingHelper
  // via onMount, onUnmount, onReceiveProps. You cannot use RenderingHelper.triggerRenderingViaPath(...) either.
  static buildDialogIndexPath(taskPath, dialog) {
    return IndexPathHelper.appendPageSegmentToPathRoot(taskPath, dialog.type, dialog.pageAreaName, IndexPathHelper.buildPageSegment(dialog.pageName));
  }

  static buildDialogRefKey(dialog) {
    const { pageAreaType, pageAreaName, pageName } = dialog;
    return `/type=${pageAreaType}/areaName=${pageAreaName}/pageName=${pageName}`;
  }

  static isDialogParentInPath(path) {
    if (path !== undefined) {
      const currentPageAreaType = IndexPathHelper.getPageAreaTypeFromPath(path);
      return currentPageAreaType !== undefined && (currentPageAreaType === "dialog" || currentPageAreaType === "modal");
    }

    return false;
  }

  static closeParentDialogFromPath(runtime, path) {
    if (DialogPresenter.isDialogParentInPath(path)) {
      const currentPageAreaType = IndexPathHelper.getPageAreaTypeFromPath(path);
      const currentPageAreaName = IndexPathHelper.getPageAreaNameFromPath(path);
      const currentTaskPath = runtime.taskManager.getCurrentStatePathRoot();

      runtime.presenterStateManager.hideDialog(currentPageAreaName, currentPageAreaType, currentTaskPath);
    } else {
      console.warn("Dialog is not in path - ", path);
    }
  }

  constructor(props) {
    super(props);
    // TODO: CKI Is the self registering a good idea? Why don't we use self registering for other global components?
    props.runtime.dialogPresenter = this;
  }

  /*
  * function to get all dialogs from the presenter state manager
  */
  getDialogsFromPresenterStateManager = () => {
    const { runtime, findPageForPageName } = this.props;
    const currentTaskPath = runtime.taskManager.getCurrentStatePathRoot();
    const taskState = runtime.presenterStateManager.getTaskState(currentTaskPath);
    const filteredDialogs = taskState.dialogs.filter(d => d.visible)
    const dialogs = this.checkAndSetFocus(filteredDialogs);

    return dialogs.map((dialog) => {
      const page = findPageForPageName(dialog.pageName, runtime);
      const { width: pageWidth, height: pageHeight } = page.content.config.position;

      // if no position center object
      if (!dialog.position || (dialog.position.x < 0 && dialog.position.y < 0)) {
        dialog.position = {
          x: window.innerWidth / 2 - pageWidth / 2,
          y: window.innerHeight / 2 - pageHeight / 2,
        }
      }

      return {
        type: dialog.type,
        position: dialog.position,
        focused: dialog.focused,
        pageAreaName: dialog.pageAreaName,
        pageName: dialog.pageName,
        visible: dialog.visible
      }
    });
  }

  checkAndSetFocus = (dialogs) => {
    for (let index = 0; index < dialogs.length; index += 1) {
      if (dialogs[index].focused) return dialogs;
      if (index === dialogs.length - 1) {
        dialogs[index].focused = true;
      }
    }

    return dialogs;
  }

  handleFocusAndDrag = (event, dialog) => {
    const { runtime } = this.props;
    const currentTaskPath = runtime.taskManager.getCurrentStatePathRoot();

    // get new position.
    // TODO: support separate namespaces for dialog/modal:
    const position = this.getCurrentDialogPosition(dialog);

    // update dialog state in task state and rerender
    runtime.presenterStateManager.updatePositionAndFocusDialog(dialog.pageAreaName, dialog.type, currentTaskPath, position);
    RenderingHelper.triggerRendering(this);
  }

  getCurrentDialogPosition = (dialog) => {
    /* Dependent on the use of transform by react-dialog (3rd party library) - could break */
    // eslint-disable-next-line
    const dialogInstance = ReactDOM.findDOMNode(this.refs[DialogPresenter.buildDialogRefKey(dialog)]).getElementsByClassName("ui-dialog")[0];
    const rawTransform = dialogInstance.style.transform.match(/\(([^)]+)\)/)[1];
    const transformValue = rawTransform.split(",");

    const x = parseInt(transformValue[0], 10);
    const y = parseInt(transformValue[1], 10);

    return {
      x,
      y,
    };
  }

  buildDialogs = () => {
    const { runtime, findPageForPageName } = this.props;
    const currentTaskPath = runtime.taskManager.getCurrentStatePathRoot();
    return this.getDialogsFromPresenterStateManager().map((dialog, index) => {
      const isModal = dialog.type === "modal";
      const page = findPageForPageName(dialog.pageName, runtime);
      const orientation = page.orientation ? "right" : "left";
      const { width: pageWidth, height: pageHeight } = page.content.config.position; // maybe make top level height and width accesible to page obj?
      const dialogPath = DialogPresenter.buildDialogIndexPath(currentTaskPath, dialog);

      return (
        <div
          key={DialogPresenter.buildDialogRefKey(dialog)}
          className={`${isModal ? "cba-modal" : "cba-dialog"} ${dialog.focused ? "focused" : ""} ${!page.closable ? "not-closable" : ""}`}
          onClick={(event) => { this.handleFocusAndDrag(event, dialog) }}
        >
          <Dialog
            // title={props.currentModal} TODO: add title when merging with other debug modals
            modal={isModal}
            isResizable={false}
            isDraggable={page.closable}
            hasCloseIcon={page.closable}
            closeOnEscape={page.closable}
            width={pageWidth}
            height={pageHeight + 25} // adding height of topbar
            onClose={() => this.closeDialog(dialog)}
            position={dialog.position}
            ref={DialogPresenter.buildDialogRefKey(dialog)}
          >
            <div className="dialog-page">
              {/* @Dejan TODO: replace with Modal Manager and move this logic to Modal Manager when merging  */}
              <CbaInterpreter runtime={runtime} path={dialogPath} config={page.content} orientation={orientation} />
            </div>
          </Dialog>
        </div>
      )
    })
  }

  closeDialog = (dialog) => {
    const { runtime } = this.props;
    const currentTaskPath = runtime.taskManager.getCurrentStatePathRoot();

    runtime.presenterStateManager.hideDialog(dialog.pageAreaName, dialog.type, currentTaskPath);
    RenderingHelper.triggerRendering(this);
  }

  render() {
    return (
      <div>
        {this.buildDialogs()}
      </div>
    )
  }

}


DialogPresenter.propTypes = {
  runtime: PropTypes.object.isRequired,
  findPageForPageName: PropTypes.func.isRequired,
}
