import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContextMenuTrigger, ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';

import './MenuItemTree.css';

/**
 * Display a context menu with optional sub menues.
 * 
 * Other components will create instances of this component explicitly by calling one of the static factory methods.
 * We don't use this component as component type in the page config structure, it is not known to the CbaInterpreter etc.
 * 
 * The component does not keep state in the ComponentStateManager and does not register in the ComponentDirectory.
 * The component is registered by the LoggedInView as a runtime global component beacuase it is a singleton and is available with different configurations throughout the app
 */
export default class MenuItemTree extends Component {

  constructor() {
    super();
    this.contextTrigger = React.createRef();
  }

  // ----- public API ----------------------------------------------------------------------

  /**
   * Logic for getting the bounding element of the menu trigger
   * 
   * @param {*} event The event which triggers the menu 
   */
  static buildTriggerBoundingRect(event) {
    const buttonElement = event.target.nodeName === "SPAN" ? event.target.parentNode : event.target;
    return buttonElement.getBoundingClientRect();
  }

  /**
   * Open a menu item tree instance for a static menu configuration. 
   * 
   * @param {*} staticMenuConfig The configuration for the menu item tree.
   */
  openMenuItemTree(staticMenuConfig) {
    this.setState({
      entries: staticMenuConfig.entries
    }, () => {
      this.contextTrigger.current.handleContextClick(staticMenuConfig.event);
    });
  }

  /**
   * Open a menu item tree instance for a menu configuration that might
   * contain entries with type 'dynamicItems'. 
   * 
   * @param {*} staticMenuConfig The configuration for the menu item tree.
   */
  openMenuItemTreeWithDynamicConfig(dynamicMenuConfig) {
    dynamicMenuConfig.event.persist();
    const staticConfig = {
      event: dynamicMenuConfig.event,
      entries: MenuItemTree.transformDynamicEntries(dynamicMenuConfig.entries)
    }
    this.openMenuItemTree(staticConfig);
  }

  // ----- private stuff --------------------------------------------------------------------
  /**
   * Transform a list of item entries by expanding each entry of type 'dynamicItems'
   * in a list of entry items without type 'dynmicItems'.
   */
  static transformDynamicEntries(dynamicEntries) {
    const result = [];
    dynamicEntries.forEach((entry) => {
      switch (entry.type) {
        case 'dynamicItems':
          MenuItemTree.expandDynamicEntry(entry).forEach((replacementEntry) => { result.push(replacementEntry) });
          break;
        case 'submenu':
        {
          const transformedCopy = {};
          Object.keys(entry).forEach((attribute) => {
            transformedCopy[attribute] = entry[attribute];
          })
          transformedCopy.submenu = MenuItemTree.transformDynamicEntries(entry.submenu);
          result.push(transformedCopy);
          break;
        }
        default:
          result.push(entry);
      }
    });
    return result;
  }


  /**
   * Transform an entry of type 'dynamicItems' in a list of entries that are not
   * of type 'dynamicItems'.
   */
  static expandDynamicEntry(entry) {
    return entry.provider(entry.providerParam);
  }

  state = {
    entries: []
  }

  handleItemClick(entry, event) {
    const { runtime } = this.props;
    entry.action(event);
    CommonActionsHelper.sendEvent(entry.event, runtime);
  }

  buildEntries(entries) {
    const { runtime } = this.props;
    return entries.map((entry, i) => {
      const key = `${entry.type}-${entry.label}-${i}`;
      const wrapStyle = {
        display: "flex",
        alignItems: "center",
        minHeight: "15px"
      };
      const itemAttributes = {
        title: entry.mouseOver,
        style: wrapStyle
      };
      const imagePath = entry.icon ? CommonConfigHelper.getProperResourcePath(entry.icon, runtime) : null;

      switch (entry.type) {
        case "action":
          return (
            <MenuItem key={key} onClick={event => this.handleItemClick(entry, event)} attributes={itemAttributes} disabled={entry.disabled}>
              <MenuItemTreeIcon disabled={entry.disabled} imagePath={imagePath} altText={`${entry.label}-icon`} />
              {entry.label}
            </MenuItem>
          );
        case "separator":
          return <MenuItem key={key} divider />;
        case "submenu":
          return (
            <SubMenu key={key} title={entry.label} disabled={entry.disabled}>
              {this.buildEntries(entry.submenu)}
            </SubMenu>
          )
        default: return null;
      }
    })
  }

  render() {
    const { entries } = this.state;
    return (
      <div className="contextMenu">
        <ContextMenuTrigger id="contextMenu" ref={this.contextTrigger}>
          <div />
        </ContextMenuTrigger>
        <ContextMenu id="contextMenu">
          {this.buildEntries(entries)}
        </ContextMenu>
      </div>
    );
  }

}

MenuItemTree.propTypes = {
  runtime: PropTypes.object.isRequired
}

function MenuItemTreeIcon(props) {
  const { imagePath, disabled, altText } = props;

  if (!imagePath) return null;

  const imageWrapStyle = {
    width: "25px",
    height: "25px",
    marginRight: "5px",
    display: "flex",
    alignItems: "center"
  };
  const imageStyle = {
    maxHeight: "100%",
    maxWidth: "100%",
    margin: "auto",
    opacity: disabled ? 0.5 : 1
  };

  return (
    <div style={imageWrapStyle}>
      <img src={imagePath} style={imageStyle} alt={altText} />
    </div>
  );
}

MenuItemTreeIcon.propTypes = {
  imagePath: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  altText: PropTypes.string.isRequired
}

MenuItemTreeIcon.defaultProps = {
  imagePath: ""
}
