import StateManagerHelper from '../../state/StateManagerHelper';
import EvaluatorHelper from "./EvaluatorHelper"

export default class ExpressionEvaluator {

  static RANK = {
    '=': 0,
    '+': 1,
    '-': 1,
    '/': 2,
    '*': 2,
    yx: 3,
    'x√y': 3,
    log: 3,
    EE: 3
  };

  constructor() {
    // state
    // stack is array of [val, key]
    this.stack = [];
    this.num = 0
    this.res = 0
    // used for repeating equals - [val, key]
    this.repeatingOperation = [false, false];
  }

  getFullState = () => {
    const state = {}
    state.stack = StateManagerHelper.deepCopy(this.stack);
    state.num = this.num;
    state.res = this.res;
    state.repeatingOperation = StateManagerHelper.deepCopy(this.repeatingOperation);
    return state;
  }

  restoreState = (state) => {
    this.stack = StateManagerHelper.deepCopy(state.stack);
    this.num = state.num;
    this.res = state.res;
    this.repeatingOperation = StateManagerHelper.deepCopy(state.repeatingOperation);
  }


  calc = (key, val) => {
    // console.log(" calc = (key, val)", key, val);

    // go back to normal arithmetic operators
    key = key.replace('×', '*').replace('÷', '/').replace('–', '-');

    const rank = ExpressionEvaluator.RANK;

    if (key === '%') {
      const result = this.calcPercent(key, val);
      return `${result}`;
    }

    this.saveForRepeatingOperation(key, val);

    if (key === '=' && !this.stack[0] && this.repeatingOperation[1]) { // repeating '='
      const result = this.calcValue(val, this.repeatingOperation[1], this.repeatingOperation[0]);
      return `${result}`;
    }
    if (!this.stack[0] && key !== '=') { // first filling
      this.repeatingOperation[0] = false;
      this.stack[this.num] = [val, key];
      this.num += 1;
      return `${val}`;
    }
    // pressing = without anything in stack
    if (!this.stack[0]) {
      return `${val}`;
    }
    // the new operation as same precendence or lower than previous one (e.g. *, +s)
    if (rank[key] <= rank[this.stack[this.num - 1][1]]) {
      // in case of nroot the second operand was this.buff[0] - tests are still working
      const result = this.calcValue(this.stack[this.num - 1][0], this.stack[this.num - 1][1], val);
      this.stack[this.num - 1] = [result, key];
    }
    // the new operation has higher precendence than previous one (e.g. +, *)
    if (rank[key] > rank[this.stack[this.num - 1][1]]) {
      // push to stack
      this.stack[this.num] = [val, key];
      this.num += 1;
    } else if (this.stack[this.num - 2] && rank[key] <= rank[this.stack[this.num - 2][1]]) {
      // the new operation has lower precendence than 2 steps ago -> (e.g.  +, *, =)
      this.num -= 1;
      this.calc(key, this.stack[this.num][0]);
    }
    this.res = `${this.stack[this.num - 1] ? this.stack[this.num - 1][0] : this.res}`;
    if (key === '=') {
      this.resetStack();
    }
    return this.res;
  }


  saveForRepeatingOperation = (key, val) => {
    if (key !== '=') { // last operator is saved
      this.repeatingOperation[1] = key;
    } else if (this.repeatingOperation[0] === false) { // only at first equals this occures
      this.repeatingOperation[0] = val; // feed buffer for repeating '='
    }
  }

  calcPercent = (key, val) => {
    const percent = (this.stack[0] ? this.stack[this.num - 1][0] / 100 * val : val / 100);
    return percent;
  }

  // operands are strings
  calcValue = (o1, operator, o2) => {
    let result;
    switch (operator) {
      case 'log':
        result = Math.log(o1) / Math.log(o2);
        break;
      case 'yx':
        result = o1 ** o2;
        break;
      case 'x√y':
        /* Math.pow(this.stack[this.num - 1][0], 1 / val) */
        result = EvaluatorHelper.nthroot(o1, o2);
        break;
      case 'EE':
        result = o1 * (10 ** o2);
        break;
      case '+':
        result = (+o1) + (+o2);
        break;
      case '-':
        result = o1 - o2;
        break;
      case '*':
        result = o1 * o2;
        break;
      case '/':
        result = o1 / o2;
        break;
      default:
        // result = eval(`(${o1})${operator}(${o2})`);
        throw new Error(`NO case for operator ${operator}`);
    }
    return result;
  }

  reset = () => {
    this.resetStack();
    this.repeatingOperation = [false, false];
  }

  resetStack = () => {
    this.stack = [];
    this.num = 0;
  }

}
