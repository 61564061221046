
export default class LibraryManager {

  constructor() {
    this.librariesUsedMap = {}
  }

  // ----- public API --------------------------------------------------------------------------

  addLibrariesForItem(itemLibraries, libraryPathsMap) {
    if (!itemLibraries || (itemLibraries && !itemLibraries.length > 0)) return;
    if (!libraryPathsMap || (libraryPathsMap && !Object.keys(libraryPathsMap).length > 0)) return;
    if (!LibraryManager.checkLibraryPaths(itemLibraries, libraryPathsMap)) return;

    itemLibraries.forEach((library) => {
      switch (library.name) {
        case "MathJax": this.addMathJax(libraryPathsMap[library.name]); break;
        default: console.error("Unexpected library provied", library.name); break;
      }
    })


  }

  // ----- private stuff -----------------------------------------------------------------------

  static checkLibraryPaths(itemLibraries, libraryPathsMap) {
    const checker = itemLibraries.map(library => !!libraryPathsMap[library.name]);

    return checker.every(c => c === true);
  }

  addMathJax(libraryPath) {
    // check if MathJax is already in use
    if (this.librariesUsedMap.MathJax) {
      return;
    }

    this.librariesUsedMap.MathJax = libraryPath;

    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', libraryPath);
    scriptTag.setAttribute('async', true);
    scriptTag.setAttribute('id', "MathJax-script")

    document.head.appendChild(scriptTag);

  }

}
