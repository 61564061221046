import React, { Component } from 'react';
import './CbaButton.css';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import MenuBuildHelper from "./MenuBuildHelper";
import SpecialClickActionsHelper from "./SpecialClickActionsHelper";
import CommonActionsHelper from "./CommonActionsHelper";
import CommonConfigHelper from '../config/CommonConfigHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import ComponentStateHelper from '../state/ComponentStateHelper';
import TermEvaluator from '../eval/TermEvaluator';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';
import Utils from "../utils/Utils";
import MenuItemTree from './MenuItemTree';

/**
 * A display component that displays a button.
 */
export default class CbaButton extends Component {

  constructor(props) {
    super(props);

    this.backgroundImage = {
      hover: "",
      pressed: "",
    }
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  onMouseDown = (event) => {
    const { config, runtime, path } = this.props;

    if (config.toggleType === "upDown") {
      const selectedImage = Utils.isEmptyObject(config.image.selected) ? "none" : config.image.selected;
      this.backgroundImage.pressed = selectedImage;
      RenderingHelper.triggerRenderingViaPath(path, runtime);
    }

    return false;
  }

  onMouseUp = (event) => {
    const { config, runtime, path } = this.props;

    if (config.toggleType === "upDown") {
      this.backgroundImage.pressed = "";
      RenderingHelper.triggerRenderingViaPath(path, runtime);
    }

    return false;
  }

  onMouseEnter = (event) => {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const isDisabled = StateAttributeAccess.extractDisabled(pathState);

    if (config.image.hover && !isDisabled) {
      this.backgroundImage.hover = config.image.hover;
      RenderingHelper.triggerRenderingViaPath(path, runtime);
    }

    return false;
  }

  onMouseLeave = (event) => {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const isDisabled = StateAttributeAccess.extractDisabled(pathState);

    if ((config.image.hover && !isDisabled) || this.backgroundImage.pressed) {
      this.backgroundImage.hover = "";
      // if user leaves button frame with mouse clicked, then reset pressed state.
      this.backgroundImage.pressed = "";
      RenderingHelper.triggerRenderingViaPath(path, runtime);
    }
    return false;
  }

  getBackgroundImage = () => {
    const { runtime } = this.props;

    if (this.backgroundImage.pressed === "none") {
      return "none";
    }

    if (this.backgroundImage.pressed) {
      return `url(${CommonConfigHelper.getProperResourcePath(this.backgroundImage.pressed, runtime)})`;
    }

    if (this.backgroundImage.hover) {
      return `url(${CommonConfigHelper.getProperResourcePath(this.backgroundImage.hover, runtime)})`;
    }

    return "";
  }

  onClickHandler = (event) => {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);

    CommonActionsHelper.stopEventPropagation(event);
    if (!CbaButton.isClipboardManagerAction(config.selectionHandlerType)) {
      CommonActionsHelper.signalFocusChangeToClipboardManager(path, runtime);
    }

    const traceDetails = CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState);
    if (config.subtype !== undefined) {
      traceDetails.subtype = config.subtype;
    }

    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      traceDetails,
      event, runtime);


    // set selected and visited state:
    this.updateStateAndTriggerRendering(pathState);

    // run configured click event handler:
    const { selectionHandlerParam, attachedScale, increment } = config;
    const { path: indexPath } = this.props;
    const triggeringType = 'button';
    const buttonBoundingRect = MenuItemTree.buildTriggerBoundingRect(event);

    switch (config.selectionHandlerType) {
      case 'none':
        SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);
        break;
      case 'switchPage':
        runtime.taskManager.switchPage(
          selectionHandlerParam.page,
          undefined, undefined,
          selectionHandlerParam.pageAreaType,
          selectionHandlerParam.pageAreaName,
          undefined, undefined, undefined, undefined
        );
        break;
      case 'switchTask':
        runtime.taskManager.switchTaskIntraItem(selectionHandlerParam.task);
        break;
      case 'switchTest':
        runtime.taskManager.switchTaskInterTest(
          selectionHandlerParam.test,
          selectionHandlerParam.item,
          selectionHandlerParam.task,
        );
        break;
      case 'logTaskResult':
        console.log("=== current task result: ===", runtime.taskManager.getCurrentTaskResults());
        break;
      case 'recommend':
        runtime.recommendationsManager.setRecommendations([{
          testName: selectionHandlerParam.test,
          taskName: selectionHandlerParam.task,
        }]);
        break;
      case 'cut':
        SpecialClickActionsHelper.cut(triggeringType, indexPath, runtime);
        break;
      case 'copy':
        SpecialClickActionsHelper.copy(triggeringType, indexPath, runtime);
        break;
      case 'paste':
        SpecialClickActionsHelper.paste(triggeringType, indexPath, runtime);
        break;
      case 'next_task':
        SpecialClickActionsHelper.nextTask(runtime);
        break;
      case 'back_task':
        SpecialClickActionsHelper.backTask(runtime);
        break;
      case 'cancel_task':
        SpecialClickActionsHelper.cancelTask(runtime);
        break;
      case 'startFullscreen':
        SpecialClickActionsHelper.startFullscreen(runtime);
        break;
      case 'stopFullscreen':
        SpecialClickActionsHelper.stopFullscreen(runtime);
        break;
      case 'close':
        SpecialClickActionsHelper.close(indexPath, runtime);
        break;
      case 'menu':
        event.clientX = buttonBoundingRect.left;
        event.clientY = buttonBoundingRect.bottom;
        runtime.contextMenu.openMenuItemTreeWithDynamicConfig(
          MenuBuildHelper.buildMenuTreeItemConfiguration(
            selectionHandlerParam.contextMenu,
            event,
            indexPath,
            StateAttributeAccess.extractDefaultLinkReceiver(pathState),
            runtime
          ),
          runtime
        );
        break;
      case 'back':
        SpecialClickActionsHelper.back(indexPath, {}, runtime);
        break;
      case 'forward':
        SpecialClickActionsHelper.forward(indexPath, {}, runtime);
        break;
      case 'home':
        SpecialClickActionsHelper.home(indexPath, {}, runtime);
        break;
      case 'addBookmark':
        SpecialClickActionsHelper.addBookmark('button', indexPath, {}, runtime);
        break;
      case 'manageBookmarks':
        SpecialClickActionsHelper.manageBookmark('button', indexPath, selectionHandlerParam, runtime);
        break;
      case 'search':
        SpecialClickActionsHelper.search(
          selectionHandlerParam.indexPath,
          selectionHandlerParam.successEvent,
          selectionHandlerParam.failureEvent,
          runtime
        );
        break;
      case 'debugOperator':
        TermEvaluator.evaluateTerm(selectionHandlerParam, runtime, [], 'callViaDebugButton');
        break;
      default:
        if (config.selectionHandlerType !== undefined) {
          console.error(`Unknown selection handler type in button: ${config.selectionHandlerType}`);
        }
    }

    if (attachedScale !== undefined) {
      SpecialClickActionsHelper.incrementScale(indexPath, attachedScale, increment, runtime);
    }

    // send statemachine event depending on old toggle state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  static isClipboardManagerAction(selectionHandlerType) {
    return selectionHandlerType === 'cut' || selectionHandlerType === 'copy' || selectionHandlerType === 'paste' || selectionHandlerType === 'menu';
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering = (pathState) => {
    const { runtime, config, path } = this.props;

    const oldSelected = SelectGroupHelper.extractSelectedState(pathState, runtime);

    StateAttributeAccess.setVisited(pathState, true);

    if (config.toggleType !== 'none') {
      // Flip the pressed state: 'yes' <-> 'no'
      SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);
    } else {
      // save state and rerender myself: color may change due to visited flag!
      runtime.componentStateManager.registerStateByPathId(path, pathState);
      RenderingHelper.triggerRenderingViaPath(path, runtime);
    }

  }

  addFlexTextAlignmentToStyles = (styleObject, config) => {
    const alignment = config.font.alignmentHorizontal;
    let flexAlignment = "";

    switch (alignment) {
      case "left": flexAlignment = "flex-start"; break;
      case "center": flexAlignment = "center"; break;
      case "right": flexAlignment = "flex-end"; break;
      default: flexAlignment = "center"; break;
    }

    return Object.assign(styleObject, {
      display: "flex",
      alignItems: "center",
      justifyContent: flexAlignment
    });
  }

  buildStyleForMask = (buttonStyle) => {
    const { width, height, top, left, right } = buttonStyle;
    return {
      position: "absolute",
      width,
      height,
      top,
      left,
      right
    }
  }

  render() {
    const { runtime, config, path, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const pressedState = SelectGroupHelper.extractSelectedState(pathState, runtime);
    const style = this.addFlexTextAlignmentToStyles(CommonConfigHelper.buildStyleByIndexPath(path, config, pressedState, orientation, runtime), config);
    const isDisabled = StateAttributeAccess.extractDisabled(pathState);

    const { toggleType, highlight, position: positonInConfig } = config;

    if (toggleType === "upDown") {
      CommonConfigHelper.setStyleAttribute(style, "borderStyle", pressedState ? "inset" : "outset");
    }

    let className = '';
    switch (highlight) {
      case 'up': className = 'CbaButton-highlight-up';
        break;
      case 'down': className = 'CbaButton-highlight-down';
        break;
      default: className = 'CbaButton';
        break;
    }

    const displayText = RenderingHelper.extractMultilineText(CommonConfigHelper.buildDisplayText(config, pathState, runtime));

    // Disabled state does not support hovered/pressed states.
    // Also, mouseleave events do not fire for disabled buttons.
    // Overriding button states here.
    if (isDisabled) {
      this.backgroundImage.hover = "";
      this.backgroundImage.pressed = "";
    }

    const backgroundImageStateResult = this.getBackgroundImage();
    if (backgroundImageStateResult) {
      CommonConfigHelper.setStyleAttribute(style, "backgroundImage", backgroundImageStateResult);
    }

    return (
      <React.Fragment>
        <button
          className={className}
          onClick={this.onClickHandler}
          onContextMenu={this.onContextMenuHandler}
          title={CommonConfigHelper.buildTitle(config)}
          style={style}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          type="button"
          disabled={isDisabled}
        >
          {CommonConfigHelper.getImageTag(pathState, config, pressedState, runtime)}
          <span style={{
            maxHeight: positonInConfig.height
          }}
          >
            {displayText}
          </span>
        </button>
        {isDisabled && <div className="CbaButton-mask" onContextMenu={e => e.preventDefault()} style={this.buildStyleForMask(style)} />}
      </React.Fragment>
    );
  }

}

CbaButton.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        selectionHandlerParam: PropTypes.object,
        selectionHandlerType: PropTypes.oneOf([
          'none',
          'switchPage', 'switchTask', 'switchTest',
          'logTaskResult', 'recommend',
          'cut', 'copy', 'paste',
          'next_task', 'back_task', 'cancel_task',
          'startFullscreen', 'stopFullscreen',
          'close',
          'menu',
          'back', 'forward', 'home',
          'addBookmark', 'manageBookmarks',
          'search',
          'debugOperator'
        ]).isRequired,
        highlight: PropTypes.oneOf(['none', 'horizontal', 'vertical']).isRequired,
        toggleType: PropTypes.oneOf(['none', 'upDown', 'colorChange']).isRequired,
        subtype: PropTypes.string
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
