import PathTranslationHelper from '../state/PathTranslationHelper';
import UserDefPathHelper from '../state/UserDefPathHelper';

/**
 * Manage the list of bookmarks maintained by a CbaPageArea display component instance.
 */
export default class BookmarkHelper {

  // ------- public API ------------------------------------------------------------------------

  /**
   * Add a page as bookmark to the list of bookmarks maintained by a CbaPageArea.
   * 
   * The method will silently ignore the call if a bookmark with the given page name already exists.
   * 
   * @param {String} path The index path of the CbaPageArea hosting the bookmarks list.
   * @param {String} pageName The name of the page to add to the bookmarks list.
   * @param {String} pageUrl The URL of the page to add to the bookmarks list.
   * @param {String} pageTab The tab of the page to add to the bookmarks list.
   * @param {String} pageImage The image of the page to add to the bookmarks list.
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {String} requestingComponentPath The index path of the component requesting to add the bookmark (for tracing purposes).
   * @param {*} runtime The common runtime context structure.
   */
  static addBookmark(path, pageName, pageUrl, pageTab, pageImage, triggeringType, requestingComponentPath, runtime) {
    const bookmarkState = BookmarkHelper.getBookmarkState(path, runtime);
    const bookmarks = bookmarkState.bookmarkEntries;
    if (bookmarks.find(value => value.pageName === pageName) === undefined) {
      bookmarks.push({
        pageName,
        pageUrl,
        pageTab,
        pageImage
      });
      BookmarkHelper.setBookmarkState(bookmarkState, path, runtime);
      BookmarkHelper.trace(triggeringType, 'add', requestingComponentPath, path, pageName, pageUrl, pageTab, runtime);
    }
  }

  /**
   * Drop a bookmark from the list of bookmarks maintained by a CbaPageArea.
   * 
   * The method will silently ignore the call if no bookmark with the given page name exists.
   * 
   * @param {*} path The index path of the CbaPageArea hosting the bookmarks list.
   * @param {*} pageName The name of the page to drop from the bookmarks list.
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu', 'keyboard'
   * @param {*} requestingComponentPath The index path of the component requesting to drop the bookmark (for tracing purposes).
   * @param {*} runtime The common runtime context structure.
   */
  static dropBookmark(path, pageName, triggeringType, requestingComponentPath, runtime) {
    const bookmarkState = BookmarkHelper.getBookmarkState(path, runtime);
    const { bookmarkEntries } = bookmarkState;
    const indexToDrop = bookmarkEntries.findIndex(entry => entry.pageName === pageName);
    if (indexToDrop !== -1) {
      const pageToDrop = bookmarkEntries[indexToDrop];
      bookmarkEntries.splice(indexToDrop, 1);
      BookmarkHelper.setBookmarkState(bookmarkState, path, runtime);
      BookmarkHelper.trace(triggeringType, 'drop', requestingComponentPath, path, pageToDrop.pageName, pageToDrop.pageUrl, pageToDrop.pageTab, runtime);
    }
  }

  /**
   * Get the list of bookmarks maintained by a CbaPageArea.
   * 
   * @param {*} path The index path of the CbaPageArea hosting the bookmarks list.
   * @param {*} runtime The common runtime context structure.
   * @return {[*]} A copy of the list of bookmarks maintained by CbaPageArea.
   */
  static getBookmarks(path, runtime) {
    const bookmarkState = BookmarkHelper.getBookmarkState(path, runtime);
    return bookmarkState.bookmarkEntries.map(entry => entry);
  }

  // ------- internal stuff ------------------------------------------------------------------------

  static getBookmarkState(path, runtime) {
    const fullState = BookmarkHelper.getFullState(path, runtime);
    if (fullState.bookmarkState === undefined) {
      BookmarkHelper.initializeBookmarkState(fullState);
    }
    return fullState.bookmarkState;
  }

  static initializeBookmarkState(fullState) {
    fullState.bookmarkState = {
      bookmarkEntries: []
    }
  }

  static setBookmarkState(state, path, runtime) {
    const fullState = BookmarkHelper.getFullState(path, runtime);
    fullState.bookmarkState = state;
    BookmarkHelper.setFullState(fullState, path, runtime);
  }

  static getFullState(path, runtime) {
    return runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
  }

  static setFullState(state, path, runtime) {
    return runtime.componentStateManager.registerStateByPathId(path, state);
  }

  /**
   * Create a trace log entry.
   * 
   * @param {String} triggeringType The type of the caller triggering the operation:: 'button', 'contextMenu'
   * @param {String} operationType The operation type to trace: add, drop
   * @param {String} triggeringIndexPath The index path of the triggering display component instance.
   * @param {String} ownerIndexPath The index path of the display component owining the bookmarks.
   * @param {String} pageName The name pf the page of the bookmark.
   * @param {String} pageUrl The URL pf the page of the bookmark.
   * @param {String} tab The browser tab the bookmark operates on.
   * @param {*} runtime The common runtime context structure.
   */
  static trace(triggeringType, operationType, triggeringIndexPath, ownerIndexPath, pageName, pageUrl, tab, runtime) {
    const triggeringUserDefPath = PathTranslationHelper.getUserDefPathForIndexPath(triggeringIndexPath, runtime);
    const ownerUserDefPath = PathTranslationHelper.getUserDefPathForIndexPath(ownerIndexPath, runtime);
    runtime.traceLogBuffer.reportEvent(
      'Bookmark',
      new Date(),
      {
        triggerType: triggeringType,
        triggerIndexPath: triggeringIndexPath,
        triggerUserDefIdPath: triggeringUserDefPath,
        triggerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(triggeringUserDefPath),
        operation: operationType,
        ownerIndexPath,
        ownerUserDefIdPath: ownerUserDefPath,
        ownerUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(ownerUserDefPath),
        pageName,
        pageUrl,
        tab
      }
    );
  }


}
