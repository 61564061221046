export default class PageHistoryHelper {

  static goBack(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.currentCanGoBack(historyState)) {
      const pagesState = PageHistoryHelper.getRelevantPagesState(historyState);
      pagesState.current -= 1;
      PageHistoryHelper.setHistoryState(historyState, path, runtime);
    }
  }

  static goForward(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.currentCanGoForward(historyState)) {
      const pagesState = PageHistoryHelper.getRelevantPagesState(historyState);
      pagesState.current += 1;
      PageHistoryHelper.setHistoryState(historyState, path, runtime);
    }
  }

  static canGoBack(path, runtime) {
    return PageHistoryHelper.currentCanGoBack(PageHistoryHelper.getHistoryState(path, runtime));
  }

  static canGoForward(path, runtime) {
    return PageHistoryHelper.currentCanGoForward(PageHistoryHelper.getHistoryState(path, runtime));
  }

  static currentCanGoBack(historyState) {
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState) || PageHistoryHelper.isHistoryModeNoTabs(historyState)) {
      const pagesState = PageHistoryHelper.getRelevantPagesState(historyState);
      return pagesState !== undefined && pagesState.current !== undefined && PageHistoryHelper.getRelevantPagesState(historyState).current > 0;
    } else {
      return false;
    }
  }

  static currentCanGoForward(historyState) {
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState) || PageHistoryHelper.isHistoryModeNoTabs(historyState)) {
      const pagesState = PageHistoryHelper.getRelevantPagesState(historyState);
      return pagesState !== undefined && pagesState.current !== undefined && pagesState.current < pagesState.pages.length -1;
    } else {
      return false;
    }
  }

  static goHome(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState) || PageHistoryHelper.isHistoryModeNoTabs(historyState)) {
      const pagesState = PageHistoryHelper.getRelevantPagesState(historyState);
      if (pagesState !== undefined && pagesState.current !== undefined) {
        pagesState.current = 0;
        PageHistoryHelper.setHistoryState(historyState, path, runtime);
      }
    }
  }

  static getPage(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState) || PageHistoryHelper.isHistoryModeNoTabs(historyState)) {
      return PageHistoryHelper.getCurrentPageFromPagesState(PageHistoryHelper.getRelevantPagesState(historyState));
    } else if (PageHistoryHelper.isHistoryModeSinglePage(historyState)) {
      return historyState.history.page;
    } else {
      return undefined;
    }
  }

  static getCurrentPageFromPagesState(pagesState) {
    return (pagesState === undefined || pagesState.current === undefined) ? undefined : pagesState.pages[pagesState.current];
  }

  static getRelevantPagesState(historyState) {
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState)) {
      const tabName = historyState.history.currentTab;
      if (tabName === undefined) {
        return undefined;
      } else {
        return historyState.history.withTabs[tabName];
      }
    } else if (PageHistoryHelper.isHistoryModeNoTabs(historyState)) {
      return historyState.history.noTabs;
    } else {
      return undefined;
    }
  }

  static getTab(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    return PageHistoryHelper.isHistoryModeWithTabs(historyState) ? historyState.history.currentTab : undefined;
  }

  static getAllTabs(path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    return PageHistoryHelper.isHistoryModeWithTabs(historyState) ? historyState.history.allTabs : [];
  }

  static getImageForTab(tabName, path, runtime) {
    const pageInfo = PageHistoryHelper.getCurrentPageInfoForTab(tabName, path, runtime);
    return pageInfo === undefined ? undefined : pageInfo.image;
  }

  static switchTab(tabName, path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState)) {
      if (historyState.history.withTabs[tabName] !== undefined) {
        historyState.history.currentTab = tabName;
        PageHistoryHelper.setHistoryState(historyState, path, runtime);
      }
    }
  }

  static closeTab(tabName, path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (PageHistoryHelper.isHistoryModeWithTabs(historyState)) {
      if (historyState.history.withTabs[tabName] !== undefined) {
        const currentIndex = historyState.history.allTabs.indexOf(tabName);
        if (currentIndex !== 0) {
          const currentTab = PageHistoryHelper.getTab(path, runtime);
          if (currentTab === tabName) {
            historyState.history.currentTab = historyState.history.allTabs[currentIndex - 1];
          }

          historyState.history.allTabs.splice(currentIndex, 1);
          delete historyState.history.withTabs[tabName];
          PageHistoryHelper.setHistoryState(historyState, path, runtime);
        } else {
          console.log("Closing first tab not possible");
        }
      }
    }
  }

  static addPage(pageName, pageUrl, pageImage, tabName, path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);
    if (historyState === undefined) {
      console.error(`History state missing when trying to add page ${pageName} to state of ${path}`);
    }
    switch (historyState.historyMode) {
      case PageHistoryHelper.Modes.withTabsMode:
        PageHistoryHelper.addPageToWithTabsHistory(pageName, pageUrl, pageImage, tabName, historyState);
        break;
      case PageHistoryHelper.Modes.noTabsMode:
        PageHistoryHelper.addPageToNoTabsHistory(pageName, pageUrl, pageImage, historyState);
        break;
      case PageHistoryHelper.Modes.singlePageMode:
        historyState.history.page = {
          name: pageName,
          url: pageUrl,
          image: pageImage
        };
        break;
      default:
        console.error(`Invalid mode type ${historyState.historyMode}`);
        break;
    }

    PageHistoryHelper.setHistoryState(historyState, path, runtime);
  }

  static addPageToWithTabsHistory(pageName, pageUrl, pageImage, tabName, historyState) {
    const { history } = historyState;
    history.currentTab = tabName;
    if (history.withTabs === undefined) {
      history.withTabs = {};
    }
    if (history.allTabs === undefined) {
      history.allTabs = [];
    }
    const { withTabs } = history;
    if (withTabs[tabName] === undefined) {
      withTabs[tabName] = PageHistoryHelper.createPagesState(pageName, pageUrl, pageImage);
      history.allTabs.push(tabName);
    } else {
      PageHistoryHelper.addPageToPagesState(pageName, pageUrl, pageImage, withTabs[tabName]);
    }
  }

  static addPageToNoTabsHistory(pageName, pageUrl, pageImage, historyState) {
    if (historyState.history.noTabs === undefined) {
      historyState.history.noTabs = PageHistoryHelper.createPagesState(pageName, pageUrl, pageImage);
    } else {
      PageHistoryHelper.addPageToPagesState(pageName, pageUrl, pageImage, historyState.history.noTabs);
    }
  }

  static createPagesState(pageName, pageUrl, pageImage) {
    return {
      current: 0,
      pages: [{
        name: pageName,
        url: pageUrl,
        image: pageImage
      }]
    }
  }

  static addPageToPagesState(pageName, pageUrl, pageImage, pagesState) {

    // do not change our history if the currently selected page is to be added once more
    const currentPage = PageHistoryHelper.getCurrentPageFromPagesState(pagesState);
    if (currentPage !== undefined
      && currentPage.name === pageName
      && currentPage.url === pageUrl
      && currentPage.image === pageImage) {
      return;
    }

    if (pagesState.current === undefined) {
      pagesState.current = -1;
    }
    if (pagesState.pages === undefined) {
      pagesState.pages = [];
    }

    pagesState.current += 1;
    pagesState.pages[pagesState.current] = {
      name: pageName,
      url: pageUrl,
      image: pageImage
    };

    let pageIndex;
    for (pageIndex = pagesState.pages.length - 1; pageIndex > pagesState.current; pageIndex -= 1) {
      pagesState.pages.pop();
    }
  }

  static getInfoForTab(tabName, path, runtime) {
    const historyState = PageHistoryHelper.getHistoryState(path, runtime);

    if (!PageHistoryHelper.isHistoryModeWithTabs(historyState)) {
      return undefined;
    }

    return historyState.history.withTabs[tabName];
  }

  static getCurrentPageInfoForTab(tabName, path, runtime) {
    const tabInfo = PageHistoryHelper.getInfoForTab(tabName, path, runtime);
    return tabInfo === undefined ? undefined : tabInfo.pages[tabInfo.current];
  }

  static isHistoryModeSinglePage(historyState) {
    return historyState !== undefined && historyState.historyMode === PageHistoryHelper.Modes.singlePageMode;
  }

  static isHistoryModeNoTabs(historyState) {
    return historyState !== undefined && historyState.historyMode === PageHistoryHelper.Modes.noTabsMode;
  }

  static isHistoryModeWithTabs(historyState) {
    return historyState !== undefined && historyState.historyMode === PageHistoryHelper.Modes.withTabsMode;
  }

  static hasStateWithoutPageHistory(path, runtime) {
    const fullState = runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
    return fullState !== undefined && fullState.pageHistory === undefined;
  }

  static getHistoryState(path, runtime) {
    const fullState = PageHistoryHelper.getFullState(path, runtime);
    return fullState.pageHistory
  }

  static initializeHistoryState(withHistory, withTabs, fullState) {
    const historyMode = PageHistoryHelper.getHistoryModeKey(withHistory, withTabs);
    const pageHistory = {
      historyMode,
    }

    switch (historyMode) {
      case PageHistoryHelper.Modes.withTabsMode:
        pageHistory.history = {
          currentTab: undefined,
          allTabs: [],
          withTabs: {}
        };
        break;
      case PageHistoryHelper.Modes.noTabsMode:
        pageHistory.history = {
          noTabs: {}
        };
        break;
      case PageHistoryHelper.Modes.singlePageMode:
        pageHistory.history = {
          page: undefined,
        };
        break;
      default:
        console.error(`Invalid mode type ${historyMode}`);
    }

    fullState.pageHistory = pageHistory;
  }

  static getHistoryModeKey(withHistory, withTabs) {
    if (withHistory) {
      return withTabs ? PageHistoryHelper.Modes.withTabsMode : PageHistoryHelper.Modes.noTabsMode;
    }
    return PageHistoryHelper.Modes.singlePageMode;
  }

  static Modes = {
    withTabsMode: 'withTabs',
    noTabsMode: 'noTabs',
    singlePageMode: 'singlePage',
  }

  static setHistoryState(state, path, runtime) {
    const fullState = PageHistoryHelper.getFullState(path, runtime);
    fullState.pageHistory = state;
    PageHistoryHelper.setFullState(fullState, path, runtime);
  }


  static getFullState(path, runtime) {
    return runtime.componentStateManager.findOrBuildStateForPathId(path, runtime);
  }

  static setFullState(state, path, runtime) {
    return runtime.componentStateManager.registerStateByPathId(path, state);
  }

}
