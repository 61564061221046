import Evaluator from './Evaluator';

export default class KeyboardHelper {

  // TODO: there might be a problem with bindings - if the calculator does not contain ln for example  - user can still press l to get it
  // (same problem exists in RAP -  but there only numbers and simple operators are bind - so having a calculator without those is improbable to have)
  // 1. find a way itembuilder calculates what it supports (it is not so trivial) OR
  // 2. implement keybindings for buttons
  // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
  static fromKeyStrokeToCalculatorSupportedOperation = (pressedKey) => {
    // it can be that some operations are not in the keyboards -  (e.g. mr -> is not supported without a memory index)
    const keyboardShortcutsWithOperations = '+|+|=|=|,|.|-|–|/|÷|*|×|#|+/–|x|x!|E|EE|e|ex| |2nd|r|x√y|R|2√|^|yx|\'|yx|"|yx|m|mr|v|mc|b|m+|n|m-|'
      + 's|sin|c|cos|t|tan|S|sin-1|C|cos-1|T|tan-1|l|ln|L|log|\\|1/x|X|2x|'
      + 'Enter|=|Backspace|back|0|0|1|1|2|2|3|3|4|4|5|5|6|6|7|7|8|8|9|9|0|0';

    // maybe to added by using maybe a alt, ctrl or shift
    // p|π|d|Degree|°|Degree
    // keyMatchHold = ('sin|sinh|cos|cosh|tan|tanh|m-|Rand|Deg|Rand|sin-1|sinh-1|cos-1|cosh-1|tan-1|tanh-1|' +
    // '1|1/x|2|x2|3|x3|x√y|√|ln|log2|ex|2x').split('|');


    let calculatorOperation = KeyboardHelper.isSupportedOperation(pressedKey) ? pressedKey : undefined;
    if (!calculatorOperation) {
      calculatorOperation = KeyboardHelper.lookupForOperation(pressedKey, keyboardShortcutsWithOperations);
    }
    return calculatorOperation;
  }

  static fromStatemachineToCalculatorSupportedOperation = (operation) => {
    const statemachineOperations = 'clear|C|clearall|AC|equals|=|add|+|subtract|–|multiply|×|divide|÷|power|operandyx|'
      + 'npower|yx|root|operandx√y|nroot|x√y|sin|sin|cos|cos|tan|tan|csc|sin-1|sec|cos-1|cot|tan-1|'
      + 'e|ex|exp|operandex|ln|ln|log|operandlog|invmult|1/x|invpower|log|leftbr|(|rightbr|)|fact|x!|'
      + 'mclear|mc|mread|mr|msave|ms|madd|m+|msubtract|m-';
    const calculatorOperation = KeyboardHelper.lookupForOperation(operation, statemachineOperations);
    return calculatorOperation;
  }

  static isSupportedOperation = calculatorOperation => Evaluator.getSupportedOperations().includes(calculatorOperation);

  static isDigitKey = key => '0123456789'.indexOf(key) !== -1;

  // private 

  static lookupForOperation(key, operationsString) {
    let calculatorOperation;
    const operationsArray = operationsString.split('|');
    for (let n = 0, m = operationsArray.length; n < m; n += 2) {
      if (key === operationsArray[n]) {
        calculatorOperation = operationsArray[n + 1];
        break;
      }
    }
    return KeyboardHelper.isSupportedOperation(calculatorOperation) ? calculatorOperation : undefined;
  }

}
