import React from 'react';
import PropTypes from 'prop-types';
import CommonConfigHelper from "../../config/CommonConfigHelper";

const Audio = (props) => {
  const { src, style } = props;
  return <audio><track kind="captions" controls src={src} style={style} /></audio>;
};

const Image = (props) => {
  const { src, style } = props;
  return <img src={src} style={style} alt={src} />;
};

const Video = (props) => {
  const { src, style } = props;
  return <video><track kind="captions" controls src={src} style={style} /></video>;
};

export const Media = runtime => (
  // Passing arguments to a JavaScript callback function
  (props) => {
    const entity = props.contentState.getEntity(
      props.block.getEntityAt(0)
    );
    const { src, style } = entity.getData();
    const type = entity.getType();

    const relativePathSrc = CommonConfigHelper.getProperResourcePath(src, runtime);

    let media;
    if (type === 'audio') {
      media = <Audio src={relativePathSrc} style={style} />;
    } else if (type === 'image') {
      media = <Image src={relativePathSrc} style={style} />;
    } else if (type === 'video') {
      media = <Video src={relativePathSrc} style={style} />;
    }

    return media;
  });

Audio.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired
}

Video.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired
}

Media.propTypes = {
  doHighlight: PropTypes.func.isRequired,
}

export { Media as default }
