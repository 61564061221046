/**
 * Encapsulate the structure of the 'common' area in the component's registered state.
 * Be carefully to name the getter/setter as you the attribute 
 * e.g. attribute name: myData 
 * getter name: extractMyData
 * setter name: setMyData
*/
export default class StateAttributeAccess {

  // generic methods (for internal use mostly) ------------------------------------
  static getStateAttribute(state, name) {
    if (state === undefined) return undefined;
    return state[name];
  }

  static setStateAttribute(state, name, value) {
    state[name] = value;
  }


  // direct state access methods: --------------------------------------------------

  // deep copy decision for state storing/retrieving operations
  static extractDeepCopy(state) {
    return StateAttributeAccess.getStateAttribute(state, "deepCopy");
  }

  static setDeepCopy(state, deepCopy) {
    StateAttributeAccess.setStateAttribute(state, "deepCopy", deepCopy);
  }

  // volatile marker for state storing/retrieving operations
  static extractVolatile(state) {
    return StateAttributeAccess.getStateAttribute(state, "volatile");
  }

  static setVolatile(state, deepCopy) {
    StateAttributeAccess.setStateAttribute(state, "volatile", deepCopy);
  }

  // component class name (for snapshot transformer method access)
  static extractComponentClassName(state) {
    return StateAttributeAccess.getStateAttribute(state, "componentClassName");
  }

  static setComponentClassName(state, componentClassName) {
    StateAttributeAccess.setStateAttribute(state, "componentClassName", componentClassName);
  }

  // disabled state of any component
  static extractDisabled(state) {
    return StateAttributeAccess.getStateAttribute(state, "disabled");
  }

  static setDisabled(state, disabled) {
    StateAttributeAccess.setStateAttribute(state, "disabled", disabled);
  }

  // hidden state of any component
  static extractHidden(state) {
    return StateAttributeAccess.getStateAttribute(state, "hidden");
  }

  static setHidden(state, hidden) {
    StateAttributeAccess.setStateAttribute(state, "hidden", hidden);
  }

  // visited state of any component
  static extractVisited(state) {
    return StateAttributeAccess.getStateAttribute(state, "visited");
  }

  static setVisited(state, visited) {
    StateAttributeAccess.setStateAttribute(state, "visited", visited);
  }

  // position of any component
  static extractPosition(state) {
    return StateAttributeAccess.getStateAttribute(state, "position");
  }

  static setPosition(state, position) {
    StateAttributeAccess.setStateAttribute(state, "position", position);
  }


  // default link receiver of any component
  static extractDefaultLinkReceiver(state) {
    return StateAttributeAccess.getStateAttribute(state, "defaultLinkReceiver");
  }

  static setDefaultLinkReceiver(state, pageSegments) {
    StateAttributeAccess.setStateAttribute(state, "defaultLinkReceiver", pageSegments);
  }

  // selectable state for table components
  static extractSelectable(state) {
    return StateAttributeAccess.getStateAttribute(state, "selectable");
  }

  static setSelectable(state, selectable) {
    StateAttributeAccess.setStateAttribute(state, "selectable", selectable);
  }

  // select state for components that are not controlled by a select-group controller in single-select mode
  static extractSelected(state) {
    return StateAttributeAccess.getStateAttribute(state, "selected");
  }

  static setSelected(state, selected) {
    StateAttributeAccess.setStateAttribute(state, "selected", selected);
  }

  // membership info for components that are under control of a select-group controller
  static extractSelectGroupMemberInfo(state) {
    return StateAttributeAccess.getStateAttribute(state, "selectGroupMemberInfo");
  }

  static setSelectGroupMemberInfo(state, singleSelectStructure) {
    StateAttributeAccess.setStateAttribute(state, "selectGroupMemberInfo", singleSelectStructure);
  }

  // select-group controller state in a select-group controller
  static extractSelectGroupControllerState(state) {
    return StateAttributeAccess.getStateAttribute(state, "selectGroupControllerState");
  }

  static setSelectGroupControllerState(state, controllerState) {
    StateAttributeAccess.setStateAttribute(state, "selectGroupControllerState", controllerState);
  }

  // text value of any component
  static extractTextValue(state) {
    return StateAttributeAccess.getStateAttribute(state, "textValue");
  }

  static setTextValue(state, value) {
    StateAttributeAccess.setStateAttribute(state, "textValue", value);
  }

  // highlightable state of any component
  static extractHighlightable(state) {
    return StateAttributeAccess.getStateAttribute(state, "highlightable");
  }

  static setHighlightable(state, highlightable) {
    StateAttributeAccess.setStateAttribute(state, "highlightable", highlightable);
  }

  // media player
  static extractMediaVolume(state) {
    return StateAttributeAccess.getStateAttribute(state, "volume");
  }

  static setMediaVolume(state, value) {
    StateAttributeAccess.setStateAttribute(state, "volume", value);
  }

  static extractCurrentTime(state) {
    return StateAttributeAccess.getStateAttribute(state, "currentTime");
  }

  static setCurrentTime(state, value) {
    StateAttributeAccess.setStateAttribute(state, "currentTime", value);
  }

  static extractPlayNumber(state) {
    return StateAttributeAccess.getStateAttribute(state, "playNumber");
  }

  static setPlayNumber(state, value) {
    StateAttributeAccess.setStateAttribute(state, "playNumber", value);
  }

  static extractMaxPlay(state) {
    return StateAttributeAccess.getStateAttribute(state, "maxPlay");
  }

  static setMaxPlay(state, value) {
    StateAttributeAccess.setStateAttribute(state, "maxPlay", value);
  }

  static extractRecordNumber(state) {
    return StateAttributeAccess.getStateAttribute(state, "recordNumber");
  }

  static setRecordNumber(state, value) {
    StateAttributeAccess.setStateAttribute(state, "recordNumber", value);
  }

  static extractMaxRecord(state) {
    return StateAttributeAccess.getStateAttribute(state, "maxRecord");
  }

  static setMaxRecord(state, value) {
    StateAttributeAccess.setStateAttribute(state, "maxRecord", value);
  }

  static extractHideControls(state) {
    return StateAttributeAccess.getStateAttribute(state, "hideControls");
  }

  static setHideControls(state, value) {
    StateAttributeAccess.setStateAttribute(state, "hideControls", value);
  }

  static extractAutomaticStart(state) {
    return StateAttributeAccess.getStateAttribute(state, "automaticStart");
  }

  static setAutomaticStart(state, value) {
    StateAttributeAccess.setStateAttribute(state, "automaticStart", value);
  }

  static extractMediaBlob(state) {
    return StateAttributeAccess.getStateAttribute(state, "mediaBlob");
  }

  static setMediaBlob(state, value) {
    StateAttributeAccess.setStateAttribute(state, "mediaBlob", value);
  }

  // external page frame
  static extractExternalContentState(state) {
    return StateAttributeAccess.getStateAttribute(state, "externalContentState");
  }

  static setExternalContentState(state, value) {
    StateAttributeAccess.setStateAttribute(state, "externalContentState", value);
  }

  // selected cell for the table
  static extractSelectedCell(state) {
    return StateAttributeAccess.getStateAttribute(state, "selectedCell");
  }

  static setSelectedCell(state, value) {
    StateAttributeAccess.setStateAttribute(state, "selectedCell", value);
  }

  static extractOldSelectedCell(state) {
    return StateAttributeAccess.getStateAttribute(state, "oldSelectedCell");
  }

  static setOldSelectedCell(state, value) {
    StateAttributeAccess.setStateAttribute(state, "oldSelectedCell", value);
  }

  static extractTableData(state) {
    return StateAttributeAccess.getStateAttribute(state, "tableData");
  }

  static setTableData(state, value) {
    StateAttributeAccess.setStateAttribute(state, "tableData", value);
  }

  // table cell value, hadFocus,
  static extractFormula(state) {
    return StateAttributeAccess.getStateAttribute(state, "formula");
  }

  static setFormula(state, value) {
    StateAttributeAccess.setStateAttribute(state, "formula", value);
  }

  static extractCellHadFocus(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellHadFocus");
  }

  static setCellHadFocus(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellHadFocus", value);
  }

  static extractCellTitle(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellTitle");
  }

  static setCellTitle(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellTitle", value);
  }

  static extractCellHasError(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellHasError");
  }

  static setCellHasError(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellHasError", value);
  }

  static extractCellType(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellType");
  }

  static setCellType(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellType", value);
  }

  // address and content for the table cell editor 
  static extractCellEditorAddress(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellEditorAddress");
  }

  static setCellEditorAddress(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellEditorAddress", value);
  }

  static extractCellEditorContent(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellEditorContent");
  }

  static setCellEditorContent(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellEditorContent", value);
  }

  static extractCellEditorIsNotValid(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellEditorIsNotValid");
  }

  static setCellEditorIsNotValid(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellEditorIsNotValid", value);
  }

  static extractCellEditorContentIsReadOnly(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellEditorContentIsReadOnly");
  }

  static setCellEditorContentIsReadOnly(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellEditorContentIsReadOnly", value);
  }

  static extractCellEditorErrorMessage(state) {
    return StateAttributeAccess.getStateAttribute(state, "cellEditorErrorMessage");
  }

  static setCellEditorErrorMessage(state, value) {
    StateAttributeAccess.setStateAttribute(state, "cellEditorErrorMessage", value);
  }

  // drag and drop
  static extractDragAndDrop(state) {
    return StateAttributeAccess.getStateAttribute(state, "dragAndDrop");
  }

  static setDragAndDrop(state, value) {
    StateAttributeAccess.setStateAttribute(state, "dragAndDrop", value);
  }

  // stopWatch
  static extractCounterValue(state) {
    return StateAttributeAccess.getStateAttribute(state, "counterValue");
  }

  static setCounterValue(state, value) {
    StateAttributeAccess.setStateAttribute(state, "counterValue", value);
  }


  // Tree
  static extractTreeNodes(state) {
    return StateAttributeAccess.getStateAttribute(state, "treeNodes");
  }

  static setTreeNodes(state, value) {
    StateAttributeAccess.setStateAttribute(state, "treeNodes", value);
  }

  static extractTreeColumns(state) {
    return StateAttributeAccess.getStateAttribute(state, "treeColumns");
  }

  static setTreeColumns(state, value) {
    StateAttributeAccess.setStateAttribute(state, "treeColumns", value);
  }

  static extractTreeState(state) {
    return StateAttributeAccess.getStateAttribute(state, "treeState");
  }

  static setTreeState(state, value) {
    StateAttributeAccess.setStateAttribute(state, "treeState", value);
  }

  static extractNodeTypes(state) {
    return StateAttributeAccess.getStateAttribute(state, "nodeTypes");
  }

  static setNodeTypes(state, value) {
    StateAttributeAccess.setStateAttribute(state, "nodeTypes", value);
  }

  static extractTreeReadOnly(state) {
    return StateAttributeAccess.getStateAttribute(state, "treeReadOnly");
  }

  static setTreeReadOnly(state, value) {
    StateAttributeAccess.setStateAttribute(state, "treeReadOnly", value);
  }

  // Tree View
  static extractTreeViewSort(state) {
    return StateAttributeAccess.getStateAttribute(state, "treeViewSort");
  }

  static setTreeViewSort(state, value) {
    StateAttributeAccess.setStateAttribute(state, "treeViewSort", value);
  }

  static extractSelectedIds(state) {
    return StateAttributeAccess.getStateAttribute(state, "selectedIds");
  }

  static setSelectedIds(state, selectedIds) {
    StateAttributeAccess.setStateAttribute(state, "selectedIds", selectedIds);
  }

  static extractDefaultHighlightColor(state) {
    return StateAttributeAccess.getStateAttribute(state, "defaultHighlightColor");
  }

  static setDefaultHighlightColor(state, defaultHighlightColor) {
    StateAttributeAccess.setStateAttribute(state, "defaultHighlightColor", defaultHighlightColor);
  }

}
