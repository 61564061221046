import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';
import SelectGroupHelper from './SelectGroupHelper';
import RenderingHelper from './RenderingHelper';
import IndexPathHelper from '../state/IndexPathHelper';

export default class CbaPolygon extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
    this.updateStateAndTriggerRendering = this.updateStateAndTriggerRendering.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    const { config, runtime, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const selectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      CommonActionsHelper.buildOldSelectedTraceLogValueObject(selectedState),
      event, runtime);

    const oldSelected = CbaPolygon.getEffectiveSelectedState(path, pathState, runtime);

    // Flip the selected state: 'yes' <-> 'no'
    this.updateStateAndTriggerRendering(pathState, oldSelected);

    CbaPolygon.setSelectedStateForParent(path, runtime);

    SelectGroupHelper.doPageSwitchOrLetTheContainerDoIt(this, pathState);

    // send statemachine event depending on state:
    CommonActionsHelper.sendStandardOrAlternateEvent(selectedState, config, runtime);

  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  updateStateAndTriggerRendering(pathState, oldSelected) {
    const { runtime, path } = this.props;
    StateAttributeAccess.setVisited(pathState, true);

    SelectGroupHelper.setSelectedForPossiblyControlledComponent(!oldSelected, path, pathState, true, runtime);
  }

  /**
   * Private helper method. 
   * 
   * Calculate the 'effective' selected state by combining our selected state and the 
   * selected state of the parent we are part of: 
   * We are 'effectively' selected only if we are selected inside our container and our container (i.e. a CbaRegionMap) is selected also. 
   */
  static getEffectiveSelectedState(path, pathState, runtime) {
    const mySelectedState = SelectGroupHelper.extractSelectedState(pathState, runtime);
    const regionSelectedState = CbaPolygon.getSelectedStateForParent(path, runtime);
    return mySelectedState && regionSelectedState;
  }

  /**
   * Private helper method.
   * 
   * Get the selected state for our parent container.
   */
  static getSelectedStateForParent(path, runtime) {
    const parentPath = IndexPathHelper.dropIndexFromPageSegment(path);
    if (parentPath === undefined) {
      console.error(`Polygon without parent`);
      return true;
    }
    const parentState = runtime.componentStateManager.findOrBuildStateForPathId(parentPath, runtime);
    if (parentState === undefined) {
      console.error(`Polygon without parent state`);
      return true;
    }
    const result = SelectGroupHelper.extractSelectedState(parentState, runtime);
    return result;
  }

  /**
   * Private helper method.
   * 
   * Set the selected state for our parent container.
   */
  static setSelectedStateForParent(path, runtime) {
    const parentPath = IndexPathHelper.dropIndexFromPageSegment(path);
    if (parentPath === undefined) {
      return;
    }
    const parentState = runtime.componentStateManager.findOrBuildStateForPathId(parentPath, runtime);
    if (parentState === undefined) {
      return;
    }
    SelectGroupHelper.setSelectedForPossiblyControlledComponent(true, parentPath, parentState, false, runtime);
  }


  render() {
    const { config, path, runtime, orientation } = this.props;
    const pathState = ComponentStateHelper.getState(this);

    const renderingSelectedState = CbaPolygon.getEffectiveSelectedState(path, pathState, runtime);

    const polygonStyle = CommonConfigHelper.buildStyleSvg(pathState, config, renderingSelectedState, orientation, runtime);
    const pointsString = CommonConfigHelper.buildPointsString(config.bendPoints);
    const title = CommonConfigHelper.buildTitle(config);

    return (
      <polygon points={pointsString} style={polygonStyle} onClick={this.onClickHandler} onContextMenu={this.onContextMenuHandler}>
        {title !== "" && <title>{title}</title>}
      </polygon>
    );

  }


}


CbaPolygon.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupMemberConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        bendPoints: PropTypes.arrayOf(PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        }))
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
