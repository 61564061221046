/**
 * Basic helper methods for string usage.
 */
export default class StringUtils {

  /**
   * This is a helper function that can be used to capitalize the first letter of the received argument. 
   * @param {*} word 
   */
  static toFirstUpper(word) {
    if (typeof word !== 'string') return ''
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

}
