import React from 'react';
import PropTypes from 'prop-types';

/**
 * A react component used by the CbaRichTextField to display bulleted or numbered lists.
 */
class DraftBullet extends React.Component {

  constructor(props) {
    super(props);
    this.style = this.getStyle();
    this.bullet = this.getBullet();
  }

  getStyle = () => {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData().style;
  }

  getBullet = () => {
    const { children } = this.props;
    const rawSymbol = children[0].props.text;
    let bullet = "";

    if (rawSymbol.indexOf("num-") === 0) {
      // ordered list
      [, bullet] = rawSymbol.split("-");
    } else {
      // unordered list
      switch (rawSymbol) {
        case "a": bullet = String.fromCharCode("0x25CF"); break;// black circle
        case "b": bullet = String.fromCharCode("0x25CB"); break;// white circle
        case "c": bullet = String.fromCharCode("0x25A0"); break;// black square
        case "d": bullet = String.fromCharCode("0x25A1"); break;// white square
        default: bullet = String.fromCharCode("0x25CF"); break;// black circle
      }
    }

    return bullet;
  }

  render() {
    return (
      <span suppressContentEditableWarning="true" className="cbaListBullet" style={this.style} contentEditable="false">
        {this.bullet}
      </span>
    );
  }

}

const findBulletEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LIST-BULLET'
      );
    },
    callback
  );
}

DraftBullet.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired
}

export { findBulletEntities, DraftBullet };
