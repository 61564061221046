export default class WebToolbarHelper {

  /**
   * Method continaing logic for top up, returns the next hidden tab to be displayed
   * @param {String[]} allTabs All toolbar tabs
   * @param {String[]} displayTabs All tabs being displayed 
   * @param {Boolean} isInFirstHalf The fact that the tab is in the first half
   * 
   * @returns {String} Tab name
   */
  static getNextTopUpTab(allTabs, displayTabs, isInFirstHalf) {
    const leftVisibleTab = displayTabs[0];
    const leftHiddenIndex = allTabs.indexOf(leftVisibleTab) - 1;
    const leftTab = leftHiddenIndex !== -1 ? allTabs[leftHiddenIndex] : undefined;

    const rightVisibleTab = displayTabs[displayTabs.length - 1];
    const rightHiddenIndex = allTabs.indexOf(rightVisibleTab) + 1;
    const rightTab = rightHiddenIndex === 0 ? undefined : allTabs[rightHiddenIndex];

    const tab = isInFirstHalf
      ? (leftTab || rightTab)
      : (rightTab || leftTab);

    return tab || null;
  }

  /**
   * Method determining if the current tab is in the first part of the spectrum
   * @param {String[]} allTabs All toolbar tabs
   * @param {String} currentTab Current tab of the toolbar
   * 
   * @returns {Boolean} true/false
   */
  static isCurrentInFirstHalf(allTabs, currentTab) {
    const indexOfCurrentTab = allTabs.indexOf(currentTab);
    return indexOfCurrentTab < allTabs.length / 2;
  }

  /**
   * Method implementing the top up operation
   * @param {String[]} displayTabs All tabs being displayed 
   * @param {String[]} hiddenTabs All tabs being hidden 
   * @param {String} newTab The new tab to be topped up
   * @param {Number} tabWidth The new tab width
   * @param {Number} displayTabsWidth Width of display tabs
   * @param {String} operation Operation to be implemented on the array (unshift/push)
   * 
   * @returns {Number} New width of the display tabs
   */
  static topUpTabs(displayTabs, hiddenTabs, newTab, tabWidth, displayTabsWidth, operation) {
    const newWidth = displayTabsWidth + tabWidth;

    hiddenTabs.splice(hiddenTabs.indexOf(newTab), 1);
    displayTabs[operation](newTab);

    return newWidth;
  }

  /**
   * Method handling overflow of tabs
   * @param {String[]} allTabs All toolbar tabs
   * @param {Object} tabDict The current tab dictionary
   * @param {Number} totalWidth Total width of the rendered toolbar
   * 
   * @returns {Boolean} true/false
   */
  static exceedsMaxTabsWidth(allTabs, tabDict, totalWidth) {
    const totalTabsWidth = allTabs.reduce((accum, reducer) => tabDict[reducer].width + accum, 0);

    return totalTabsWidth > totalWidth;
  }

  /**
   * 
   * @param {String[]} displayTabs All tabs being displayed 
   * @param {String[]} hiddenTabs All tabs being hidden 
   * @param {String[]} allTabs All toolbar tabs
   * @param {String} currentTab Current tab of the toolbar
   * @param {Object} tabDict The current tab dictionary
   * @param {Number} totalWidth Total width of the rendered toolbar
   * 
   * 
   * @returns {Object} Object of the form { displayTabs, hiddenTabs }
   */
  static topUpDisplayTabs(displayTabs, hiddenTabs, allTabs, currentTab, tabDict, totalWidth) {
    let displayTabsWidth = displayTabs.reduce((accum, reducer) => accum + tabDict[reducer].width, 0);
    const isInFirstHalf = WebToolbarHelper.isCurrentInFirstHalf(allTabs, currentTab);

    while (totalWidth > displayTabsWidth && hiddenTabs.length > 0) {
      const nextTab = WebToolbarHelper.getNextTopUpTab(allTabs, displayTabs, isInFirstHalf);
      const tabWidth = nextTab && tabDict[nextTab].width;

      if (nextTab && hiddenTabs.indexOf(nextTab) !== -1 && totalWidth > tabWidth + displayTabsWidth) {
        const operation = allTabs.indexOf(nextTab) < allTabs.indexOf(displayTabs[0]) ? "unshift" : "push";
        displayTabsWidth = WebToolbarHelper.topUpTabs(displayTabs, hiddenTabs, nextTab, tabWidth, displayTabsWidth, operation);
      } else {
        break;
      }
    }

    return {
      displayTabs,
      hiddenTabs
    }
  }

  /**
   * 
   * @param {String[]} allTabs All toolbar tabs
   * @param {String} currentTab Current tab of the toolbar
   * @param {Object} tabDict The current tab dictionary
   * @param {Number} totalTabsMaxWidth Total width of the rendered toolbar
   * 
   * @returns {Object} Object of the form { displayTabs, hiddenTabs }
   */
  static buildDisplayTabs(allTabs, currentTab, tabDict, totalTabsMaxWidth) {
    const displayTabs = [];
    const hiddenTabs = [];
    const scoreArray = [];
    const indexOfCurrentTab = allTabs.indexOf(currentTab);

    // build score array representing the total width accumulated until the end of the tab
    allTabs.reduce((accum, reducer) => {
      const accumulatedWidth = accum + tabDict[reducer].width;
      scoreArray.push(accumulatedWidth);
      return accumulatedWidth;
    }, 0);

    let lowerLimit;
    let upperLimit;
    const currentTabScore = scoreArray[indexOfCurrentTab];

    if (WebToolbarHelper.isCurrentInFirstHalf(allTabs, currentTab)) {
      lowerLimit = currentTabScore - tabDict[currentTab].width;
      upperLimit = lowerLimit + totalTabsMaxWidth;
    } else {
      upperLimit = currentTabScore;
      lowerLimit = currentTabScore - totalTabsMaxWidth;
    }

    scoreArray.forEach((tabScore, index) => {
      const tabWidth = tabDict[allTabs[index]].width;
      if (tabScore - tabWidth < lowerLimit || tabScore > upperLimit) {
        hiddenTabs.push(allTabs[index]);
      } else {
        displayTabs.push(allTabs[index]);
      }
    });

    return {
      hiddenTabs,
      displayTabs
    };
  }

}
