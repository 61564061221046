import axios from 'axios';
import StateManagerHelper from "./StateManagerHelper";

export default class RecordingBuffer {

  constructor() {
    this.recordingList = [];
    this.newEntryListener = undefined;
  }

  // public API for components ------------------------------------------------------------------

  /**
   * Report recording.
   * 
   * The method expects these parameters:
   * - userDefId: A String representing the userDefinedId of the media component.
   * - timestamp: A Date representing the timestamp for the recording ending.
   * - recordingNumber: A Number representing the number of the created recording.
   * - blobUrl: The recording blob URL.
   * 
   * The method will retrieve the blob content as base64 data and store it internally.
   * 
   */
  reportRecording = (userDefId, timestamp, recordingNumber, blobUrl, taskInfo, userDefinedIDPath) => new Promise((resolve, reject) => {
    axios.get(blobUrl, {
      responseType: 'blob'
    }).then((response) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const data = reader.result;
        const { test, item, task } = taskInfo;
        const recordingInfo = {
          sourceComponent: userDefId,
          userDefinedIDPath,
          nbOfRecordingOnComponentInstance: recordingNumber,
          recordingStartTime: timestamp,
          test,
          item,
          task
        }
        const newEntry = {
          recordingInfo,
          data
        }
        this.recordingList.push(newEntry);
        if (this.newEntryListener !== undefined) {
          this.newEntryListener();
        }
        resolve();
      }
    })
  })


  // public API for recording consumers --------------------------------------------------------------------

  /**
   * Get the next bunch of recording entries for delivery to the server and drop them from our queue.
   * 
   * Note: This method must be called from the GUI thread to avoid multithreading issues!
   * 
   * The method returns a list of recording entry objects. I returns an empty list of no recording entries are pending.
   */
  popEntries = () => {
    const deliveredEntries = this.recordingList;
    this.recordingList = [];
    return deliveredEntries;
  }


  /**
   * Get the list of current recording entries (without dropping them).
   * 
   * The method returns a list of recording entry objects. It returns an empty list of no recording entries are pending.
   */
  peekEntries = () => StateManagerHelper.deepCopy(this.recordingList);

  /**
   * Set a function to call each time we receive a new entry.
   * 
   * We support a single entry listener only. The entry listener is called after the new recording entry is put into our buffer.
   * 
   * @param callback The function to call each time a new entry is put into our buffer.
   */
  setNewEntryListener = (callback) => {
    this.newEntryListener = callback;
  }

}
