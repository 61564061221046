import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import RenderingHelper from '../RenderingHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import CbaSingleLineInputField from '../CbaSingleLineInputField';
import CbaComboBox from '../CbaComboBox';
import CommonActionsHelper from '../CommonActionsHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import IndexPathHelper from '../../state/IndexPathHelper';
import CbaRichTextField from '../CbaRichTextField/CbaRichTextField';
import TableHelper from './TableHelper';
import PathTranslationHelper from '../../state/PathTranslationHelper';
import UserDefPathHelper from '../../state/UserDefPathHelper';
import StateManagerHelper from '../../state/StateManagerHelper';

export default class CbaTableCell extends Component {

  constructor(props) {
    super(props);

    const { runtime, config } = this.props;
    this.eventEmitter = runtime.eventEmitter;
    this.state = {
      isInEditMode: config.isInEditMode,
      hasHover: false
    }
    this.onCellFocused = this.onCellFocused.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.buildTableIndexPath = this.buildTableIndexPath.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { config } = this.props;
    if (config.isSpreadsheet) {
      this.initializeSpreadsheet();
    }

    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-removeLastSelection-[${config.row},${config.column}]`, this.onRemoveLastSelection.bind(this));
  }

  initializeSpreadsheet = () => {
    const { config, path, runtime } = this.props;
    const { row, column, isReadOnly } = config;
    this.registerEventListeners(row, column);
    const pathState = ComponentStateHelper.getState(this);
    const hadFocus = StateAttributeAccess.extractCellHadFocus(pathState);
    // the default selected cell will be cell[1,1] if there was no focus registered for the page
    const pagePath = IndexPathHelper.getPagePath(path);
    if (row === 1 && column === 1 && !hadFocus
      && !runtime.focusRegister.hasActionsRegisteredForPath(pagePath)) {
      window.setTimeout(() => {
        this.onCellFocused(row, column, isReadOnly);
      }, 100);
      StateAttributeAccess.setCellHadFocus(pathState, true);
      ComponentStateHelper.registerState(this, pathState);
      this.updateStateAndTriggerRender(true);
    }
  }

  registerEventListeners(row) {
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-rowHeaderUpdate`, this.onRowHeaderUpdate.bind(this));
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-columnHeaderUpdate`, this.onColumnHeaderUpdate.bind(this));
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-cell-mouse-enter-${row}`, this.onCellMouseEnter.bind(this));
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-cell-mouse-leave-${row}`, this.onCellMouseLeave.bind(this));
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-updateCellValue`, this.onUpdateValue.bind(this));
    this.eventEmitter.addListener(`${this.buildTableIndexPath()}-autoFocus`, this.onAutoFocus.bind(this));
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  /**
   * Callback method registered as a listener for cell update events. 
   * Update the cell state accordingly and notifies the edit mode component about the current updates.
   * @param {*} selectedCell 
   * @param {*} value 
   * @param {*} isNotValidFormula 
   * @param {*} formula 
   * @param {*} errorMessage 
   * @param {*} isInitialization 
   */
  onUpdateValue(selectedCell, value, isNotValidFormula, formula, errorMessage, isInitialization) {
    const { config } = this.props;
    if (selectedCell.row === config.row && selectedCell.column === config.column) {
      this.eventEmitter.emit(`${this.buildTableIndexPath()}-inputContentUpdate`, value, selectedCell.row, selectedCell.column);
      this.updateStateAndTriggerRender(true);

      const pathState = ComponentStateHelper.getState(this);
      const oldValue = StateAttributeAccess.extractTextValue(pathState);
      const oldFormula = StateAttributeAccess.extractFormula(pathState);

      // update our full state in state manager:
      if (isNotValidFormula !== undefined) {
        StateAttributeAccess.setCellHasError(pathState, isNotValidFormula);
      }
      if (errorMessage !== undefined) {
        StateAttributeAccess.setCellTitle(pathState, errorMessage);
      }
      StateAttributeAccess.setTextValue(pathState, value);
      StateAttributeAccess.setFormula(pathState, formula);
      StateAttributeAccess.setVisited(pathState, true);
      ComponentStateHelper.registerState(this, pathState);


      // handle tracing 
      if (!isInitialization && oldValue !== undefined && oldValue !== value) {
        this.traceCellModified(undefined, oldValue, value, oldFormula, formula, errorMessage);
      }

      this.setState({
        isInEditMode: false
      });
    }
  }

  onClickHandler = (event, keepHover) => {
    const { config, runtime, path } = this.props;
    const { row, column, isSpreadsheet, readOnly } = config;
    const pathState = ComponentStateHelper.getState(this);
    const oldSelected = StateAttributeAccess.extractSelected(pathState);

    if (isSpreadsheet) {
      if (row !== 0 && column !== 0) {
        this.onCellFocused(row, column, readOnly);
        this.updateStateAndTriggerRender(true);
      } else if (row === 0) {
        // second row same column should be focused
        this.eventEmitter.emit(`${this.buildTableIndexPath()}-autoFocus`, row + 1, column);
        // avoid container tracing 
        CommonActionsHelper.stopEventPropagation(event);
        return;
      } else if (column === 0) {
        // same row second column should be focused
        this.eventEmitter.emit(`${this.buildTableIndexPath()}-autoFocus`, row, column + 1);
        // avoid container tracing 
        CommonActionsHelper.stopEventPropagation(event);
        return;
      }
    } else {
      // ignore click if our parent table is switched to 'not selectable' currently:
      const tableState = runtime.componentStateManager.findOrBuildStateForPathId(this.buildTableIndexPath(), runtime);
      if (StateAttributeAccess.extractSelectable(tableState)) {
        // when multi select is disabled we need to clean the last selection 
        if (!config.isMultiSelectEnabled) {
          this.onCellFocused(row, column, readOnly);
        }
        this.updateStateAndTriggerRender();
      }
    }

    // handle tracing
    const tablePath = this.buildTableIndexPath();
    const tableUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(tablePath, runtime);
    const traceDetails = {
      tableUserDefIdPath,
      tableUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(tableUserDefIdPath),
      row,
      column,
      oldSelected
    }
    CommonActionsHelper.traceUserInteractionPerConfig(config, path,
      traceDetails,
      event, runtime);
    // avoid container tracing 
    CommonActionsHelper.stopEventPropagation(event);

    // when a cell is clicked the row hover should disapeer 
    if (row !== 0 && !keepHover) {
      this.eventEmitter.emit(`${this.buildTableIndexPath()}-cell-mouse-leave-${row}`, row);
    }
  }

  checkSelectable = () => {
    const { config, runtime } = this.props;

    if (config.isSpreadsheet) {
      return true;
    }

    const tableState = runtime.componentStateManager.findOrBuildStateForPathId(this.buildTableIndexPath(), runtime);
    return StateAttributeAccess.extractSelectable(tableState);
  }

  updateStateAndTriggerRender = (keepSelection, selectionValue) => {
    const pathState = ComponentStateHelper.getState(this);
    const { runtime, path } = this.props;

    // update our full state in state manager:
    if (!keepSelection) {
      const oldSelected = StateAttributeAccess.extractSelected(pathState);
      StateAttributeAccess.setSelected(pathState, selectionValue !== undefined ? selectionValue : !oldSelected);
    }

    StateAttributeAccess.setVisited(pathState, true);
    ComponentStateHelper.registerState(this, pathState);
    RenderingHelper.triggerRenderingViaPath(path, runtime);
  }

  onAutoFocus(rowToFocus, columnToFocus) {
    const { config } = this.props;
    const { row, column } = config;

    if (row === rowToFocus && column === columnToFocus) {
      this.onClickHandler(undefined, true);
    }
  }

  onCellFocused(row, column, isReadOnly) {
    const { config } = this.props;
    this.eventEmitter.emit(`${this.buildTableIndexPath()}-cellFocused`, row, column, config.address, isReadOnly);
    this.setState({
      isInEditMode: true
    });
  }

  onRowHeaderUpdate(currentSelected, newSelected) {
    const { config } = this.props;
    if ((config.row === currentSelected || config.row === newSelected)
      && (currentSelected !== newSelected) && (config.column === 0)) {
      this.updateStateAndTriggerRender();
    }
  }

  onColumnHeaderUpdate(currentSelected, newSelected) {
    const { config } = this.props;
    if ((config.column === currentSelected || config.column === newSelected)
      && (currentSelected !== newSelected) && (config.row === 0)) {
      this.updateStateAndTriggerRender();
    }
  }

  onRemoveLastSelection() {
    this.updateStateAndTriggerRender(false, false);
  }

  onMouseEnter() {
    const { config } = this.props;
    const { row } = config;
    if (row !== 0) {
      this.eventEmitter.emit(`${this.buildTableIndexPath()}-cell-mouse-enter-${row}`, row);
    }
  }

  onCellMouseEnter(row) {
    const { config } = this.props;
    if (config.row === row && config.column !== 0) {
      this.setState({
        hasHover: true
      });
    }
  }

  onMouseLeave() {
    const { config } = this.props;
    const { row } = config;
    if (row !== 0) {
      this.eventEmitter.emit(`${this.buildTableIndexPath()}-cell-mouse-leave-${row}`, row);
    }
  }

  onCellMouseLeave(row) {
    const { config } = this.props;
    if (config.row === row && config.column !== 0) {
      this.setState({
        hasHover: false
      });
    }
  }

  /**
   * Callback method sent as prop to edit mode components.
   * Will forward the changed data to the table component and exit the edit mode(rerender).
   */
  onSelectionChange = (e, textValue, isReadOnly) => {
    if (textValue !== undefined) {
      const pathState = ComponentStateHelper.getState(this);
      const oldValue = StateAttributeAccess.extractTextValue(pathState);
      const { config } = this.props;
      if (textValue !== oldValue) {
        const { row, column } = config;
        this.eventEmitter.emit(`${this.buildTableIndexPath()}-contentUpdate`, textValue, row, column, isReadOnly);
      }
    }

    this.updateStateAndTriggerRender(false, true);
    this.setState({
      isInEditMode: false
    });
  }

  buildLabel = (config, pathState) => {
    let label = StateAttributeAccess.extractTextValue(pathState);
    const { text } = config;

    label = (label === undefined ? text.label : label);

    if (TableHelper.isInt(label)) {
      label = Number.parseInt(label, 10);
    }

    if (TableHelper.isFloat(label)) {
      if (config.numberOfDecimalPlaces !== -1) {
        label = Number.parseFloat(label).toFixed(config.numberOfDecimalPlaces);
      } else if (config.defaultNumberOfDecimalPlaces !== 0) {
        label = Number.parseFloat(label).toFixed(config.defaultNumberOfDecimalPlaces);
      }
    }

    return label;
  }

  buildTitle = (config, pathState) => {
    const hasError = StateAttributeAccess.extractCellHasError(pathState);
    let title;
    if (hasError) {
      const errorMessage = StateAttributeAccess.extractCellTitle(pathState);
      title = errorMessage !== undefined ? errorMessage : '';
    } else if (config.hasStandardTooltip) {
      title = CommonConfigHelper.buildTitle(config);
      if (title === undefined || title === '') {
        title = config.address;
      }
    }
    return title;
  }

  /**
   * Helper method used to decide which kind of React component will be rendered as a table cell.
   * In standard mode this will usually return a CbaRichTextField or a <div>. 
   * In spreadsheed mode the cell in read mode will be represented by a <div> 
   * and in edit mode they'll become a CbaSingleLineInputField or a CbaCombobox.
   */
  buildTableCell = (config, path, runtime, orientation, style) => {
    const { isSpreadsheet } = config;
    const { isInEditMode } = this.state;
    const pathState = ComponentStateHelper.getState(this);
    const imageStyle = {
      maxHeight: "100%",
      maxWidth: "100%"
    }
    let tableCell;
    if (isSpreadsheet) {
      tableCell = (
        <div
          style={style}
          onClick={this.onClickHandler}
          onDoubleClick={this.onDoubleClickCaptureHandler}
          title={this.buildTitle(config, pathState)}
          onMouseEnter={() => this.onMouseEnter()}
          onMouseLeave={() => this.onMouseLeave()}
          onKeyDown={this.onKeyDownHandler}
        >
          {config.imageReference
          && <img src={CommonConfigHelper.getProperResourcePath(config.imageReference, runtime)} style={imageStyle} alt="" /> }
          {this.buildLabel(config, pathState)}
        </div>
      );

      // in edit we render a child component 
      if (isInEditMode) {
        const cellConfig = {
          config
        }
        if (config.items !== undefined) {
          const comboboxCopy = CbaTableCell.createCellCopy(cellConfig, path, "CbaComboBox");
          tableCell = <CbaComboBox config={comboboxCopy.config} path={comboboxCopy.path} runtime={runtime} orientation={orientation} title={this.buildTitle(config, pathState)} onBlur={this.onSelectionChange} isInEditMode={isInEditMode} />
          StateAttributeAccess.setCellType(pathState, "combo");
        } else {
          const inputCopy = CbaTableCell.createCellCopy(cellConfig, path, "CbaSingleLineInputField");
          tableCell = <CbaSingleLineInputField config={inputCopy.config} path={inputCopy.path} runtime={runtime} orientation={orientation} title={this.buildTitle(config, pathState)} onBlur={this.onSelectionChange} isInEditMode={isInEditMode} />
          StateAttributeAccess.setCellType(pathState, "text");
        }
        ComponentStateHelper.registerState(this, pathState);
      }
    } else {
      const delegates = config.cbaChildren.map((child, index) => {
        const childPath = IndexPathHelper.appendIndexToPageSegment(path, index);

        // Override child config with some of the table cell config attributes,
        // needed for selected/deselected behavior, 
        // because the text field model doesn't contain the selectGroupMember attribute
        const childCopy = StateManagerHelper.deepCopy(child);
        childCopy.config.color = config.color;
        childCopy.config.toggleType = "colorChange";

        return (
          <CbaRichTextField
            key={childPath}
            config={childCopy.config}
            path={childPath}
            runtime={runtime}
            orientation={orientation}
            row={config.row}
            column={config.column}
            parentTableUserDefId={config.parentTableUserDefId}
            onParentClick={this.onClickHandler}
            checkSelectable={this.checkSelectable}
          />
        )
      });

      // shrink borders inside the cell
      style.boxSizing = 'border-box';
      tableCell = (
        <div style={style} onClick={this.onClickHandler} title={CommonConfigHelper.buildTitle(config)}>
          {config.imageReference
            && <img src={CommonConfigHelper.getProperResourcePath(config.imageReference, runtime)} style={imageStyle} alt="" /> }
          {delegates}
        </div>
      )
    }
    return tableCell;
  }

  /**
   * The method returns the index path of the parent table
   */
  buildTableIndexPath() {
    const { path } = this.props;
    return IndexPathHelper.dropIndexFromPageSegment(path);
  }

  traceCellModified(event, oldEvaluatedValue, newEvaluatedValue, oldValue, newValue, errorInFormula) {
    const { config, path, runtime } = this.props;
    const { row, column } = config;
    const pathState = ComponentStateHelper.getState(this);
    const tablePath = this.buildTableIndexPath();
    const tableUserDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(tablePath, runtime);
    const currentCellType = StateAttributeAccess.extractCellType(pathState);
    const cellType = TableHelper.isFormula(newValue) ? 'formula' : currentCellType;
    const oldCellType = TableHelper.isFormula(oldValue) ? 'formula' : currentCellType;
    const oldErrorState = Number.isNaN(Number.parseFloat(oldEvaluatedValue));
    let traceDetails;

    if (oldCellType !== 'formula') {
      // do not show old evaluated value for old text cells  
      oldValue = oldEvaluatedValue;
      oldEvaluatedValue = undefined;
    } else if (oldErrorState) {
      // do not show old evaluated value for old formula cells with errors 
      oldEvaluatedValue = undefined;
    }

    // do not show new evaluated value for new formula cells with errors 
    if (errorInFormula) {
      newEvaluatedValue = undefined;
    }

    if (cellType === 'formula') {
      traceDetails = {
        tableUserDefIdPath,
        tableUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(tableUserDefIdPath),
        row,
        column,
        oldValue,
        newValue,
        cellType,
        oldEvaluatedValue,
        newEvaluatedValue,
        errorInFormula
      }
    } else {
      newValue = newEvaluatedValue;
      traceDetails = {
        tableUserDefIdPath,
        tableUserDefId: UserDefPathHelper.getLastUserDefIdFromPath(tableUserDefIdPath),
        row,
        column,
        oldValue,
        newValue,
        cellType,
        oldEvaluatedValue
      }
    }

    CommonActionsHelper.traceUserInteraction("TableCellModified", path, traceDetails,
      event,
      undefined,
      runtime);
  }

  /**
   * Helper method used to create a copy of the cell coponent and override some info.
   * @param {*} cell the table cell component instance
   * @param {*} type the type of the copy created
   */
  static createCellCopy(cell, path, type) {
    const cellCopy = StateManagerHelper.deepCopy(cell);
    cellCopy.config.userDefinedId = undefined;
    cellCopy.type = type;
    cellCopy.path = IndexPathHelper.appendIndexToPageSegment(path, 0);
    return cellCopy;
  }

  static getFormulaOrValue(selector, userDefPath, runtime) {
    const cellState = runtime.componentStateManager.findOrBuildStateByUserDefPath(userDefPath, runtime);
    if (selector === 'formula') {
      return StateAttributeAccess.extractFormula(cellState);
    }
    return StateAttributeAccess.extractTextValue(cellState);
  }

  render() {
    const { runtime, config, path, orientation } = this.props;
    const { hasHover } = this.state;
    const hoverBackgroundColor = "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(235, 235, 235)), to(rgb(213, 213, 213)))";

    const pathState = ComponentStateHelper.getState(this);
    const selectedState = StateAttributeAccess.extractSelected(pathState);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, selectedState, orientation, runtime);

    const { isSpreadsheet } = config;
    if (isSpreadsheet) {
      // when a cell has hover a grey gradient will be applied to its background, 
      // otherwise should return to its previous state
      if (hasHover) {
        CommonConfigHelper.setStyleAttribute(style, "background", hoverBackgroundColor);
      } else if (selectedState) {
        CommonConfigHelper.setStyleAttribute(style, "background", config.color.background.selected);
      } else {
        CommonConfigHelper.setStyleAttribute(style, "background", config.color.background.default);
      }

      // these are styling settings to keep the label inside the cell 
      CommonConfigHelper.setStyleAttribute(style, "textOverflow", "ellipsis");
      CommonConfigHelper.setStyleAttribute(style, "whiteSpace", "nowrap");
      CommonConfigHelper.setStyleAttribute(style, "overflow", "hidden");

    }

    return this.buildTableCell(config, path, runtime, orientation, style);
  }

}

CbaTableCell.propTypes = {
  runtime: PropTypes.object.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        row: PropTypes.number.isRequired,
        column: PropTypes.number.isRequired
      }
    )
  ).isRequired,
  path: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired
}
