import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import CbaPolygon from './CbaPolygon';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import RenderingHelper from './RenderingHelper';

export default class CbaRegionMap extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  render() {
    const { runtime, path: controllerPath, config, orientation } = this.props;

    const positionConfig = CommonConfigHelper.getPosition(config);

    const delegates = config.regions.map((child, index) => {
      if (child.type !== "CbaPolygon") console.warn(`Unexpected region type: ${child.type}`);
      const childPath = IndexPathHelper.appendIndexToPageSegment(controllerPath, index);
      return (
        <CbaPolygon
          key={childPath}
          config={child.config}
          path={childPath}
          orientation={orientation}
          runtime={runtime}
        />
      )
    });
    return (
      <div
        title={CommonConfigHelper.buildTitle(config)}
        style={CommonConfigHelper.buildStyleByIndexPath(controllerPath, config, false, orientation, runtime)}
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
      >
        <svg height={positionConfig.height} width={positionConfig.width}>
          {delegates}
        </svg>
      </div>
    );
  }

}

CbaRegionMap.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupControllerConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        regions: PropTypes.array.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
