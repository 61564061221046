import Controller from './controller/Controller';
import registerServiceWorker from './registerServiceWorker';
import TaskPlayer from './preamble/TaskPlayer';
import Utils from './utils/Utils';


function shouldActivateInternalController() {
  const controllerMode = Utils.getControllerMode();
  const useExternalController = controllerMode === 'external-controller';
  if (useExternalController) {
    console.log('Wait for external controller to take over...');
  }
  return !useExternalController;
}

// Wait for service worker activation before starting the application:
window.addEventListener("service-worker-intallation", (event) => {
  console.log(event);
  if (event.detail.success) {
    console.log(`SW is ${event.detail.status}. app can be used in offline mode!`);
  } else {
    console.error(`SW installation ${event.detail.status}. app can NOT be used in offline mode!`);
  }
  const eventReceiverConfiguration = Utils.getEventReceiverConfiguration();
  const taskPlayer = new TaskPlayer(eventReceiverConfiguration.eventTargetWindow, eventReceiverConfiguration.eventDomainUri);
  if (shouldActivateInternalController()) {
    const internalController = new Controller();
    internalController.runController(taskPlayer);
  } else {
    taskPlayer.sendPlayerReady();
    taskPlayer.runPlayer();
  }
});

// Trigger server worker activation:
registerServiceWorker();
