import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TopButtonToolBar from './TopButtonToolbar'
import RecommendationsManager from '../state/RecommendationsManager';
import CommonActionsHelper from "./CommonActionsHelper";
import './TaskNavigator.css';

/**
 * Component that manages the test/task menu bars in the header area.
 */
export default class TaskNavigator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      recommendations: []
    }

  }

  // public API -----------------------------------------------------------------------------

  /**
   * Set the currently recommended test/task combination.
   * 
   * The method expects an array of objects. Each object must specify a testName and a taskName.
   */
  highlightRecommendations = (recommendations) => {
    if (recommendations === undefined) {
      console.error("Invalid recommendations in highlight call ignored!");
    }
    const verifiedRecommendations = recommendations === undefined ? [] : recommendations;
    this.setState(previousState => ({
      recommendations: verifiedRecommendations
    }));
  }


  // private stuff -----------------------------------------------------------------------------

  /**
   * Build the CCS style for all buttons that we display in our menu bars.
   * 
   * We support two decorations: 
   *  - active marks the currently active test/task.
   *  - recommended marks the tests currently recommended by the some adaptation logic. 
   */
  static buildButtonStyle(buttonHeight, isActive, isRecommended) {
    const style = {
      height: buttonHeight
    };

    if (isActive) {
      // compute growth of button based on the padding value
      const growth = 1 + ((TopButtonToolBar.getButtonPadding() * 2) / buttonHeight) // padding/height
      style.transform = `scale(${growth})`;
    }
    if (isRecommended) {
      // TODO: move this to CSS class? 
      style.backgroundColor = RecommendationsManager.getRecommendationColor();
    }
    return style;
  }

  static buildButtonClassName(isActive, isRecommended) {
    let className = "";
    if (isActive) {
      className += "active";
    }

    if (isRecommended) {
      className += " recommended";
    }

    return className
  }

  /**
   * Find the index for the test specified by the test name. 
   */
  static findTestIndexForName(tests, testName) {
    const testIndexForName = tests.findIndex((value, index, theArray) => value.name === testName);
    if (testIndexForName === undefined) {
      console.error(`Test navigator could not find test with name ${testName}`);
      return undefined;
    }
    return testIndexForName;
  }


  /**
   * Find the test configuration for the test specified by the test name. 
   */
  static findTestForName(tests, testName) {
    const testForName = tests.find((value, index, theArray) => value.name === testName);
    if (testForName === undefined) {
      console.error(`Test navigator could not find test with name ${testName}`);
      return undefined;
    }
    return testForName;
  }

  /**
   * Find the the first task in the given test. 
   * 
   * The method returns an object describing the task with two attributes:
   *  - task
   *  - item
   */
  static findFirstTaskInTest(test) {
    if (test === undefined || test.taskCourse[0] === undefined) {
      return undefined;
    }
    return test.taskCourse[0];
  }

  /**
   * Find the 'default' task in the test specified by the test name.
   *
   * The method returns an object describing the task with two attributes:
   *  - task
   *  - item
   */
  static findDefaultTaskForTest(tests, testName) {
    const test = TaskNavigator.findTestForName(tests, testName);
    return TaskNavigator.findFirstTaskInTest(test)
  }

  /**
   * Find the first task with matching task name in the test specified by the test name.
   *
   * The method returns an object describing the task with two attributes:
   *  - task
   *  - item
   */
  static findFirstTaskWithNameForTest(tests, testName, taskName) {
    const targetTest = TaskNavigator.findTestForName(tests, testName);
    if (targetTest === undefined) {
      return undefined;
    }
    return targetTest.taskCourse.find((taskEntry, index, theArray) => taskEntry.task === taskName);
  }


  /**
   * Render a single button in the test menu bar.
   */
  buildTestButton = (testName) => {
    const { recommendations } = this.state;
    const matchingRecommendation = RecommendationsManager.findFirstRecommendationForTest(recommendations, testName);
    const targetTaskInfo = this.selectDefaultTaskForTest(testName, matchingRecommendation);
    const { runtime, testButtonHeight, currentTest } = this.props;

    // Do nothing on button click if the test does not exist or has no tasks assigned:
    const buttonClickHandler = (targetTaskInfo === undefined
      ? (event) => {
        console.info(`Ignored navigation request to test named ${testName} since the test has no tasks assigned.`)
      }
      : (event) => {
        TaskNavigator.traceNavigation(true, testName, event, runtime);
        TaskNavigator.navigateToTest(testName, targetTaskInfo.item, targetTaskInfo.task, runtime);
      });

    return (
      <button
        type="button"
        key={testName}
        onClick={buttonClickHandler}
        title={testName}
        style={TaskNavigator.buildButtonStyle(testButtonHeight, testName === currentTest, matchingRecommendation !== undefined)}
        className={TaskNavigator.buildButtonClassName(testName === currentTest, matchingRecommendation !== undefined)}
      >
        {testName}
      </button>
    );
  }

  selectDefaultTaskForTest = (testName, matchingRecommendation) => {
    const { runtime, tests } = this.props;
    if (matchingRecommendation !== undefined && matchingRecommendation.taskName !== undefined) {
      const taskEntry = TaskNavigator.findFirstTaskWithNameForTest(tests, testName, matchingRecommendation.taskName);
      if (taskEntry !== undefined) {
        return {
          item: taskEntry.item,
          task: taskEntry.task
        };
      }
    }

    const testState = runtime.taskNavigatorStateManager.getTestState(testName);
    if (testState !== undefined) {
      return {
        item: testState.itemName,
        task: testState.taskName
      };
    }

    const defaultTask = TaskNavigator.findDefaultTaskForTest(tests, testName);
    if (defaultTask !== undefined) {
      return {
        item: defaultTask.item,
        task: defaultTask.task
      };
    }

    return undefined;
  }

  static navigateToTest(testName, itemName, taskName, runtime) {
    runtime.taskManager.switchTaskInterTest(testName, itemName, taskName);
  }


  /**
   * Render the test menu bar.
   */
  buildTestNavigation = () => {
    const { courses } = this.props;
    return courses.map(testName => this.buildTestButton(testName));
  }

  /**
   * Render a single button in the task menu bar.
   */
  buildTaskButton = (testName, itemName, taskName) => {

    const { recommendations } = this.state;
    const { runtime, taskButtonHeight, currentItem, currentTask } = this.props;
    const matchingRecommendation = RecommendationsManager.findFirstRecommendationForTask(recommendations, testName, taskName);

    // Do nothing on button click if task entry is invalid:
    const buttonClickHandler = (taskName === undefined
      ? (event) => { }
      : (event) => {
        TaskNavigator.traceNavigation(false, taskName, event, runtime);
        TaskNavigator.navigateToTask(taskName, itemName, runtime);
        runtime.recommendationsManager.processTaskSelected(testName, itemName, taskName);
      });

    return (
      <button
        type="button"
        key={`${testName}.${itemName}.${taskName}`}
        onClick={buttonClickHandler}
        title={taskName}
        style={TaskNavigator.buildButtonStyle(taskButtonHeight,
          taskName === currentTask && itemName === currentItem, matchingRecommendation !== undefined)}
        className={TaskNavigator.buildButtonClassName(taskName === currentTask && itemName === currentItem, matchingRecommendation !== undefined)}
      >
        {taskName}
      </button>
    );

  }

  buildTopLayerStyle = () => {
    const { runtime } = this.props;

    return {
      width: runtime.taskManager.getTopLevelConfiguration().itemWidth
    }
  }

  assignRatioToButton = buttonRatio => ((button) => {
    const width = buttonRatio * button.props.style.height;

    button.props.style.width = width;
    button.props.style.minWidth = width;

    return button;
  });

  /**
   * Trace the navigation action in the log.
   * 
   * @param {*} isTest Is this a test navigation? If false we assume a task navigation.
   * @param {*} navigationTarget The test/task we navigate to.
   * @param {*} browserEvent The event that triggered the button's action.
   * @param {*} runtime The common runtime structure.
   */
  static traceNavigation(isTest, navigationTarget, browserEvent, runtime) {
    const traceDetails = {
      navigationType: (isTest ? 'test' : 'task'),
      navigationTarget
    };
    CommonActionsHelper.addMouseEventDetails(browserEvent, traceDetails);

    runtime.traceLogBuffer.reportEvent('NavigationButton', new Date(), traceDetails);
  }

  /**
   * Render the task menu bar.
   */
  buildTaskNavigation = () => {
    const { tests, currentTest } = this.props;
    const activeTest = TaskNavigator.findTestForName(tests, currentTest);
    if (activeTest === undefined || activeTest.taskCourse === undefined) {
      return <div>--- Test invalid, no tasks found ---</div>
    }

    return activeTest.taskCourse.map(taskEntry => this.buildTaskButton(currentTest, taskEntry.item, taskEntry.task));
  }

  static navigateToTask(taskName, itemName, runtime) {
    runtime.taskManager.switchTaskIntraTest(itemName, taskName);
  }

  /*
  * computes the total height of the task navigator
  */
  static getTaskNavigatorHeight = (testButtonHeight, taskButtonHeight) => {
    const testNavHeight = TopButtonToolBar.getCarouselHeight(testButtonHeight);
    const taskNavHeight = TopButtonToolBar.getCarouselHeight(taskButtonHeight);
    const negateMargin = TopButtonToolBar.getCarouselMarginBottom();

    return testNavHeight + taskNavHeight - negateMargin;
  }

  render() {
    const { runtime, testButtonHeight } = this.props;
    const { itemWidth } = runtime.taskManager.getTopLevelConfiguration();
    const buttonRatio = 2;

    return (
      <div className="task-navigator" style={this.buildTopLayerStyle()}>
        <TopButtonToolBar
          className="test-navigation"
          buttons={this.buildTestNavigation()}
          itemWidth={itemWidth}
          buttonHeight={testButtonHeight}
          buttonRatio={buttonRatio}
          activateCenterToolbarOnChange={false}
        >
          {/* activateCenterToolbarOnChange flag when set to false works only with fixed width items */}
        </TopButtonToolBar>

        <div className="task-navigation">
          {this.buildTaskNavigation().map(this.assignRatioToButton(buttonRatio))}
        </div>

      </div>
    );
  }

}

TaskNavigator.propTypes = {
  runtime: PropTypes.object.isRequired,
  courses: PropTypes.arrayOf(PropTypes.string).isRequired,
  tests: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    const testPropTypes = {
      name: PropTypes.string.isRequired,
      taskCourse: PropTypes.arrayOf((propValue2, key2, componentName2, location2, propFullName2) => {
        const taskEntryPropTypes = {
          item: PropTypes.string.isRequired,
          task: PropTypes.string.isRequired,
        };
        return PropTypes.checkPropTypes(taskEntryPropTypes, propValue2[key2], propFullName2, componentName2)
      }).isRequired
    };
    return PropTypes.checkPropTypes(testPropTypes, propValue[key], propFullName, componentName)
  }).isRequired,
  currentTest: PropTypes.string.isRequired,
  currentItem: PropTypes.string.isRequired,
  currentTask: PropTypes.string.isRequired,
  testButtonHeight: PropTypes.number.isRequired,
  taskButtonHeight: PropTypes.number.isRequired,
}
