import IndexPathHelper from '../../state/IndexPathHelper';

/**
 * Helper methods used by CbaRichTextField 
 * to calculate positions in the Draft.js editor state 
 * taking 'content modifiers' into account.
 */
export default class PositionCalculationHelper {

  /**
   * Positions (block key and character offset) as given in the CbaRichTextField configuration 
   * are valid as long a no content modifiers are applied to the editor state. 
   * As soon as content modifiers change the block content of the editor state one has to 
   * apply an offset to obtain positions that take the modifications done by the 
   * content modifiers into account.
   * 
   * The method calculates the actual position for the given modifier in the given editor state.
   * 
   * @param {*} targetModifier The static configuration (from the contentModifiers list in the static configuration of the rich text field) of the content modifier to calculate the actual target position for.
   * @param {[*]} configBlocks The text blocks as specified in the static configuration of the rich text field.
   * @param {{}} blockKeyToIndexMap A map from each block key to its index in the configBlocks array.
   * @param {[*]} configContentModifiers The content modifiers list in the static configuration of the rich text field.
   * @param {{}} contentModifiersInEditorState The extract of the modifications implanted by the content modifiers in the current editor state.
   * @param {string} contentModifierParentPath The parent path used to calculate the content modifiers' path ids with.
   * @param {*} editorState The current editor state in which the calculated actual target position should be valid.
   */
  static calculateActualPosition(
    targetModifier,
    configBlocks,
    blockKeyToIndexMap,
    configContentModifiers,
    contentModifiersInEditorState,
    contentModifierParentPath,
    editorState
  ) {
    const actualPositionOffset = PositionCalculationHelper.calculateCurrentPositionOffset(
      targetModifier,
      configBlocks,
      blockKeyToIndexMap,
      configContentModifiers,
      contentModifiersInEditorState,
      contentModifierParentPath
    );
    const modifierPosition = targetModifier.config.position;
    const actualBlockKey = PositionCalculationHelper.calculateBlockKey(modifierPosition.blockKey, actualPositionOffset.blockOffset, editorState);
    if (actualBlockKey === undefined) {
      console.error(`Actual block key undefined for ${targetModifier.config.userDefinedId}`);
    }
    return {
      blockKey: actualBlockKey,
      offset: modifierPosition.offset + actualPositionOffset.characterOffset
    }
  }


  /**
   * The method calculates the block key for the requested block (specified by the start block key and a block offset) 
   * in the given editor state. 
   * 
   * @param {*} startBlockKey The block key of the block the given block offset is applied to.
   * @param {*} blockOffset The block offset to apply to the startBlockKey.
   * @param {*} editorState The editor state containing the targeted block.
   */
  static calculateBlockKey(startBlockKey, blockOffset, editorState) {
    const contentState = editorState.getCurrentContent();
    let movedBlockKey = startBlockKey;
    let offsetCounter;
    for (offsetCounter = 0; offsetCounter < blockOffset; offsetCounter+=1) {
      movedBlockKey = contentState.getKeyAfter(movedBlockKey);
    }
    return movedBlockKey;
  }


  /**
   * The method calculates the necessary offset (block offset and character offset)
   * to be applied to a given 'config' position to obtain the 'actual' position in the
   * editor state.
   * 
   * @param {*} targetModifier The content modifier (in the contentModifiers list) to calculate the actual target position for.
   * @param {[*]} configBlocks The text blocks as specified in the configuration.
   * @param {{}} blockKeyToIndexMap A map from each block key to its index in the configBlocks array.
   * @param {[*]} configContentModifiers The content modifiers specified in the configuration.
   * @param {{}} contentModifiersInEditorState The activation status of the content modifiers in the current editor state.
   * @param {string} contentModifierParentPath The parent path used to calculate the content modifiers' path ids with.
   */
  static calculateCurrentPositionOffset(
    targetModifier,
    configBlocks,
    blockKeyToIndexMap,
    configContentModifiers,
    contentModifiersInEditorState,
    contentModifierParentPath
  ) {

    // Only currently activated modifiers and modifiers in the our target block and before our target position can affect our calculations:
    const targetModifierBlockKey = targetModifier.config.position.blockKey;
    const relevantModifiers = configContentModifiers.filter((modifier, index, all) => {
      const stateInEditorState = PositionCalculationHelper.getModifierStateInEditorState(index, contentModifierParentPath, contentModifiersInEditorState);
      return (PositionCalculationHelper.isEvaluatedInject(modifier) ? stateInEditorState.injectText !== "" : stateInEditorState.active)
        && PositionCalculationHelper.inBlock(modifier, targetModifierBlockKey) && PositionCalculationHelper.beforePosition(modifier, targetModifier, blockKeyToIndexMap);
    });


    // The block offset is the sum of all blocks added by active dynamic fragments before our target position.
    const blockShiftingModifiers = relevantModifiers.filter((modifier, index, all) => PositionCalculationHelper.hasMoreThanOneBlock(modifier));
    const blockOffset = blockShiftingModifiers.reduce((total, currentBlockShifter) => total + currentBlockShifter.config.blocks.length - 1, 0);


    // To calculate the character offset we have to consider the last block-creating fragment in the target block before the target position. 
    // If such a fragment exists, its last block replaces all characters in the target block up to the fragment's position 
    // and we start calculating the character offset from this position and with an initial value of (characters in replacing block - character up to fragment's position on old block).
    // If no such fragment exists we start calculating the character offset with inital value 0 at the beginning of the target block.
    // Now we add to the character offset the count of characters injected by all active modifiers up to the target position.

    const lastBlockShifterInSameBlock = PositionCalculationHelper.getLastModifierPerOffset(blockShiftingModifiers);
    const characterSwitchersStartOffset = lastBlockShifterInSameBlock === undefined ? 0 : lastBlockShifterInSameBlock.config.position.offset;
    const characterSwitchersStartIndex = lastBlockShifterInSameBlock === undefined ? 0 : lastBlockShifterInSameBlock.config.position.index;
    const characterOffsetStartValue = lastBlockShifterInSameBlock === undefined ? 0
      : PositionCalculationHelper.getLastBlockOfFragment(lastBlockShifterInSameBlock).text.length - lastBlockShifterInSameBlock.config.position.offset;

    // Note: We need the index in the array of all configModifiers to calculate the injected text for evaluating modifiers. 
    //       Therefore we have to map the full contentModifiers array here! 
    const allCharacterShifterTexts = configContentModifiers.map((modifier, index, all) => {
      if (!PositionCalculationHelper.afterPositionInSameBlock(modifier, targetModifierBlockKey, characterSwitchersStartOffset, characterSwitchersStartIndex)) return "";
      if (!PositionCalculationHelper.beforePosition(modifier, targetModifier, blockKeyToIndexMap)) return "";
      if (PositionCalculationHelper.isDynamicFragment(modifier)) {
        return PositionCalculationHelper.getModifierStateInEditorState(index, contentModifierParentPath, contentModifiersInEditorState).active ? modifier.config.blocks[0].text : "";
      }
      if (PositionCalculationHelper.isEvaluatedInject(modifier)) {
        return PositionCalculationHelper.getModifierStateInEditorState(index, contentModifierParentPath, contentModifiersInEditorState).injectText;
      }
      return "";
    });

    const characterOffset = allCharacterShifterTexts.reduce((total, injectedText) => total + injectedText.length, characterOffsetStartValue);


    return {
      blockOffset,
      characterOffset
    }
  }


  static isDynamicFragment(modifier) {
    return modifier.type === 'DynamicFragment';
  }

  static isEvaluatedInject(modifier) {
    return modifier.type === 'EvaluatedInject';
  }

  static hasMoreThanOneBlock(modifier) {
    return PositionCalculationHelper.isDynamicFragment(modifier) && modifier.config.blocks.length > 1;
  }

  static beforePosition(candidateModifier, compareModifier, blockKeyToIndexMap) {
    const candidatePosition = candidateModifier.config.position;
    const comparePosition = compareModifier.config.position;
    const candidateBlockIndex = blockKeyToIndexMap[candidatePosition.blockKey];
    const compareBlockIndex = blockKeyToIndexMap[comparePosition.blockKey];

    // check blocks: 
    if (candidateBlockIndex < compareBlockIndex) return true;
    if (candidateBlockIndex > compareBlockIndex) return false;

    // blocks are the same, now check offsets in block:
    if (candidatePosition.offset < comparePosition.offset) return true;
    if (candidatePosition.offset > comparePosition.offset) return false;

    // character offset are the same also, now check index:
    return candidatePosition.index < comparePosition.index;
  }

  static afterPositionInSameBlock(candidateModifier, compareBlockKey, compareOffset, compareIndex) {
    const candidatePosition = candidateModifier.config.position;
    return (candidatePosition.blockKey === compareBlockKey)
      && (candidatePosition.offset > compareOffset || (candidatePosition.offset === compareOffset && candidatePosition.index > compareIndex));
  }

  static inBlock(candidateModifier, compareBlockKey) {
    return candidateModifier.config.position.blockKey === compareBlockKey;
  }

  static getLastBlockOfFragment(dynamicFragment) {
    const { blocks } = dynamicFragment.config;
    return blocks.length < 1 ? undefined : blocks[blocks.length - 1];
  }

  static getLastModifierPerOffset(candidateModifiers) {
    let result;
    candidateModifiers.forEach((candidate, index, all) => {
      if (result === undefined || PositionCalculationHelper.isGreaterPositionOffset(candidate.config.position, result.config.position)) {
        result = candidate;
      }
    });
    return result;
  }

  static isGreaterPositionOffset(left, right) {
    if (left.offset > right.offset) return true;
    if (right.offset > left.offset) return false;
    return left.index > right.index;
  }


  static getModifierStateInEditorState(contentModifierIndex, contentModifierParentPath, contentModifiersInEditorState) {
    const fromState = contentModifiersInEditorState[IndexPathHelper.appendIndexToPageSegment(contentModifierParentPath, contentModifierIndex)];
    return fromState === undefined
      ? {
        injectText: "",
        active: false
      }
      : fromState;
  }


}
