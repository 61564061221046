import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      loggedUserName: '',
      isLoginPressed: false
    };
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  switchToLoginState = () => {
    this.setState({
      isLoginPressed: true
    });
    const { handleLogin } = this.props;
    const { loggedUserName } = this.state;
    handleLogin(loggedUserName);
  }

  handleLoginClick = (evt) => {
    this.switchToLoginState();
  }

  updateInputLoginValue = (evt) => {
    this.setState({
      loggedUserName: evt.target.value
    });
  }

  handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      this.switchToLoginState();
    }
  }

  render() {
    const { loggedUserName, isLoginPressed } = this.state;
    const { titleLabel, fieldLabel, buttonLabel } = this.props;
    const usernameInputName = 'usernameInputName';
    return (
      <div className="appDiv">
        <div className="login-container">
          <div className="login-head">
            <h3>{titleLabel}</h3>
          </div>
          <div className="login-body">

            <div className="form-group">
              <label htmlFor={usernameInputName}>
                <b>{fieldLabel}</b>
                <input
                  ref={this.inputRef}
                  type="text"
                  placeholder=""
                  name={usernameInputName}
                  value={loggedUserName}
                  onChange={evt => this.updateInputLoginValue(evt)}
                  onKeyPress={this.handleKeyPress}
                />
              </label>
            </div>
            <div className="btn-container">
              <button className="btn blue-button" type="button" onClick={this.handleLoginClick} disabled={isLoginPressed}>{buttonLabel}</button>
            </div>

          </div>
        </div>

      </div>
    )
  }

}

Login.propTypes = {
  titleLabel: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  handleLogin: PropTypes.func.isRequired
}
