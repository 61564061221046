export default class ValueFormatter {

  static FORMATS = {
    DE: {
      decimal: ",", groupping: "."
    },
    EN: {
      decimal: ".", groupping: ","
    }
  }

  constructor(withThousandSeparator, format) {
    this.withThousandSeparator = withThousandSeparator;
    this.format = format;
  }

  formatValue = (number) => {
    if (number) {
      number = this.numberWithCommas(number);
    }
    return number;
  }

  // private

  numberWithCommas = (x) => {
    const parts = x.toString().split('.');
    if (this.withThousandSeparator) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.getGrouppingSeparator());
    }
    return parts.join(this.getDecimalSeparator());
  }

  getDecimalSeparator = () => this.format.decimal;

  getGrouppingSeparator = () => this.format.groupping;

}
