import ServerCalls from './ServerCalls';
import Utils from '../utils/Utils';
import StandardTaskSequencer from './StandardTaskSequencer';

/**
 * Implementation of controller layer.
 * 
 * This implementation uses the 'direct JavaScript' TaskPlayer API.
 * 
 * We support two running modes:
 *  - The standard mode expecting a REST API on the server to obtain configuration data.
 *  - A simplified mode expecting a plain static content server. This mode is meant to 
 *  support item authoring tools that do not embed a dynamic content server. 
 * 
 *   Action              | Preview mode                                      | Rest-API mode
 *   --------------------|---------------------------------------------------|---------------------------------------------------------
 *   Trace log target    | console.log                                       | <WindowURLNoPath>/ee4cba-api/trace
 *                       |                                                   | 
 *   --------------------|---------------------------------------------------|---------------------------------------------------------
 *   Show Login Box      | no                                                | yes
 *                       |                                                   | 
 * 
 */

 window.assessmentId = window.cba_runtime_config.assessment_id || "react";

 export default class Controller {

  constructor() {
    this.taskPlayer = undefined;
    this.taskSequencer = undefined;

    this.items = [];

    this.settings = [];
    this.headerButtons = [];

    this.sessionId = undefined;
    this.userId = undefined;

    this.activeTestName = undefined;
    this.activeItemName = undefined;
    this.activeTaskName = undefined;
  }

  // ---------- public API -----------------------------------------------------------------

  /**
   * Let the controller drive the given task player. 
   */
  runController = (taskPlayer) => {

    this.taskPlayer = taskPlayer;

    taskPlayer.runPlayer();

    // Redirect the trace log stream to the trace endpoint of the server REST-API.
    // If running as preview in an item authoring tool we let the trace logger write to the console instead.
    if (!ServerCalls.runWithSimplifiedPreviewServer()) {
      taskPlayer.setHttpTraceTransmission(`${Utils.getCallingUrlWithoutPath()}/${ServerCalls.restApiTargets.CONFIGURATION_API}${ServerCalls.restApiTargets.TRACE_ENDPOINT}`, 20000, 10000);
      taskPlayer.setHttpRecordingTransmission(`${Utils.getCallingUrlWithoutPath()}/${ServerCalls.restApiTargets.CONFIGURATION_API}${ServerCalls.restApiTargets.RECORDING_ENDPOINT}`);
    }
    this.stateEndpoint = `${Utils.getCallingUrlWithoutPath()}/${ServerCalls.restApiTargets.CONFIGURATION_API}${ServerCalls.restApiTargets.STATE_ENDPOINT}`;
    //Register worker to check for state
    if(!window.Worker) {
      this.worker = { postMessage: () => {}};
      console.error("Worker API not available -> state is not (re-)stored!");
    }
    else {
      this.worker = new Worker("./stateWorker.js");
      this.worker.addEventListener("message", (evt) => {
        switch(evt.data.cmd) {
          case "requestStore":
            this.saveState();
            break;
          case "notify":
            console.log(`StateWorker reports: ${evt.data.message}`);
            break;
          case "stored":
            if(!evt.data.status) {
              console.error("Failed to store state");
            }
        }
      });
      //register
      this.worker.postMessage({
        action: "register",
        endpoint : this.stateEndpoint,
        interval: 5000
      });
    }

    // Do not show a login box if running as preview in an item authoring tool:
    if (ServerCalls.runWithSimplifiedPreviewServer()) {
      const startWithLogin = Utils.getQueryParam('showLogin');
      if (startWithLogin === 'true') {
        this.showLogin();
      } else {
        this.obtainConfigurationAndRunFirstTask('PreviewUser');
      }
    } else {
      this.showLogin();
    }
  }

  // ---------- private stuff -----------------------------------------------------------------

  /**
   * Show the login dialog via the task player.
   */
  showLogin = () => {
    this.taskPlayer.showLogin('Login', 'Username: ', 'Ok', this.loginDialogClosedCallback);
  }

  /**
   * Callback for the task player to run once the user filled in the login dialog.
   * 
   * We don't authenticate the user. The server will pick an appropriate test configuration
   * for the given user.
   */
  loginDialogClosedCallback = (username) => {
    this.obtainConfigurationAndRunFirstTask(username);
  }

  /**
   * Obtain the full configuration for the given user from the server 
   * and start the first task in the task player.
   */
  obtainConfigurationAndRunFirstTask = (username) => {
    ServerCalls.getJsonsData(username)
      .then((data) => {
        this.processConfigurationAndRunFirstTask(username, data);
      })
      .catch((error) => {
        console.error('Failure during task start in task player.', error);
      });
  }

  /**
   * Process the full configuration given by the server for the given user
   * and start the first task in the task player.
   */
  processConfigurationAndRunFirstTask = (username, configurationData) => {
    this.userId = username;
    if (configurationData.success) {
      this.digestConfigurationResponse(configurationData.payload);
      this.startFirstTaskInTaskPlayer();
    } else {
      console.error(`Server failed to send test configuration for user ${username}`);
      this.taskPlayer.showLogin('Login ungültig', 'Username: ', 'Ok', this.loginDialogClosedCallback);
    }

  }

  startFirstTaskInTaskPlayer = async () => {
    this.sendItemConfigurationToTaskPlayer();

    this.taskPlayer.setTraceContextId(this.sessionId);
    this.taskPlayer.setRecordingContextId(this.sessionId);
    this.taskPlayer.setUserId(this.userId);
    this.taskPlayer.setTaskSequencer(this.taskSwitchCallback, this.taskAvailableCallback);
    this.taskPlayer.setHeaderButtons(this.headerButtons);
    const { course, tests } = this.taskSequencer.getConfigurationInfo();

    if (this.settings.ShowTaskNavigationBars === true) {
      this.taskPlayer.setMenuCarousels(course, tests.map(test => ({
        // TODO: CKI clean up these attribute name inconsistencies in config files
        name: test.name,
        tasks: test.taskCourse
      })));
    } else {
      this.taskPlayer.setMenuCarousels([], []);
    }

    this.taskPlayer.activateDebuggingWindows(
      this.settings.scoreDebugging.hotKey,
      this.settings.traceDebugging.hotKey,
      this.settings.statemachineDebugging.hotKey
    );
    let res = await fetch(`${this.stateEndpoint}/${this.userId}/${window.assessmentId}`);
    //We have a state
    if(res.status === 200) {
      let data = await res.json();
      console.log(`Received state:`, data.state);
      this.taskPlayer.clearTasksState();
      this.taskPlayer.preloadTasksState(data.state.player);
      //set the current task, so that follow up logic will correctly initialize the player
      this.taskSequencer.switchCurrentTask(data.state.sequencer.testName, data.state.sequencer.itemName, data.state.sequencer.taskName)
    }
    //load default/current task
    const initialTask = this.taskSequencer.getCurrentTaskInfo();
    if (initialTask.taskName === undefined) {
      console.error("No task found.");
    } else {
      this.taskSequencer.switchCurrentTask(initialTask.testName, initialTask.itemName, initialTask.taskName);
      this.taskPlayer.startTask(initialTask.testName, initialTask.itemName, initialTask.taskName);
    }
  }


  /**
   * Digest the configuration data that we got from the server.
   */
  digestConfigurationResponse = (receivedData) => {
    this.taskSequencer = new StandardTaskSequencer(receivedData.courses, receivedData.tests);
    this.items = receivedData.items;
    this.settings = receivedData.settings;
    this.headerButtons = receivedData.headerButtons;
    this.sessionId = receivedData.sessionId;
    console.log("courses:", this.taskSequencer.getConfigurationInfo().course);
    console.log("tests", this.taskSequencer.getConfigurationInfo().tests);
    console.log("items", this.items);
    console.log("settings", this.settings);
    console.log("headerButtons", this.headerButtons);
    console.log("sessionId", this.sessionId);
  }

  /**
   * Send all item configurations to the task player.
   */
  sendItemConfigurationToTaskPlayer = () => {
    const dependenciesUrl = {
      MathJax: "./MathJax-local-server/mathjax@3.1.2-tex-mml-chtml.js"
    };

    this.items.forEach((item) => {
      const prefix = ServerCalls.runWithSimplifiedPreviewServer()
        ? './'
        : `${Utils.getCallingUrlWithoutPath()}/${ServerCalls.restApiTargets.ASSETS_API}/${item.name}/`;
      const libraryPathsMap = {};

      if (item.dependencies && item.dependencies.length > 0) {
        item.dependencies.forEach((dependency) => {
          libraryPathsMap[dependency.name] = dependenciesUrl[dependency.name]
        })
      }

      this.taskPlayer.addItem(item, `${prefix}resources`, `${prefix}external-resources`, libraryPathsMap);
    })
  }


  /**
   * Switch the task player to the given task
   * and update our internal structures accordingly.
   * 
   * The method assumes that the task player is running another task already.
   */
  stopOldTaskAndStartNewTask = (test, item, task) => {
    this.taskSequencer.switchCurrentTask(test, item, task);
    this.taskPlayer.stopTask();
    this.taskPlayer.startTask(test, item, task);
  }


  /**
   * Stop the currently running task and return to the login dialog.
   */
  stopOldTaskLogoutAndShowLogin = () => {
    this.taskPlayer.stopTask();
    this.taskPlayer.logout();
    this.taskPlayer.clearItems();
    if (ServerCalls.runWithSimplifiedPreviewServer()) {
      // eslint-disable-next-line no-alert
      alert("Session finished.");
    }
    this.showLogin();
  }

  /**
   * Callback for task player to trigger a task switch.
   * 
   * @param {String} request The type of switch request. Valid types are: 'nextTask', 'previousTask', 'cancelTask' and 'goToTask'.
   * @param {String} scope For request type 'goToTask' only: The scope (i.e. test) of the task to switch to.
   * @param {String} item For request type 'goToTask' only: The item of the task to switch to. If no item is given we will switch to the first task with matching name in the given scope.
   * @param {String} task For request type 'goToTask' only: The the task to switch to. 
   */
  taskSwitchCallback = (request, scope, item, task) => {
    this.saveState();
    switch (request) {
      case 'nextTask':
        this.switchTaskNext();
        break;
      case 'previousTask':
        this.switchTaskPrevious();
        break;
      case 'cancelTask':
        this.stopOldTaskLogoutAndShowLogin();
        break;
      case 'goToTask':
        if (item === undefined) {
          this.switchFirstMatchingTaskInterTest(scope, task);
        } else {
          this.stopOldTaskAndStartNewTask(scope, item, task);
        }
        break;
      default:
        console.error(`Unknown switch task callback type: ${request}`);
    }
  }

  /**
   * Helper method to store the current state of the CBA for resuming
   */
  saveState() {
    let userId = this.taskPlayer.getUserId();
    if(!userId || !this.taskSequencer) {
      return;
    }
    //Otherwise run
    let state = {
      player: this.taskPlayer.getTasksState(),
      sequencer: this.taskSequencer.getCurrentTaskInfo()
    };
     
    if(!!state && !!userId) {
      this.worker.postMessage({
        action: "store",
        userId,
        assessmentId: window.assessmentId,
        state
      });
    }
  }


  /**
   * Callback for task player to ask for the availability of a task switch.
   * 
   * @param {String} request The type of switch request. Valid types are: 'nextTask', 'previousTask', 'cancelTask' and 'goToTask'.
   * @param {String} scope For request type 'goToTask' only: The scope (i.e. test) of the task to switch to.
   * @param {String} item For request type 'goToTask' only: The item of the task to switch to. If no item is given we will switch to the first task with matching name in the given scope.
   * @param {String} task For request type 'goToTask' only: The the task to switch to. 
   */
  taskAvailableCallback = (request, scope, item, task) => {
    switch (request) {
      case 'nextTask':
        return this.taskSequencer.nextTaskAvailable();
      case 'previousTask':
        return this.taskSequencer.previousTaskAvailable();
      case 'goToTask':
        if (item === undefined) {
          return this.taskSequencer.findFirstMatchingTaskInTest(scope, task, false) !== undefined;
        } else {
          return true;
        }
      default:
        console.error(`Unknown switch task callback type: ${request}`);
        return false;
    }
  }


  /**
   * Switch to the first task with the given name inside a specific test (i.e. we ignore the source item).
   */
  switchFirstMatchingTaskInterTest = (newTestName, newTaskName) => {
    const newTaskEntry = this.taskSequencer.findFirstMatchingTaskInTest(newTestName, newTaskName, true);
    if (newTaskEntry !== undefined) {
      this.stopOldTaskAndStartNewTask(newTaskEntry.testName, newTaskEntry.itemName, newTaskEntry.taskName);
    } else {
      this.missingTaskErrorFeedback(`Task ${newTaskName} in test ${newTestName} is unknown. We are in ${this.buildCurrentTaskLogString()}`, newTestName);
    }
  }

  /**
   * Switch to the next task in our test course.
   */
  switchTaskNext = () => {
    const newTaskInfo = this.taskSequencer.getNextTaskInfo();
    if (newTaskInfo !== undefined) {
      this.stopOldTaskAndStartNewTask(newTaskInfo.testName, newTaskInfo.itemName, newTaskInfo.taskName, false);
    } else {
      this.missingTaskErrorFeedback(`There is no next task. We are in ${this.buildCurrentTaskLogString()}`, undefined);
    }
  }

  /**
   * Switch to the previous task in our test course.
   */
  switchTaskPrevious = () => {
    const newTaskInfo = this.taskSequencer.getPreviousTaskInfo();
    if (newTaskInfo !== undefined) {
      this.stopOldTaskAndStartNewTask(newTaskInfo.testName, newTaskInfo.itemName, newTaskInfo.taskName);
    } else {
      this.missingTaskErrorFeedback(`There is no previous task. We are in ${this.buildCurrentTaskLogString()}`, undefined);
    }
  }

  /**
   * Give feedback on missing task errors:
   *  - If we are in 'preview' controller mode raise an alert box with the message. 
   *  - In any case write a warning to the console.
   */
  missingTaskErrorFeedback = (message, newTestName) => {
    if (ServerCalls.runWithSimplifiedPreviewServer()) {
      const isTestSwitch = newTestName !== undefined && this.taskSequencer.getCurrentTaskInfo().testName !== newTestName;
      // eslint-disable-next-line no-alert
      alert(`The preview failed to do a task switch for this reason: ${message} ${isTestSwitch ? ". Task switches with explicit test names are not supported by the preview." : ". If the missing task exists in your item consider running a project preview."}`);
    }
    console.warn(message);
  }


  /**
   * Build a string describing our current task.
   */
  buildCurrentTaskLogString = () => Controller.buildLogString(this.taskSequencer.getCurrentTaskInfo());

  /**
   * Build a string representation of the task described by the given task info.
   * 
   * @param { test, item, task} currentTaskInfo 
   */
  static buildLogString(currentTaskInfo) {
    return (ServerCalls.runWithSimplifiedPreviewServer()
      ? `task ${currentTaskInfo.taskName}`
      : `task ${currentTaskInfo.taskName} from item ${currentTaskInfo.itemName} in test ${currentTaskInfo.testName}`);
  }

}
