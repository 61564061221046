import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoggedInView from '../components/LoggedInView';
import Login from './Login';
import PageEventsObserver from '../components/PageEventsObserver';

export default class App extends Component {

  constructor(props) {
    super(props);

    // Make ourselves available via the runtime context:
    const { runtime } = this.props;
    this.runtime = runtime;
    runtime.app = this;

    // Possible display modes are: 'waiting', 'showLogin', 'showTask'.
    this.state = {
      displayMode: 'waiting'
    }

    // init observer that traces page visibility state
    runtime.pageEventsObserver = new PageEventsObserver(runtime);
    runtime.pageEventsObserver.initObserver();

  }

  /**
   * Show the waiting page.
   */
  showWaiting = () => {
    this.setState(prevState => ({
      displayMode: 'waiting',
    }));
  }

  /**
   * Show the login dialog.
   * 
   * @param {String} fieldLabel The label to use for the field in the login dialog.
   * @param {function} loginDialogClosedHandler A callback that accepts the field value obtained by the login dialog.
   */
  showLogin = (titleLabel, fieldLabel, buttonLabel, loginDialogClosedHandler) => {
    this.setState(prevState => ({
      displayMode: 'showLogin',
      loginTitleLabel: titleLabel,
      loginFieldLabel: fieldLabel,
      loginButtonLabel: buttonLabel,
      loginDialogClosedHandler
    }));
  }

  /**
   * Show a running task.
   */
  showTask = (testName, itemName, taskName, settings, headerButtons, courseForNavigator, testsForNavigator) => {
    const taskId = this.runtime.taskManager.switchTask(testName, itemName, taskName);
    if (taskId === undefined) {
      console.info(`Could not switch to task ${testName}/${itemName}/${taskName}.`);
      return;
    }

    App.dumpTasksViewConfigurationToTraceLog(courseForNavigator, testsForNavigator, settings, headerButtons, this.runtime.traceLogBuffer);
    this.setState(prevState => ({
      displayMode: 'showTask',
      settings,
      headerButtons,
      courseForNavigator,
      testsForNavigator
    }));
  }

  /** 
   * The login screen is filled in and closed. -> Run callback with obtained field value.
   */
  handleLoginClick = (fieldValue) => {
    const { loginDialogClosedHandler } = this.state;
    loginDialogClosedHandler(fieldValue);
  }

  /**
   * Dump the configuration of the tasks view to the trace log.
   * 
   * @param {*} courseForNavigator The list of tests in the navigator menu.
   * @param {*} testsForNavigator The test configurations used by the navigator menu.
   * @param {*} settings The general settings used.
   * @param {*} headerButtons The configurable header buttons used.
   * @param {*} traceLogBuffer The trace log buffer to dump to.
   */
  static dumpTasksViewConfigurationToTraceLog(courseForNavigator, testsForNavigator, settings, headerButtons, traceLogBuffer) {
    traceLogBuffer.reportEvent('TasksViewVisible', new Date(), {
      settings,
      headerButtons,
      upperHeaderMenu: courseForNavigator,
      lowerHeaderMenu: testsForNavigator,
    })
  }

  renderWaiting = () => (
    // configNok -> wait for the configuration setting event to arrive:
    <div className="App">
      We are waiting to receive the test configuration...
      <br />
      <br />
      If this waiting takes more than a few seconds inform your test administrator.
    </div>
  );

  renderLogin = () => {
    const { loginTitleLabel, loginFieldLabel, loginButtonLabel } = this.state;
    return <Login handleLogin={this.handleLoginClick} titleLabel={loginTitleLabel} fieldLabel={loginFieldLabel} buttonLabel={loginButtonLabel} />
  };

  renderTask = () => {
    const { runtime } = this.props;

    const { settings, headerButtons, courseForNavigator, testsForNavigator } = this.state;

    return (
      <LoggedInView
        courses={courseForNavigator}
        tests={testsForNavigator}
        settings={settings}
        headerButtons={headerButtons}
        runtime={runtime}
      />
    );
  };


  render() {

    const { displayMode } = this.state;

    switch (displayMode) {
      case 'waiting':
        return this.renderWaiting();
      case 'showLogin':
        return this.renderLogin();
      case 'showTask':
        return this.renderTask();
      default:
        return this.renderWaiting();
    }
  }

}

App.propTypes = {
  runtime: PropTypes.object.isRequired,
}
