import UserDefPathHelper from "./UserDefPathHelper";

/**
 * A global text search mechanism. 
 */
export default class SearchManager {

  constructor(componentDirectory, statemachinesManager, traceLogBuffer) {
    this.componentDiretory = componentDirectory;
    this.statemachinesManager = statemachinesManager;
    this.traceLogBuffer = traceLogBuffer;
    this.pattern = undefined;
    this.scope = undefined;
    this.events = {
      success: undefined,
      failure: undefined
    }
  }

  // ----- public API --------------------------------------------------------------------------

  /**
   * Change the currently active search. 
   * 
   * @param {*} pattern The pattern to search for.
   * @param {*} scopeIndexPath The index path of the component that will keep the search result visible:
   *      The search result is 'highlighted' as long as this component is rendered. 
   * @param {*} scopeUserDefPath The user defined ID path of the component that will keep the search result visible.
   */
  setSearch = (pattern, scopeIndexPath, scopeUserDefPath, events) => {
    this.pattern = pattern;
    this.scopeIndexPath = scopeIndexPath;
    this.events.success = events.success;
    this.events.failure = events.failure;


    // TODO: CKI Update all relevant components that render currently and raise the state machine event accordingly.
    const matchFound = true;

    const eventToSend = matchFound ? this.events.success : this.events.failure;

    this.traceLogBuffer.reportEvent(
      'Search',
      new Date(),
      {
        indexPath: scopeIndexPath,
        userDefIdPath: scopeUserDefPath,
        userDefId: UserDefPathHelper.getLastUserDefIdFromPath(scopeUserDefPath),
        pattern,
        matchFound,
        eventTriggered: eventToSend
      }
    );

    if (eventToSend !== undefined) {
      this.statemachinesManager.triggerEvent(eventToSend);
    }

  }


  /**
   * Get the currently active search pattern. 
   * 
   * The method will return 'undefined' if no search is currently active.
   */
  getCurrentSearchPattern = () => {
    if (this.pattern === undefined || this.scope === undefined) {
      return undefined;
    }

    return this.componentDirectory.findComponent(this.scope) ? this.pattern : undefined;
  }

  // ----- private stuff --------------------------------------------------------------------------


}
