import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from '../PropTypesHelper';
import CbaSimpleTextField from '../CbaSimpleTextField';
import RenderingHelper from '../RenderingHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import IndexPathHelper from '../../state/IndexPathHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateManagerHelper from '../../state/StateManagerHelper';

/**
 * 
 */
export default class CbaCalculatorInput extends Component {

  static keyboardShortcutsRegistered = 0;

  componentDidMount() {
    RenderingHelper.onMount(this);

    const { config } = this.props;
    if (config.catchKeyboardInputEvents === true) {
      if (!CbaCalculatorInput.keyboardShortcutsRegistered) {
        document.addEventListener('keydown', this.keydown, false);
        document.addEventListener('paste', this.paste, false);
        // document.addEventListener('copy', this.copy, false);
      }
      CbaCalculatorInput.keyboardShortcutsRegistered += 1;
    }
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);

    const { config } = this.props;
    if (config.catchKeyboardInputEvents === true) {
      CbaCalculatorInput.keyboardShortcutsRegistered -= 1;
      if (!CbaCalculatorInput.keyboardShortcutsRegistered) {
        document.removeEventListener('keydown', this.keydown);
        document.removeEventListener('paste', this.paste);
        // document.removeEventListener('copy', this.copy);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  keydown = (event) => {
    // key press overrides the paste event
    if (event.ctrlKey) {
      return false;
    } else {
      event.preventDefault();
      const { runtime } = this.props;
      runtime.calculatorsManager.keypress(event.key);
      return true;
    }
  }

  paste = (event) => {
    event.preventDefault();
    const { runtime } = this.props;
    runtime.calculatorsManager.paste(event.clipboardData.getData("Text").toString());
  }

  static getSimpleTextFieldPath(path) {
    return IndexPathHelper.appendIndexToPageSegment(path, 0);
  }

  static createDelegateComponent(component, path, type) {
    const delegateComponent = StateManagerHelper.deepCopy(component);
    delegateComponent.config.userDefinedId = undefined;
    delegateComponent.type = type;
    // Set the new value for the CbaSimpleTextField that we use for display:
    const delegatePath = CbaCalculatorInput.getSimpleTextFieldPath(path);
    return {
      delegatePath, delegateComponent
    };
  }

  render() {
    const { config, runtime, path, orientation } = this.props;

    const newValue = runtime.calculatorsManager.getDisplayingValue();

    // Make the new value available for TermEvaluator operators using user defined IDs: 
    const myState = ComponentStateHelper.getState(this);
    StateAttributeAccess.setTextValue(myState, newValue);
    ComponentStateHelper.registerState(this, myState);


    const { delegatePath, delegateComponent } = CbaCalculatorInput.createDelegateComponent({
      config
    }, path, "CbaSimpleTextField");
    const simpleTextFieldState = runtime.componentStateManager.findOrBuildStateForPathId(delegatePath, runtime);
    StateAttributeAccess.setTextValue(simpleTextFieldState, newValue);
    runtime.componentStateManager.registerStateByPathId(delegatePath, simpleTextFieldState);


    return (
      <CbaSimpleTextField
        runtime={runtime}
        path={delegatePath}
        config={delegateComponent.config}
        orientation={orientation}
      />
    );
  }

}

CbaCalculatorInput.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        catchKeyboardInputEvents: PropTypes.bool.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired
}
