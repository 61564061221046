
/**
 * A catalog of all timed events of a single statemachine.
 * 
 * A timed event in our catalog always keeps these attributes:
 *  - name
 *  - trigger interval
 *  - accepting states list
 * While a timed event is 'running' it also keeps these attributes
 *  - handle of the 'timeout' scheduled on the global 'window'
 *  - planned point in time for the 'timeout' to trigger
 * While a timed event is 'paused' it also keeps these attributes:
 *  - remaining milli seconds when 'pause' state was entered
 * 
 * The lifecycle states of an event entry are:
 *  - stopped: The timed event is declared but was stopped or was never started at all.
 *  - running: The timed event was started and now waits for the timeout interval to run out.
 *  - paused: A previously started event was paused, i.e. it has interrupted the run down of the timeout interval.
 * 
 * The lifecycle state changes are:
 * - An event is created in the 'stopped' state: name, trigger interval and accepting states list are given explicitly.
 * - Starting an event establishes a timeout on the global window and stores the planned trigger time (planned time is 'now' + 'trigger interval').
 * - Pausing an event clears the timeout on the global window and stores the remaining interval (by comparing the current time with the planned trigger time).
 * - Resuming an event establishes a timeout on the global window with a new planned trigger time (planned time is 'now' + 'remaining millis').
 * - Stopping an event clears the timeout on the global window (if 'running') and clears the remaining millis (if 'paused').
 * - A triggering timeout sets the event back to 'stopped' state before triggering the statemachine action. 
 * 
 * An event in state 'running' or 'paused' is filtered as 'scheduled', an event in 'stopped' state is 'unscheduled'.
 */
export default class TimedEventsCatalog {

  constructor() {
    this.timedEventsList = [];
  }

  // ------------- public API -------------------------------------------------------------------------

  /**
   * Put a timed event entry into our catalog.
   * 
   * The method will update the entry if an entry for the given name exists already.
   * 
   * @param {String} name The name of the timed event.
   * @param {Number} triggerInterval The interval (in milliseconds) that will elapse between starting the timer and triggering the event.
   * @param {[String]} acceptingStatesList The list of state machine states accepting the timed event.
   */
  putEntry = (name, triggerInterval, acceptingStatesList) => {
    const oldEntry = this.getEntry(name);
    if (oldEntry !== undefined) {
      console.warn(`Updating already existing timed event with name ${name}`);
      oldEntry.triggerInterval = triggerInterval;
      oldEntry.acceptingStatesList = acceptingStatesList;
    } else {
      this.timedEventsList.push(TimedEventsCatalog.buildTimedEventEntry(name, triggerInterval, acceptingStatesList, undefined, undefined, undefined));
    }
  }


  /**
   * Find a timed event entry in our catalog by event name.
   * 
   * @param {String} name The name of the timed event.
   */
  getEntry = name => this.timedEventsList.find(entry => entry.name === name);

  /**
   * Get an excerpt of the event entry for logging purposes.
   * 
   * @param {*} eventEntry The event entry to get the excerpt from.
   * @param {*} now The current point in time as base for remaining time calculation.
   */
  static getEventData(eventEntry, now) {
    const isRunning = eventEntry.scheduledTimeoutHandle !== undefined;
    const remainingInterval = isRunning ? eventEntry.scheduledTriggerTime.getTime() - now.getTime() : eventEntry.remainingInterval;
    return {
      totalTime: eventEntry.triggerInterval,
      isRunning,
      remainingTime: remainingInterval
    }
  }

  /**
   * Is the given event paused currently?
   * 
   * @param {*} eventEntry 
   */
  static isPaused(eventEntry) {
    return eventEntry.remainingInterval !== undefined;
  }

  /**
   * Find the entries of the timed events that the given state accepts and that are not scheduled currently.
   * 
   * @param {String} stateName The name of the state that must accept the matching events.
   */
  findUnscheduledEventsByState(stateName) {
    return this.timedEventsList.filter(
      eventEntry => eventEntry.acceptingStatesList.includes(stateName)
      && eventEntry.scheduledTimeoutHandle === undefined
      && eventEntry.scheduledTriggerTime === undefined
      && eventEntry.remainingInterval === undefined
    );
  }

  /**
   * Find the entries of the timed events that the given state accepts and that are scheduled currently.
   * 
   * @param {String} stateName The name of the state that must accept the matching events.
   */
  findScheduledEventsByState(stateName) {
    return this.timedEventsList.filter(
      eventEntry => eventEntry.acceptingStatesList.includes(stateName)
      && (
        eventEntry.scheduledTimeoutHandle !== undefined
        || eventEntry.scheduledTriggerTime !== undefined
        || eventEntry.remainingInterval !== undefined)
    );
  }


  /**
   * Find the entries of all timed events that are scheduled currently.
   */
  findScheduledEvents() {
    return this.timedEventsList.filter(
      eventEntry => eventEntry.scheduledTimeoutHandle !== undefined
      || eventEntry.scheduledTriggerTime !== undefined
      || eventEntry.remainingInterval !== undefined
    );
  }

  /**
   * Get a map eventName -> interval of the current event interval settings.
   */
  getTimerIntervals = () => {
    const result = {};
    this.timedEventsList.forEach((entry) => {
      result[entry.name] = entry.triggerInterval;
    });
    return result;
  }


  /**
   * (Re)start the timed event. 
   * 
   * The method will schedule the event with a full trigger interval.
   * 
   * @param {*} timedEventEntry 
   * @param {*} statemachine 
   */
  startTimedEvent(timedEventEntry, statemachine) {
    this.scheduleTimedEvent(timedEventEntry, timedEventEntry.triggerInterval, statemachine);
  }

  /**
   * Resume the timed event. 
   * 
   * The method will schedule the event with the remaining interval
   * as calculated when the event was paused.
   * 
   * @param {*} timedEventEntry 
   * @param {*} statemachine 
   */
  resumeTimedEvent(timedEventEntry, statemachine) {
    // Don't try to resume an event that is not paused (should not happen):
    if (timedEventEntry.remainingInterval !== undefined) {
      this.scheduleTimedEvent(timedEventEntry, timedEventEntry.remainingInterval, statemachine);
    }
  }

  /**
   * Stop the running timer for the given timer info structure.
   * 
   * @param {*} timedEventEntry 
   */
  stopTimedEvent(timedEventEntry) {
    // The event might be 'paused': handle is undefined but remainingInterval is set.
    // -> Reset to fully stopped state.
    if (timedEventEntry.scheduledTimeoutHandle !== undefined) {
      this.clearTimeoutHook(timedEventEntry.scheduledTimeoutHandle);
    }
    TimedEventsCatalog.setScheduledDataInTimedEventEntry(undefined, undefined, undefined, timedEventEntry);
  }

  /**
   * Pause the running timer for the given timer info structure.
   * 
   * @param {*} timedEventEntry The timed event entry to modify.
   * @param {Date} now The current time (as base to calculate the remaining time).
   */
  pauseTimedEvent(timedEventEntry, now) {
    // There is nothing to do if the event is paused already or is not scheduled at all:
    if (timedEventEntry.scheduledTimeoutHandle !== undefined) {
      this.clearTimeoutHook(timedEventEntry.scheduledTimeoutHandle);
      const remainingInterval = timedEventEntry.scheduledTriggerTime.getTime() - now.getTime();
      TimedEventsCatalog.setScheduledDataInTimedEventEntry(undefined, undefined, remainingInterval, timedEventEntry);
    }
  }

  /**
   * Change the trigger interval in a timed event entry.
   * 
   * @param {Number} triggerInterval The new value for the trigger interval.
   * @param {*} entryToModify The timer entry that the method will modify.
   */
  static setTriggerIntervalInEventEntry(triggerInterval, entryToModify) {
    entryToModify.triggerInterval = triggerInterval;
  }


  // ------------ private stuff ----------------------------------------------------------------------------

  /**
   * Schedule the given event to trigger at the given interval from now. 
   * 
   * Due to the startInterval parameter we can use this method to restart 
   * an event completely or to just resume the event after a pause.
   * 
   * @param {*} timedEventEntry The event to schedule.
   * @param {*} startInterval The interval (in milliseconds) from now to the triggering point in time.
   * @param {*} statemachine The statemachine providing the 'now' time and the callback to be triggered.
   */
  scheduleTimedEvent(timedEventEntry, startInterval, statemachine) {

    // Determine point in time to trigger event action:
    const now = statemachine.getNow();
    const targetTime = new Date(now.getTime() + startInterval);
    if (targetTime === undefined) {
      console.error(`Invalid target time to schedule event: ${timedEventEntry.name} with start interval ${startInterval}`);
      return;
    }

    // Check and normalize event scheduling status: 
    if (timedEventEntry.scheduledTimeoutHandle !== undefined) {
      console.warn(`Rescheduling scheduled event: ${timedEventEntry.name} from ${timedEventEntry.scheduledTriggerTime} to ${targetTime}`);
      this.clearTimeoutHook(timedEventEntry.scheduledTimeoutHandle);
    }

    // Schedule event in global event loop:
    const timeoutId = this.setTimeoutHook((eventEntry) => {
      // Drop schedule handler in event entry in timed events catalog as soon as scheduled event is triggered.
      // Do this before triggering the event on the state machine since this might restart the timeout!
      TimedEventsCatalog.setScheduledDataInTimedEventEntry(undefined, undefined, undefined, eventEntry);
      // Actually trigger the event. 
      statemachine.triggerEvent(eventEntry.name);
    },
    startInterval,
    timedEventEntry);

    // Set scheduling data in event entry:
    TimedEventsCatalog.setScheduledDataInTimedEventEntry(timeoutId, targetTime, undefined, timedEventEntry);
  }

  /**
   * Update the scheduling related data in a timed event entry.
   * 
   * @param {*} scheduledTimeoutHandle The handle to the object keeping track of the scheduled action that will trigger the event.
   * @param {Date} scheduledTriggerTime The point in time when the timer will trigger the event the next time.
   * @param {Number} remainingInterval The new value for the remaining interval.
   * @param {*} entryToModify The timer entry that the method will modify.
   */
  static setScheduledDataInTimedEventEntry(scheduledTimeoutHandle, scheduledTriggerTime, remainingInterval, entryToModify) {
    entryToModify.scheduledTimeoutHandle = scheduledTimeoutHandle;
    entryToModify.scheduledTriggerTime = scheduledTriggerTime;
    entryToModify.remainingInterval = remainingInterval;
  }


  /**
   * Create a timed event entry to be kept in our catalog.
   * 
   * @param {String} name The name of the timed event.
   * @param {Number} triggerInterval The interval (in milliseconds) that will elapse between starting the timer and triggering the event.
   * @param {[String]} acceptingStatesList The list of state machine states accepting the timed event.
   * @param {*} scheduledTimeoutHandle The handle to the object keeping track of the scheduled action that will trigger the event.
   * @param {Date} scheduledTriggerTime The point in time when the timer will trigger the event the next time.
   * @param {Number} remainingInterval The rest of the triggerInterval (in milliseconds) remaining once the state machine resumes operation after a pause. 
   * This is undefined while the state machine is not paused.
   */
  static buildTimedEventEntry(name, triggerInterval, acceptingStatesList, scheduledTimeoutHandle, scheduledTriggerTime, remainingInterval) {
    const statesList = acceptingStatesList === undefined ? [] : acceptingStatesList.map(value => value);
    return {
      name,
      triggerInterval,
      acceptingStatesList: statesList,
      scheduledTimeoutHandle,
      scheduledTriggerTime,
      remainingInterval
    }
  }


  /**
   * Test helper: Mock hook for window.clearTimeout calls.
   */
  clearTimeoutHook = (handle) => {
    window.clearTimeout(handle);
  }

  /**
   * Test helper: Mock hook for window.setTimeout calls.
   */
  setTimeoutHook = (callback, interval, argument) => window.setTimeout(callback, interval, argument);

}
