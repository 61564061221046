import EvaluatorHelper from './EvaluatorHelper';
import ExpressionEvaluator from './ExpressionEvaluator';
import StateManagerHelper from '../../state/StateManagerHelper';

/* 
  This evaluator calculates the current value that the calculator should display
  and then send commands to history and input display to render the new value. 
  The commands look like a depedendency between evaluator and history/input display. 
  (In some case the evaluator knows that sending AC will reset the history -> 
  if possible remove this dependency and let maybe history handle when to reset its history)
 */
export default class Evaluator {

  // a bit too much maybe to get operations => create an evaluator
  static getSupportedOperations = () => Object.keys(new Evaluator().operations);

  constructor(displayWidth, angle) {
    this.isError = false;
    this.memory = {};
    this.expressions = [];
    this.brackets = 0; // count of current open brackets TODO: maybe it can be removed and use this.expressions.length instead
    this.expressions[0] = new ExpressionEvaluator(); // TODO: can here be use TermEvaluator instead
    // contains the value that will have to be displayed
    this.resultBuffer = '0';
    // contains all key inputs until = is hit; when at next key this buffer is reset
    // usefull for backspace and verifing in some case what was the previous key 
    // structure {key, isEndOfTerm, shouldResetHistoryIfDigitOnNextKey}
    this.keysBuffer = [];

    this.calcSettings(displayWidth, angle);

    // each function receives an object { key, operand, dispVal } and returns the new evaluated value
    this.operations = {
      sin: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalsin))),
      "sin-1": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalsin1))),
      "1/x": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.eval1perx))),
      cos: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalcos))),
      "cos-1": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalcos1))),
      x2: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalx2))),
      x3: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalx3))),
      "2√": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.eval2root))),
      "3√": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.eval3root))),
      "x!": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalfactorial))),
      tan: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaltan))),
      "tan-1": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaltan1))),
      ex: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalex))),
      ln: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalln))),
      log2: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evallog2))),
      log10: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evallog10))),
      AC: this.evalOperation(this.evalAC),
      C: this.evalOperation(this.evalC),
      π: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalPI))),
      back: this.evalOperation(this.evalBackspace),
      0: this.evalOperation(this.evalDigitOrDecimalPoint),
      1: this.evalOperation(this.evalDigitOrDecimalPoint),
      2: this.evalOperation(this.evalDigitOrDecimalPoint),
      3: this.evalOperation(this.evalDigitOrDecimalPoint),
      4: this.evalOperation(this.evalDigitOrDecimalPoint),
      5: this.evalOperation(this.evalDigitOrDecimalPoint),
      6: this.evalOperation(this.evalDigitOrDecimalPoint),
      7: this.evalOperation(this.evalDigitOrDecimalPoint),
      8: this.evalOperation(this.evalDigitOrDecimalPoint),
      9: this.evalOperation(this.evalDigitOrDecimalPoint),
      "+/–": this.evalPlusMinus,
      ".": this.evalDecimalPoint,
      "(": this.evalEndOfTerm(this.evalOperation(this.evalLeftBracket)),
      ")": this.evalEndOfTerm(this.evalOperation(this.evalRightBracket)),
      "×": this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      "÷": this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      "+": this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      "–": this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      "=": this.evalEndOfTerm(this.evalOperation(this.evalEquals)),
      log: this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      "x√y": this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      yx: this.evalEndOfTerm(this.evalOperation(this.evalOperationExpectingTerm)),
      operandyx: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaloperandyx))),
      "operandx√y": this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaloperandxrooty))),
      operandex: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaloperandex))),
      operandlog: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaloperandlog))),
      mc: this.evalEndOfTerm(this.evalOperation(this.evalMC)),
      ms: this.evalEndOfTerm(this.evalOperation(this.evalMSave)),
      "m+": this.evalEndOfTerm(this.evalOperation(this.evalMAdd)),
      "m-": this.evalEndOfTerm(this.evalOperation(this.evalMMinus)),
      mr: this.evalOperation(this.evalMR),
      sinh: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalsinh))),
      'sinh-1': this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalsinh1))),
      cosh: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalcosh))),
      'cosh-1': this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalcosh1))),
      tanh: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaltanh))),
      'tanh-1': this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evaltanh1))),
      '2x': this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.eval2x))),
      Rand: this.evalEndOfTerm(this.evalShouldResetHistoryIfDigitOnNextKey(this.evalOperation(this.evalRandom))),
    }
  }

  calcSettings = (displayWidth, angle) => {
    this.angle = angle || EvaluatorHelper.ANGLE_DEGREE;
    this.bigger = EvaluatorHelper.isBigger(displayWidth);
  }

  getFullState = () => {
    const state = {};
    state.expressions = StateManagerHelper.deepCopy(this.expressions.map(expression => expression.getFullState()));
    state.isError = this.isError;
    state.memory = StateManagerHelper.deepCopy(this.memory);
    state.brackets = this.brackets;
    state.resultBuffer = this.resultBuffer;
    state.keysBuffer = StateManagerHelper.deepCopy(this.keysBuffer);
    state.angle = this.angle;
    state.bigger = this.bigger;
    return state;
  }

  restoreState = (state) => {
    this.expressions = state.expressions.map((s) => {
      const calc = new ExpressionEvaluator();
      calc.restoreState(s);
      return calc;
    });
    this.isError = state.isError;
    this.memory = state.memory;
    this.brackets = state.brackets;
    this.resultBuffer = state.resultBuffer;
    this.keysBuffer = state.keysBuffer;
    this.angle = state.angle;
    this.bigger = state.bigger;
  }

  getMem = (memIdx) => {
    memIdx = memIdx || 0;
    if (this.memory[memIdx]) {
      return Math.round(Number(this.memory[memIdx]))
    }
    return 0;
  }

  // naive implementation - same in library
  paste = (text) => {
    this.initRenderCommands();

    const newValue = Number.parseFloat(text).toString();
    this.setResultBuffer(newValue)
    this.sendToRender(newValue);

    return this.renderCommands;
  }

  // TODO: maybe it should contain something more dynamic than 1 operand
  // e.g min function contains variable number of parameters
  evalKey = (key, operand) => {
    this.initRenderCommands();

    this.evalKeyInternal(key, operand);

    return this.renderCommands;
  }

  // private 

  initRenderCommands = () => {
    this.renderCommands = {
      render: [],
      history: []
    }
  }

  evalKeyInternal = (key, operand) => {

    if (this.isError && !this.isKeyAC(key) && !this.isKeyC(key)) {
      return;
    }

    this.isError = false;

    this.pushKeyToKeysBuffer(key);

    // current value
    const dispVal = this.resultBuffer;

    this.operations[key]({
      key,
      operand,
      dispVal
    });

  }

  /*
    Evaluates the functions and send events to render and history about the new value.
    Each functionForEval should return the new current value of the calculator.
    If sending events to render/history is to be avoided the functionForEval should throw an exception.
   */
  evalOperation = (functionForEval) => {
    const evalOperationInternal = ({ dispVal, key, operand }) => {
      try {
        // it changes the  type of the value => maybe some function up the stream needs it originally
        let value = functionForEval({
          key,
          operand,
          dispVal
        });
        value = value === undefined ? undefined : value.toString();

        this.setResultBuffer(value);
        this.sendToRender(value);
        this.sendToRenderHistory(key, operand);

        return value;
      } catch (ex) {
        // ignore it - if someone wants to ignore
        return undefined;
      }
    }
    return evalOperationInternal;
  }

  /*
   Sets to the current key that  if the next key will be a digit it should resets the entire history.
   E.g. 90, sin, 23 => 23 resets the history. (TODO: i think this behaviour should be in history renderer)
  */
  evalShouldResetHistoryIfDigitOnNextKey = (functionForEval) => {
    const evalShouldResetHistoryIfDigitOnNextKeyInternal = (dispValKeyOperand) => {
      const value = functionForEval(dispValKeyOperand);

      const currentKeyFromKeysBuffer = this.getCurrentKey();
      currentKeyFromKeysBuffer.shouldResetHistoryIfDigitOnNextKey = true;

      return value;
    }

    return evalShouldResetHistoryIfDigitOnNextKeyInternal;
  }

  /*
   Sets to the current key that it represents the end of a term.
  */
  evalEndOfTerm = (functionForEval) => {
    const evalEndOfTermInternal = (dispValKeyOperand) => {
      const value = functionForEval(dispValKeyOperand);

      const currentKeyFromKeysBuffer = this.getCurrentKey();
      currentKeyFromKeysBuffer.isEndOfTerm = true;

      return value;
    }

    return evalEndOfTermInternal;
  }

  setResultBuffer = (value) => {
    if (value.match(/NaN|Inf|Error/)) {
      this.resultBuffer = '0';
      this.isError = true;
    } else {
      this.resultBuffer = value;
    }
  }

  sendToRender = (value, args) => {
    this.renderCommands.render.push({
      value,
      args
    });
  }

  // operand - is the operand used in immediate operations(like x3 => operand is 3) 
  sendToRenderHistory = (key, operand) => {
    // there was an error in evaluation
    if (this.isError) {
      this.renderCommands.history.push({
        key: "AC"
      });
    } else {
      this.renderCommands.history.push({
        key,
        operand,
        value: this.resultBuffer
      });
    }
  }

  pushKeyToKeysBuffer = (key) => {

    // add the key to buffer
    this.keysBuffer.push({
      key
    });

    const { key: previousKey } = this.getPreviousKey();
    // if previous was equals or current is AC
    if ((previousKey === '=' && key !== '=') || this.isKeyAC(key)) {
      this.keysBuffer = [{
        key
      }];
    }
  }

  isKeyAC = key => key === "AC";

  isKeyC = key => key === "C";

  evalC = ({ key }) => "0"

  evalAC = ({ key }) => {
    // this is buggy => should AC  cancel all expressions?
    this.expressions[this.brackets].reset();
    return "0"
  }

  // by far the most complicated function
  evalBackspace = () => {
    // remove "back" from keysbuffer - no reason - adapt the indexs if you leave it in keysBuffer
    this.keysBuffer.pop();

    const { isEndOfTerm } = this.getPreviousKey();
    if (isEndOfTerm) {
      throw Error("nothing to delete");
    }
    let currentValue = this.resultBuffer;
    if (this.getCurrentKey().key === '+/–') {
      // sends to evaluation in order to reset the sign (cool)
      this.evalKeyInternal('+/–');
      this.keysBuffer.pop();// removes the +/- from keysBuffer
      currentValue = this.resultBuffer
    } else if (this.resultBuffer.match(/-\d$/) || this.resultBuffer.match(/^\d$/)) {
      // if the current value is one digit value
      this.keysBuffer.pop();
      currentValue = "0";
    } else {
      currentValue = currentValue.substring(0, currentValue.length - 1);
    }
    this.keysBuffer.pop();
    // if after deletion there is decimal point => delete that also
    if (this.getCurrentKey().key === '.') {
      this.keysBuffer.pop();
      currentValue = currentValue.substring(0, currentValue.length - 1);
    }
    return currentValue;
  }

  evalEquals = ({ key, dispVal }) => {
    while (this.brackets > -1) {
      this.setResultBuffer(dispVal = this.expressions[this.brackets].calc('=', dispVal));
      this.brackets -= 1;
    }
    this.brackets = 0;

    return dispVal;
  }

  evalLeftBracket = ({ key, dispVal }) => {
    this.brackets += 1;
    this.expressions[this.brackets] = new ExpressionEvaluator();
    return dispVal;
  }

  evalRightBracket = ({ key, dispVal }) => {
    if (this.brackets > 0) {
      dispVal = this.expressions[this.brackets].calc('=', dispVal);
      this.brackets -= 1;
    }
    return dispVal;
  }

  evalMC = ({ dispVal, operand }) => {
    delete this.memory[operand];
    return dispVal;
  }

  evalMAdd = ({ dispVal, operand }) => {
    this.doMemoryOperation(operand, '+', dispVal);
    return dispVal;
  }

  evalMMinus = ({ dispVal, operand }) => {
    this.doMemoryOperation(operand, '-', dispVal);
    return dispVal;
  }

  evalMSave = ({ dispVal, operand }) => {
    this.memory[operand] = dispVal;
    return dispVal;
  }

  evalMR = ({ operand }) => {
    // calling this as it is recursive call inside
    this.evalEndOfTerm(() => { })();

    if (this.memory[operand] !== undefined) {
      const mem = `${this.getMemoryValue(operand)}`;
      for (let i = 0; i < mem.length; i += 1) {
        this.evalKeyInternal(mem[i]);
      }
    }
  }

  getMemoryValue = location => this.memory[location] || 0;

  doMemoryOperation = (location, operation, operand) => {
    const calculator = new ExpressionEvaluator();
    calculator.calc(operation, this.getMemoryValue(location));
    const res = calculator.calc('=', operand);
    this.memory[location] = res;
  }

  eval1perx = ({ dispVal }) => (1 / dispVal);

  evaloperandyx = ({ dispVal, operand }) => dispVal ** operand;

  evalx2 = ({ dispVal }) => dispVal ** 2;

  evalx3 = ({ dispVal }) => dispVal ** 3;

  evalfactorial = ({ dispVal }) => EvaluatorHelper.fak(dispVal);

  eval2root = ({ dispVal }) => Math.sqrt(dispVal);

  eval3root = ({ dispVal }) => EvaluatorHelper.nthroot(dispVal, 3);

  evaloperandxrooty = ({ dispVal, operand }) => EvaluatorHelper.nthroot(dispVal, operand);

  evalsin = ({ dispVal }) => {
    if (!this.deg() && Math.abs(dispVal) === Math.PI) {
      return 0;
    } else {
      return Math.sin(dispVal * (this.deg() ? Math.PI / 180 : 1))
    }
  }

  // Math.asin(dispVal) * (this.deg() ? 180 / Math.PI : 1);
  evalsin1 = ({ dispVal }) => 1 / this.evalsin({
    dispVal
  });

  evalcos = ({ dispVal }) => Math.cos(dispVal * (this.deg() ? Math.PI / 180 : 1));

  // Math.acos(dispVal) * (this.deg() ? 180 / Math.PI : 1)
  evalcos1 = ({ dispVal }) => 1 / this.evalcos({
    dispVal
  });

  evaltan = ({ dispVal }) => {
    if (this.deg() && Math.abs(dispVal) === Math.PI) {
      return 0;
    } else {
      return Math.tan(dispVal * (this.deg() ? Math.PI / 180 : 1));
    }
  }

  // Math.atan(dispVal) * (this.deg() ? 180 / Math.PI : 1)
  evaltan1 = ({ dispVal }) => 1 / this.evaltan({
    dispVal
  });

  evalln = ({ dispVal }) => Math.log(dispVal);

  evallog2 = ({ dispVal }) => Math.log(dispVal) / Math.log(2);

  evaloperandlog = ({ dispVal, operand }) => Math.log(dispVal) / Math.log(operand);

  evallog10 = ({ dispVal }) => Math.log(dispVal) / Math.log(10);

  evalsinh = ({ dispVal }) => (((Math.E ** dispVal) - (Math.E ** -dispVal)) / 2);

  evalsinh1 = ({ dispVal }) => Math.log(+dispVal + Math.sqrt(1 + (dispVal ** 2)));

  evalcosh = ({ dispVal }) => (((Math.E ** dispVal) + (Math.E ** -dispVal)) / 2);

  evalcosh1 = ({ dispVal }) => 2 * Math.log(Math.sqrt((+dispVal + 1) / 2) + Math.sqrt((+dispVal - 1) / 2));

  evaltanh = ({ dispVal }) => {
    const e1 = (Math.E ** dispVal);
    const e2 = (Math.E ** -dispVal);
    return (e1 - e2) / (e1 + e2);
  }

  evaltanh1 = ({ dispVal }) => (Math.log(+dispVal + 1) - Math.log(1 - dispVal)) / 2;

  // e pow whatever user chooses
  evalex = ({ dispVal }) => Math.exp(dispVal);

  // e^2 power is fixed
  evaloperandex = ({ operand }) => Math.exp(operand);

  eval2x = ({ dispVal }) => (2 ** (dispVal));

  evalPI = ({ dispVal }) => Math.PI;

  evalRandom = ({ dispVal }) => Math.random();

  deg = () => this.angle === EvaluatorHelper.ANGLE_DEGREE;

  evalDigitOrDecimalPoint = ({ dispVal, key }) => {
    const { shouldResetHistoryIfDigitOnNextKey, isEndOfTerm } = this.getPreviousKey();
    // resets the current value if it is endofterm (e.g a brackets, a sin operation) 
    // or if there is nothing in the keysbuffer except this key (e.g. keysbuffer was reset in case previous it was equals)
    if (isEndOfTerm || this.keysBuffer.length === 1) {
      dispVal = '0';
    }
    if (this.willOverflow(dispVal, key) || this.isAlreadyDecimalPoint(dispVal, key)) {
      // remove last key inserted in buffer
      this.keysBuffer.pop();
      throw Error(`cannot add key ${key}`)
    }

    // resets the history after an immediate function
    // e.g user does 90 , sin, 23 => 23 will reset the history
    if (shouldResetHistoryIfDigitOnNextKey) {
      this.sendToRenderHistory('AC');
    }

    return (dispVal + key).replace(/^(-)*?0(\d)$/, '$1$2');
  }

  evalDecimalPoint = ({ dispVal, key }) => {
    let value;
    try {
      value = this.evalDigitOrDecimalPoint({
        dispVal,
        key
      });
      this.setResultBuffer(value);
      this.sendToRender(value, {
        doNotRemoveDecimalPoint: true
      });
      this.sendToRenderHistory(key, undefined);
    } catch (err) {
      value = dispVal;
    }

    return value;
  }

  willOverflow = (dispVal, key) => (Math.abs(+(dispVal + key)) > (this.bigger ? 1e15 : 1e9)
    || dispVal.replace(/^-/, '').length > 15
    || (dispVal.replace('-', '').replace(/\./g, '').length > (this.bigger ? 14 : 8)));

  isAlreadyDecimalPoint = (dispVal, key) => dispVal.match(/\.|e\+/) && key === '.';

  /**
   * Supposes that the current key was already added to keysBuffer.
   */
  getPreviousKey = () => this.keysBuffer[this.keysBuffer.length - 2] || {};

  /**
   * Returns from keysbuffer last entry.
   */
  getCurrentKey = () => this.keysBuffer[this.keysBuffer.length - 1] || {};

  evalPlusMinus = ({ dispVal, key }) => {
    const { isEndOfTerm } = this.getPreviousKey();
    if (isEndOfTerm) {
      dispVal = '0';
    }

    // change the sign
    const value = !(dispVal.replace(/e[+|-]/, '')).match('-')
      ? `-${dispVal}` : dispVal.replace(/^-/, '');

    // same problem as for decimal point -> renderer removes the . if exists
    this.setResultBuffer(value);
    this.sendToRender(value, {
      doNotRemoveDecimalPoint: true
    });
    this.sendToRenderHistory(key, undefined);

    return value;
  }

  evalOperationExpectingTerm = ({ dispVal, key }) => {
    const { key: lastKey } = this.getPreviousKey()
    if (lastKey === '(' && key.match(/^[+|–|×|*]+$/)) dispVal = 0;
    // push a new val expression evaluator
    return (this.expressions[this.brackets].calc(key, dispVal));
  }

}
