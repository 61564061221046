/**
 * Utility class meant to store actions that need to be applied over components that were not rendered yet.
 * We store the wanted actions in map where the component indexPath is the key 
 * and the list of actions to be applied once the component was render is the value.  
 */
export default class ActionRegister {

  constructor() {
    this.actionsMap = new Map();
  }

  registerAction = (indexPath, action) => {
    let actions = [];
    if (this.actionsMap.has(indexPath)) {
      actions = this.actionsMap.get(indexPath);
    }

    actions.push(action);
    this.actionsMap.set(indexPath, actions);
  }

  hasActionsRegisteredForPath = indexPath => this.actionsMap.has(indexPath) && this.actionsMap.get(indexPath).length !== 0;

  getRegisteredActionsForPath = indexPath => this.actionsMap.get(indexPath);

  clearRegisteredActionsForPath = indexPath => this.actionsMap.delete(indexPath);

}
