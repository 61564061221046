import React from 'react';
import Utils from '../../utils/Utils';
import IndexPathHelper from '../../state/IndexPathHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import PathTranslationHelper from '../../state/PathTranslationHelper';
import { CbaTreeHeader } from './DataGridRenderers';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import TreeTraceHelper from './TreeTraceHelper';
import CommonActionsHelper from '../CommonActionsHelper';

/**
 * Helper class for CbaTree, CbaTreeView & CbaTreeChildArea:
 * 
 * - contains constans
 * 
 * - contains static methods
 *
 *  ____________________
 * | TREE   | TREE VIEW |
 * |________|___________|
 * |  TREE CHILD AREA   |
 * |____________________|
 * 
 */
export default class TreeUtils {

  static
    const = {
      COLUMN_HEIGHT: 34,
      ROW_HEIGHT: 25,
      DEFAULT_COLUMN: {
        label: "default",
        isPrimary: true,
        isDefault: true
      }
    }

  /* Static string builder functions */

  static buildCellPath = (rowPath, columnPath) => `${rowPath}_${columnPath}`;

  static buildColumnId = (path, index) => `${path}--${index}`;

  static generateNodePathId = (node, parentNode) => `${parentNode.nodePathId}_${node.userDefinedId}-${parentNode.children + 1}`;

  static generateNodePathIdChild = (node, parentNode) => `${parentNode.nodePathId}_${node.nodePathId.split("_").slice(-1).pop()}`;

  static getTreeEventChangeName = treePath => `${treePath}-TreeChangeEvent`;

  static getTreePathFromColumnPath = columnPath => columnPath.split("--")[0];


  /* Initialisation functions */
  static initTreeNodes(rootNodes, nodeTypes, path, runtime, treeSettings) {
    rootNodes = Utils.deepCopy(rootNodes);

    TreeUtils.resetPathsToNodes(rootNodes, path);

    TreeUtils.parseNodes(rootNodes, (node) => {
      const nodeType = TreeUtils.getNodeType(nodeTypes, node.nodeType);

      node.cells = node.cells.map((cell, index) => {
        cell.columnId = TreeUtils.buildColumnId(path, index);
        return cell;
      });

      node.isLeaf = nodeType.childTypes.length === 0; // leaf is defined by not having the possiblity of children.
      node.imagePath = CommonConfigHelper.getProperResourcePath(nodeType.image, runtime);
      node.expanded = TreeUtils.isNodeExpandedAtInit(node, nodeType, treeSettings.startCollapsed);
      node.children = node.nodes.length - 1; // needed to compute the userDefPath of pasted/new nodes
      return true;
    });

    return rootNodes;
  }

  static initTreeColumns(columns, path) {
    columns = Utils.deepCopy(columns);

    if (columns.length === 0) {
      columns.push(TreeUtils.const.DEFAULT_COLUMN);
    }

    return columns.map((column, index) => {
      column.key = TreeUtils.buildColumnId(path, index);
      column.name = column.label;

      if (column.width === 0) {
        delete column.width;
      }

      return column
    });

  }

  static initTreeState(columns) {
    const modifiedColumns = {}
    columns.forEach((column, index) => {
      modifiedColumns[column.key] = {
        order: index,
        width: column.width
      }
    });

    return {
      modifiedColumns
    }
  }

  /* Column operations */
  static handleColumnResize(columnKey, newWidth, instance) {
    const state = ComponentStateHelper.getState(instance);
    const treeState = StateAttributeAccess.extractTreeState(state) || {};

    treeState.modifiedColumns[columnKey].width = newWidth;

    StateAttributeAccess.setTreeState(state, treeState);
    ComponentStateHelper.registerState(instance, state);
  }

  static handleColumnMove(displayDroppedColumn, draggedColumnId) {
    const { runtime, key, parentPath } = displayDroppedColumn;
    const treePath = TreeUtils.getTreePathFromColumnPath(key);

    const parentInstance = runtime.componentDirectory.findComponent(parentPath);
    const parentPathState = ComponentStateHelper.getState(parentInstance);
    const parentTreeState = StateAttributeAccess.extractTreeState(parentPathState) || {};
    const { modifiedColumns } = parentTreeState;

    const draggedColumn = modifiedColumns[draggedColumnId];
    const droppedColumn = modifiedColumns[displayDroppedColumn.key];

    // figure out direction of dragging
    const isDraggedToStart = draggedColumn.order > droppedColumn.order;

    Object.keys(modifiedColumns)
      .map((columnKey) => {
        const column = modifiedColumns[columnKey];

        // drag column gets the order of the dropped column
        if (columnKey === draggedColumnId) {
          return {
            key: columnKey,
            order: droppedColumn.order
          }
        }

        // handle drag to start case
        if (isDraggedToStart && (column.order >= droppedColumn.order && column.order < draggedColumn.order)) {
          return {
            key: columnKey,
            order: column.order + 1
          }
        }

        // handle drag to end case
        if (!isDraggedToStart && (column.order > draggedColumn.order && column.order <= droppedColumn.order)) {
          return {
            key: columnKey,
            order: column.order - 1
          }
        }

        return {
          key: columnKey,
          order: column.order
        }

      }).forEach((column) => {
        modifiedColumns[column.key].order = column.order;
      });

    StateAttributeAccess.setTreeState(parentPathState, parentTreeState);
    ComponentStateHelper.registerState(parentInstance, parentPathState);
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static getDisplayColumns(treeColumns, sortable, modifiedColumns, parentPath, runtime) {
    treeColumns = Utils.deepCopy(treeColumns);

    return treeColumns.map((column) => {
      column.sortable = sortable;
      column.headerRenderer = <CbaTreeHeader column={column} />;
      column.parentPath = parentPath;
      column.runtime = runtime;

      // handles losing resize on rerender
      if (modifiedColumns && modifiedColumns[column.key] && modifiedColumns[column.key].width) {
        column.width = modifiedColumns[column.key].width;
      }
      return column;
    }).sort(TreeUtils.sortAscendingByParameter("order", c => modifiedColumns[c.key].order));
  }

  static isDefaultColumn(treeColumns) {
    return treeColumns.length === 1 && treeColumns[0].isDefault;
  }

  /* Getters for "display nodes" */
  static getTreeDisplayNodes(rootNodes, path, hideLeaves) {
    rootNodes = Utils.deepCopy(rootNodes);
    const flatmap = [];
    TreeUtils.parseNodes(rootNodes, (node) => {
      if (!(node.isLeaf && hideLeaves)) {
        flatmap.push(node);
      }

      return node.expanded;
    });

    flatmap.sort(TreeUtils.sortAscendingByParameter("path"));
    flatmap.map((node) => {
      node.depth = IndexPathHelper.ExtractTreeChildPathFromTreePath(path, node.path).split("/").length - 1

      return node;
    });

    return flatmap;
  }

  static getTreeViewDisplayNodes(treeNodes, filterLeaves, selectedNodePath, sort) {
    const allNodes = Utils.deepCopy(treeNodes);
    const isRoot = !selectedNodePath;
    const selectedNode = TreeUtils.getNode(allNodes, selectedNodePath);

    let shownNodes;

    if (!isRoot && selectedNode) {
      shownNodes = selectedNode.isLeaf ? TreeUtils.getParentNode(allNodes, selectedNode.path).nodes : selectedNode.nodes;
    } else {
      shownNodes = allNodes;
    }

    const flatmap = shownNodes.map((child) => {
      child.expanded = false;
      child.depth = 1;
      child.nodes = [];
      return child;
    });

    const sortCellFinderFunction = (node, param) => {
      const cell = node.cells.find(c => c.columnId === sort.column);
      return cell ? cell[param] : "";
    };

    switch (sort.type) {
      case "NONE": flatmap.sort(TreeUtils.sortAscendingByParameter("path")); break;
      case "ASC": flatmap.sort(TreeUtils.sortAscendingByParameter("label", sortCellFinderFunction)); break;
      case "DESC": flatmap.sort(TreeUtils.sortDescendingByParameter("label", sortCellFinderFunction)); break;
      default: flatmap.sort(TreeUtils.sortAscendingByParameter("path")); break;
    }

    const filteredFlatMap = filterLeaves ? flatmap.filter(child => child.isLeaf && filterLeaves) : flatmap;

    return filteredFlatMap;
  }

  /* helper methods */
  static resetPathsToNodes(nodes, rootPath) {
    nodes.forEach((node, index) => {
      node.path = IndexPathHelper.appendIndexToPageSegment(rootPath, index);

      if (node.nodes.length > 0) {
        TreeUtils.resetPathsToNodes(node.nodes, node.path)
      }
    })
  }

  static flattenNodes(nodes) {
    const flatmap = [];
    TreeUtils.parseNodes(nodes, node => flatmap.push(node));
    return flatmap;
  }

  static parseNodes(rootNodes, callback) {
    rootNodes.forEach((node) => {

      const shouldContinue = callback(node);

      if (node.nodes.length > 0 && shouldContinue) {
        TreeUtils.parseNodes(node.nodes, callback);
      }

    });
  }

  static isNodeExpandedAtInit(node, nodeType, treeStartCollapsed) {
    if (node.nodes.length === 0) {
      return false;
    }

    return treeStartCollapsed ? false : !nodeType.startCollapsed;

  }

  static getNodeType(nodeTypes, type) {
    return nodeTypes.filter(nodeType => nodeType.name === type)[0];
  }

  static createNodeFromNodeType(nodeType, treeColumns, parentNode, runtime) {
    const cells = Utils.deepCopy(nodeType.cellInitSettings);

    return {
      cells: cells.map((c, i) => Object.assign(c, {
        columnId: treeColumns[i].key
      })),
      contentPage: nodeType.contentPage,
      event: nodeType.event,
      expanded: false,
      imagePath: CommonConfigHelper.getProperResourcePath(nodeType.image, runtime),
      isLeaf: nodeType.childTypes.length === 0,
      link: nodeType.link,
      nodePathId: TreeUtils.generateNodePathId(nodeType, parentNode),
      nodeType: nodeType.name,
      nodes: [],
      path: null,
      tooltip: nodeType.tooltip,
      children: -1, // -1 so that first child has index 0;
      userDefinedId: nodeType.userDefId, // figure out what to do here
    }
  }

  static getNode(nodes, searchedNodePath) {
    let foundNode;
    TreeUtils.parseNodes(nodes, (node) => {
      if (node.path === searchedNodePath) {
        foundNode = node;
        return false;
      }

      return true;
    });

    return foundNode
  }

  static getNodeName(rowData) {
    if (rowData !== undefined) {
      const cell = rowData.cells.find(c => c.isPrimary);

      if (cell !== undefined) return cell.label;
    }
    return undefined;
  }

  static getParentNode(nodes, searchedNodePath) {
    const parentPath = IndexPathHelper.dropIndexFromPageSegment(searchedNodePath);
    const parentNode = TreeUtils.getNode(nodes, parentPath);

    if (parentNode) {
      return parentNode;
    } else {
      return {
        root: true,
        nodes
      }
    }
  }

  static buildTreeCellStyle(cell) {
    if (!cell) return {};

    const result = {
      fontFamily: `${cell.font.name}, Geneva, sans-serif`,
      fontSize: cell.font.size,
      fontWeight: cell.font.bold ? 'bold' : 'normal',
      fontStyle: cell.font.italic ? 'italic' : 'normal',
      textDecoration: cell.font.underlined ? 'underline' : 'none',
      textAlign: cell.font.alignmentHorizontal,
    }

    CommonConfigHelper.setStyleAttribute(result, "color", CommonConfigHelper.chooseImageOrColorVariant(cell.color.text, false, false, false));

    return result;
  }

  /* User actions */
  static selectNodeAction(treePath, rowData) {
    const { runtime, path, contentPage } = rowData;

    const { treeState, treeNodes } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const currentNode = TreeUtils.getNode(treeNodes, path);

    treeState.currentNode = path;
    treeState.currentPage = contentPage;
    currentNode.visited = true;

    TreeTraceHelper.traceNodeAction("selection", treePath, rowData, runtime);

    TreeUtils.setTreeDataByPath(treePath, runtime, treeNodes, treeState, null, null);

    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static emitTreeEvent(eventEmitter, treePath) {
    eventEmitter.emit(TreeUtils.getTreeEventChangeName(treePath));
  }

  static sortAscendingByParameter = (param, compareLocator) => (node1, node2) => {
    const comparator1 = compareLocator ? compareLocator(node1, param) : node1[param];
    const comparator2 = compareLocator ? compareLocator(node2, param) : node2[param];

    let comparison = 0;
    if (comparator1 > comparator2) {
      comparison = 1;
    } else if (comparator1 < comparator2) {
      comparison = -1;
    }
    return comparison;
  }

  static sortDescendingByParameter = (param, compareLocator) => (node1, node2) => {
    const comparator1 = compareLocator ? compareLocator(node1, param) : node1[param];
    const comparator2 = compareLocator ? compareLocator(node2, param) : node2[param];

    let comparison = 0;
    if (comparator1 < comparator2) {
      comparison = 1;
    } else if (comparator1 > comparator2) {
      comparison = -1;
    }
    return comparison;
  }

  static collapseNode = (node) => {
    node.expanded = false;

    if (!node.isLeaf && node.nodes.length) {
      TreeUtils.parseNodes(node.nodes, (innerNode) => {
        innerNode.expanded = false;
        return true;
      })
    }

    return node;
  }

  /* Drag&Drop Actions */
  static getTreeDragDropAction = (treePath, runtime) => {
    const treeInstance = runtime.componentDirectory.findComponent(treePath);
    if (treeInstance) {
      return treeInstance.props.config["drag&dropMode"];
    }

    return "";
  }

  static canNodeTypeDrag = (nodeType, treePath, runtime, isReadOnly) => {
    const treeInstance = runtime.componentDirectory.findComponent(treePath);
    const canTreeDoIt = treeInstance.props.config.dragSource;
    const canNodeTypeDoIt = nodeType.dragSource;

    return !isReadOnly && canTreeDoIt && canNodeTypeDoIt
  }

  static canNodeTypeDrop = (nodeType, treePath, runtime, type, isReadOnly) => {
    const treeInstance = runtime.componentDirectory.findComponent(treePath);
    const canTreeDoIt = treeInstance.props.config.dropTarget;
    const canNodeTypeDoIt = nodeType.dropTarget;

    return !isReadOnly && canTreeDoIt && canNodeTypeDoIt && nodeType.childTypes.includes(type);
  }

  /* Methods used by tree child components */

  static getTreeInstanceFromChildComponent = (props) => {
    const { runtime, config, path } = props;
    const { treePathId } = config;
    const treePath = TreeUtils.getTreePathFromChildComponent(treePathId, path);
    const treeInstance = runtime.componentDirectory.findComponent(treePath);

    return treeInstance;
  }

  static getTreePathFromChildComponent = (treePathId, path) => IndexPathHelper.dropPageSegmentFromPath(path) + treePathId;

  /* Methods handling userDefIdPath operations */

  static getTreeFromUserDefIdPath = (userDefPath, runtime) => runtime.componentDirectory.findComponent(PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime));

  static getTreeFlatNodesFromUserDefIdPath = (userDefPath, runtime) => {
    const path = PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime);
    const treeData = TreeUtils.getTreeDataByPath(path, runtime);
    return TreeUtils.flattenNodes(treeData.treeNodes);
  }

  /* Methods handling Getting/Setting TreeState */

  static getTreeDataByPath = (path, runtime) => {
    const treeNodes = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractTreeNodes, path, runtime) || [];
    const treeState = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractTreeState, path, runtime) || {};
    const treeColumns = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractTreeColumns, path, runtime) || [];
    const nodeTypes = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractNodeTypes, path, runtime) || [];
    const treeReadOnly = ComponentStateHelper.getStateAttributeByPathId(StateAttributeAccess.extractTreeReadOnly, path, runtime) || false;

    return {
      treeNodes,
      treeState,
      treeColumns,
      nodeTypes,
      treeReadOnly
    }
  }

  static setTreeDataByPath = (path, runtime, treeNodes, treeState, treeColumns, nodeTypes) => {
    if (treeNodes) {
      const { extractTreeNodes: getter, setTreeNodes: setter } = StateAttributeAccess;
      ComponentStateHelper.updateStateAttribute(getter, setter, treeNodes, path, runtime, false);
    }

    if (treeState) {
      const { extractTreeState: getter, setTreeState: setter } = StateAttributeAccess;
      ComponentStateHelper.updateStateAttribute(getter, setter, treeState, path, runtime, false);
    }

    if (treeColumns) {
      const { extractTreeColumns: getter, setTreeColumns: setter } = StateAttributeAccess;
      ComponentStateHelper.updateStateAttribute(getter, setter, treeColumns, path, runtime, false);
    }

    if (nodeTypes) {
      const { extractNodeTypes: getter, setNodeTypes: setter } = StateAttributeAccess;
      ComponentStateHelper.updateStateAttribute(getter, setter, nodeTypes, path, runtime, false);
    }
  }

  /* Methods used by State Machine */

  static getVisitedPathIds(userDefPath, runtime) {
    const nodes = TreeUtils.getTreeFlatNodesFromUserDefIdPath(userDefPath, runtime);

    return nodes.filter(node => node.visited).map(node => node.nodePathId);
  }

  static getNodePathIds(userDefPath, runtime) {
    const nodes = TreeUtils.getTreeFlatNodesFromUserDefIdPath(userDefPath, runtime);

    return nodes.map(node => node.nodePathId);
  }

  static getCurrentNodePathId(userDefPath, runtime) {
    const path = PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime);
    const { treeState, treeNodes } = TreeUtils.getTreeDataByPath(path, runtime);
    const nodes = TreeUtils.flattenNodes(treeNodes);
    const currentNodePath = treeState.currentNode;

    const currentNode = nodes.find(node => node.path === currentNodePath);

    return currentNode ? currentNode.nodePathId : null;
  }

  static getColumnValuesMap(userDefPath, nodePathIds, runtime) {
    const nodes = TreeUtils.getTreeFlatNodesFromUserDefIdPath(userDefPath, runtime);
    const map = new Map();

    nodePathIds.forEach((nodePath) => {
      const node = nodes.find(n => n.nodePathId === nodePath);
      const columnValues = node.cells.map(cell => cell.label);
      map.set(nodePath, columnValues);
    });

    return map;
  }

  static treeMoveCurrentNode(userDefPath, targetNode, runtime) {
    TreeUtils.treeActionCurrentNode(userDefPath, targetNode, runtime, TreeUtils.doTreeMoveAction);
  }

  static treeCopyCurrentNode(userDefPath, targetNode, runtime) {
    TreeUtils.treeActionCurrentNode(userDefPath, targetNode, runtime, TreeUtils.doTreeCopyAction);
  }

  static treeActionCurrentNode(userDefPath, targetNode, runtime, action) {
    const treePath = PathTranslationHelper.getIndexPathForUserDefPath(userDefPath, runtime);
    const { treeState, treeNodes } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const currentNodePath = treeState.currentNode;
    const flatNodes = TreeUtils.flattenNodes(treeNodes);
    const targetNodeInstance = flatNodes.find(node => node.nodePathId === targetNode);

    if (targetNodeInstance && !targetNodeInstance.isLeaf && treeState.currentNode) {
      action(treePath, currentNodePath, targetNodeInstance.path, runtime)
    }
  }

  /*
    Methods used by Drag&Drop
  */
  static doTreeMoveAction(treePath, fromPath, toPath, runtime) {
    if (toPath.includes(fromPath)) {
      console.error("Could not move tree node from parent to child", fromPath, toPath);
      return;
    }

    TreeUtils.doTreeCut(treePath, fromPath, runtime);
    TreeUtils.doTreePaste(treePath, toPath, runtime);
  }

  static doTreeCopyAction(treePath, fromPath, toPath, runtime) {
    TreeUtils.doTreeCopy(treePath, fromPath, runtime);
    TreeUtils.doTreePaste(treePath, toPath, runtime);
  }

  /* Context menu operations */
  static doTreeCopy(treePath, nodePath, runtime, shouldNotClearCut) {
    // get tree state
    const { treeNodes: nodes, treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);

    // copy logic
    const node = TreeUtils.getNode(nodes, nodePath);
    if (!shouldNotClearCut) {
      treeState.currentlyCutNodePath = null;
    }

    treeState.copiedNode = Utils.deepCopy(node);
    treeState.copiedNode = TreeUtils.collapseNode(treeState.copiedNode);
    // treeState.copiedNode.path = null;

    // set tree state
    TreeUtils.setTreeDataByPath(treePath, runtime, null, treeState, null);
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static doTreeCut = (treePath, nodePath, runtime) => {
    // get tree state
    const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);

    // cut logic
    treeState.currentlyCutNodePath = nodePath;

    // set tree state
    TreeUtils.setTreeDataByPath(treePath, runtime, null, treeState, null);

    // this also emits event
    TreeUtils.doTreeCopy(treePath, nodePath, runtime, true);
  }

  static doTreePaste(treePath, nodePath, runtime) {
    // get tree state
    let { treeNodes: nodes, treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);

    // paste logic
    let node = TreeUtils.getNode(nodes, nodePath);

    const { copiedNode, currentlyCutNodePath } = treeState;

    if (!copiedNode) {
      console.error("Tree context menu error, no node to paste");
      return;
    }

    // delete node if cut node exists
    if (currentlyCutNodePath) {
      TreeUtils.doTreeDelete(treePath, currentlyCutNodePath, runtime, true);
      // update function variables to new state after delete
      ({ treeNodes: nodes, treeState } = TreeUtils.getTreeDataByPath(treePath, runtime));
      node = TreeUtils.getNode(nodes, nodePath);

      // clear copied node and cut node
      treeState.currentlyCutNodePath = null;
      treeState.copiedNode = null;
    }

    // update nodePathId for copied node and children
    copiedNode.nodePathId = TreeUtils.generateNodePathId(copiedNode, node);
    TreeUtils.parseNodes(copiedNode.nodes, (copiedNodeChild) => {
      let copiedNodeParent = TreeUtils.getParentNode(copiedNode.nodes, copiedNodeChild.path);
      if (copiedNodeParent.root) {
        copiedNodeParent = copiedNode;
      }
      copiedNodeChild.nodePathId = TreeUtils.generateNodePathIdChild(copiedNodeChild, copiedNodeParent);
      return true;
    });

    // add node
    node.nodes.push(copiedNode);

    // update children number
    node.children += 1;

    // reset paths
    TreeUtils.resetPathsToNodes(nodes, treePath);

    // update tree state
    TreeUtils.setTreeDataByPath(treePath, runtime, nodes, treeState, null);
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static doTreeDelete(treePath, nodePath, runtime, isPasted) {
    // get tree state
    const treeData = TreeUtils.getTreeDataByPath(treePath, runtime);
    let { treeNodes: nodes } = treeData;
    const { treeState, nodeTypes } = treeData;

    // delete logic 
    const parentNode = TreeUtils.getParentNode(nodes, nodePath);
    const deletedNode = TreeUtils.getNode(nodes, nodePath);
    const deleteEventNodeType = TreeUtils.getNodeType(nodeTypes, deletedNode.nodeType);

    // Don't delete for raised event
    if (!isPasted && deleteEventNodeType.deleteEvent) {
      CommonActionsHelper.sendEvent(deleteEventNodeType.deleteEvent, runtime);
      return;
    }

    // clear selection in deleted nodes
    TreeUtils.parseNodes(nodes, (currentParsedNode) => {
      let deletedNodes = [];
      if (currentParsedNode.path === nodePath) {
        deletedNodes = TreeUtils.flattenNodes(currentParsedNode.nodes);
        deletedNodes.push(currentParsedNode);

        const selectedNodeInDeletedNodes = !!deletedNodes.find(node => treeState.currentNode === node.path);
        const cutNodeInDeleteNodes = !!deletedNodes.find(node => treeState.currentlyCutNodePath === node.path);

        if (selectedNodeInDeletedNodes) treeState.currentNode = null;
        if (cutNodeInDeleteNodes) {
          treeState.currentlyCutNodePath = null;
          treeState.copiedNode = null
        }

        return false;
      }

      return true;
    });

    // filter out node
    if (parentNode.root) {
      nodes = nodes.filter(node => node.path !== nodePath);
    } else {
      parentNode.nodes = parentNode.nodes.filter(node => node.path !== nodePath);
    }

    TreeUtils.setTreeDataByPath(treePath, runtime, nodes, treeState);
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static startRenameOperation(rowData, columnKey) {
    const { treePath, path: nodePath, runtime, isTreeView } = rowData;
    const { treeState } = TreeUtils.getTreeDataByPath(treePath, runtime);

    treeState.currentRenameNode = {
      isTreeView,
      cellPath: TreeUtils.buildCellPath(nodePath, columnKey)
    }

    TreeUtils.setTreeDataByPath(treePath, runtime, null, treeState, null, null)
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);
  }

  static doTreeRename(rowData, columnKey, newValue) {
    const { treePath, path: nodePath, runtime } = rowData;
    const { treeNodes: nodes, treeColumns: columns } = TreeUtils.getTreeDataByPath(treePath, runtime);

    const node = TreeUtils.getNode(nodes, nodePath);
    const cell = node.cells.find(c => c.columnId === columnKey);
    const column = columns.find(c => c.key === columnKey);
    const oldValue = cell.label;

    cell.label = newValue;

    TreeUtils.setTreeDataByPath(treePath, runtime, nodes, null, null, null);
    TreeUtils.emitTreeEvent(runtime.eventEmitter, treePath);

    TreeTraceHelper.traceNodeAction("rename", treePath, rowData, runtime, oldValue, newValue, column.name);
  }

  static doTreeNew(treePath, nodePath, runtime, newTreeType, isTreeView, treeViewPath) {
    const { treeNodes: nodes, treeColumns, nodeTypes } = TreeUtils.getTreeDataByPath(treePath, runtime);
    const node = TreeUtils.getNode(nodes, nodePath); // need this to compute pathid of new node

    const newNodeType = TreeUtils.getNodeType(nodeTypes, newTreeType);
    const newNode = TreeUtils.createNodeFromNodeType(newNodeType, treeColumns, node, runtime);

    node.expanded = true;
    node.nodes.push(newNode);
    node.children += 1;
    TreeUtils.resetPathsToNodes(nodes, treePath)

    TreeUtils.setTreeDataByPath(treePath, runtime, nodes, null, null, null);

    const primaryColumn = newNode.cells.find(c => c.isPrimary).columnId;
    const mockRowData = Object.assign({
      runtime,
      treePath,
      isTreeView,
      treeViewPath
    }, newNode);

    TreeUtils.selectNodeAction(treePath, mockRowData);
    TreeUtils.startRenameOperation(mockRowData, primaryColumn);
  }

}
