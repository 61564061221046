import Utils from '../utils/Utils';
import UserDataUploader from './UserDataUploader';

/**
 * Manage the transfer of the media stream recordings to the server. 
 * 
 * We support transmission of recording entries 
 *  - via HTTP PUT calls or
 *  - window.postMessage events sent to the given target domain origin or
 *  - writing the trace logs to the console.
 */
export default class RecordingUploader extends UserDataUploader {

  constructor(recordingBuffer) {
    super(recordingBuffer, RecordingUploader.buildRecordingPostContent, "recording");
    this.transmitInterval = undefined;
    this.startAutomaticTransmissions();
  }

  // ------- public API -----------------------------------------------------------------------------

  // See the base class UserDataUploader for our public API.

  // ------- private stuff --------------------------------------------------------------------------

  /**
   * Build the content of a recording transmission to the server. 
   * 
   * This consists of a meta data structure and the list of recording entries to transmit.
   * We take the list of recording entries from our internal recording entries list but
   * we do not drop the entries from this list yet.
   * 
   * @param {String} sendTimestamp The time of send operation.
   * @param {*} runtime 
   */
   static buildRecordingPostContent = (sendTimestamp, sessionContext, recordingEntriesList) => {
     const { sessionId, username, loginTimestamp } = sessionContext;
     if (sessionId === undefined) {
       console.warn("Sending recording with dummy session id!");
     }
     const metaData = {
       userId: username,
       sessionId: sessionId === undefined ? 'dummySessionId' : sessionId,
       loginTimestamp,
       sendTimestamp,
       cbaVers: Utils.getCbaVersion()
     };

     const recordingPostContent = {
       metaData,
       recordingEntriesList
     };

     return recordingPostContent;
   }

}
