
import CBACalculatorHistory from "./CbaCalculatorHistory";
import CbaCalculatorInput from "./CbaCalculatorInput";
import RenderingHelper from "../RenderingHelper";
import StateManagerHelper from '../../state/StateManagerHelper';
import KeyboardHelper from "./KeyboardHelper";
import Renderer from "./Renderer";
import HistoryRenderer from "./HistoryRenderer";
import Evaluator from "./Evaluator";
import ValueFormatter from "./ValueFormatter";
import EvaluatorHelper from "./EvaluatorHelper";

/**
 * Using the algorithm from https://github.com/DIPFtba/calculator
 */
export default class CbaCalculatorEngine {

  constructor(taskPath, runtime) {
    this.taskPath = taskPath;
    this.runtime = runtime;

    this.settings = {
      angle: EvaluatorHelper.ANGLE_DEGREE,
      // used only to calculate bigger flag which is used by algorithm toPrecision the result
      displayWidth: 10,
      // displays each digit in history or only the whole number when an operator is hit
      includeOperandInHistory: true,
      withThousandSeparator: true,
      // not used - library does its scaling
      scale: 0,
      format: ValueFormatter.FORMATS.EN,
    };

    this.evaluator = new Evaluator(this.settings.displayWidth, this.settings.angle);
    this.historyRenderer = new HistoryRenderer(this.settings.withThousandSeparator, this.settings.format);

    this.displayingValue = '0'; // maybe evalKey 0 should be called
    this.historyDisplayingValue = undefined;
  }

  getFullState = () => {
    const state = {}
    state.evaluator = StateManagerHelper.deepCopy(this.evaluator.getFullState());
    state.historyRenderer = StateManagerHelper.deepCopy(this.historyRenderer.getFullState());
    state.settings = StateManagerHelper.deepCopy(this.settings);

    state.displayingValue = this.displayingValue;
    state.historyDisplayingValue = this.historyDisplayingValue;
    return state;
  }

  restoreState = (state) => {
    this.evaluator.restoreState(state.evaluator);
    this.historyRenderer.restoreState(state.historyRenderer);
    this.settings = state.settings;

    this.displayingValue = state.displayingValue;
    this.historyDisplayingValue = state.historyDisplayingValue;
  }

  calcGetMem = memIdx => this.evaluator.getMem(memIdx);

  calcOp = (operation, baseOrExponentOrMemIdx) => {
    const key = KeyboardHelper.fromStatemachineToCalculatorSupportedOperation(operation);
    if (key) {
      this.evalKey(key, baseOrExponentOrMemIdx);
    }
  }

  calcOpnd = (operation, digits) => {
    switch (operation) {
      case "add":
        digits = `${digits}`;
        for (let i = 0, iMax = digits.length; i < iMax; i += 1) {
          this.evalKey(digits.charAt(i));
        }
        break;
      case "decimal":
        this.evalKey(".");
        break;
      case "back":
        this.evalKey("back");
        break;
      case "invadd":
        this.evalKey("+/–");
        break;
      default:
        // ignore operation
        break;
    }
  }

  calcSettings = (calcEngineParams) => {
    this.settings = Object.assign(this.settings, calcEngineParams);
    this.historyRenderer.calcSettings(this.settings.withThousandSeparator, this.settings.format);
    this.evaluator.calcSettings(this.settings.displayWidth, this.settings.angle);
  }

  // the key pressed events (and paste events) are not going through the statemachine (RAP is doing the same thing) 
  keypress = (pressedKey) => {
    const key = KeyboardHelper.fromKeyStrokeToCalculatorSupportedOperation(pressedKey);
    if (key) {
      this.evalKey(key);
    }
  }

  paste = (text) => {
    const renderCommands = this.evaluator.paste(text);
    this.triggerRendering(renderCommands);
  }

  //  copy = (e) => {
  // TODO:
  // hiddenCopy.textContent = resBuffer.replace(/\s/g, '');
  // hiddenCopy.focus();
  // hiddenCopy.select();
  // }

  evalKey = (key, operand) => {
    const renderCommands = this.evaluator.evalKey(key, operand);
    this.triggerRendering(renderCommands);
  }

  triggerRendering = (renderCommands) => {
    renderCommands.render.forEach(command => this.render(command));
    renderCommands.history.forEach(command => this.renderHistory(command));
  }

  render = ({ value, args }) => {
    this.displayingValue = this.calculateRenderedValue(value, args);
    const inputs = this.runtime.componentDirectory.findByComponentType(CbaCalculatorInput);
    inputs.forEach(input => RenderingHelper.triggerRendering(input));
  }

  calculateRenderedValue(value, args) {
    if (value !== undefined) {
      return new Renderer(this.settings.displayWidth, this.settings.withThousandSeparator, this.settings.format).render(value, args);
    }
    return undefined;
  }

  renderHistory = ({ key, value, operand }) => {
    this.historyDisplayingValue = this.historyRenderer.render(key, this.calculateRenderedValue(value), operand);
    // trigger rendering when includeOperandInHistory should display the operand when typing
    if (!KeyboardHelper.isDigitKey(key) || (KeyboardHelper.isDigitKey(key) && this.settings.includeOperandInHistory)) {
      this.triggerHistoryRendering();
    }
  }

  /**
   * rerenders only calculator history components
   */
  triggerHistoryRendering = () => {
    const histories = this.runtime.componentDirectory.findByComponentType(CBACalculatorHistory);
    histories.forEach(h => RenderingHelper.triggerRendering(h));
  }

}
