import ValueFormatter from "./ValueFormatter";
import StateManagerHelper from '../../state/StateManagerHelper';
import Renderer from "./Renderer";

// TODO: an approach similar to Evaluator
export default class HistoryRenderer {

  constructor(withThousandSeparator, format) {
    this.withThousandSeparator = withThousandSeparator;
    this.format = format;

    this.historyEntries = [];
  }

  calcSettings = (withThousandSeparator, format) => {
    this.withThousandSeparator = withThousandSeparator;
    this.format = format;
  }

  getFullState = () => {
    const state = {}
    state.historyEntries = StateManagerHelper.deepCopy(this.historyEntries);
    state.withThousandSeparator = this.withThousandSeparator;
    state.format = StateManagerHelper.deepCopy(this.format);
    return state;
  }

  restoreState = (state) => {
    this.historyEntries = state.historyEntries;
    this.withThousandSeparator = state.withThousandSeparator;
    this.format = state.format;
  }

  /** 
   * @param key - current key pressed
   * @param value - the current value calculator is displaying. It is the value used by history when equals is pressed.
   * @param operand - fixed operand used for functions like exponential, log, square.
  */
  render = (key, value, operand) => {
    this.pushToHistory(key, value, operand);
    return this.recalculateHistoryString();
  }

  // private 

  pushToHistory = (key, value, operand) => {
    if (this.isKeyCorAC(key)) {
      this.pushToHistoryCorAC(key, value);
    } else if (this.isKeyOneOperatorAfterAnotherOperator(key)) {
      this.pushToHistoryOneOperatorAfterAnotherOperator(key, value);
    } else if (this.isKeyBackspace(key, value)) {
      this.pushToHistoryBackspace();
    } else if (this.isKeyEquals(key)) {
      this.pushKeyToHistoryEquals(key, value);
    } else if (key.match(/2nd|Degree|Radian|m|C|AC/)) {
      // ignore it -> do not add anything to history
    } else if (this.isKeyUsingOperand(operand)) {
      this.pushToHistoryKey(`operand${operand}`, value);
      this.pushToHistoryKey(key, value);
    } else {
      this.pushToHistoryKey(key, value);
    }
  }

  // ... do not log operators multiple times in succession / last replaces previous ones
  pushToHistoryOneOperatorAfterAnotherOperator = (key, value) => {
    this.historyEntries[this.historyEntries.length - 1] = key;
  }

  pushToHistoryBackspace = (key, value) => {
    const len = this.historyEntries.length;
    const prevKey = (len > 0) ? this.historyEntries[len - 1] : undefined;

    if (prevKey !== undefined && prevKey.match(/^(\.|\d|\(|\))/)) {
      this.historyEntries.pop();
      if (this.historyEntries[this.historyEntries.length - 1] === '.') {
        this.historyEntries.pop();
      }
    }
  }

  pushKeyToHistoryEquals = (key, value) => {
    this.historyEntries = [`=${value}`];
  }

  pushToHistoryCorAC = (key, value) => {
    this.historyEntries = [];
  }

  pushToHistoryKey = (key, value) => {
    this.handleCaseOfEqualsFollowedByAnOperationOrByAnOperand(key);
    this.historyEntries.push(key);
  }

  handleCaseOfEqualsFollowedByAnOperationOrByAnOperand = (key) => {
    const previousKey = this.historyEntries[this.historyEntries.length - 1];
    // previous key was a number starting with = (it was inserted when = was pressed)
    if (previousKey !== undefined && previousKey.match(/^=-?\d/)) {
      if (Number.isNaN(Number(key))) {
        // is it an function ?
        this.historyEntries[this.historyEntries.length - 1] = previousKey.substring(1);
      } else {
        // is it a operand ?
        this.historyEntries = [];
      }
    }
  }

  isKeyCorAC = key => key.match(/^C|AC/);

  isKeyOneOperatorAfterAnotherOperator = (key) => {
    const len = this.historyEntries.length;
    const prevKey = (len > 0) ? this.historyEntries[len - 1] : undefined;
    const isOperatorOneAfterTheOther = prevKey !== undefined && prevKey.match(/^(\+|–|÷|×|yx|log|x√y|E)+$/) && key.match(/^(\+|–|÷|×|yx|log|x√y|E)+$/);
    return isOperatorOneAfterTheOther;
  }

  isKeyBackspace = key => key === "back";

  isKeyEquals = key => key === "=";

  isKeyUsingOperand = operand => operand !== undefined;

  recalculateHistoryString = () => {
    let historyString = "";
    let brackets = 0;
    let betweenOperatorsStack = [];

    this.historyEntries.forEach((key) => {
      key = key.replace('÷', '/').replace('-', '–');
      if (key === '+' || key === '×' || key === '/' || key === '–') {
        historyString += this.recalculateHistoryForStackBetweenOperators(betweenOperatorsStack);
        historyString += key;
        betweenOperatorsStack = [];
      } else if (key === '(') {
        brackets += 1;
        betweenOperatorsStack.push(key);
      } else if (key === ')') {
        if (brackets > 0) {
          brackets -= 1;
          betweenOperatorsStack.push(key);
        }
      } else if (key.startsWith("=")) {
        // handleCaseOfEqualsFollowedByAnOperationOrByAnOperand + pushKeyToHistoryEquals
        betweenOperatorsStack.push(key.substring(1));
      } else {
        betweenOperatorsStack.push(key);
      }
    });

    historyString += this.recalculateHistoryForStackBetweenOperators(betweenOperatorsStack);

    historyString = this.formatAllNumbers(historyString);
    return historyString;
  }

  recalculateHistoryForStackBetweenOperators = (stack) => {
    let ret = "";
    for (let i = 0; i < stack.length; i += 1) {
      const key = stack[i];
      if (key === "ex") {
        ret = `e^(${ret})`;
      } else if (key === "ln") {
        ret = `ln(${ret})`;
      } else if (key === "x3") {
        ret = `${ret}^3`;
      } else if (key === "x2") {
        ret = `${ret}^2`;
      } else if (key === "yx") {
        ret = `${ret}^`;
      } else if (key === "x√y") {
        ret = `${ret} ${Renderer.msg.nroot} `;
      } else if (key === "3√") {
        ret = `${ret} ${Renderer.msg.nroot} 3`;
      } else if (key === "2√") {
        ret = `${ret} ${Renderer.msg.nroot} 2`;
      } else if (key === "x!") {
        ret = `${ret}!`;
      } else if (key === "+/–") {
        // switch minus
        ret = ret.startsWith("-") ? ret.substring(1) : `-${ret}`;
      } else if (key === "1/x") {
        ret = `1/${ret}`;
      } else if (key === "sin" || key === "cos" || key === "tan" || key === "sin–1" || key === "cos–1" || key === "tan–1") {
        ret = `${key}(${ret})`;
      } else if (key === "operandlog") {
        ret = `${ret}log${this.getOperandValue(stack, i)}`;
      } else if (key === "operandyx") {
        ret = `${ret}^${this.getOperandValue(stack, i)}`;
      } else if (key === "operandx√y") {
        ret = `${ret} ${Renderer.msg.nroot} ${this.getOperandValue(stack, i)}`;
      } else if (key === "operandex") {
        ret = `e^(${this.getOperandValue(stack, i)})`;
      } else if (key.startsWith("operand")) {
        // it is the base of a log or the power of an exponent
        // ignore it as it is needed in operator evaluation
      } else {
        ret += key;
      }
    }
    return ret;
  }

  getOperandValue = (stack, index) => {
    let operand = index > 0 ? stack[index - 1] : "";
    operand = operand.startsWith("operand") ? operand : "";
    const ret = operand.substring("operand".length);
    return ret;
  }

  /**
   * formats all numbers in the history string
   */
  formatAllNumbers = (historyString) => {
    const regexNumbers = new RegExp(`([0-9]+(.[0-9]+)?)`, "g");
    historyString = historyString.replace(regexNumbers, (str, p1) => new ValueFormatter(this.withThousandSeparator, this.format).formatValue(p1));
    return historyString;
  }

}
