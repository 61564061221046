import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumericInput from 'react-numeric-input';
import TermEvaluator from '../../eval/TermEvaluator';
import RenderingHelper from '../RenderingHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import PropTypesHelper from '../PropTypesHelper';
import CommonActionsHelper from '../CommonActionsHelper';

class CbaSpinnerValueInput extends Component {

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler = (event) => {
    const pathState = ComponentStateHelper.getState(this);
    if (!pathState.disabled) {
      CommonActionsHelper.doStandardOnClick(event, undefined, this);
    } else {
      CommonActionsHelper.stopEventPropagation(event);
    }
  }

  onChangeHandler = (value) => {
    const { config, runtime } = this.props;
    if (value !== null) {
      TermEvaluator.setStatemachineVariable({
        name: config.text.dynamic.variable,
        value
      }, runtime);
    }
    RenderingHelper.triggerRendering(this);
  }

  onInvalid = (errorMessage, valueAsNumber, valueAsString) => {
    if (errorMessage !== undefined) {
      const { config, runtime } = this.props;
      const value = TermEvaluator.getStatemachineVariable({
        name: config.text.dynamic.variable
      }, runtime);
      this.onChangeHandler(value);
    }
  }

  onKeyDownHandler = (e) => {
    const { config, runtime } = this.props;
    const { pageIncrement, minValue, maxValue } = config;
    const value = TermEvaluator.getStatemachineVariable({
      name: config.text.dynamic.variable
    }, runtime);
    if (e.keyCode === 33) {
      let newValue = value + pageIncrement;
      newValue = newValue > maxValue ? maxValue : newValue;
      this.onChangeHandler(newValue);
      e.preventDefault();
    }
    if (e.keyCode === 34) {
      let newValue = value - pageIncrement;
      newValue = newValue < minValue ? minValue : newValue;
      this.onChangeHandler(newValue);
      e.preventDefault();
    }
  }

  validateValue = (value, minValue, maxValue) => {
    let numberValue = parseInt(value, 10);
    numberValue = numberValue < minValue ? minValue : numberValue;
    numberValue = numberValue> maxValue ? maxValue : numberValue;
    return numberValue;
  }

  render() {
    const { config, path, runtime, orientation } = this.props;
    const { minValue, maxValue, stepIncrement } = config;
    const pathState = ComponentStateHelper.getState(this);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);
    let value = CommonConfigHelper.buildDisplayText(config, pathState, runtime);
    value = this.validateValue(value, minValue, maxValue);
    const childStyle={
      wrap: {
        position: 'unset',
        display: 'unset'
      },
      input: {
        width: '100%',
        height: '100%',
        backgroundColor: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontStyle: 'inherit',
        color: 'inherit'
      }
    }

    return (
      <div
        style={style}
        title={CommonConfigHelper.buildTitle(config)}
        onKeyDown={this.onKeyDownHandler}
        onClickCapture={this.onClickHandler}
      >
        <NumericInput
          style={childStyle}
          disabled={pathState.disabled}
          min={minValue}
          max={maxValue}
          value={value}
          step={stepIncrement}
          onChange={this.onChangeHandler}
          onInvalid={this.onInvalidHandler}
        />
      </div>
    );
  }

}

CbaSpinnerValueInput.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape().isRequired,
  orientation: PropTypes.string.isRequired,
};

export default CbaSpinnerValueInput;
