import RenderingHelper from '../components/RenderingHelper';
import PathTranslationHelper from './PathTranslationHelper';
import IndexPathHelper from './IndexPathHelper';
import UserDefPathHelper from './UserDefPathHelper';

/**
 * General helper methods to manage the component's registered state.
*/
export default class ComponentStateHelper {

  // ----------- public API ----------------------------------------------------------------------

  /**
   * Get the 'path' state of the given component.
   */
  static getState(component) {
    return component.props.runtime.componentStateManager.findOrBuildStateForPathId(component.props.path, component.props.runtime);
  }

  /**
   * Register the 'path' state for the given component.
   */
  static registerState(component, fullState) {
    component.props.runtime.componentStateManager.registerStateByPathId(component.props.path, fullState);
  }

  /**
   * Get an attribute from the 'path' state of the component specified by a user defined path (without path root).
   */
  static getStateAttributeByUserDefPath(getter, userDefPath, runtime) {
    const fullState = runtime.componentStateManager.findOrBuildStateByUserDefPath(userDefPath, runtime);
    return getter(fullState);
  }

  /**
   * Get an attribute from the 'path' state of the component specified by a  path id.
   */
  static getStateAttributeByPathId(getter, indexPath, runtime) {
    const fullState = runtime.componentStateManager.findOrBuildStateForPathId(indexPath, runtime);
    return getter(fullState);
  }

  /**
   * Update an attribute in the 'path' state of the component specified by a path (with path root).
   */
  static updateStateAttribute(getter, setter, newValue, pathId, runtime, triggerRendering) {
    const stateManager = runtime.componentStateManager;
    const fullState = stateManager.findOrBuildStateForPathId(pathId, runtime);
    const oldValue = getter(fullState);
    if (oldValue !== newValue) {
      setter(fullState, newValue);
      stateManager.registerStateByPathId(pathId, fullState);
      if (triggerRendering) {
        RenderingHelper.triggerRenderingViaPath(pathId, runtime);
      }
    }
  }

  /**
   * Update an attribute in the 'path' state of the component specified by a user defined path (without path root).
   */
  static updateStateAttributeByUserDefPath(getter, setter, newValue, userDefPath, runtime, triggerRendering) {
    const stateManager = runtime.componentStateManager;
    const fullState = stateManager.findOrBuildStateByUserDefPath(userDefPath, runtime);
    const oldValue = getter(fullState);
    if (oldValue !== newValue) {
      setter(fullState, newValue);
      stateManager.registerStateByUserDefPath(userDefPath, fullState, runtime);
      if (triggerRendering) {
        RenderingHelper.triggerRenderingViaUserDefPath(userDefPath, runtime);
      }
    }
  }

  /**
   * Build an array describing the state of all components in the specified task.
   */
  static buildComponentsSnapshot(test, item, task, runtime) {
    const { componentStateManager, componentDirectory } = runtime;
    return ComponentStateHelper.formatSnapshotDump(componentStateManager.getTaskSnapshot(test, item, task, componentDirectory), runtime);
  }


  /**
   * Return all indexPath keys in the current task that already exist in the ComponentStateManager
   * and belong to a component instance that has the given component type.
   * 
   * @param {String} componentType : The component type that must match.
   * @param {*} runtime The common runtime context structure. 
   * @return {[String]} The matching indexPath keys.
   */
  static findIndexPathsInCurrentTaskOfComponentWithType(componentType, runtime) {
    const { componentStateManager, taskManager } = runtime;
    const rootInRuntime = taskManager.getCurrentStatePathRoot();
    return componentStateManager.filterExistingPathIds(id => IndexPathHelper.getRootFromPath(id) === rootInRuntime
      && ComponentStateHelper.getComponentTypeForIndexPath(id, runtime) === componentType);
  }

  /**
   * Return all indexPath keys that already exist in the ComponentStateManager 
   * and are children of the given parent index path key.
   * 
   * @param {String} parentIndexPath The indexPath key of the parent component.
   * @param {*} runtime The common runtime context structure. 
   * @return {[String]} The matching indexPath keys.
   */
  static findIndexPathsOfChildren(parentIndexPath, runtime) {
    return runtime.componentStateManager.filterExistingPathIds(id => IndexPathHelper.dropIndexFromPageSegment(id) === parentIndexPath);
  }

  // ----------- private stuff ----------------------------------------------------------------------


  /**
   * Enrich and restructure the data from the component state manager to be useful in the trace log. 
   * 
   * @param {*} taskData The task's data from the component state manager to be 'formatted' for dumping it to the trace log.
   */
  static formatSnapshotDump(taskData, runtime) {
    return Object.entries(taskData).map(entry => ComponentStateHelper.formatEntryForSnapshotDump(entry, runtime));
  }

  static formatEntryForSnapshotDump(entry, runtime) {
    const [indexPath, componentState] = entry;

    // drop deepCopy state
    componentState.deepCopy = undefined;
    const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(indexPath, runtime);

    return {
      indexPath,
      userDefIdPath,
      userDefId: UserDefPathHelper.getLastUserDefIdFromPath(userDefIdPath),
      componentType: ComponentStateHelper.getComponentTypeForIndexPath(indexPath, runtime),
      componentState
    };
  }

  /**
   * Find the display component type of the component specified by the given index path.
   * 
   * The method assumes that the index path belongs to the currently loaded task (and therefore to the currently loaded item).
   * Otherwise it will match the page segment with a wrong page in the page configurations manager. 
   * 
   * We return the type name specified in the item configuration 
   * (i.e. not necessarily the component's class name).
   */
  static getComponentTypeForIndexPath(indexPath, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(indexPath);
    if (pageSegment === undefined) {
      return undefined;
    }
    const componentConfiguration = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    return componentConfiguration === undefined ? undefined : componentConfiguration.type;
  }

}
