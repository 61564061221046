import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import PropTypesHelper from './PropTypesHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateManagerHelper from '../state/StateManagerHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import RenderingHelper from './RenderingHelper';
import StateAttributeAccess from '../state/StateAttributeAccess'
import SelectGroupHelper from './SelectGroupHelper';
import CommonActionsHelper from './CommonActionsHelper';
import TableHelper from './table/TableHelper';

/**
 * A display component that displays a combo box.
 * 
 * This component manages the items in the combo box as children of its own 
 * in the display component instances tree.
 */
export default class CbaComboBox extends Component {

  constructor(props) {
    super(props);
    this.comboRef = React.createRef();
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { isInEditMode } = this.props;
    if (isInEditMode) {
      this.focus();
    }
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  // basic state management --------------------------------------------------------------

  static getItemType() {
    return 'ComboBoxItem';
  }

  /**
   * Build an array of 'standard' configuration structures for the items in the items list.
   * The page configurations manager expects a structure with the attributes 'type' and 'config'. 
   * When accessing this structure the state manager expects a config.state object as a minimum. 
   */
  static buildComboBoxItemsArray(itemsInConfig) {
    return itemsInConfig.map((itemInConfig, index, all) => {
      const configOnPathSegment = StateManagerHelper.deepCopy(itemInConfig);
      const additionalState = {}
      StateAttributeAccess.setSelected(additionalState, parseInt(index, 10) === 0);
      StateAttributeAccess.setDisabled(additionalState, false);
      StateAttributeAccess.setHidden(additionalState, false);
      configOnPathSegment.state = additionalState;
      return {
        type: CbaComboBox.getItemType(),
        config: configOnPathSegment
      }
    })
  }

  /**
   * Calculate the index of the currently selected item by looking at the state 
   * of all items in the state manager:
   */
  getSelectedIdFromState = () => {
    const { runtime, path: comboBoxPath, config } = this.props;
    const stateManager = runtime.componentStateManager;
    let foundSelectedId;
    config.items.forEach((itemConfig, index, all) => {
      const itemPath = IndexPathHelper.appendIndexToPageSegment(comboBoxPath, index);
      const itemState = stateManager.findOrBuildStateForPathId(itemPath, runtime);
      if (SelectGroupHelper.extractSelectedState(itemState, runtime)) {
        // in case of multiple selections we display the first selected item in the closed combo box:
        if (foundSelectedId === undefined) {
          foundSelectedId = parseInt(index, 10);
        } else {
          console.warn("Multiple selections in a combo box are not supported yet!");
        }
      }
    })
    return foundSelectedId;
  }


  /**
   * Change the selected setting for all combo box items to reflect
   * the currently selected item. 
   */
  setSelectedIdInState = (newSelectedId) => {
    const { runtime, path: comboBoxPath } = this.props;
    const stateManager = runtime.componentStateManager;
    const comboBoxState = ComponentStateHelper.getState(this);
    const selectGroupControllerState = StateAttributeAccess.extractSelectGroupControllerState(comboBoxState);
    if (selectGroupControllerState === undefined) {
      console.warn("Multiple select combo boxes are not supported yet!");
      // just set the selected state of the additionally selected item:
      const itemPath = IndexPathHelper.appendIndexToPageSegment(comboBoxPath, newSelectedId);
      const itemState = stateManager.findOrBuildStateForPathId(itemPath, runtime);
      StateAttributeAccess.setSelected(itemState, true);
      stateManager.registerStateByPathId(itemPath, itemState);
    } else {
      SelectGroupHelper.delegateSetSelectedToController(true, newSelectedId, comboBoxPath, undefined, undefined, true, runtime);
    }
  }

  // handle user triggered events ----------------------------------------------------------------

  onChangeHandler = (event) => {
    const { runtime, config, path, isInEditMode } = this.props;
    const oldSelectedId = this.getSelectedIdFromState();
    const newSelectedId = parseInt(this.hasVisibleItemCount() ? event.value : event.target.value, 10);
    const oldSelectedItem = config.items[oldSelectedId];
    const newSelectedItem = config.items[newSelectedId];

    this.updateStateAndTriggerRendering(newSelectedId);

    // CommonActionsHelper.doBasicOnClick(event, path, runtime);
    if (!isInEditMode) {
      CommonActionsHelper.traceUserInteractionPerConfig(config, path, CbaComboBox.buildTraceDetailsAddOn(oldSelectedItem, oldSelectedId, newSelectedItem, newSelectedId), event, runtime);
    }
    CommonActionsHelper.sendStandardEvent(newSelectedItem, runtime);
    const defaultLinkReceiver = CommonActionsHelper.getDefaultLinkReceiver(this);
    CommonActionsHelper.doPageSwitch(newSelectedItem.link, runtime, defaultLinkReceiver, path);

  }

  onClickHandler = (event) => {
    // Catch on click events to avoid trace logs on the container of the combo box.
    const { runtime, path } = this.props;

    CommonActionsHelper.doBasicOnClick(event, path, runtime);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  // ------------- Table specific handlers -------------------------------------//
  onKeyDownHandler = (e) => {
    const { config, path, runtime, isInEditMode } = this.props;
    TableHelper.handleKeyDown(e, runtime, config, TableHelper.buildTablePath(path), isInEditMode);
  }

  onFocusHandler = () => {
    const { path, runtime, isInEditMode } = this.props;
    TableHelper.handleCellFocus(runtime, TableHelper.buildTablePath(path), isInEditMode);
  }

  static buildTraceDetailsAddOn(oldSelectedItem, oldSelectedId, newSelectedItem, newSelectedId) {
    return {
      oldSelected: oldSelectedId,
      oldSelectedUserDefId: oldSelectedItem.userDefinedId,
      newSelected: newSelectedId,
      newSelectedUserDefId: newSelectedItem.userDefinedId
    };
  }

  updateStateAndTriggerRendering = (newSelectedId) => {
    const { runtime, config, path: comboBoxPath, isInEditMode, onBlur } = this.props;

    this.setSelectedIdInState(newSelectedId);

    // specific behavior for table cell usage of this component
    if (isInEditMode) {
      const { row, column } = config;
      runtime.eventEmitter.emit(`${TableHelper.buildTablePath(comboBoxPath)}-contentUpdate`, config.items[newSelectedId].text, row, column, true);
      onBlur();
    }

    // trigger rendering of the combo box itself:
    RenderingHelper.triggerRenderingViaPath(comboBoxPath, runtime);
  }


  // rendering ---------------------------------------------------------------------------------------

  checkForInvalidItems = () => {
    const { config } = this.props;
    const { items } = config;
    return !Array.isArray(items) || (Array.isArray(items) && items.length === 0);
  }

  generateOptions = itemsConfig => (
    // disabled because we don't have a unique id to use as key and also the array does not change.
    // eslint-disable-next-line react/no-array-index-key
    itemsConfig.map((itemConfig, i) => (<option key={i} value={i}>{itemConfig.text}</option>))
  )

  generateOptionsForSelect = (itemsConfig) => {
    // disabled because we don't have a unique id to use as key and also the array does not change.
    // eslint-disable-next-line react/no-array-index-key
    const options = [];
    itemsConfig.map((itemConfig, i) => (options.push({
      value: i, label: itemConfig.text
    })));
    return options;
  }

  focus = () => {
    this.comboRef.current.focus();
  }

  hasVisibleItemCount = () => {
    const { config } = this.props;
    const { visibleItemCount, items } = config;

    return visibleItemCount !== undefined && visibleItemCount > 0 && visibleItemCount < items.length
  }

  render() {
    if (this.checkForInvalidItems()) {
      return React.createElement(
        'div',
        null,
        `Invalid item configuration in combo box.`
      );
    }


    const { config, path, runtime, orientation, onBlur } = this.props;
    const { visibleItemCount, position } = config;
    const wrapperStyle = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    const selectedId = this.getSelectedIdFromState();
    let options = this.generateOptions(config.items);

    // do this only when visible item count was set properly
    if (this.hasVisibleItemCount()) {
      options = this.generateOptionsForSelect(config.items);
      const maxHeightSize = visibleItemCount * position.height;

      const customStyles = {
        menu: provided => ({
          ...provided,
          marginTop: 2
        }),
        menuList: provided => ({
          ...provided,
          padding: 0,
          width: wrapperStyle.width,
          backgroundColor: wrapperStyle.backgroundColor,
        }),
        option: (provided, state) => {
          let backgroundColor
          if (state.isSelected) {
            backgroundColor = 'blue';
          } else if (state.isFocused) {
            backgroundColor = 'grey';
          } else {
            backgroundColor = 'inherit';
          }

          return {
            ...provided,
            width: 'auto',
            height: wrapperStyle.height,
            color: state.isSelected ? 'white' : wrapperStyle.color,
            fontSize: wrapperStyle.fontSize,
            fontFamily: wrapperStyle.fontFamily,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor
          }
        },
        control: (provided, state) => ({
          ...provided,
          // none of react-select's styles are passed to <Control />
          width: wrapperStyle.width,
          height: wrapperStyle.height,
          backgroundColor: wrapperStyle.backgroundColor,
          minHeight: "auto",
          boxShadow: 'rgb(79, 124, 177) 0px 0px 5px',
          borderColor: 'rgb(79, 124, 177)',
          borderRadius: '2px'
        }),
        container: (provided, s) => ({
          ...provided,
          ...wrapperStyle
        }),
        valueContainer: provided => ({
          ...provided,
          padding: "0 3px"
        }),
        singleValue: (provided, state) => ({
          ...provided,
          width: "100%",
          textAlign: wrapperStyle.textAlign,
          color: wrapperStyle.color
        }),
        indicatorsContainer: provided => ({
          ...provided,
          height: "100%"
        }),
        dropdownIndicator: provided => ({
          color: "#808080",
          ":hover": {
            color: "#000"
          }
        }),
        indicatorSeparator: provided => ({
          ...provided,
          display: "none"
        })
      }

      return (
        <div onClick={this.onClickHandler}>
          <Select
            styles={customStyles}
            title={CommonConfigHelper.buildTitle(config)}
            value={options[selectedId]}
            onChange={this.onChangeHandler}
            onBlur={e => onBlur(e, config.items[selectedId].text, true)}
            onContextMenu={this.onContextMenuHandler}
            onKeyDown={this.onKeyDownHandler}
            onFocus={this.onFocusHandler}
            options={options}
            maxMenuHeight={maxHeightSize}
            menuPortalTarget={document.body}
          />
        </div>
      )
    }

    return (
      <select
        ref={this.comboRef}
        style={wrapperStyle}
        title={CommonConfigHelper.buildTitle(config)}
        value={selectedId}
        onChange={this.onChangeHandler}
        onBlur={e => onBlur(e, config.items[selectedId].text, true)}
        onContextMenu={this.onContextMenuHandler}
        onKeyDown={this.onKeyDownHandler}
        onFocus={this.onFocusHandler}
        onClick={this.onClickHandler}
      >
        {options}
      </select>
    );
  }

}


CbaComboBox.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.addSelectGroupControllerConfigPropTypes(PropTypesHelper.getStandardConfigPropTypes(false)),
      {
        items: PropTypes.array.isRequired
      }
    )
  ).isRequired,
  isInEditMode: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  onBlur: PropTypes.func
}

CbaComboBox.defaultProps = {
  isInEditMode: false,
  onBlur: () => { }
}
