import Statemachine from "./Statemachine";
import RenderingHelper from "../components/RenderingHelper";
import StateManagerHelper from './StateManagerHelper';

/**
 * Manager for all state machines.
 * 
 * We keep one state machine per task. 
 * Our operations targeting a single state machine operate on the state machine 
 * assigned to the currently running task. We call this state machine the 'currently active' 
 * state machine. 
 * We need to be informed about the currently running task to switch our 'currently active' 
 * state machine accordingly.
 */
export default class StatemachinesManager {

  constructor() {
    this.statemachinesList = {};
    this.statemachineModelsList = {};
    this.currentMachine = undefined;
    this.variableDefaultValues = {};
    this.timedEventDefaults = {};
    this.statePageMapDefaults = [];
    this.variableObservers = [];
    this.preInitTriggeredEvents = [];

  }

  /**
   * Stop the currently running state machine. 
   */
  stopCurrentStatemachine = () => {
    if (this.currentMachine !== undefined) {
      this.currentMachine.stop();
      this.currentMachine = undefined;
    }
    this.variableDefaultValues = {};
    this.timedEventDefaults = {};
    this.statePageMapDefaults = [];
    this.preInitTriggeredEvents = [];
  }

  /**
   * Start a state machine as currently active state machine.
   * 
   * The method will: 
   *  - Create and start a new sate machine for the given task if no such state machine exists yet.
   *  - Continue the state machine for the given task if it does exist already.
   * 
   * @param taskPath The path of the new task to switch to.
   * @param model The state machine model to create a new state machine with. We will use this only if there is no state machine for the given task yet.
   * @param runtime The common runtime context structure. 
   */
  startOrInitializeCurrentStatemachine = (taskPath, model, runtime) => {
    if (this.currentMachine !== undefined) {
      console.error('Cannot run two state machines in parallel.', taskPath);
      this.stopCurrentStatemachine();
    }
    const existingMachine = this.statemachinesList[taskPath];
    if (existingMachine === undefined) {
      this.statemachineModelsList[taskPath] = model;
      const newMachine = model === undefined ? undefined : new Statemachine(model, this.variableDefaultValues, this.timedEventDefaults, this.statePageMapDefaults, undefined, undefined, runtime);
      this.statemachinesList[taskPath] = newMachine;
      this.currentMachine = newMachine;
      // The new state machine must be the 'current' state machine before we start it (Term evaluation!)
      if (this.currentMachine !== undefined) {
        this.currentMachine.start();
        this.preInitTriggeredEvents.forEach((event) => { this.currentMachine.triggerEvent(event); });
      }
    } else {
      this.currentMachine = existingMachine;
      this.currentMachine.continue();
    }
    this.variableDefaultValues = {};
    this.timedEventDefaults = {};
    this.statePageMapDefaults = [];
    this.preInitTriggeredEvents = [];

    this.variableObservers
      .forEach((entry) => {
        RenderingHelper.triggerRenderingViaPath(entry.indexPath, runtime);
      })
  }

  /**
   * Pause the currently running state machine. 
   * 
   * Pausing the state machines pauses all timed events and blocks event execution. 
   * Use resumeCurrentStatemachine() to finish the pause. This will continue the 
   * paused timed events where they left off. (A stop-continue sequence will 
   * restart all interrupted timed events from the start instead.)
   */
  pauseCurrentStatemachine = () => {
    if (this.currentMachine !== undefined) {
      this.currentMachine.pause();
    }
  }

  /**
   * Resume the currently running but 'paused' state machine. 
   * 
   * This will continue the paused timed events where they left off. 
   * (A stop-continue sequence will restart all interrupted timed events from their start instead.)
   * In a sequence pause-continue the 'continue' call will switch the state machine to a non-paused, 
   * running state and restart all paused timed events from their start.
   */
  resumeCurrentStatemachine = () => {
    if (this.currentMachine !== undefined) {
      this.currentMachine.resume();
    }
  }


  /**
   * Trigger an event on the currently running state machine.
   * 
   * The method implicitly triggers all events on the queue of pending events of the currently running state machine.
   * 
   * If there is no state machine running yet the method keeps the event in a buffer to be exectued as soon as
   * a state machine is created.
   */
  triggerEvent = (event) => {
    if (this.currentMachine === undefined) {
      this.preInitTriggeredEvents.push(event);
    } else {
      this.currentMachine.triggerEvent(event);
    }
  }

  /**
   * Postpone a task switch on the currently running state machine.
   * 
   * The method sets the postponed task switch member of the current state machine.
   * 
   * If there is no state machine running yet the method ignores the call.
   */
  setPostponedTaskSwitch = (taskSwitchCall) => {
    if (this.currentMachine !== undefined) {
      this.currentMachine.setPostponedTaskSwitch(taskSwitchCall);
    }
  }

  /**
   * Get the timer data for a timed event.
   * 
   * @param {String} eventName The name of the timed event.
   */
  getTimerData = (eventName) => {
    if (this.currentMachine === undefined) {
      // Note: We cannot return our buffered value here since the state machine will return more details than we have.
      return undefined;
    } else {
      return this.currentMachine.getTimerData(eventName);
    }
  }

  /**
   * Set the trigger interval for a timed event.
   * 
   * @param {String} eventName The name of the timed event.
  */
  setTimedEventInterval = (eventName, interval) => {
    if (this.currentMachine === undefined) {
      this.timedEventDefaults[eventName] = interval;
    } else {
      this.currentMachine.setTimedEventInterval(eventName, interval);
    }
  }

  /**
   * Assign a page to a state. 
   * 
   * @param {*} state The name of the state to assign the page to.
   * @param {*} pageName The name of the page to assign to the state.
   * @param {*} areaType The type of the area that will get the page assigned at the state switch.
   * @param {*} areaName The name of the area that will get the page assigned at the state switch. 
   */
  setStatePageAssignment = (state, pageName, areaType, areaName) => {
    if (this.currentMachine === undefined) {
      const existingEntryIndex = this.statePageMapDefaults.findIndex(value => value.state === state);
      if (existingEntryIndex === -1) {
        this.statePageMapDefaults.push({
          state, pageName, areaType, areaName
        });
      } else {
        this.statePageMapDefault[existingEntryIndex] = {
          state, pageName, areaType, areaName
        };
      }
    } else {
      this.currentMachine.setStatePageAssignment(state, pageName, areaType, areaName);
    }
  }

  /**
   * Get the current value of the variable with the given name in the currently running state machine
   * or the collected variable default values (if no state machine is running currently).
  */
  getVariable = (variableName) => {
    if (this.currentMachine === undefined) {
      return this.variableDefaultValues[variableName];
    } else {
      return this.currentMachine.getVariable(variableName);
    }
  }

  /**
   * Set the value of the variable with the given name in the currently running state machine
   * or the collected variable default values (if no state machine is running currently).
   * 
   * The method triggers render calls for all components enlisted as observers on the variable.
  */
  setVariable = (variableName, value, runtime) => {
    if (this.currentMachine === undefined) {
      this.variableDefaultValues[variableName] = value;
    } else {
      this.currentMachine.setVariable(variableName, value);
    }

    this.variableObservers
      .filter(entry => entry.variableName === variableName)
      .forEach((entry) => {
        RenderingHelper.triggerRenderingViaPath(entry.indexPath, runtime);
      })
  }

  /**
   * Add a display component instance as observer on variable changes. 
   * 
   * The statemachines manager will trigger a rerendering on the component 
   * each time the given variable changes its value.
   * 
   * @param {String} indexPath The index path of the observing component.
   * @param {String} variableName The name of the variable to be observed.
   */
  addVariableChangeObserver = (indexPath, variableName) => {
    if (this.variableObservers.find(entry => entry.indexPath === indexPath && entry.variableName === variableName) === undefined) {
      this.variableObservers.push({
        indexPath,
        variableName
      });
    }
  }

  /**
   * Drop a display component instance from the list of observers. 
   * 
   * @param {String} indexPath The index path of the observing component.
   * @param {String} variableName The name of the variable to be observed.
   */
  dropVariableChangeObserver = (indexPath, variableName) => {
    const entryIndex = this.variableObservers.findIndex(entry => entry.indexPath === indexPath && entry.variableName === variableName);
    if (entryIndex !== undefined) {
      this.variableObservers.splice(entryIndex, 1);
    }
  }

  dropChangeObserverForPath = (indexPath) => {
    const entryList = this.variableObservers.filter(entry => entry.indexPath === indexPath);
    if (entryList !== undefined && entryList.length > 0) {
      entryList.forEach((entry) => {
        this.dropVariableChangeObserver(indexPath, entry.variableName);
      });
    }
  }

  /**
   * Get the values of all state machine variables of the currently running state machine
   * and return them as a states and a variables array.
   * 
   * The variables array contains one object per variable with two attributes:
   * The attribute 'name' contains the variable name, the attribute 'value' contains the variable's current value. 
   * The states array contains the currently active leaf-states.
   */
  getCurrentStatemachineData = () => ((this.currentMachine === undefined) ? {} : this.currentMachine.getCurrentData());

  /**
   * Get the history data accumulated by the current state machine.
   */
  getHistoryStatemachineData = () => ((this.currentMachine === undefined) ? {} : this.currentMachine.getHistoryData());

  /**
   * Get an array of all events that were raised in the current statemachine. 
   */
  getRaisedEvents = () => ((this.currentMachine === undefined) ? [] : this.currentMachine.getRaisedEvents());

  /**
   * Get an array of all events that were raised while the given state was active in the current statemachine.
   */
  getRaisedEventsInState = state => ((this.currentMachine === undefined) ? [] : this.currentMachine.getRaisedEventsInState(state));

  /**
   * Get the total number of events that were raised in the current statemachine. 
   */
  getTotalNbOfRaisedEvents = () => ((this.currentMachine === undefined) ? 0 : this.currentMachine.getTotalNbOfRaisedEvents());

  /**
   * Get an array of all states that were visited in the current statemachine.
   */
  getVisitedStates = () => ((this.currentMachine === undefined) ? [] : this.currentMachine.getVisitedStates());

  /**
   * Get an array of all values that a variable had before or after triggering an event in the current statemachine.
   */
  getValuesOfVariable = variable => ((this.currentMachine === undefined) ? [] : this.currentMachine.getValuesOfVariable(variable));

  /**
   * Get a snapshot of our statemachines that is compatible 
   * as parameter for the preloadStatemachinesData method.
   */
  getStatemachinesPreloadData = () => {
    const result = {};
    Object.keys(this.statemachinesList).forEach((taskPath) => {
      const machine = this.statemachinesList[taskPath];
      if (machine !== undefined) {
        result[taskPath] = {
          variableValues: machine.getCurrentVariableValues(),
          timerIntervals: machine.getCurrentTimerIntervals(),
          statePageMap: machine.getStatePageAssignments(),
          snapshot: machine.getSnapshot(),
          historyData: machine.getHistoryData(),
          model: StateManagerHelper.deepCopy(this.statemachineModelsList[taskPath])
        }
      }
    });

    return result;
  }

  /**
   * Drop all exisiting state machines.
   */
  clearStatemachines = () => {
    this.stopCurrentStatemachine();
    this.statemachinesList = {};
    this.statemachineModelsList = {};
    this.variableObservers = [];
  }

  /**
   * Preload statemachines.
   * 
   * The method implicitly drops all existing state machines before creating new machines according 
   * to the preload data.
   * 
   * @param preloadData: Preload data obtained by calling getStatemachinesPreloadData on another instance.
   */
  preloadStatemachinesData = (preloadData, runtime) => {
    this.clearStatemachines();

    Object.keys(preloadData).forEach((taskPath) => {
      const machineData = preloadData[taskPath];
      this.statemachinesList[taskPath] = new Statemachine(
        machineData.model,
        machineData.variableValues,
        machineData.timerIntervals,
        machineData.statePageMap,
        machineData.snapshot,
        machineData.historyData,
        runtime
      );
      this.statemachineModelsList[taskPath] = machineData.model;
    });

  }

}
