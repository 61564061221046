import ComponentStateHelper from './ComponentStateHelper';
import CommonActionsHelper from '../components/CommonActionsHelper';

/**
 * Helper methods to create trace log entries.
*/
export default class TraceLogHelper {

  /**
   * Dump the state stored in all relevant state managers (ComponentStateManager, PresenterStateManager, IncidentsAccumulator etc.) to the trace log.
   * 
   * @param {*} runtime The common runtime context structure.
   */
  static dumpSnapshotToTrace(runtime) {
    const {
      taskManager,
      presenterStateManager,
      incidentsAccumulator,
      clipboardManager,
      statemachinesManager,
      calculatorsManager
    } = runtime;
    const { test, item, task } = taskManager.getCurrentTestTaskItemNames();
    const currentStatePathRoot = taskManager.getCurrentStatePathRoot();

    const now = new Date();
    runtime.traceLogBuffer.reportEvent('Snapshot', now, {
      components: ComponentStateHelper.buildComponentsSnapshot(test, item, task, runtime),
      presentation: presenterStateManager.getTaskState(currentStatePathRoot),
      incidents: incidentsAccumulator.getAllValuesForTask(currentStatePathRoot, now.getTime()),
      clipboard: clipboardManager.getStateForTracing(),
      statemachine: {
        current: statemachinesManager.getCurrentStatemachineData(),
        history: statemachinesManager.getHistoryStatemachineData()
      },
      calculator: calculatorsManager.getStateForTask(currentStatePathRoot),
    });

    // return the time of the dump for testing purposes:
    return now;
  }

  /**
   * Wrapper function for throttling/debouncing 
   * 
   * 
   * @returns {Function(currentScrollValue, currentOrientationValue, path, runtime)}
   */
  static traceScrollWrap() {
    return (currentScrollValue, currentOrientationValue, path, runtime) => {
      TraceLogHelper.traceScroll(path, runtime, currentScrollValue, currentOrientationValue);
    }
  }

  /**
   * Traces the scroll interaction
   * 
   * @param {string} path The Index Path of the dislplay component instance.
   * @param {*} runtime The common runtime context structure.
   * @param {*} currentScrollValue The current scroll values of the component
   * @param {*} currentOrientationValue The current orientation of the scrolling component
   * 
   */
  static traceScroll(path, runtime, currentScrollValue, currentOrientationValue) {
    const scrollTraceDetails = TraceLogHelper.buildScrollTraceDetails(currentScrollValue, currentOrientationValue);
    if (scrollTraceDetails) {
      CommonActionsHelper.traceUserInteraction("ScrollbarMove", path, scrollTraceDetails, undefined, undefined, runtime);
    }
  }

  /**
   * Helper function that builds the trace details needed for scrolling
   * 
   * @param {*} currentScrollValue The current scroll values of the component
   * @param {*} currentOrientationValue The current orientation of the scrolling component
   * 
   * @returns {Object} the trace log details
   */
  static buildScrollTraceDetails(currentScrollValue, currentOrientationValue) {
    const { horizontal, vertical } = TraceLogHelper.computeScrollPosition(currentScrollValue);
    const traceDetails = {
      orientation: currentOrientationValue.orientation,
      direction: currentOrientationValue.direction
    }

    if (horizontal || horizontal === 0) {
      traceDetails.horizontalScroll = horizontal;
    }

    if (vertical || vertical === 0) {
      traceDetails.verticalScroll = vertical;
    }

    if (currentOrientationValue.orientation || currentOrientationValue.direction) {
      return traceDetails;
    }

    return null;
  }

  /**
   * Helper function that computs the scroll orientation
   * 
   * @param {*} currentScrollValue The current scroll values of the component
   * @param {*} lastScrollValue The last known scroll values of the component
   * 
   * @returns {Object} the scroll orientation
   */
  static computeScrollOrientation(currentScrollValue, lastScrollValue) {
    if (!currentScrollValue || !lastScrollValue) {
      return {
        direction: null,
        orientation: null
      };
    }

    const isVerticalOrientation = currentScrollValue.scrollTop !== lastScrollValue.scrollTop;
    const orientation = isVerticalOrientation ? "vertical" : "horizontal";
    let direction;

    if (isVerticalOrientation) {
      direction = currentScrollValue.scrollTop < lastScrollValue.scrollTop ? "up" : "down";
    } else {
      direction = currentScrollValue.scrollLeft < lastScrollValue.scrollLeft ? "left" : "right";
    }

    return {
      direction,
      orientation
    }
  }

  /**
   * Helper function that computs the scroll position
   * 
   * @param {*} currentScrollValue The current scroll values of the component
   * @param {*} lastScrollValue The last known scroll values of the component
   * 
   * @returns {Object} the scroll position
   */
  static computeScrollPosition(currentScrollValue) {
    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = currentScrollValue;

    return {
      vertical: Math.round(100 * (scrollTop / (scrollHeight - clientHeight))),
      horizontal: Math.round(100 * (scrollLeft / (scrollWidth - clientWidth))),
    }
  }

  /**
   * Helper function that gets scroll information from a browser event
   * 
   * @param {*} event The browser event
   * 
   * @returns {Object} the scroll data
   */
  static getScrollDataFromEvent(event) {
    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = event.target;

    return {
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      clientHeight,
      clientWidth
    }
  }

}
