import StateAttributeAccess from '../state/StateAttributeAccess';
import CommonActionsHelper from './CommonActionsHelper';
import IndexPathHelper from '../state/IndexPathHelper';
import RenderingHelper from './RenderingHelper';
import CbaComboBox from './CbaComboBox';
import PathTranslationHelper from '../state/PathTranslationHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';

/**
 * Helper methods to implement the 'select-group' mechanism.
 * 
 * The select-group mechanism is based on two state structures kept in the index path state in the ComponentStateManager:
 * 
 * A container managing a select-group for its children (the 'controller') keeps this structure:
 * selectGroupControllerState: {
 *   selectionChangesBlocked: bool (Do we block changes to the selection state of our group members currently?)
 *   singleSelectActive: bool (Is the single-select restriction active, i.e. do we enforce a single select behavior for participating children?)
 *   currentlySelected: int (The index of the currently selected child in the children's array of the controller.)
 *   allowDeselect: bool (Do we allow the currently selected child to be deselected by a click? If not the selected child can change only by clicking on a currently not selected child.)
 *   deselectTarget: int (The index of the child to select if the currently selected child is deselected.)
 *   propagateChanges: bool (Should we propagate changes in our settings to a parent select-group controller if we are a member of a parent select-group?)
 * }
 * 
 * Each child participating in the select-group keeps this structure:
 * selectGroupMemberInfo: {
 *  contollerPathId: String (The index path of the container managing the single-select group.)
 *  selectedIndex: int (My index in the children's array of the container managing the single-select group.)
 * }
 * 
 * For children participating in a select-group in 'single-select-mode' the 'selected' attribute in the index path state is not relevant. 
 * It is 'shadowed' by the data kept in the selectGroupControllerState structure. 
 * 
 * For components participating in a select-group in 'single-select' mode the 'select-group' mechanism runs the following actions on behalf of the onClick handler of the component:
 *  - Set the 'selected' status of the component: The controller might decide to select another component than the one that runs the onClick handler.
 *  - Do the appropriate page switch: The controller will execute the page switch specified for the component that becomes actually selected.
 * 
 * For components participating in a select-group in 'single-select' mode the setSelected operator in the TermEvaluator will trigger the page switch configured for the actually selected component. 
 * (For other components the operator will not trigger a page switch configured for the component.) 
 * 
 */
export default class SelectGroupHelper {

  // methods for select group members:  ---------------------------------------------------------------------

  /**
   * Calculate the select flag for a member component by investigating the path state.
   * 
   * The method uses the select-group structures to determine the select status if the member component is controlled by a select-group controller in 'single-select' mode. 
   * Otherwise it falls back to the plain 'selected' flag in the path state of the child component. 
   */
  static extractSelectedState(pathState, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(pathState);
    if (selectGroupMemberInfo === undefined) {
      return StateAttributeAccess.extractSelected(pathState);
    }

    const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
    if (!controllerState.singleSelectActive) {
      return StateAttributeAccess.extractSelected(pathState);
    }

    return selectGroupMemberInfo.selectedIndex === controllerState.currentlySelected;

  }

  /**
   * Is the component member of a select-group (i.e. it is controlled by a select-group controller)?
   */
  static isSelectGroupMember(pathState) {
    return StateAttributeAccess.extractSelectGroupMemberInfo(pathState) !== undefined;
  }

  /**
   * Is the component controlled by a select-group controller in 'single-select' mode?
   */
  static isSingleSelectControlled(pathState, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(pathState);
    if (selectGroupMemberInfo === undefined) {
      return false;
    }

    const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
    return controllerState.singleSelectActive;
  }


  /**
   * Handle a request to set the 'selected' status for a component that might be controlled by a select-group controller in 'single-select' mode: 
   * Update the controller's state and the component's state in the ComponentStateManager and trigger rendering on an appropriate scope.
   * 
   * For components that are in fact controlled by a select-group controller in 'single-select' mode
   * the method also does the page switch for the actually selected component if the 'singleSelectWithPageSwitch' parameter is set to true.
   * 
   * Components control themselves which page switch they do if they happen to be not controlled by a select-group controller in 'single-select' mode: 
   * They should use doPageSwitchOrLetTheContainerDoIt(...) to trigger a page switch.
   * 
   * @param {boolean} requestedSelectState The 'selected' state that is requested for the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {*} controlledPathId The index path of the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {*} controlledPathState The path state of the component that might be controlled by a select-group controller in 'single-select' mode.
   * @param {boolean} singleSelectWithPageSwitch Should we do the page switches of the actually selected component (for single-select mode case only)?
   * @param {*} runtime The common runtime context structure.
   */
  static setSelectedForPossiblyControlledComponent(requestedSelectState, controlledPathId, controlledPathState, singleSelectWithPageSwitch, runtime) {
    const selectGroupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(controlledPathState);
    if (selectGroupMemberInfo === undefined) {
      // do standard selection flag setting for non-members:
      SelectGroupHelper.doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime);
    } else {
      const controllerState = StateAttributeAccess.extractSelectGroupControllerState(runtime.componentStateManager.findOrBuildStateForPathId(selectGroupMemberInfo.controllerPathId, runtime));
      // do nothing for members of a blocked group:
      if (!controllerState.selectionChangesBlocked) {
        if (SelectGroupHelper.isSingleSelectControlled(controlledPathState, runtime)) {
          // notify parent container and let it trigger the new rendering:
          const { controllerPathId, selectedIndex: newSelectedIndex } = selectGroupMemberInfo;
          SelectGroupHelper.delegateSetSelectedToController(
            requestedSelectState,
            newSelectedIndex,
            controllerPathId,
            controlledPathId,
            controlledPathState,
            singleSelectWithPageSwitch,
            runtime
          );
        } else {
          // do standard selection flag setting for members in a non-blocked multiple-select mode group:
          SelectGroupHelper.doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime);
        }
      }
    }
  }


  /**
   * Change the currently selected component in a select-group in 'single-select' mode.
   * 
   * The method manages the controller's state in the ComponentStateManager and registers the given state for the controlled component in the ComponentStateManager.
   * Finally it triggers a rendering on the controller level. 
   */
  static delegateSetSelectedToController(requestedSelectState, newSelectedIndex, controllerPathId, controlledPathId, controlledPathState, withPageSwitch, runtime) {

    const controllerState = runtime.componentStateManager.findOrBuildStateForPathId(controllerPathId, runtime);

    SelectGroupHelper.handleSelectedChildChanges(requestedSelectState, newSelectedIndex, controllerState, controllerPathId, withPageSwitch, runtime);

    runtime.componentStateManager.registerStateByPathId(controllerPathId, controllerState);
    // We should register the given controlled state in any case to have a consistent contract with the caller:
    if (controlledPathId !== undefined && controlledPathState !== undefined) {
      runtime.componentStateManager.registerStateByPathId(controlledPathId, controlledPathState);
    }
    RenderingHelper.triggerRenderingViaPath(controllerPathId, runtime);

  }

  /**
   * Do the standard page switch for a component if the component is not controlled by a select-group controller in 'single-select' mode. 
   */
  static doPageSwitchOrLetTheContainerDoIt(component, pathState) {
    if (!SelectGroupHelper.isSingleSelectControlled(pathState, component.props.runtime)) {
      CommonActionsHelper.doPageSwitchForComponent(component);
    }
  }

  // methods for state initialization ------------------------------------------------------------------------------------------

  /**
   * Build the select settings in the initial state for a possibly controlled component. 
   */
  static addSelectGroupMemberInfo(pathState, componentType, pathId, runtime) {

    if (
      (SelectGroupHelper.isControlledType(componentType) && SelectGroupHelper.isSelectGroupMemberPerConfig(pathId, runtime))
      || SelectGroupHelper.isComboBoxItem(componentType)
    ) {

      const controlledPageSegment = IndexPathHelper.getLastPageSegmentFromPath(pathId);
      const containerPageSegment = IndexPathHelper.dropIndexFromPageSegment(controlledPageSegment);
      if (containerPageSegment !== undefined) {
        const containerConfiguration = runtime.pageConfigurationsManager.findConfigurationForPageSegment(containerPageSegment).config;
        if (containerConfiguration.selectGroupMode !== undefined) {
          const containerPathId = IndexPathHelper.appendPageSegmentsToPath(IndexPathHelper.dropPageSegmentFromPath(pathId), containerPageSegment);
          const selectedIndex = parseInt(IndexPathHelper.getLastIndexFromPageSegment(controlledPageSegment), 10);
          const selectGroupMemberInfo = {
            controllerPathId: containerPathId,
            selectedIndex
          };
          StateAttributeAccess.setSelectGroupMemberInfo(pathState, selectGroupMemberInfo);
        }
      }
    }
  }

  /**
   * Build the select-group controller settings for a potential select-group controller component.
   */
  static addSelectGroupControllerState(pathState, configProps) {
    const config = configProps.selectGroupMode;
    if (config !== undefined) {
      const controllerState = {
        selectionChangesBlocked: config.blockSelectionChanges,
        singleSelectActive: config.enforceSingleSelect,
        currentlySelected: config.initiallySelected,
        allowDeselect: config.allowDeselect,
        deselectTarget: config.deselectTarget,
        propagateChanges: config.propagateChanges
      }
      StateAttributeAccess.setSelectGroupControllerState(pathState, controllerState);
    }
  }

  // methods for term evaluator ----------------------------------------------------------------------------------------------------------------------

  /**
   * Set the 'blockSelectionChanges' attribute in the select-group configuration for the group controller specified by the user defined id path.
   * 
   * The method propagates the value change to a parent select-group controller if the propagateChanges flag is set.
   */
  static setSelectionChangesBlockedForController(controllerUserDefPath, value, runtime) {
    SelectGroupHelper.setStateAttributeForControllerByUserDefPath(controllerUserDefPath, (controllerModeState) => { controllerModeState.selectionChangesBlocked = value; }, runtime);
  }

  /**
   * Set the 'singleSelectActive' attribute in the select-group configuration for the group controller specified by the user defined id path.
   * 
   * The method propagates the value change to a parent select-group controller if the propagateChanges flag is set.
   */
  static setSingleSelectActiveForController(controllerUserDefPath, value, runtime) {
    SelectGroupHelper.setStateAttributeForControllerByUserDefPath(controllerUserDefPath, (controllerModeState) => { controllerModeState.singleSelectActive = value; }, runtime);
  }

  // private stuff -----------------------------------------------------------------------------------------------------------------------------------

  /**
   * Private helper method.
   */
  static setStateAttributeForControllerByUserDefPath(controllerUserDefPath, controllerAttributeSetter, runtime) {
    const controllerIndexPath = PathTranslationHelper.getIndexPathForUserDefPath(controllerUserDefPath, runtime);
    SelectGroupHelper.setStateAttributeForControllerByIndexPath(controllerIndexPath, controllerAttributeSetter, runtime);
  }

  /**
   * Private helper method.
   */
  static setStateAttributeForControllerByIndexPath(controllerIndexPath, controllerAttributeSetter, runtime) {
    const { componentStateManager } = runtime;
    const fullControllerState = componentStateManager.findOrBuildStateForPathId(controllerIndexPath, runtime);
    const controllerMode = StateAttributeAccess.extractSelectGroupControllerState(fullControllerState);
    if (controllerMode === undefined) {
      console.log(`Ignored request to set controller mode for a component that isn't a select-group controller: ${controllerIndexPath}`);
      return;
    }
    const propagate = controllerMode.propagateChanges;
    const oldSingleSelectActive = controllerMode.singleSelectActive;
    const oldSelectedIndex = controllerMode.currentlySelected;

    controllerAttributeSetter(controllerMode);

    // Rearrange select flags of members and currentlySelected index in controller if we switch between single-select and multi-select mode:
    const newSingleSelectActive = controllerMode.singleSelectActive;
    if (newSingleSelectActive !== oldSingleSelectActive) {
      if (newSingleSelectActive === false) {
        // Set select flags to false for all members except the currently selected one according to the old selectedIndex.
        SelectGroupHelper.applyToMembers(
          controllerIndexPath,
          (fullMemberState, memberInfo) => {
            StateAttributeAccess.setSelected(fullMemberState, oldSelectedIndex === memberInfo.selectedIndex);
          },
          runtime
        );
      } else {
        // Set selected flags to false for all members.
        controllerMode.currentlySelected = undefined;
        SelectGroupHelper.applyToMembers(
          controllerIndexPath,
          (fullMemberState, memberInfo) => {
            StateAttributeAccess.setSelected(fullMemberState, false);
          },
          runtime
        );
      }
    }
    componentStateManager.registerStateByPathId(controllerIndexPath, fullControllerState, runtime);


    if (propagate === true) {
      const groupMemberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(fullControllerState);
      const parentControllerIndexPath = groupMemberInfo.controllerPathId;
      if (parentControllerIndexPath !== undefined) {
        this.setStateAttributeForControllerByIndexPath(parentControllerIndexPath, controllerAttributeSetter, runtime);
      }
    }
  }

  /**
   * Private helper method.
   */
  static applyToMembers(controllerIndexPath, methodToApply, runtime) {
    const { componentStateManager } = runtime;
    const memberPaths = ComponentStateHelper.findIndexPathsOfChildren(controllerIndexPath, runtime);
    memberPaths.forEach((memberPath) => {
      const fullMemberState = componentStateManager.findOrBuildStateForPathId(memberPath, runtime);
      const memberInfo = StateAttributeAccess.extractSelectGroupMemberInfo(fullMemberState);
      if (memberInfo !== undefined && memberInfo.controllerPathId === controllerIndexPath) {
        methodToApply(fullMemberState, memberInfo);
        componentStateManager.registerStateByPathId(memberPath, fullMemberState, runtime);
      }
    });
  }


  /**
   * Private helper method.
   * 
   */
  static handleSelectedChildChanges(requestedSelectState, clickedSelectedIndex, controllerState, controllerPath, withPageSwitch, runtime) {
    const selectGroupControllerState = StateAttributeAccess.extractSelectGroupControllerState(controllerState);
    const newIndexResult = SelectGroupHelper.calculateNewSelectedIndex(requestedSelectState, selectGroupControllerState, clickedSelectedIndex);

    if (newIndexResult.noChange === false) {
      const changedSelectedIndex = newIndexResult.newIndex;
      selectGroupControllerState.currentlySelected = changedSelectedIndex;
      StateAttributeAccess.setSelectGroupControllerState(controllerState, selectGroupControllerState);
    }

    // We have to do the page switches since we sometimes select another button 
    // than the one clicked on by the user. In such a case the button's onClick handler 
    // would do the wrong page switch.
    if (selectGroupControllerState.currentlySelected !== undefined && withPageSwitch) {
      SelectGroupHelper.doPageSwitchForSelectedSwitcher(controllerPath, selectGroupControllerState.currentlySelected, runtime);
    }

  }

  /**
   * Private helper method.
   * 
   * Calculate the new index of the currently selected component. 
   * The method returns 'undefined' if the currently selected component does not change.
   */
  static calculateNewSelectedIndex(requestedSelectState, controllerState, clickedSelectedIndex) {
    if (requestedSelectState === true) {
      if (controllerState.currentlySelected === clickedSelectedIndex) {
        // Component was selected and selected is requested -> signal no change:
        return {
          noChange: true,
        }
      } else {
        // Switching selection to another component is always possible:
        return {
          noChange: false,
          newIndex: clickedSelectedIndex
        };
      }
    } else if (controllerState.currentlySelected !== clickedSelectedIndex) {
      // Component was not selected and not selected is requested -> signal no change:
      return {
        noChange: true,
      };
    } else {
      // Component was selected and not selected is requested.
      const { allowDeselect, deselectTarget } = controllerState;
      if (!allowDeselect) {
        // Deselect is not allowed -> signal no change:
        return {
          noChange: true,
        };
      } else if (deselectTarget !== undefined) {
        // Jump to deselect target if we aren't there anyhow:
        if (deselectTarget === clickedSelectedIndex) {
          return {
            noChange: true,
          }
        } else {
          return {
            noChange: false,
            newIndex: deselectTarget
          }
        }
      } else {
        // No deselect target defined -> set currently selected to undefined
        return {
          noChange: false,
          newIndex: undefined
        }
      }
    }
  }

  /**
   * Private helper method.
   */
  static doPageSwitchForSelectedSwitcher(controllerPath, selectedSwitcherIndex, runtime) {
    // We don't do any switch if no switcher is selected:
    if (selectedSwitcherIndex !== undefined) {
      const selectedSwitcherPath = IndexPathHelper.appendIndexToPageSegment(controllerPath, selectedSwitcherIndex);

      const pathState = runtime.componentStateManager.findOrBuildStateForPathId(selectedSwitcherPath, runtime);
      const defaultLinkReceiver = StateAttributeAccess.extractDefaultLinkReceiver(pathState);

      const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(selectedSwitcherPath);
      const selectedSwitcherConfig = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);

      CommonActionsHelper.doPageSwitch(selectedSwitcherConfig.config.link, runtime, defaultLinkReceiver, controllerPath);
    }
  }

  /**
   * Private helper method.
   */
  static doStandardSelectFlagSetting(requestedSelectState, controlledPathId, controlledPathState, runtime) {
    StateAttributeAccess.setSelected(controlledPathState, requestedSelectState);
    runtime.componentStateManager.registerStateByPathId(controlledPathId, controlledPathState);
    RenderingHelper.triggerRenderingViaPath(controlledPathId, runtime);
  }

  static isControlledType(componentType) {
    return componentType === 'CbaRadioButton'
      || componentType === 'CbaCheckbox'
      || componentType === 'CbaButton'
      || componentType === 'CbaLink'
      || componentType === 'CbaRichTextField'
      || componentType === 'CbaPolygon'
      || componentType === 'CbaRegionMap';
  }

  static isComboBoxItem(componentType) {
    return componentType === CbaComboBox.getItemType();
  }

  static isSelectGroupMemberPerConfig(pathId, runtime) {
    const pageSegment = IndexPathHelper.getLastPageSegmentFromPath(pathId);
    const { config } = runtime.pageConfigurationsManager.findConfigurationForPageSegment(pageSegment);
    return config.selectGroupMember;
  }

}
