import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactMediaRecorder } from 'react-media-recorder';
import PropTypesHelper from '../PropTypesHelper';
import CommonActionsHelper from '../CommonActionsHelper';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import RenderingHelper from '../RenderingHelper';
import ComponentStateHelper from '../../state/ComponentStateHelper';
import StateAttributeAccess from '../../state/StateAttributeAccess';
import StateManagerHelper from '../../state/StateManagerHelper';
import IndexPathHelper from '../../state/IndexPathHelper';
import CbaInterpreter from '../CbaInterpreter';
import MediaPreview from './MediaPreview';
import PathTranslationHelper from '../../state/PathTranslationHelper';


export default class CbaMedia extends Component {

  constructor(props) {
    super(props);

    this.mediaRef = React.createRef();
    this.lastInternallyTriggeredHandler = undefined;
    this.startRecordingTime = undefined;
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
    const { runtime, config, path } = this.props;
    if (config.src !== undefined && config.src.dynamic !== undefined) {
      runtime.statemachinesManager.addVariableChangeObserver(path, config.src.dynamic.variable);
    }

    this.setVolumeFromState();
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
    const { runtime, config, path } = this.props;
    if (config.src !== undefined && config.src.dynamic !== undefined) {
      runtime.statemachinesManager.dropVariableChangeObserver(path, config.src.dynamic.variable);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  componentDidUpdate() {
    this.setVolumeFromState();
  }

  onClickHandler = (event) => {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onClickCapture = (event) => {
    // TODO: check if this is really needed otherwise remove it as it block access to text flash children
    // CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  onPlayHandler = (event) => {
    const { config, runtime } = this.props;
    this.traceAndSaveLastOperationIfExternalEvent('play', event);
    CommonActionsHelper.sendEvent(config.startEvent, runtime);
  }

  onPauseHandler = (event) => {
    const { config, runtime } = this.props;
    this.traceAndSaveLastOperationIfExternalEvent('pause', event);
    CommonActionsHelper.sendEvent(config.pauseEvent, runtime);
  }

  traceAndSaveLastOperationIfExternalEvent(operation, event) {
    // don't trace and update state if we were triggered internally:
    if (this.lastInternallyTriggeredHandler === undefined || this.lastInternallyTriggeredHandler !== operation) {
      this.saveInStateLastOperation(operation);
      this.traceMedia(operation, false, event);
    }
    this.lastInternallyTriggeredHandler = undefined;
  }

  onEndedHandler = (event) => {
    const operation = 'ended';
    const { config, runtime } = this.props;
    this.saveInStateLastOperation(operation);
    this.traceMedia(operation, false, event);
    this.increasePlayNumberAndCheckMaxPlay();
    CommonActionsHelper.sendEvent(config.stopEvent, runtime);
  }


  onVolumeChange = () => {
    const state = ComponentStateHelper.getState(this);
    // the mediaRef volume value is between [0,1] and the state media volume stores values between [0,10]
    StateAttributeAccess.setMediaVolume(state, parseInt(this.mediaRef.current.volume * 10, 10));
    ComponentStateHelper.registerState(this, state);
  }

  onTimeUpdate = () => {
    const { runtime } = this.props;
    const state = ComponentStateHelper.getState(this);
    const newTime = parseInt(this.mediaRef.current.currentTime, 10);
    const oldTime = StateAttributeAccess.extractCurrentTime(state);
    if (oldTime !== newTime) {
      StateAttributeAccess.setCurrentTime(state, newTime);
      runtime.eventEmitter.emit("currentTimeUpdate", newTime);
    }
    ComponentStateHelper.registerState(this, state);
  }

  play() {
    const { config } = this.props;
    const { recording } = config;
    const state = ComponentStateHelper.getState(this);
    const maxPlay = StateAttributeAccess.extractMaxPlay(state);
    const playNumber = StateAttributeAccess.extractPlayNumber(state);
    const maxRecord = StateAttributeAccess.extractMaxRecord(state);
    const recordNumber = StateAttributeAccess.extractRecordNumber(state);

    if (recording) {
      // when maxRecord is reached we should stop here
      if (maxRecord > 0 && recordNumber === maxRecord) {
        console.info("The max record number was reached!");
        return;
      }
      this.isRecording = true;
      this.startRecordingTime = moment().format();
      StateAttributeAccess.setRecordNumber(state, recordNumber + 1);
      ComponentStateHelper.registerState(this, state);
      RenderingHelper.triggerRendering(this);
    } else if (maxPlay > 0 && playNumber === maxPlay) {
      console.info("The max play number was reached!");
    } else {
      this.triggerEventOnPlayerAndTraceAndSaveOperation('play', 'play', (player) => { player.play(); });
    }
  }

  stop() {
    if (this.stopRecording) {
      this.stopRecording();
    } else {
      this.triggerEventOnPlayerAndTraceAndSaveOperation('stop', 'pause', (player) => { player.pause(); player.currentTime = 0; });
    }
  }

  pause() {
    if (this.pauseRecording) {
      this.pauseRecording();
      RenderingHelper.triggerRendering(this)
    } else {
      this.triggerEventOnPlayerAndTraceAndSaveOperation('pause', 'pause', (player) => { player.pause(); });
    }
  }

  triggerEventOnPlayerAndTraceAndSaveOperation(operation, triggeredHandler, eventMethod) {
    const player = this.mediaRef.current;
    if (player === null || player === undefined) {
      console.info("The player is not there (anymore); probably max play number was reached or there is nothing to play.");
    } else {
      // signal the triggered handler that we did the tracing and state update already:
      this.lastInternallyTriggeredHandler = triggeredHandler;
      eventMethod(player);
      this.saveInStateLastOperation(operation);
      this.traceMedia(operation, true, undefined);
    }
  }

  saveInStateLastOperation(operation) {
    const state = ComponentStateHelper.getState(this);
    StateAttributeAccess.setStateAttribute(state, 'lastOperation', operation);
    ComponentStateHelper.registerState(this, state);
  }

  setVolumeFromState() {
    const state = ComponentStateHelper.getState(this);

    const volume = StateAttributeAccess.extractMediaVolume(state);
    const newVolume = volume !== undefined ? CbaMedia.convertToMediaRefVolume(volume) : undefined;

    // mediaRef might refer to a simple div if there is nothing to play currently:
    if (newVolume !== undefined && this.mediaRef.current !== null) {
      this.mediaRef.current.volume = newVolume;
    }
  }

  increasePlayNumberAndCheckMaxPlay() {
    const state = ComponentStateHelper.getState(this);
    const maxPlay = StateAttributeAccess.extractMaxPlay(state);
    const playNo = StateAttributeAccess.extractPlayNumber(state);
    const currentPlayNumber = playNo + 1;
    StateAttributeAccess.setPlayNumber(state, currentPlayNumber);
    ComponentStateHelper.registerState(this, state);

    // when current play number equals maxPlay we need to disable media controls
    if (currentPlayNumber === maxPlay) {
      StateAttributeAccess.setHideControls(state, true);
      ComponentStateHelper.registerState(this, state);
      RenderingHelper.triggerRendering(this);
    }
  }

  traceMedia(operation, isStatemachineTriggered, event) {
    const { config, path, runtime } = this.props;
    const { trace } = config;
    const state = ComponentStateHelper.getState(this);
    const automaticStart = StateAttributeAccess.extractAutomaticStart(state);
    const maxPlay = StateAttributeAccess.extractMaxPlay(state);
    const hideControls = StateAttributeAccess.extractHideControls(state);
    const modifiedTraceConfig = StateManagerHelper.deepCopy(config.trace);

    if (trace.type !== undefined && trace.type !== 'ValueDisplay') {
      modifiedTraceConfig.type = `${trace.type}Control`;
    }

    CommonActionsHelper.traceUserInteractionPerTraceConfig(
      modifiedTraceConfig,
      path,
      trace.type === 'ValueDisplay' ? {} : {
        operation,
        maxPlay,
        currentPlayNo: StateAttributeAccess.extractPlayNumber(state),
        automaticStart,
        hideControls,
        volumeLevel: CbaMedia.computeVolumeInPercentage(StateAttributeAccess.extractMediaVolume(state)),
        isStatemachineTriggered
      },
      trace.type === 'ValueDisplay' ? event : undefined,
      runtime
    );

  }

  // ------------ media recorder methods ----------------

  /**
   * Creates a media recorder component and exposes callbacks to the current instance
   * @param {*} type The media component type
   * @param {*} mediaStyle The component style
   */
  createMediaRecorder(type, mediaStyle) {
    return (
      <ReactMediaRecorder
        audio
        video={type === "video"}
        render={({ status, startRecording, stopRecording, pauseRecording, resumeRecording, mediaBlobUrl, previewStream }) => {
          this.stopRecording = stopRecording;

          this.pauseRecording = () => {
            pauseRecording();
            this.shouldPause = true;
          }

          this.resumeRecording = () => {
            resumeRecording();
            this.shouldPause = false;
            if (type==="audio") {
              // we need to rerender the preview in order to have recording resume feedback
              RenderingHelper.triggerRendering(this)
            }
          }

          if (status === "idle") {
            // directly start the recording when the media recorder is mounted
            startRecording();
          }
          if (status === "stopped" && mediaBlobUrl) {
            // offsetting rerendering 
            window.setTimeout(() => {
              this.endRecording(mediaBlobUrl);
            }, 0)
          }

          return (
            <MediaPreview
              type={type}
              stream={status === "recording" ? previewStream : null}
              style={mediaStyle}
              pauseRecording={this.pauseRecording}
              resumeRecording={this.resumeRecording}
              shouldPause={this.shouldPause}
            />
          );
        }}
      />
    );
  }

  /**
   * Handles the end recording logic which will store the recorded blob inside the component state,
   * then passes it to the transfer mechanism for global storage.
   * @param {*} mediaBlob The recorded media blob URL
   */
  endRecording = (mediaBlob) => {
    const { runtime, config, path } = this.props;
    const pathState = ComponentStateHelper.getState(this);
    const recordNumber = StateAttributeAccess.extractRecordNumber(pathState);
    StateAttributeAccess.setMediaBlob(pathState, mediaBlob)
    ComponentStateHelper.registerState(this, pathState);

    const taskInfo = runtime.taskManager.getCurrentTestTaskItemNames();
    const userDefinedIDPath = PathTranslationHelper.getUserDefPathForIndexPath(path, runtime);
    runtime.recordingBuffer.reportRecording(config.userDefinedId, this.startRecordingTime, recordNumber, mediaBlob, taskInfo, userDefinedIDPath);

    this.isRecording = false;
    this.shouldPause = false;
    RenderingHelper.triggerRendering(this);
  }

  /**
   * Converts the config value to a range value supported by MediaRef.
   * @param volume 
   */
  static convertToMediaRefVolume(volume) {
    if (volume < 0) {
      volume = 0;
    } else if (volume > 10) {
      volume = 10;
    }
    return volume / 10;
  }

  static computeVolumeInPercentage(volume) {
    const volumeValue = parseInt(volume * 10, 10);
    return volumeValue;
  }

  static addAttributesToInitialState(initialState, configProps) {
    initialState.volume = configProps.useAudio ? 1 : 0;
    initialState.lastOperation = undefined;
    initialState.playNumber = 0;
    initialState.hideControls = configProps.hideControls;
    initialState.automaticStart = configProps.automaticStart;
    initialState.hideControls = configProps.hideControls;
    initialState.maxPlay = configProps.maxPlay;
    initialState.maxRecord = configProps.maxRecord;
    initialState.recordNumber = 0;
  }

  static createDelegateComponent(component, path, type) {
    const delegateComponent = StateManagerHelper.deepCopy(component);
    delegateComponent.config.userDefinedId = undefined;
    delegateComponent.type = type;
    const delegatePath = IndexPathHelper.appendIndexToPageSegment(path, 0);
    return {
      delegatePath, delegateComponent
    };
  }

  render() {
    const { config, runtime, path, orientation } = this.props;
    const { type, imageReference } = config;
    const state = ComponentStateHelper.getState(this);
    const muted = StateAttributeAccess.extractMediaVolume(state) <= 0;
    const automaticStart = StateAttributeAccess.extractAutomaticStart(state);
    const hideControls = StateAttributeAccess.extractHideControls(state);
    const mediaBlob = StateAttributeAccess.extractMediaBlob(state);
    const style = CommonConfigHelper.buildStyleByIndexPath(path, config, false, orientation, runtime);

    const srcFromVariable = (config.src !== undefined && config.src.dynamic !== undefined)
      ? CommonConfigHelper.getDynamicValueFromStatemachineAndMapper(config.src.dynamic, runtime)
      : undefined;
    // const src = mediaBlobUrl !== undefined ? mediaBlobUrl
    //   : CommonConfigHelper.getProperResourcePath((srcFromVariable === undefined || srcFromVariable.length === 0) ? config.src.mediaReference : srcFromVariable, runtime);

    const src = mediaBlob || CommonConfigHelper.getProperResourcePath((srcFromVariable === undefined || srcFromVariable.length === 0) ? config.src.mediaReference : srcFromVariable, runtime);
    const altSrc = (config.src.mediaAlternateRef) ? CommonConfigHelper.getProperResourcePath(config.src.mediaAlternateRef, runtime) : undefined;
    const extSrc = config.src.mediaReferenceExternal;
    const altExtSrc = config.src.mediaAlternateRefExternal;
    const poster = (imageReference === undefined || imageReference.length === 0) ? undefined : CommonConfigHelper.getProperResourcePath(imageReference, runtime);

    // Avoid blocking the loading mechanism if there is nothing to play currently:
    const someResourceToPlay = (
      (src !== undefined && src !== null && src.length > 0)
      || (altSrc !== undefined && altSrc.length > 0)
      || (extSrc !== undefined && extSrc.length > 0)
      || (altExtSrc !== undefined && altExtSrc.length > 0)
      || this.isRecording);

    // Make sure the audio/video reload each time the primary local resource changes:
    // (Alternate and external resources do not change dynamically.)
    const key = src;
    const mediaStyle = {
      width: '100%',
      height: '100%'
    }

    // we might have childrens only for video component
    const delegates = config.cbaChildren !== undefined ? config.cbaChildren.map((child, index) => {
      const childPath = IndexPathHelper.appendIndexToPageSegment(path, index);
      return (
        <CbaInterpreter
          key={childPath}
          config={child}
          path={childPath}
          runtime={runtime}
          orientation={orientation}
        />
      )
    }) : undefined;

    let media;
    if (this.isRecording) {
      media = this.createMediaRecorder(type, mediaStyle)
    } else if (type === 'audio') {
      media = (
        <audio
          ref={this.mediaRef}
          key={key}
          style={mediaStyle}
          controls={!hideControls}
          autoPlay={automaticStart}
          muted={muted}
          onClick={this.onClickHandler}
          onContextMenu={this.onContextMenuHandler}
          onPlay={this.onPlayHandler}
          onPause={this.onPauseHandler}
          onEnded={this.onEndedHandler}
          onVolumeChange={this.onVolumeChange}
          title={CommonConfigHelper.buildTitle(config)}
        >
          <source src={src} />
          {(altSrc) && <source src={altSrc} />}
          {(extSrc) && <source src={extSrc} />}
          {(altExtSrc) && <source src={altExtSrc} />}
          Your browser does not support the audio element.
        </audio>
      );
    } else if (type === 'video') {
      media = (
        <video
          ref={this.mediaRef}
          key={key}
          style={mediaStyle}
          controls={!hideControls}
          autoPlay={automaticStart}
          muted={muted}
          poster={poster}
          onClick={this.onClickHandler}
          onContextMenu={this.onContextMenuHandler}
          onPlay={this.onPlayHandler}
          onPause={this.onPauseHandler}
          onEnded={this.onEndedHandler}
          onVolumeChange={this.onVolumeChange}
          onTimeUpdate={this.onTimeUpdate}
          title={CommonConfigHelper.buildTitle(config)}
        >
          <source src={src} type="video/mp4" />
          {(altSrc) && <source src={altSrc} type="video/mp4" />}
          {(extSrc) && <source src={extSrc} type="video/mp4" />}
          {(altExtSrc) && <source src={altExtSrc} type="video/mp4" />}
          Your browser does not support the video element.
        </video>
      );
    }

    return (
      <div
        style={style}
        onClick={someResourceToPlay ? undefined : this.onClickHandler}
        onClickCapture={this.onClickCapture}
      >
        {someResourceToPlay && media}
        {delegates}
      </div>
    );
  }


}


CbaMedia.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.getStandardConfigPropTypes(false)
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}
