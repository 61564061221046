import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WebTab from './WebTab';

import './WebToolbar.css';
import PageHistoryHelper from '../PageHistoryHelper';
import RenderingHelper from '../RenderingHelper';
import WebToolbarHelper from './WebToolbarHelper';
import MenuItemTree from '../MenuItemTree';
import CommonConfigHelper from '../../config/CommonConfigHelper';
import CbaPageArea from '../CbaPageArea';

const webTabMaxWidth = 200;
const webToolbarDefaultWidth = 1000;
const showMoreButtonWidth = 40;

export default class WebToolbar extends Component {

  constructor() {
    super();

    this.tabContainerRef = React.createRef();
    this.tabDict = {};
    this.displayTabs = [];
    this.hiddenTabs = [];
  }

  componentDidUpdate() {
    this.updateTabDictionary();
  }

  updateTabDictionary = () => {
    const { parentPath, runtime } = this.props;
    const wrapper = this.tabContainerRef.current;
    const allTabs = PageHistoryHelper.getAllTabs(parentPath, runtime);

    if (wrapper) {
      const renderedTabs = this.getTabsArrayFromWrapper(wrapper);

      renderedTabs.forEach((el) => {
        this.tabDict[el.innerText] = {
          element: el,
          width: el.offsetWidth,
          index: allTabs.indexOf(el.innerText)
        }
      });
    }

    // fallback for unseen tabs
    allTabs.forEach((tab, tabIndex) => {
      if (!this.tabDict[tab]) {
        this.tabDict[tab] = {
          element: null,
          width: webTabMaxWidth,
          index: tabIndex
        }
      }
    });
  }

  closeTab = (event, tabName) => {
    event.preventDefault();
    event.stopPropagation();

    const { parentPath, runtime } = this.props;
    PageHistoryHelper.closeTab(tabName, parentPath, runtime);

    const currentTab = PageHistoryHelper.getTab(parentPath, runtime);
    const allTabs = PageHistoryHelper.getAllTabs(parentPath, runtime);

    this.displayTabs.splice(this.displayTabs.indexOf(tabName), 1);
    ({
      displayTabs: this.displayTabs,
      hiddenTabs: this.hiddenTabs
    } = WebToolbarHelper.topUpDisplayTabs(this.displayTabs, this.hiddenTabs, allTabs, currentTab, this.tabDict, this.getTabWrapperWidth()));
    this.triggerRendering();
  }

  switchTab = (event, tabName) => {
    const { parentPath, runtime } = this.props;
    const currentTab = PageHistoryHelper.getTab(parentPath, runtime);

    if (currentTab !== tabName) {
      CbaPageArea.switchTab(parentPath, tabName, event, runtime);
      this.triggerRendering();
    }
  }

  switchHiddenTab = (showMoreClickEvent, tab) => (tabClickEvent) => {
    this.switchTab(tabClickEvent, tab);
  }

  handleShowMoreTabs = (event) => {
    const { runtime, parentPath } = this.props;
    const buttonBoundingRect = MenuItemTree.buildTriggerBoundingRect(event);

    const menuItems = this.hiddenTabs.map(tab => ({
      type: 'action',
      label: tab,
      action: this.switchHiddenTab(event, tab),
      disabled: false,
      icon: PageHistoryHelper.getImageForTab(tab, parentPath, runtime)
    }));

    event.clientX = buttonBoundingRect.left;
    event.clientY = buttonBoundingRect.bottom;

    runtime.contextMenu.openMenuItemTreeWithDynamicConfig({
      entries: menuItems,
      event
    });
  }

  /**
   * Helper function to trigger rendering on both this component and it's parent (content update)
   */
  triggerRendering = () => {
    const { parentPath, runtime } = this.props;
    RenderingHelper.triggerRendering(this);
    RenderingHelper.triggerRenderingViaPath(parentPath, runtime);
  }

  /**
   * Get the optional icon resource to be displayed on the tab.
   */
  getTabIconResource = (tabName) => {
    const { parentPath, runtime } = this.props;
    const iconForTab = PageHistoryHelper.getImageForTab(tabName, parentPath, runtime);
    return CommonConfigHelper.getProperResourcePath(iconForTab, runtime);
  }

  renderTabs = (currentTab, allTabs) => allTabs.map(tab => (
    <WebTab
      key={tab}
      tabName={tab}
      isActive={tab === currentTab}
      closeTab={event => this.closeTab(event, tab)}
      onClick={event => this.switchTab(event, tab)}
      maxWidth={webTabMaxWidth}
      icon={this.getTabIconResource(tab)}
    />
  ));


  getTabsArrayFromWrapper = (wrapper) => {
    const childrenArray = [...wrapper.children];
    return childrenArray.filter((el, index) => index !== childrenArray.length - 1);
  }

  getTabWrapperWidth = () => {
    const wrapper = this.tabContainerRef.current;
    if (wrapper && wrapper.offsetWidth) {
      return wrapper.offsetWidth - showMoreButtonWidth;
    }

    return webToolbarDefaultWidth;
  }

  render() {
    const { parentPath, runtime } = this.props;
    const currentTab = PageHistoryHelper.getTab(parentPath, runtime);
    const allTabs = PageHistoryHelper.getAllTabs(parentPath, runtime);
    this.updateTabDictionary();

    const showMoreTabs = WebToolbarHelper.exceedsMaxTabsWidth(allTabs, this.tabDict, this.getTabWrapperWidth());

    if (showMoreTabs) {
      if (!this.displayTabs.includes(currentTab)) {
        ({
          displayTabs: this.displayTabs,
          hiddenTabs: this.hiddenTabs
        } = WebToolbarHelper.buildDisplayTabs(allTabs, currentTab, this.tabDict, this.getTabWrapperWidth()));

        ({
          displayTabs: this.displayTabs,
          hiddenTabs: this.hiddenTabs
        } = WebToolbarHelper.topUpDisplayTabs(this.displayTabs, this.hiddenTabs, allTabs, currentTab, this.tabDict, this.getTabWrapperWidth()));
      }
    } else {
      this.displayTabs = allTabs;
    }

    return (
      <div className="webtoolbar-wrap">
        <div className="webtoolbar-inner">
          <div ref={this.tabContainerRef} className="webtoolbar-tab-container">
            {this.renderTabs(currentTab, this.displayTabs)}
            <div className="webtoolbar-show-more">
              <i className={showMoreTabs ? "show" : ""} onClick={this.handleShowMoreTabs} />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

WebToolbar.propTypes = {
  parentPath: PropTypes.string.isRequired,
  runtime: PropTypes.object.isRequired
}
