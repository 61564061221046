import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesHelper from './PropTypesHelper';
import CommonActionsHelper from './CommonActionsHelper';
import CommonConfigHelper from '../config/CommonConfigHelper';
import RenderingHelper from './RenderingHelper';
import ComponentStateHelper from '../state/ComponentStateHelper';
import StateManagerHelper from '../state/StateManagerHelper';
import StateAttributeAccess from '../state/StateAttributeAccess';

export default class CbaRectangle extends Component {

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    RenderingHelper.onMount(this);
  }

  componentWillUnmount() {
    RenderingHelper.onUnmount(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    RenderingHelper.onReceiveProps(this, nextProps);
  }

  onClickHandler(event) {
    CommonActionsHelper.doStandardOnClick(event, undefined, this);
  }

  onContextMenuHandler = (event) => {
    CommonActionsHelper.doContextMenuOpen(this, event);
  }

  buildLineDiv(style, config) {
    return (
      <div
        onClick={this.onClickHandler}
        onContextMenu={this.onContextMenuHandler}
        title={CommonConfigHelper.buildTitle(config)}
        style={style}
        className="overflow-hidden"
      />
    )
  }

  render() {
    const { config, runtime, path: controllerPath, orientation } = this.props;

    const pathState = ComponentStateHelper.getState(this);
    const selectedState = StateAttributeAccess.extractSelected(pathState);

    const style = CommonConfigHelper.buildStyleByIndexPath(controllerPath, config, selectedState, orientation, runtime);

    const { position, lineWidth } = config;

    const northStyle = StateManagerHelper.deepCopy(style);
    const southStyle = StateManagerHelper.deepCopy(style);
    const westStyle = StateManagerHelper.deepCopy(style);
    const eastStyle = StateManagerHelper.deepCopy(style);

    const lineWidthString = `${lineWidth}px`;
    northStyle.height = lineWidthString
    southStyle.height = lineWidthString;
    westStyle.width = lineWidthString;
    eastStyle.width = lineWidthString;

    southStyle.top = `${position.y + position.height - lineWidth}px`;
    eastStyle.left = `${position.x + position.width - lineWidth}px`;

    return (
      <div>
        {this.buildLineDiv(northStyle, config)}
        {this.buildLineDiv(southStyle, config)}
        {this.buildLineDiv(eastStyle, config)}
        {this.buildLineDiv(westStyle, config)}
      </div>
    )
  }

}


CbaRectangle.propTypes = {
  runtime: PropTypes.shape(PropTypesHelper.getStandardRuntimePropTypes()).isRequired,
  path: PropTypes.string.isRequired,
  config: PropTypes.shape(
    PropTypesHelper.addPropTypes(
      PropTypesHelper.getStandardConfigPropTypes(false),
      {
        lineWidth: PropTypes.number.isRequired
      }
    )
  ).isRequired,
  orientation: PropTypes.string.isRequired,
}
