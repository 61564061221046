import { convertToRaw, SelectionState } from 'draft-js';
import Utils from '../../utils/Utils';
import CbaRichTextField from './CbaRichTextField';

/**
 * Helper mehtods used for the 'highlighting' implementation
 * in CbaRichTextField.
 */
export default class SelectionHelper {

  // ------------- editor state related methods ------------------------------------------------------------

  /**
   * Compare the texts inside the content structures of the given Draft editor states. 
   * The method will not take style ranges into account (e.g. highlighted areas do not matter here).
   */
  static contentStateEqual = (oldEditorState, newEditorState) => {
    const oldContentRaw = convertToRaw(oldEditorState.getCurrentContent());
    const newContentRaw = convertToRaw(newEditorState.getCurrentContent());

    for (let i = 0; i < oldContentRaw.blocks.length; i += 1) {
      if (oldContentRaw.blocks[i].key !== newContentRaw.blocks[i].key || oldContentRaw.blocks[i].text !== newContentRaw.blocks[i].text) {
        return false;
      }
    }

    return true;
  }

  /**
   * Is there a non-empty selection active in the given Draft editor state?
   */
  static isSelection(editorState) {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    return startOffset !== endOffset || startKey !== endKey;
  }

  static areEqual(selection1, selection2) {
    return selection1.startKey === selection2.startKey
      && selection1.startKey === selection2.startKey
      && selection1.endKey === selection2.endKey
      && selection1.startOffset === selection2.startOffset
      && selection1.endOffset === selection2.endOffset
      && selection1.currentHighlightKey === selection2.currentHighlightKey
  }

  /**
   * 
   * Serializes the selection from the editorState object
   * 
   * @param {EditorState} editorState 
   * @param {String} highlightKey 
   */
  static getSelection(editorState, highlightKey, blockKeysList) {
    const selection = editorState.getSelection();
    const editorContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(editorContent);

    const builtSelection = SelectionHelper.buildSelection(selection.getStartKey(), selection.getEndKey(),
      selection.getStartOffset(), selection.getEndOffset(), highlightKey);

    // Move start of selection to begining of next available block if start of selection is beyond the end of it's block
    while (rawContent.blocks[builtSelection.startKey].text.length === builtSelection.startOffset) {
      const startKeyIndex = blockKeysList.indexOf(builtSelection.startKey);
      if (blockKeysList[startKeyIndex + 1]) {
        builtSelection.startOffset = 0;
        builtSelection.startKey = blockKeysList[startKeyIndex + 1];
      } else break;

    }

    return builtSelection;
  }

  static buildSelection(startKey, endKey, startOffset, endOffset, highlightKey) {
    return {
      startKey, endKey, startOffset, endOffset, highlightKey
    }
  }

  /**
   * 
   * @param {*} selection1 
   * @param {*} selection2 
   * 
   * @returns {Object} interesction - intersection object
   * @returns {Boolean} intersection.isIntersect - 
   * @returns {String} intersection.type - one of ["left", "right", "cover", "inside", "none"]. Specifies the raport of selection 1 with selection 2
   */
  static getIntersect(selection1, selection2, blockKeysList) {
    let type = "";
    let isIntersect = true;

    const selection1StartKeyIndex = blockKeysList.indexOf(selection1.startKey);
    const selection2StartKeyIndex = blockKeysList.indexOf(selection2.startKey);
    const selection1EndKeyIndex = blockKeysList.indexOf(selection1.endKey);
    const selection2EndKeyIndex = blockKeysList.indexOf(selection2.endKey);

    const isSameRowStart = selection1StartKeyIndex === selection2StartKeyIndex;
    const isSameRowEnd = selection1EndKeyIndex === selection2EndKeyIndex;

    const isS1StartSameRowAsS2End = selection1StartKeyIndex === selection2EndKeyIndex;
    const isS1EndSameRowAsS2Start = selection1EndKeyIndex === selection2StartKeyIndex;

    const isSel1StartToLeftOfSel2 = selection1StartKeyIndex < selection2StartKeyIndex || (isSameRowStart && selection1.startOffset < selection2.startOffset);
    const isSel1EndToLeftOfSel2 = selection1EndKeyIndex < selection2StartKeyIndex || (isS1EndSameRowAsS2Start && selection1.endOffset < selection2.startOffset)
    const isSel1StartToRightOfSel2 = selection1StartKeyIndex > selection2EndKeyIndex || (isS1StartSameRowAsS2End && selection1.startOffset > selection2.endOffset)
    const isSel1EndToRightOfSel2 = selection1EndKeyIndex > selection2EndKeyIndex || (isSameRowEnd && selection1.endOffset > selection2.endOffset);

    if (isSel1StartToLeftOfSel2 && isSel1EndToRightOfSel2) {
      type = "cover";
    }

    if (!isSel1StartToLeftOfSel2 && !isSel1EndToRightOfSel2) {
      type = "inside";
    }

    if (isSel1StartToLeftOfSel2 && !isSel1EndToRightOfSel2) {
      type = "left";
    }

    if (!isSel1StartToLeftOfSel2 && isSel1EndToRightOfSel2) {
      type = "right";
    }

    if (!type || (isSel1StartToLeftOfSel2 && isSel1EndToLeftOfSel2) || (isSel1StartToRightOfSel2 && isSel1EndToRightOfSel2)) {
      type = "none";
      isIntersect = false;
    }

    return {
      type,
      isIntersect
    };
  }


  /*
   * Build a selections array from the 'HIGHLIGHT'-styled areas in the given Draft editor state.
   */
  static calculateSelectionsArray = (editorState, blockKeysList) => {
    const editorContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(editorContent);
    return SelectionHelper.calculateSelectionsArrayFromContentState(rawContent, blockKeysList);
  }


  // -------------- selection related methods ------------------------------------------------------------------

  /**
   * Create a new list of selections that contains all selections of the given list
   * excluding the given selection to be removed.
   * 
   * The method expects and returns selection objects with the four attributes startKey, startOffset, endKey, endOffset.
   */
  static removeSelectionFromSelectionsArray(selectionsArray, toRemove) {
    const selections = selectionsArray.slice();
    const filteredArray = selections.filter(item => !(
      toRemove.startOffset === item.startOffset
      && toRemove.endOffset === item.endOffset
      && toRemove.startKey === item.startKey
      && toRemove.endKey === item.endKey
      && toRemove.highlightKey === item.highlightKey))

    return filteredArray;
  }


  /**
   * Get the selection from the given selections that is hit be the given click.
   * 
   * The method expects and returns selection objects with the four attributes startKey, startOffset, endKey, endOffset.
   * 
   * The method returns undefined if the click does not hit any selection in the given selections array.
   */
  static getSelectionHitByClick(click, selections, keyRowOrder) {
    const clickedPosition = {
      rowKey: click.getStartKey(),
      pos: click.getStartOffset()
    }

    const filterConditions = selection => (
      SelectionHelper.isMultipleRow(selection)
        ? SelectionHelper.isSelectionHitByClickOnMultipleRows(clickedPosition, selection, keyRowOrder)
        : (
          SelectionHelper.isSelectionHitByClickInsideOneRow(clickedPosition, selection)
          || SelectionHelper.isSelectionHitByClickOnOneCharacter(clickedPosition, selection)
        )
    );

    const filteredSelections = selections.filter(selection => filterConditions(selection));

    const { length } = filteredSelections;

    return length > 0 ? filteredSelections[length - 1] : undefined;
  }


  /**
   * Clears the given selection object
   * Selection keeps the position of the cursor
   * @param {*} selection -> Given selection state object
   * @returns {*} SelectionState
   */
  static clearSelection(selection) {

    const focusKey = selection.getFocusKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const emptySelection = SelectionState.createEmpty(focusKey);

    if (selection.getIsBackward()) {
      return emptySelection.merge({
        focusOffset: startOffset,
        anchorOffset: startOffset
      });
    }

    return emptySelection.merge({
      focusOffset: endOffset,
      anchorOffset: endOffset
    });

  }

  // ---------------- blocks related methods ------------------------------------------------------------------

  /**
   * Get the list of block keys in the the order of the blocks appearing in the given blocks list, 
   * e.g. ["k0", "k1"] 
   */
  static getListOfBlockKeysInBlockOrder(blocks) {
    return blocks.map(block => block.key);
  }

  /**
   * Get a map from block-key to index-of-block for the given block keys list.
   */
  static getBlockKeysToBlockIndexMap(blockKeysList) {
    const keyToIndexMap = {};
    blockKeysList.forEach((element, index) => {
      keyToIndexMap[element] = index;
    });
    return keyToIndexMap;
  }

  /**
   * Transforms given Selections list to a list of DTOs for tracing
   * @param {Array} selections 
   */
  static TransformSelectionsToTraceSelectionsDTO(selections, path, runtime) {
    const traceSelectionsDTO = [];

    selections.forEach((selection) => {
      const { startKey, endKey, startOffset, endOffset, highlightKey } = selection;
      traceSelectionsDTO.push({
        startKey,
        endKey,
        startOffset,
        endOffset,
        highlightColor: CbaRichTextField.convertHighlightKeyToValue(highlightKey, path, runtime)
      })
    });

    return traceSelectionsDTO;
  }


  // ------------------ private stuff ----------------------------------------------------------
  //
  // All methods here expect and return selection objects with the four attributes startKey, startOffset, endKey, endOffset.
  // 

  /*
   * Build a selections array from the 'HIGHLIGHT'-styled areas in the given 
   * serializable content state (i.e. a RawDraftContentState).
   * 
   * The returned array contains selection objects with five attributes: startKey, startOffset, endKey, endOffset, highlightKey.
   */
  static calculateSelectionsArrayFromContentState = (rawContent, blockKeysList) => {
    const highlightSelections = {
      blockTextLengths: {},
      rawSelections: []
    }

    // Find all areas in the editor's blocks with 'HIGHLIGHT' style and collect them in the builtSelections structure:
    rawContent.blocks.forEach((block, blockIndex) => {
      // set block length
      highlightSelections.blockTextLengths[block.key] = block.text.length;
      block.inlineStyleRanges.forEach((inlineStyle) => {
        if (inlineStyle.style && inlineStyle.style.includes("HIGHLIGHT")) {
          highlightSelections.rawSelections.push({
            startKey: blockKeysList[blockIndex],
            endKey: blockKeysList[blockIndex],
            startOffset: inlineStyle.offset,
            endOffset: inlineStyle.offset + inlineStyle.length,
            highlightKey: inlineStyle.style
          });
        }
      });
    });

    // merge all selections that overlap
    return SelectionHelper.mergeRowSelections(highlightSelections.rawSelections, highlightSelections.blockTextLengths, blockKeysList);
  }


  /** 
   * Merge each group of contiguous (or even overlapping?) selections into a single selection. 
   * 
   * The method returns a new selections array with the merged selections (having attributes startKey, startOffset, endKey, endOffset).
   * 
   * @param {[*]} selections Array of selections to be merged. Each selection must have the four attributes startKey, startOffset, endKey, endOffset.
   * @param {*} blockTextLengths Map 'block key -> block's text length'
   * @param {[string]} keyRowOrder List of block keys in the order of block appearance in the editor content.
   */
  static mergeRowSelections(selections, blockTextLengths, keyRowOrder) {
    const blockKeyToIndexMap = SelectionHelper.getBlockKeysToBlockIndexMap(keyRowOrder);
    const selectionsMergedStepByStep = Utils.deepCopy(selections);

    for (let currentSelectionIndex = 0; currentSelectionIndex < selectionsMergedStepByStep.length - 1; currentSelectionIndex += 1) {
      const currentSelection = selectionsMergedStepByStep[currentSelectionIndex];
      const nextSelection = selectionsMergedStepByStep[currentSelectionIndex + 1];

      const isSelectionToEndOfBlock = currentSelection.endOffset === blockTextLengths[currentSelection.endKey];
      const isNextSelectionFromStart = (blockKeyToIndexMap[currentSelection.endKey] + 1) === blockKeyToIndexMap[nextSelection.startKey] && nextSelection.startOffset === 0;
      const isNextSelectionSameColor = currentSelection.highlightKey === nextSelection.highlightKey;

      if (isNextSelectionSameColor && isSelectionToEndOfBlock && isNextSelectionFromStart) {
        const newMergedSelection = {
          startKey: currentSelection.startKey,
          endKey: nextSelection.endKey,
          startOffset: currentSelection.startOffset,
          endOffset: nextSelection.endOffset,
          highlightKey: currentSelection.highlightKey
        }

        selectionsMergedStepByStep[currentSelectionIndex] = newMergedSelection;
        selectionsMergedStepByStep.splice(currentSelectionIndex + 1, 1);
        currentSelectionIndex -= 1;
      }
    }

    return selectionsMergedStepByStep;
  }

  /**
   * Does the given selection  span more than one row (i.e. block in the Draft's editor value model)?
   */
  static isMultipleRow = selection => selection.startKey !== selection.endKey

  /**
   * Does the given clicked position hit the given multiline selection?
   */
  static isSelectionHitByClickOnMultipleRows(clickedPosition, selection, keyRowOrder) {
    return (
      SelectionHelper.isTopRowClick(clickedPosition, selection)
      || SelectionHelper.isBottomRowClick(clickedPosition, selection)
      || SelectionHelper.isMiddleRowClick(clickedPosition, selection, keyRowOrder));
  }

  /**
   * Does the given clicked position hit a 'middle' row of the given multiline selection?
   * 
   * We accept a click between the characters of the selection but not before or after the selection.
   */
  static isMiddleRowClick(clickedPosition, selection, keyRowOrder) {
    const startIndex = keyRowOrder.indexOf(selection.startKey);
    const endIndex = keyRowOrder.indexOf(selection.endKey);

    for (let i = startIndex + 1; i < endIndex; i += 1) {
      if (keyRowOrder[i] === clickedPosition.rowKey) {
        return true;
      }
    }
    return false;
  }

  /**
   * Does the given clicked position hit the given multiline selection in its first row?
   * 
   * We accept a click between the characters of the selection but not before or after the selection.
   */
  static isTopRowClick(clickedPosition, selection) {
    return clickedPosition.rowKey === selection.startKey && clickedPosition.pos > selection.startOffset;
  }

  /**
   * Does the given clicked position hit the given multiline selection in its last row?
   * 
   * We accept a click between the characters of the selection but not before or after the selection.
   */
  static isBottomRowClick(clickedPosition, selection) {
    return clickedPosition.rowKey === selection.endKey && clickedPosition.pos < selection.endOffset;
  }

  /**
   * Does the given clicked position hit inside the given single row selection?
   * 
   * We accept a click between the characters of the selection but not before or after the selection.
   */
  static isSelectionHitByClickInsideOneRow(clickedPosition, selection) {
    return clickedPosition.pos > selection.startOffset
      && clickedPosition.pos < selection.endOffset
      && clickedPosition.rowKey === selection.startKey;
  }

  /**
   * Does the given clicked position hit the given single character selection?
   * 
   * We accept a click before and after the single character.
   */
  static isSelectionHitByClickOnOneCharacter(clickedPosition, selection) {
    return (clickedPosition.rowKey === selection.startKey && selection.endOffset - selection.startOffset === 1)
      && (clickedPosition.pos === selection.startOffset || clickedPosition.pos === selection.endOffset);
  }


}
