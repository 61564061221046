import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MathJaxTranspiler extends Component {

  componentDidMount() {
    if (!window.MathJax) {
      this.interval = window.setInterval(() => {
        const { MathJax } = window;

        if (MathJax) {
          this.setState(prevState => ({
            // eslint-disable-next-line react/no-unused-state
            update: prevState + 1
          }));

          clearInterval(this.interval);
        }
      }, 100);

      // Timeout for clearing interval if no library is found
      window.setTimeout(() => {
        if (!window.MathJax) {
          console.error("MathJax not found, check item dependency library");
          clearInterval(this.interval);
        }
      }, 10000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { MathJax } = window;
    const { tex } = this.props;

    if (!MathJax) {
      return <span>MathJax Not loaded</span>
    }

    MathJax.texReset();

    const result = MathJax.tex2chtml(tex);

    MathJax.startup.document.clear();
    MathJax.startup.document.updateDocument();

    return (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{
        __html: result.innerHTML
      }}
      />
    )
  }

}

MathJaxTranspiler.propTypes = {
  tex: PropTypes.string.isRequired
}
