import IndexPathHelper from './IndexPathHelper';
import ConditionalLinkHelper from '../eval/ConditionalLinkHelper';
import PageConfigurationsManager from '../config/PageConfigurationsManager';
import CalculationsConfigurationManager from '../config/CalculationsConfigurationManager';
import PresenterStateManager from './PresenterStateManager';
import TraceLogHelper from './TraceLogHelper';
import CbaPageArea from '../components/CbaPageArea';
import RenderingHelper from '../components/RenderingHelper';
import TermEvaluator from '../eval/TermEvaluator';
import PathTranslationHelper from './PathTranslationHelper';
import ValueMapper from '../eval/ValueMapper';
import UserDefPathHelper from './UserDefPathHelper';
import Utils from '../utils/Utils';

export default class TaskManager {

  /**
   * @param {*} runtime Access to the global services. 
   */
  constructor(runtime) {

    this.itemInfoEntries = [];
    this.handleTopLevelPageChange = undefined;
    this.switchCallback = undefined;
    this.availabilityCallback = undefined;
    this.runtime = runtime;

    this.activeTestName = undefined;
    this.activeItemName = undefined;
    this.activeTaskName = undefined;

    this.activeResourcePath = undefined;
    this.activeExternalResourcePath = undefined;
  }


  // -------- public API -------------------------------------------------------------------------------------

  /**
   * Set the callback that we will call each time a top level page changes.
   */
  setTopLevelPageChangeHandler = (topLevelPageChangeHandler) => {
    this.handleTopLevelPageChange = topLevelPageChangeHandler;
  }

  /**
   * Add an item to our item configurations array.
   */
  addItem = (itemConfiguration, resourcePath, externalResourcePath) => {
    this.itemInfoEntries.push({
      itemConfiguration, resourcePath, externalResourcePath
    });
  }


  /**
   * Clear all items in our item configurations array.
   */
  clearItems = () => {
    this.itemInfoEntries = [];
  }

  /**
   * Set the callback that we will use to trigger task switches.
   */
  setSwitchCallback = (switchCallback) => {
    this.switchCallback = switchCallback;
  }

  /**
   * Set the callback that we will ask about availability of task switches.
   */
  setAvailabilityCallback = (availabilityCallback) => {
    this.availabilityCallback = availabilityCallback;
  }


  /**
   * Trigger a switch to the first task with the given name inside a specific test (i.e. we ignore the source item).
   */
  switchFirstMatchingTaskInterTest = (newTestName, newTaskName) => {
    this.triggerGoToTask(newTestName, undefined, newTaskName);
  }

  /**
   * Trigger a switch to the first task with a matching name in the current test (i.e. we ignore the source item). 
   */
  switchFirstMatchingTaskIntraTest = (newTaskName) => {
    this.switchFirstMatchingTaskInterTest(this.activeTestName, newTaskName);
  };

  /**
   * Trigger a switch to the specified task in the specified test. 
   */
  switchTaskInterTest = (newTestName, newItemName, newTaskName) => {
    this.triggerGoToTask(newTestName, newItemName, newTaskName);
  }

  /**
   * Trigger a switch to the specified task in the current test. 
   */
  switchTaskIntraTest = (newItemName, newTaskName) => {
    this.triggerGoToTask(this.activeTestName, newItemName, newTaskName);
  }

  /**
   * Trigger a switch to another task inside the active item.
   */
  switchTaskIntraItem = (newTaskName) => {
    this.triggerGoToTask(this.activeTestName, this.activeItemName, newTaskName);
  }

  /**
   * Trigger a switch to the next task in our test course. 
   */
  switchTaskNext = () => {
    this.triggerTaskSwitch('nextTask', undefined, undefined, undefined);
  }

  /**
   * Could we currently perform a switch to the next task?
   */
  nextTaskAvailable = () => this.checkAvailableTask('nextTask', undefined, undefined, undefined);

  /**
   * Trigger a switch to the previous task in our test course.
   */
  switchTaskPrevious = () => {
    this.triggerTaskSwitch('previousTask', undefined, undefined, undefined);
  }

  /**
   * Could we currently perform a switch to the previous task?
   */
  previousTaskAvailable = () => this.checkAvailableTask('previousTask', undefined, undefined, undefined);

  /**
   * Trigger to cancel the currently running task.
   */
  cancelTask = () => {
    this.triggerTaskSwitch('cancelTask', undefined, undefined, undefined);
  }


  /**
   * Switch to another page inside the same item (no task switch).
   * 
   * This is a method combining all page switching types implied by the config.link configuration variants. 
   * // TODO: Simplify the link configuration structure and the TermEvaluator.switchPage operator parameters.
   * 
   * @param {String} newPageName The name of a default page to load into the page area if no conditional link is given or it does not return a page.
   * @param {Object} conditionalLink A conditional link configuration to be evaluated and to obtain a page to be loaded.
   * @param {String} pageUrl The URL to use for the new page.
   * @param {String} pageAreaType The type of the page area in the test presenter resp. containing the receiver.
   * @param {String} pageAreaName The name of the page area in the test presenter resp. containing the receiver.
   * @param {String} receiver The index path (without path root and page area type/name) of the CbaPageArea that should be modified.
   * @param {{name: String, image: String}} receiverTab The tab in the CbaPageArea to load the page into (optional). 
   * @param {String} historyMove The kind of 'move' in the page history: 'forward', 'back' or 'home'
   * @param {{x: int, y: int}} position The position where we open the dialog window (not used for pageAreaType='main'). 
   * If not specified the dialog will open at its previous position or centered if it was not opened before.
   */
  switchPage = (newPageName, conditionalLink, pageUrl, pageAreaType, pageAreaName, receiver, receiverTab, historyMove, position) => {
    if (receiver === undefined) {
      const evaluationResult = ConditionalLinkHelper.evaluateConditionalLink(conditionalLink, this.runtime);
      if (evaluationResult === undefined) {
        if (newPageName === undefined) {
          console.error(`No page chosen by link specification: default page: ${newPageName} conditionalLink: ${conditionalLink}`);
        } else {
          this.switchTopLevelPage(pageAreaType, pageAreaName, newPageName, position);
        }
      } else {
        this.switchTopLevelPage(
          evaluationResult.pageAreaType,
          evaluationResult.pageAreaName,
          evaluationResult.pageName,
          evaluationResult.position
        );
      }
    } else {
      const fullReceiverPath = IndexPathHelper.appendPageSegmentToPathRoot(this.getCurrentStatePathRoot(), pageAreaType, pageAreaName, receiver);
      if (historyMove === undefined) {
        const evaluationResult = ConditionalLinkHelper.evaluateConditionalLink(conditionalLink, this.runtime);
        if (evaluationResult === undefined) {
          if (newPageName === undefined) {
            console.error(`No page chosen by link specification: default page: ${newPageName} conditionalLink: ${conditionalLink}`);
          } else {
            this.switchEmbeddedPage(fullReceiverPath, newPageName, pageUrl, receiverTab);
          }
        } else {
          const { pageAreaType: evaluatedPageAreaType } = evaluationResult;
          if (evaluatedPageAreaType === 'main') {
            this.switchEmbeddedPage(fullReceiverPath, evaluationResult.pageName, evaluationResult.pageUrl, evaluationResult.receiverTab);
          } else {
            this.switchTopLevelPage(
              evaluatedPageAreaType,
              evaluationResult.pageAreaName,
              evaluationResult.pageName,
              evaluationResult.position
            );
          }
        }
      } else {
        this.doHistoryMove(fullReceiverPath, historyMove);
      }
    }
  }

  /**
   * Switch the top level page of a page area in the test presenter.
   * 
   * @param {String} pageAreaType The type of the page area in the test presenter.
   * @param {String} pageAreaName The name of the page area in the test presenter.
   * @param {String} newPageName The name of the page to load into the page area.
   * @param {{x: integer, y: interger}} position The position of the the page area (for 'dialog' page areas only).
   */
  switchTopLevelPage = (pageAreaType, pageAreaName, newPageName, position) => {
    this.runtime.traceLogBuffer.reportEvent('PageSwitchTopLevel', new Date(),
      {
        pageAreaType,
        pageAreaName,
        newPageName,
        position
      });

    TaskManager.preparePageSwitchInPresenterState(
      this.activeTestName, this.activeItemName, this.activeTaskName,
      pageAreaType, pageAreaName, newPageName, position,
      this.runtime.presenterStateManager
    );
    if (this.handleTopLevelPageChange !== undefined) {
      this.handleTopLevelPageChange();
    }
  }

  /**
   * Switch the currently embedded page in a CbaPageArea display component instance to an explicitly given page.
   * 
   * @param {String} fullReceiverPath The full index path of the CbaPageArea display component instance to modify.
   * @param {String} newPageName The name of the page to load into the page area.
   * @param {String} pageUrl The URL to use for the new page.
   * @param {{name: String, image: String}} receiverTab The tab in the CbaPageArea to load the page into (optional). 
   */
  switchEmbeddedPage = (fullReceiverPath, newPageName, pageUrl, receiverTab) => {
    const userDefIdPath = PathTranslationHelper.getUserDefPathForIndexPath(fullReceiverPath, this.runtime);
    this.runtime.traceLogBuffer.reportEvent('PageSwitchEmbedded', new Date(),
      {
        indexPath: fullReceiverPath,
        userDefIdPath,
        userDefId: UserDefPathHelper.getLastUserDefIdFromPath(userDefIdPath),
        tab: receiverTab === undefined ? undefined : receiverTab.name,
        newPageName
      });
    CbaPageArea.setPageName(fullReceiverPath, receiverTab, newPageName, pageUrl, this.runtime);
    RenderingHelper.triggerRenderingViaPath(fullReceiverPath, this.runtime);
  }

  /**
   * Switch the currently embedded page in a CbaPageArea to the next or previous page in the page history.
   * 
   * @param {String} fullReceiverPath The full index path of the CbaPageArea display component instance to modify.
   * @param {String} historyMove The kind of 'move' in the page history: 'forward', 'back' or 'home'
   */
  doHistoryMove = (fullReceiverPath, historyMove) => {
    CbaPageArea.doHistoryMove(fullReceiverPath, historyMove, this.runtime);
    RenderingHelper.triggerRenderingViaPath(fullReceiverPath, this.runtime);
  }

  /**
   * Get the path root for the current task (which is the same as the task ID returned by the methods above).
   */
  getCurrentStatePathRoot = () => IndexPathHelper.buildPathRoot(this.activeTestName, this.activeItemName, this.activeTaskName);

  /**
   * Get the path root for the given task (taking the current test and item for the other path components).
   */
  getStatePathRootForTask = taskName => IndexPathHelper.buildPathRoot(this.activeTestName, this.activeItemName, taskName);

  /**
   * The the names of the currently active test/item/task.
   */
  getCurrentTestTaskItemNames = () => ({
    test: this.activeTestName,
    item: this.activeItemName,
    task: this.activeTaskName
  });


  /**
   * Get the names of the pages currently displayed in the standard page area and the Xpage area.
   */
  getCurrentPageNames = () => {
    const taskId = IndexPathHelper.buildPathRoot(this.activeTestName, this.activeItemName, this.activeTaskName);
    const taskEntry = this.runtime.presenterStateManager.getTaskState(taskId);
    return {
      standardPage: taskEntry === undefined ? undefined : taskEntry.standardPage,
      xPage: taskEntry === undefined ? undefined : taskEntry.xPage
    }
  }


  /**
   * Calculate the results for all named calculations of the current task and save them in the task results manager. 
   */
  saveCurrentTaskResults = () => {
    const calculations = this.runtime.calculationsConfigurationManager.findAllCalculationsByTaskName(this.activeTaskName);
    this.runtime.taskResultsManager.saveTaskResults(this.getCurrentStatePathRoot(), calculations, this.runtime);
  }

  /**
   * Calculate the results for all named calculations of the current task, save them in the task results manager
   * and return them as an object.
   * The result object has one attribute per named calculation:
   * The attribute name is the calculation name, the attribute's value is the calculation result. 
   */
  getCurrentTaskResults = () => {
    this.saveCurrentTaskResults();
    return this.runtime.taskResultsManager.getResultsListForTask(this.getCurrentStatePathRoot());
  }


  /**
   * Calculate the scoring results as specified in the scoring results configuration.
   */
  getScoring = () => {

    const evaluationResult = {
      hitCalculations: this.buildScoringListEvaluationResult('hitList'),
      missCalculations: this.buildScoringListEvaluationResult('missList')
    }

    this.addScoringAttributesEvaluationResult(evaluationResult);

    return evaluationResult;
  }


  /*
  * Gets the top level configuration of the current item
  */
  getTopLevelConfiguration = () => this.runtime.presenterStateManager.getTaskState(this.getCurrentStatePathRoot());


  /**
   * Get the resource path for the currently active item.
   */
  getResourcePath = () => this.activeResourcePath;

  /**
   * Get the external resource path for the currently active item.
   */
  getExternalResourcePath = () => this.activeExternalResourcePath;

  /**
   * Switch to a new test/task setting.
   * 
   * The method returns the task ID or undefined if it could not do the switch.
   */
  switchTask = (newTestName, newItemName, newTaskName) => {
    const { runtime } = this;
    const isInitialTask = this.activeTaskName === undefined;
    if (!isInitialTask) {
      this.saveCurrentTaskResults();
      TraceLogHelper.dumpSnapshotToTrace(runtime);
    }

    // Make sure we know the new item and task:
    const itemInfo = TaskManager.getItemInfoForName(this.itemInfoEntries, newItemName);
    if (itemInfo === undefined) {
      return undefined;
    }
    const { itemConfiguration } = itemInfo;

    const newTask = TaskManager.getTaskForName(itemConfiguration, newTaskName);
    if (newTask === undefined) {
      return undefined;
    }
    const newTaskId = IndexPathHelper.buildPathRoot(newTestName, newItemName, newTaskName);

    // Do the switch: We cannot bail out now anymore...


    // Trace task switch
    runtime.traceLogBuffer.reportEvent('TaskSwitch', new Date(),
      {
        oldTask: this.activeTaskName,
        oldItem: this.activeItemName,
        oldTest: this.activeTestName,
        newTask: newTaskName,
        newItem: newItemName,
        newTest: newTestName,
        taskResult: runtime.taskResultsManager.getResultsListForTask(this.getCurrentStatePathRoot()),
      });

    // Switch configurations managers to new item if necessary:
    if (newItemName !== this.activeItemName) {
      runtime.pageConfigurationsManager = new PageConfigurationsManager(itemConfiguration);
      runtime.calculationsConfigurationManager = new CalculationsConfigurationManager(itemConfiguration);
      runtime.valueMapper = new ValueMapper(itemConfiguration, runtime);
      runtime.traceLogBuffer.reportEvent('ItemSwitch', new Date(), {
        item: itemConfiguration,
      })
      this.activeResourcePath = itemInfo.resourcePath;
      this.activeExternalResourcePath = itemInfo.externalResourcePath;
    }

    this.activeTestName = newTestName;
    this.activeItemName = newItemName;
    this.activeTaskName = newTaskName;

    runtime.incidentsAccumulator.enterTask(newTaskId, new Date().getTime());

    runtime.statemachinesManager.stopCurrentStatemachine();
    TaskManager.prepareTaskSwitchInPresenterAndNavigatorState(newTestName, newItemName, newTaskName, newTaskId, newTask, runtime);
    runtime.calculatorsManager.setOrInitializeCurrentCalculator(newTaskId, runtime);
    runtime.statemachinesManager.startOrInitializeCurrentStatemachine(newTaskId, itemConfiguration.statemachine, runtime);

    return newTaskId;
  }


  /**
   * Get the full state for all existing tasks.
   * 
   * Use the result of this method as parameter to preloadTasksState to preload another instance to our current state. 
   */
  getAllTasksState = () => {
    const {
      componentStateManager,
      componentDirectory,
      statemachinesManager,
      incidentsAccumulator,
      presenterStateManager,
      taskResultsManager
    } = this.runtime;

    return {
      componentsState: componentStateManager.getStateSnapshot(componentDirectory),
      statemachines: statemachinesManager.getStatemachinesPreloadData(),
      incidents: incidentsAccumulator.getAllTasksState(),
      presenterState: presenterStateManager.getAllTasksState(),
      taskResults: taskResultsManager.getAllTasksState()
    }
  };

  /**
   * Clear the current state of all state managers.
   */
  clearTasksState = () => {
    const {
      componentStateManager,
      statemachinesManager,
      incidentsAccumulator,
      presenterStateManager,
      taskResultsManager
    } = this.runtime;
    componentStateManager.clear();
    statemachinesManager.clearStatemachines();
    incidentsAccumulator.clearTasksState();
    presenterStateManager.clearTasksState();
    taskResultsManager.clearTasksState();
  }

  /**
   * Preload the state managers with the state returned by a call to getAllTasksState.
   */
  preloadTasksState = (allTasksState) => {
    const {
      componentStateManager,
      statemachinesManager,
      incidentsAccumulator,
      presenterStateManager,
      taskResultsManager
    } = this.runtime;
    componentStateManager.preloadWithStateSnapshot(allTasksState.componentsState);
    statemachinesManager.preloadStatemachinesData(allTasksState.statemachines, this.runtime);
    incidentsAccumulator.preloadTasksState(allTasksState.incidents);
    presenterStateManager.preloadTasksState(allTasksState.presenterState);
    taskResultsManager.preloadTasksState(allTasksState.taskResults);
  }

  /**
   * 
   * @param {string} itemName Name of the item.
   * 
   * @returns {Array} Item resources
   */
  getItemResources = (itemName) => {
    const itemInfo = this.getItemInfo(itemName);

    if (!itemInfo) {
      console.error("Could not find item", itemName);
      return null;
    }
    const { itemConfiguration, externalResourcePath, resourcePath } = itemInfo;
    const { usedResources } = itemConfiguration;

    const externalResources = Utils.mapResourcePath(usedResources.externalResources, externalResourcePath, true);
    const internalResources = Utils.mapResourcePath(usedResources.resources, resourcePath, false);
    const resources = externalResources.concat(internalResources);

    return resources;
  }

  /**
   * 
   * @param {*} itemName Name of the item.
   * 
   * @returns {*} Item configuration object.
   */
  getItemInfo = itemName => this.itemInfoEntries.find(itemInfoEntry => itemInfoEntry.itemConfiguration.name === itemName);

  // --- private stuff -------------------------------------------------------------------------

  /**
   * Check the availability of a task switch using the availabilty callback.
   */
  checkAvailableTask = (requestType, newTestName, newItemName, newTaskName) => {
    const callback = this.availabilityCallback;
    return (callback !== undefined
      ? callback(requestType, newTestName, newItemName, newTaskName)
      : false);
  }

  /**
   * Trigger a task switch to the specified task using the swich callback.
   */
  triggerGoToTask = (newTestName, newItemName, newTaskName) => {
    this.triggerTaskSwitch('goToTask', newTestName, newItemName, newTaskName);
  }

  /**
   * Trigger a task switch using the swich callback.
   */
  triggerTaskSwitch = (requestType, newTestName, newItemName, newTaskName) => {
    const callback = this.switchCallback;
    if (callback !== undefined) {
      callback(requestType, newTestName, newItemName, newTaskName);
    }
  }

  /**
   * Evaluate the given conditional link and return the calculated target page name. 
   * 
   * The method returns the given default page name if there is no conditional link 
   * or none of the guard conditions evaluate to true.
   */
  static evaluateConditionalLink(defaultPageName, conditionalLink, runtime) {
    if (conditionalLink === undefined) return defaultPageName;

    const conditionalLinkResult = ConditionalLinkHelper.evaluateConditionalLink(conditionalLink, runtime);
    return conditionalLinkResult === undefined ? defaultPageName : conditionalLinkResult;
  }


  /**
   * Get the item info object for the specified item.
   * 
   * The method returns the item info object, i.e. an object with attributes 
   *  - itemConfiguration (which has attributes name, pages, statemachine, tasks)
   *  - resourcePath
   *  - externalResourcePath
   */
  static getItemInfoForName(items, itemName) {
    const result = items.find((value, index, theArray) => value.itemConfiguration.name === itemName);
    if (result === undefined) {
      console.error(`Could not find info for item ${itemName}`);
    }
    return result;
  }


  /**
   * Get the task configuration object for the specified task defined by the specified 
   * item configuration object.
   * 
   * The method returns the task configuration object, i.e. an object with attributes 
   *  - name
   *  - initialPage
   *  - itemWidth
   *  - ...
   */
  static getTaskForName(item, taskName) {
    const result = item.tasks.find((value, index, theArray) => value.name === taskName);
    if (result === undefined) {
      console.error(`Could not find task ${taskName} in item ${item.name}`);
    }
    return result;
  }


  /**
   * Change the state of the given test and task in the TaskNavigatorStateManager and the PresenterStateManager
   * according to the current task switch.
   * 
   * The method will always set the new item/task name in the test state.
   * The method will not change an already existing task state but will create an initial task state if there is none yet.
   * To build the initial task state it will evaluate the task initialization rule.
   */
  static prepareTaskSwitchInPresenterAndNavigatorState(
    testName, itemName, taskName,
    taskId, task, runtime
  ) {
    const { taskNavigatorStateManager, presenterStateManager } = runtime;
    taskNavigatorStateManager.saveTestState(
      testName,
      {
        itemName,
        taskName,
      }
    );

    const oldTaskEntry = presenterStateManager.getTaskState(taskId);
    if (oldTaskEntry === undefined) {
      const initialTaskEntry = PresenterStateManager.buildInitialTaskStateObject(
        task.initialPage, task.initialXPage,
        task.itemWidth, task.itemHeight, task.itemLayout,
        task.withEditContextMenu, task.itemHighlightColor, task.highlightColors
      );
      presenterStateManager.saveTaskState(taskId, initialTaskEntry);

      const conditionResult = ConditionalLinkHelper.evaluateConditionalLink(task.initRule, runtime);
      if (conditionResult !== undefined) {
        const afterConditionEvaluationTaskEntry = presenterStateManager.getTaskState(taskId);
        PresenterStateManager.setPageForPageAreaInTaskState(
          conditionResult.pageName,
          conditionResult.position,
          conditionResult.pageAreaType,
          conditionResult.pageAreaName,
          afterConditionEvaluationTaskEntry
        );
        presenterStateManager.saveTaskState(taskId, afterConditionEvaluationTaskEntry);
      }
    }
  }


  /**
   * Change the state of the current task in the PresenterStateManager
   * according to the current page switch.
   * 
   * The method will set the new page (and for dialogs the new position) in the task state.
   */
  static preparePageSwitchInPresenterState(testName, itemName, taskName, pageAreaType, pageAreaName, newPage, position, presenterStateManager) {
    const taskId = IndexPathHelper.buildPathRoot(testName, itemName, taskName);
    const taskEntry = presenterStateManager.getTaskState(taskId);
    if (taskEntry === undefined) {
      console.error(`Switch to page ${newPage} for not existing task: ${taskId}`);
    } else {
      PresenterStateManager.setPageForPageAreaInTaskState(newPage, position, pageAreaType, pageAreaName, taskEntry);
      presenterStateManager.saveTaskState(taskId, taskEntry);
    }
  }


  /**
   * Helper method that calculates the attributes of a scoring result configuration.
   */
  addScoringAttributesEvaluationResult = (evaluationResult) => {
    const attributes = this.runtime.calculationsConfigurationManager.findScoreResultAttributesByTaskName(this.activeTaskName);
    Object.keys(attributes).forEach((key) => {
      evaluationResult[key] = TermEvaluator.evaluateTerm(attributes[key], this.runtime, [], key);
    });
  }

  /**
   * Helper method that calculates the elements of a scoring results list.
   */
  buildScoringListEvaluationResult = (scoringListName) => {
    const scoringList = this.runtime.calculationsConfigurationManager.findScoreResultListByTaskName(this.activeTaskName, scoringListName);

    const resultRows = [];
    scoringList.forEach((calculation) => {
      if (TermEvaluator.evaluateTerm(calculation.result, this.runtime, [], calculation.name)) {
        const resultText = TermEvaluator.evaluateTerm(calculation.resultText, this.runtime, [], `${calculation.name}_text`);
        resultRows.push({
          name: calculation.name,
          weight: calculation.weight,
          class: calculation.class,
          resultText
        });
      }
    });
    return resultRows;
  }

}
